import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAdressKeyItemDM from './TAdressKeyItemDM'

export default class TAdressKeyDM extends Data {
	public static className = 'TAdressKeyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'Timestamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreatedOn?: Date
	Timestamp?: string
	ChangedOn?: Date
	Description?: string
	Abbr?: string
	SortingKeyTypeId?: number
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ConfirmedBitCalc?: number
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TAdressKeyItemDM)
	SortingKeyItemChild?: TAdressKeyItemDM[]
}