import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrCalendarDM from './TPrCalendarDM'
import TBookDM from './TBookDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TContactPersonDM from './TContactPersonDM'

export default class TAttendancePersonWHModelItemDM extends Data {
	public static className = 'TAttendancePersonWHModelItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DateTo?: Date
	DateFrom?: Date
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId?: TPrCalendarDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TPrCalendarDM)
	WHModelId?: TPrCalendarDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
}