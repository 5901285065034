import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TAddressDM from './TAddressDM'
import StockType from './StockType'
import TSPSPostingKindDM from './TSPSPostingKindDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingChartDM from './TAccountingChartDM'
import TLocationArticleDM from './TLocationArticleDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TWarehouseItemDM from './TWarehouseItemDM'
import TWarehouseSummationDM from './TWarehouseSummationDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TWarehouseDM extends Data {
	public static className = 'TWarehouseDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	Settings?: string
	CompanyId?: number
	BlockingDate?: Date
	TimeStamp?: string
	RightGroupId?: number
	Transfer_F?: number
	ChangedOn?: Date
	WarehousemanId?: number
	Area?: string
	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	WarehouseTypeImgCalc?: string
	AccountWarehouseImgCalc?: string
	BlockedMovesImgCalc?: string
	LanguageTextCalc?: string
	IsDefaultAccountingWarehouseCalc?: string
	IsSummingWarehouseCalc?: string
	SummingBitCalc?: number
	AllStockMovementsBitCalc?: number
	ReceiptCardReceiptBitCalc?: number
	ReceiptCardReleaseBitCalc?: number
	ReleaseNoteReceiptBitCalc?: number
	ReleaseNoteReleaseBitCalc?: number
	TransferNoteReceiptBitCalc?: number
	TransferNoteReleaseBitCalc?: number
	AccountingWarehouseBitCalc?: number
	LocationEvidenceBitCalc?: number
	ContractCodeEvidenceBitCalc?: number
	JobCardReceiptBitCalc?: number
	JobCardReleaseBitCalc?: number
	LoadWthWrhToResAndOrdBitCalc?: number
	HandlingUnitRecordsBitCalc?: number
	Automat1IdCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	SumCalculationModeId?: TTextDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => StockType)
	WarehouseTypeId?: StockType
	@Type(() => TTextDM)
	StockPriceTypeId?: TTextDM
	@Type(() => TSPSPostingKindDM)
	PostingKindId?: TSPSPostingKindDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TLocationArticleDM)
	SurplusLocationIdCalc?: TLocationArticleDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TWarehouseZoneDM)
	ZoneChild?: TWarehouseZoneDM[]
	@Type(() => TWarehouseItemDM)
	ItemChild?: TWarehouseItemDM[]
	@Type(() => TWarehouseSummationDM)
	SummationChild?: TWarehouseSummationDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}