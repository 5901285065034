import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'

export default class TWorkflowWorkStepInProcessDM extends Data {
	public static className = 'TWorkflowWorkStepInProcessDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	IsInvalidRecord?: string
	WorkStepUserRID?: number
	OrgStrRID?: number
	DeadlineDate?: Date
	CompletedDate?: Date
	DefaultWorkflowProcedureStepId?: number
	StepDescription?: string
	ProductNumber?: number
	ProductTypeId?: number
	ProductBook?: string
	ProductTableNumber?: number
	Comment?: string
	SubordinateProcessId?: number
	WorkStepVersion?: number
	SequenceNumber?: number
	RoleId?: number
	WorkStepId?: number
	Text?: number
	SortDate?: string
	Header?: number
	StepName?: string
	MemoRID?: number
	Date?: Date
	WorkStepUserId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsTextImgCalc?: string
	IsFulfillExpiredCalc?: string
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM
	@Type(() => TTextDM)
	StepTypeId?: TTextDM
	@Type(() => TTextDM)
	StatusId?: TTextDM
	@Type(() => TContactPersonDM)
	OwnerUserId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	SentUserId?: TContactPersonDM
}