import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import PrCalTyp from './PrCalTyp'
import TPrPeriodDM from './TPrPeriodDM'
import TCommentDM from './TCommentDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TPrCalendarDefinitionDM from './TPrCalendarDefinitionDM'
import TPrCalendarItemTimeDM from './TPrCalendarItemTimeDM'
import TCodeCalendarItemDayDM from './TCodeCalendarItemDayDM'
import TPrCalendarItemDurationDM from './TPrCalendarItemDurationDM'
import TCodeCalendarOverlayDM from './TCodeCalendarOverlayDM'
import TPrCalendarItemVisualDM from './TPrCalendarItemVisualDM'

export default class TPrCalendarDM extends Data {
	public static className = 'TPrCalendarDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	BoolValue3?: string
	BoolValue2?: string
	BoolValue1?: string
	AdditionalParameters?: string
	CompanyId?: number
	BigintValue4?: number
	BigintValue3?: number
	BigintValue2?: number
	BigintValue1?: number
	RealValue2?: number
	RealValue1?: number
	TimeStamp?: string
	ConfirmedOn?: Date
	ValidTo?: number
	ValidFrom?: number
	ChangedOn?: Date
	TypeId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	CalendarActualDateCalc?: Date
	LastShiftDateCalc?: Date
	ValidFromDateCalc?: Date
	ValidToDateCalc?: Date
	TotalShiftHoursCalc?: number
	HolidayHourCountCalc?: number
	TotalShiftDaysCalc?: number
	HolidayDayCountCalc?: number
	ActualWeekCalc?: number
	WeekTotalShiftHourCalc?: number
	WeekShiftHourInPublicHlCalc?: number
	WeekTotalShiftDayCalc?: number
	WeekShiftDayInPublicHlCalc?: number
	ActualYearCalc?: number
	YearTotalShiftHourCalc?: number
	YearShiftHoursInPublicHlCalc?: number
	YearTotalShiftDayCalc?: number
	YearShiftDayInPublicHlCalc?: number
	ActualWeekYearCalc?: number
	ActualMonthCalc?: number
	MonthShiftTimeCountCalc?: number
	PublicHlMonthShiftHoursCalc?: number
	MonthShiftDayCountCalc?: number
	PublicHlMonthShiftDayCountCalc?: number
	ClosedCalendarBitCalc?: number
	UseAttendanceScheduleCalc?: string
	RangeFromDateCalc?: Date
	RangeToDateCalc?: Date
	BlockingDateCalc?: Date
	BlockedRecordBitCalc?: number
	WeekWorkingHourCalc?: number
	DailyWorkingHourCalc?: number
	BreakDurationCalc?: number
	ShiftsWeekNumberCalc?: number
	IsFactorySettingsCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	LongValue?: TTextDM
	@Type(() => TTextDM)
	ShortValue?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedbyId?: TK2UserLookupDM
	@Type(() => PrCalTyp)
	UserTypeId?: PrCalTyp
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeChild?: TAttendanceWorkShiftTypeDM[]
	@Type(() => TPrCalendarDefinitionDM)
	DefinitionChild?: TPrCalendarDefinitionDM[]
	@Type(() => TPrCalendarItemTimeDM)
	TimeItemChild?: TPrCalendarItemTimeDM[]
	@Type(() => TCodeCalendarItemDayDM)
	DayItemChild?: TCodeCalendarItemDayDM[]
	@Type(() => TPrCalendarItemDurationDM)
	TimePeriodItemChild?: TPrCalendarItemDurationDM[]
	@Type(() => TCodeCalendarOverlayDM)
	CalendarOverlayChild?: TCodeCalendarOverlayDM[]
	@Type(() => TPrCalendarItemVisualDM)
	FragmentItemChild?: TPrCalendarItemVisualDM[]
}