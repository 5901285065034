import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPStatus from './TPStatus'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TWarehouseDM from './TWarehouseDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

export default class TArticleRoutingVariantDM extends Data {
	public static className = 'TArticleRoutingVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	BlockTradeDocuments?: string
	BlockJobCards?: string
	IsInvalidRecord?: string
	IsIncompleteRouting?: string
	MeasureUnitId?: number
	SerieTo?: number
	SerieFrom?: number
	TimeStamp?: string
	ArticleName?: string
	ArticleAbbr2?: string
	ArticleAbbr1?: string
	CreatedOn?: Date
	RightGroupId?: number
	LeadTime?: number
	StockPrice?: number
	ProductionLot?: number
	CalculationLot?: number
	ValidTo?: Date
	ValidFrom?: Date
	Weight?: number
	Description?: string
	ItemCount?: number
	ChangedOn?: Date
	Drawing?: string
	Quantity?: number
	Abbr?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TotalWeightCalc?: number
	TotalPriceCalc?: number
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPStatus)
	StatusId?: TPStatus
	@Type(() => TOfficerDocumentDM)
	BOMOfficerId?: TOfficerDocumentDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TRoutingVariantDM)
	Id?: TRoutingVariantDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
}