import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TOfficerDM from './TOfficerDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TitleName from './TitleName'
import TK2UserLookupDM from './TK2UserLookupDM'
import PositionName from './PositionName'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemAnalyticalDimensionDM from './TSalesItemAnalyticalDimensionDM'
import TPurchaseItemAnalyticalDimensionDM from './TPurchaseItemAnalyticalDimensionDM'
import TPaymentDocumentItemAnalyticalDimensionDM from './TPaymentDocumentItemAnalyticalDimensionDM'
import TAccountingJournalItemAnalyticalDimensionDM from './TAccountingJournalItemAnalyticalDimensionDM'
import TAssetMovementAnalyticalDimensionDM from './TAssetMovementAnalyticalDimensionDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TOfficerDocumentDM extends Data {
	public static className = 'TOfficerDocumentDM'
	public static primaryKey = 'OfficerId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.OfficerId!
	}

	HasAttendanceWHModel?: string
	IsAttendancePerson?: string
	ForeignFoodAllowanceRate?: number
	SequenceNumber?: number
	IsBlockedRecord?: string
	NotInsertIntoDocument?: string
	NotLoadItemsAutomatically?: string
	NotEvaluateAutomatically?: string
	Abbr?: string
	EshopGroupId?: number
	ContactPersonGroupId?: number
	TimeStamp?: string
	FormatId?: number
	Born?: string
	RightGroupId?: number
	FunctionId?: number
	Comment?: string
	AcquisitionId?: number
	SatisfactionId?: number
	NextPhoneCallDate?: Date
	LastPhoneCallDate?: Date
	NextVisitDate?: Date
	LastVisitDate?: Date
	PhoneToTime?: Date
	PhoneSinceTime?: Date
	PhoneDay?: number
	VisitToTime?: Date
	VisitSinceTime?: Date
	VisitingDay?: number
	SubstituteId?: number
	AsistantId?: number
	PriorityId?: number
	DepartmentId?: number
	SamePersonId?: number
	CreatedOn?: Date
	ChangedOn?: Date
	TransportTypeId?: number
	OfficeId?: number
	CountryId?: number
	MaritalStatus?: string
	Nationality?: string
	BirthDate?: Date
	BirthIdNumber?: string
	Name?: string
	Surname?: string
	OfficerId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FullNameCalc?: string
	FullNameTitleCalc?: string
	OwnAbbrBitCalc?: number
	LimitedProcessingBitCalc?: number
	ShowItemCalc?: number
	SummationEvaluationField1Calc?: number
	SummationEvaluationField2Calc?: number
	SummationEvaluationField3Calc?: number
	SummationEvaluationField4Calc?: number
	SummationEvaluationField5Calc?: number
	SummationEvaluationField6Calc?: number
	EvaluationConfigCalc?: string
	UseAnalyticalDimensionCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TOfficerDM)
	NodeId?: TOfficerDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TEmployedPersonSimpleDM)
	WorkerId?: TEmployedPersonSimpleDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TitleName)
	TitleAfterName?: TitleName
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM
	@Type(() => PositionName)
	PositionId?: PositionName
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TitleName)
	Title?: TitleName
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSalesItemAnalyticalDimensionDM)
	SalesItemChild?: TSalesItemAnalyticalDimensionDM[]
	@Type(() => TPurchaseItemAnalyticalDimensionDM)
	PurchaseItemChild?: TPurchaseItemAnalyticalDimensionDM[]
	@Type(() => TPaymentDocumentItemAnalyticalDimensionDM)
	FinanceItemChild?: TPaymentDocumentItemAnalyticalDimensionDM[]
	@Type(() => TAccountingJournalItemAnalyticalDimensionDM)
	AccountingJournaItemBookChild?: TAccountingJournalItemAnalyticalDimensionDM[]
	@Type(() => TAssetMovementAnalyticalDimensionDM)
	AssetMovementChild?: TAssetMovementAnalyticalDimensionDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}