import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TWorkflowRoleDM extends Data {
	public static className = 'TWorkflowRoleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	TimeStamp?: string
	RightGroupId?: number
	HeaderTypeId?: number
	TypeId?: number
	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageNameCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}