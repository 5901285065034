import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardConfigDM from './TJobCardConfigDM'
import TAccountingChartDM from './TAccountingChartDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

export default class TCompanyLinkArticleDM extends Data {
	public static className = 'TCompanyLinkArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ExchangeRate?: number
	Currency?: string
	MarginId?: number
	SellingPrice?: number
	PurchasePrice?: number
	PurchaseCosts?: number
	PlannedStockPrice?: number
	RoutingVariantId?: number
	IsValid?: string
	ChangedOn?: Date
	RecordNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IconCalc?: number
	@Type(() => TJobCardConfigDM)
	JobCardConfigId?: TJobCardConfigDM
	@Type(() => TAccountingChartDM)
	ConsolidationSalesAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	ConsolidationPurchaseAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	SalesAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	PurchaseAccountId?: TAccountingChartDM
	@Type(() => TTradingPartnerDM)
	OptimalSupplierId?: TTradingPartnerDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}