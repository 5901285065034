import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TSpecialDSDistributionDaysItem extends Data {
	public static className = 'TSpecialDSDistributionDaysItem'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	ChangedOn?: Date
	CreatedOn?: Date
	PrintTime?: Date
	PrintDayOfWeek?: number
	DistributionDayOfWeek?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DistributionDayOfWeekCalc?: string
	PrintDayOfWeekCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM
}