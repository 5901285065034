import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TDocumentShreddingPlanDM from './TDocumentShreddingPlanDM'
import TCompanyDM from './TCompanyDM'
import TExternalDocumentConfigurationDM from './TExternalDocumentConfigurationDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TDocumentItemDM from './TDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TExternalDocumentDM extends Data {
	public static className = 'TExternalDocumentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	SigniContractId?: number
	RevisionId?: string
	Tags?: string
	Keywords?: string
	DocumentManagementTypeId?: number
	DocumentManagementId?: string
	ValidTo?: Date
	ValidFrom?: Date
	TimeStamp?: string
	FileChangeTime?: Date
	FileChangeDate?: Date
	Description2?: string
	Description1?: string
	RightGroupId?: number
	ChangedOn?: Date
	IssueDate?: Date
	File?: string
	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	FullFileCalc?: string
	IsDMSCalc?: string
	IsMyRevisionCalc?: string
	IsRevisionCalc?: string
	FileNameCalc?: string
	IsShreddedCalc?: string
	IsArchivedCalc?: string
	IsDocumentAccesibleCalc?: string
	IsTimeToArchiveCalc?: string
	IsTimeToShredCalc?: string
	IconCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	SigniDocumentStateId?: TTextDM
	@Type(() => TK2UserLookupDM)
	RevisionUserId?: TK2UserLookupDM
	@Type(() => TDocumentShreddingPlanDM)
	ShreddingPlanId?: TDocumentShreddingPlanDM
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
	@Type(() => TK2UserLookupDM)
	ChangedFileById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TExternalDocumentConfigurationDM)
	TypeId?: TExternalDocumentConfigurationDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	StateBitCalc?: TTextDM
	@Type(() => TTextDM)
	DMSStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TDocumentItemDM)
	InternalDocumentItemChild?: TDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	LinkedWorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}