import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import BatchStatus from './BatchStatus'
import KindAccounting from './KindAccounting'
import TRoutingVariantDM from './TRoutingVariantDM'
import TCountryDM from './TCountryDM'
import GoodsName from './GoodsName'
import Kvalita from './Kvalita'
import Typ from './Typ'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TWarehouseDM from './TWarehouseDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TTextDM from './TTextDM'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TBatchParameterValueDM from './TBatchParameterValueDM'
import TBatchCostItemDM from './TBatchCostItemDM'
import TStockCardDM from './TStockCardDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

export default class TBatchDM extends Data {
	public static className = 'TBatchDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CompanyId?: number
	TimeStamp?: string
	AUnit?: string
	Coefficient?: number
	TypeId?: number
	CostPrice?: number
	StockPrice?: number
	OutputDayCount?: number
	OutputDate?: Date
	InputDayCount?: number
	InputDate?: Date
	ChangedOn?: Date
	SellingPrice?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	ReceivedCalc?: number
	AvailableCalc?: number
	AssignedCalc?: number
	Available2Calc?: number
	AvailableUMCalc?: number
	AssignedUMCalc?: number
	Available2UMCalc?: number
	ReceivedUMCalc?: number
	BookValueCalc?: number
	DispatchCalc?: number
	DispatchUMCalc?: number
	InReceiptQuantityCalc?: number
	InReceiptQuantityUMCalc?: number
	InTransferCalc?: number
	InTransferUMCalc?: number
	MeasureUnitCoefficientCalc?: number
	ShowRecordActualCompanyCalc?: string
	AvailableCoefficientCalc?: number
	AssignedCoefficientCalc?: number
	ParameterSetIdCalc?: number
	PaidStateIdCalc?: number
	NotInsertStockPriceBitCalc?: number
	UnspecifiedBitCalc?: number
	NotInsertCostPriceBitCalc?: number
	NotInsertSellingPriceBitCalc?: number
	BatchSellingPriceCalc?: number
	NotReleaseAutomaticallyBitCalc?: number
	PeriodFromDateCalc?: Date
	PeriodToDateCalc?: Date
	SkipNullCalc?: string
	BatchValuePARAMCalc?: string
	Parameter01Calc?: string
	Parameter02Calc?: string
	Parameter03Calc?: string
	Parameter04Calc?: string
	Parameter05Calc?: string
	Parameter06Calc?: string
	Parameter07Calc?: string
	Parameter08Calc?: string
	Parameter09Calc?: string
	Parameter10Calc?: string
	Parameter11Calc?: string
	Parameter12Calc?: string
	Parameter13Calc?: string
	Parameter14Calc?: string
	Parameter15Calc?: string
	Parameter16Calc?: string
	Parameter17Calc?: string
	Parameter18Calc?: string
	Parameter19Calc?: string
	Parameter20Calc?: string
	Parameter21Calc?: string
	Parameter22Calc?: string
	Parameter23Calc?: string
	Parameter24Calc?: string
	Parameter25Calc?: string
	Parameter26Calc?: string
	Parameter27Calc?: string
	Parameter28Calc?: string
	Parameter29Calc?: string
	Parameter30Calc?: string
	Parameter31Calc?: string
	Parameter32Calc?: string
	Parameter33Calc?: string
	Parameter34Calc?: string
	Parameter35Calc?: string
	Parameter36Calc?: string
	Parameter37Calc?: string
	Parameter38Calc?: string
	Parameter39Calc?: string
	Parameter40Calc?: string
	Parameter41Calc?: string
	Parameter42Calc?: string
	Parameter43Calc?: string
	Parameter44Calc?: string
	Parameter45Calc?: string
	Parameter46Calc?: string
	Parameter47Calc?: string
	Parameter48Calc?: string
	Parameter49Calc?: string
	Parameter50Calc?: string
	Parameter51Calc?: string
	Parameter52Calc?: string
	Parameter53Calc?: string
	Parameter54Calc?: string
	Parameter55Calc?: string
	Parameter56Calc?: string
	Parameter57Calc?: string
	Parameter58Calc?: string
	Parameter59Calc?: string
	Parameter60Calc?: string
	Parameter61Calc?: string
	Parameter62Calc?: string
	Parameter63Calc?: string
	Parameter64Calc?: string
	Parameter65Calc?: string
	Parameter66Calc?: string
	Parameter67Calc?: string
	Parameter68Calc?: string
	Parameter69Calc?: string
	Parameter70Calc?: string
	Parameter71Calc?: string
	Parameter72Calc?: string
	Parameter73Calc?: string
	Parameter74Calc?: string
	Parameter75Calc?: string
	Parameter76Calc?: string
	Parameter77Calc?: string
	Parameter78Calc?: string
	Parameter79Calc?: string
	Parameter80Calc?: string
	Parameter81Calc?: string
	Parameter82Calc?: string
	Parameter83Calc?: string
	Parameter84Calc?: string
	Parameter85Calc?: string
	Parameter86Calc?: string
	Parameter87Calc?: string
	Parameter88Calc?: string
	Parameter89Calc?: string
	Parameter90Calc?: string
	Parameter91Calc?: string
	Parameter92Calc?: string
	Parameter93Calc?: string
	Parameter94Calc?: string
	Parameter95Calc?: string
	Parameter96Calc?: string
	Parameter97Calc?: string
	Parameter98Calc?: string
	Parameter99Calc?: string
	Parameter01TypeCalc?: number
	Parameter02TypeCalc?: number
	Parameter03TypeCalc?: number
	Parameter04TypeCalc?: number
	Parameter05TypeCalc?: number
	Parameter06TypeCalc?: number
	Parameter07TypeCalc?: number
	Parameter08TypeCalc?: number
	Parameter09TypeCalc?: number
	Parameter10TypeCalc?: number
	Parameter11TypeCalc?: number
	Parameter12TypeCalc?: number
	Parameter13TypeCalc?: number
	Parameter14TypeCalc?: number
	Parameter15TypeCalc?: number
	Parameter16TypeCalc?: number
	Parameter17TypeCalc?: number
	Parameter18TypeCalc?: number
	Parameter19TypeCalc?: number
	Parameter20TypeCalc?: number
	Parameter21TypeCalc?: number
	Parameter22TypeCalc?: number
	Parameter23TypeCalc?: number
	Parameter24TypeCalc?: number
	Parameter25TypeCalc?: number
	Parameter26TypeCalc?: number
	Parameter27TypeCalc?: number
	Parameter28TypeCalc?: number
	Parameter29TypeCalc?: number
	Parameter30TypeCalc?: number
	Parameter31TypeCalc?: number
	Parameter32TypeCalc?: number
	Parameter33TypeCalc?: number
	Parameter34TypeCalc?: number
	Parameter35TypeCalc?: number
	Parameter36TypeCalc?: number
	Parameter37TypeCalc?: number
	Parameter38TypeCalc?: number
	Parameter39TypeCalc?: number
	Parameter40TypeCalc?: number
	Parameter41TypeCalc?: number
	Parameter42TypeCalc?: number
	Parameter43TypeCalc?: number
	Parameter44TypeCalc?: number
	Parameter45TypeCalc?: number
	Parameter46TypeCalc?: number
	Parameter47TypeCalc?: number
	Parameter48TypeCalc?: number
	Parameter49TypeCalc?: number
	Parameter50TypeCalc?: number
	Parameter51TypeCalc?: number
	Parameter52TypeCalc?: number
	Parameter53TypeCalc?: number
	Parameter54TypeCalc?: number
	Parameter55TypeCalc?: number
	Parameter56TypeCalc?: number
	Parameter57TypeCalc?: number
	Parameter58TypeCalc?: number
	Parameter59TypeCalc?: number
	Parameter60TypeCalc?: number
	Parameter61TypeCalc?: number
	Parameter62TypeCalc?: number
	Parameter63TypeCalc?: number
	Parameter64TypeCalc?: number
	Parameter65TypeCalc?: number
	Parameter66TypeCalc?: number
	Parameter67TypeCalc?: number
	Parameter68TypeCalc?: number
	Parameter69TypeCalc?: number
	Parameter70TypeCalc?: number
	Parameter71TypeCalc?: number
	Parameter72TypeCalc?: number
	Parameter73TypeCalc?: number
	Parameter74TypeCalc?: number
	Parameter75TypeCalc?: number
	Parameter76TypeCalc?: number
	Parameter77TypeCalc?: number
	Parameter78TypeCalc?: number
	Parameter79TypeCalc?: number
	Parameter80TypeCalc?: number
	Parameter81TypeCalc?: number
	Parameter82TypeCalc?: number
	Parameter83TypeCalc?: number
	Parameter84TypeCalc?: number
	Parameter85TypeCalc?: number
	Parameter86TypeCalc?: number
	Parameter87TypeCalc?: number
	Parameter88TypeCalc?: number
	Parameter89TypeCalc?: number
	Parameter90TypeCalc?: number
	Parameter91TypeCalc?: number
	Parameter92TypeCalc?: number
	Parameter93TypeCalc?: number
	Parameter94TypeCalc?: number
	Parameter95TypeCalc?: number
	Parameter96TypeCalc?: number
	Parameter97TypeCalc?: number
	Parameter98TypeCalc?: number
	Parameter99TypeCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => BatchStatus)
	StatusId?: BatchStatus
	@Type(() => KindAccounting)
	PostingKindId?: KindAccounting
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM
	@Type(() => GoodsName)
	NameId?: GoodsName
	@Type(() => Kvalita)
	QualityId?: Kvalita
	@Type(() => Typ)
	KindId?: Typ
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	StockMeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TTextDM)
	ProductionInProgressBitCalc?: TTextDM
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TBatchParameterValueDM)
	BatchParameterChild?: TBatchParameterValueDM[]
	@Type(() => TBatchCostItemDM)
	BatchCostChild?: TBatchCostItemDM[]
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
	@Type(() => TStockCardOldDM)
	OldStockCardOldChild?: TStockCardOldDM[]
}