import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWebTextPageTypeDM from './TWebTextPageTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TextPageType from './TextPageType'
import ShopGroupId from './ShopGroupId'
import TContactPersonDM from './TContactPersonDM'
import TEshopDM from './TEshopDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TWebModuleLinkDM from './TWebModuleLinkDM'
import TWebModuleLinkSuperiorDM from './TWebModuleLinkSuperiorDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopTextPageDM extends Data {
	public static className = 'TEshopTextPageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TemplateName?: string
	PageUrlCanonical?: string
	ChangedOn?: Date
	CreatedOn?: Date
	LastUpdateDate?: Date
	PublicationDate?: Date
	RightGroupid?: number
	Name?: string
	TimeStamp?: string
	PageKeyWords?: string
	ImageFileName?: string
	IconFileName?: string
	PageAnalytics?: string
	PageUrl?: string
	PageDescription?: string
	PageTitle?: string
	Description?: string
	ShortDescription?: string
	Title?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	DescriptionCalc?: string
	PublicationDateCalc?: Date
	PublicationTimeCalc?: Date
	ForLoggedOnlyCalc?: string
	DetailUrlCalc?: string
	DetailUrlAbsCalc?: string
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedNameCalc?: string
	TranslatedTitleCalc?: string
	TranslatedShortDescriptionCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedPageUrlCalc?: string
	TranslatedPageAnalyticsCalc?: string
	TranslatedPageKeyWordsCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	UserAccessId?: TTextDM
	@Type(() => TTextDM)
	OpenGraphTypeId?: TTextDM
	@Type(() => TWebTextPageTypeDM)
	PageTypeRID?: TWebTextPageTypeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TextPageType)
	TextPageTypeId?: TextPageType
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId
	@Type(() => TContactPersonDM)
	AuthorId?: TContactPersonDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TWebModuleLinkDM)
	WebModuleLinkChild?: TWebModuleLinkDM[]
	@Type(() => TWebModuleLinkSuperiorDM)
	SuperiorWebModuleLinkChild?: TWebModuleLinkSuperiorDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}