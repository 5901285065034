import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TABCItemDM from './TABCItemDM'

export default class TABCAnalysisDM extends Data {
	public static className = 'TABCAnalysisDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Abbr?: string
	TotalValue?: number
	RID?: number
	Name?: string
	ItemCount?: number
	DateTo?: Date
	DateFrom?: Date
	ABCConfigRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TrendCalc?: number
	LastFlagBitCalc?: number
	@Type(() => TTextDM)
	DataModuleNumber?: TTextDM
	@Type(() => TABCItemDM)
	ItemChild?: TABCItemDM[]
	@Type(() => TABCItemDM)
	NonZeroItemChild?: TABCItemDM[]
}