import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'
import TTextDM from './TTextDM'
import TCurrencyCountryExchangeRateDM from './TCurrencyCountryExchangeRateDM'
import TCustomExchangeRateDM from './TCustomExchangeRateDM'

export default class TCurrencyDM extends Data {
	public static className = 'TCurrencyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	RoundingTravelOrdersConstant?: number
	RoundingTravelOrdersOrder?: number
	TimeStamp?: string
	RightGroupId?: number
	RoundingInvoiceOutConstant?: number
	RoundingInvoiceOutOrder?: number
	RoundingInvoiceInConstant?: number
	RoundingInvoiceInOrder?: number
	ValutaRateSale?: number
	ValutaRateMiddle?: number
	ValutaRatePurchase?: number
	ExchangeRateSale?: number
	ExchangeRateMiddle?: number
	ExchangeRatePurchase?: number
	CurrencyUnitNumber?: number
	ExchangeRateDate?: Date
	Code?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CompanyIdCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TCurrencyCountryExchangeRateDM)
	ExchangeRateChild?: TCurrencyCountryExchangeRateDM[]
	@Type(() => TCustomExchangeRateDM)
	CustomExchangeRateChild?: TCustomExchangeRateDM[]
}