import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import esFeedAssignment from './esFeedAssignment'
import TEshopStockTextDM from './TEshopStockTextDM'
import Availability from './Availability'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopDM from './TEshopDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'

export default class TEshopArticleShopValueTextDM extends Data {
	public static className = 'TEshopArticleShopValueTextDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	MinPurchaseQuantity?: number
	PageUrl?: string
	PageAnalytics?: string
	IsQuotationRequest?: string
	KeyWords?: string
	PageDescription?: string
	PageTitle?: string
	Description?: string
	OriginalPrice?: number
	Note?: string
	ShortDescription?: string
	Name?: string
	IsComingSoon?: string
	IsTip?: string
	IsSecondHand?: string
	IsFreeShipping?: string
	IsSellout?: string
	IsNewArticle?: string
	IsDiscount?: string
	IsCampaign?: string
	IsUnableToBuyNotLogged?: string
	IsUnableToBuy?: string
	IsHiddenOnShop?: string
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DescriptionCalc?: string
	CurrencyCalc?: string
	UrlCalc?: string
	TranslatedNameCalc?: string
	TranslatedShortDescriptionCalc?: string
	TranslatedNoteCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedKeyWordsCalc?: string
	TranslatedPageAnalyticsCalc?: string
	TranslatedPageUrlCalc?: string
	@Type(() => esFeedAssignment)
	FeedAssignmentId?: esFeedAssignment
	@Type(() => TEshopStockTextDM)
	StockTextRID?: TEshopStockTextDM
	@Type(() => Availability)
	AvailabilityId?: Availability
	@Type(() => TEshopCategoryInfoDM)
	MainCategoryRID?: TEshopCategoryInfoDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	AssignedCalc?: TTextDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
}