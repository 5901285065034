import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import PrEmpWorksCatalog from './PrEmpWorksCatalog'
import TPrTariffDM from './TPrTariffDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import MzdLAU1 from './MzdLAU1'
import Group2 from './Group2'
import Group1 from './Group1'
import SkupPrac from './SkupPrac'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import DegreeName from './DegreeName'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import ProfessionName from './ProfessionName'
import CatWork from './CatWork'
import TypWork from './TypWork'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TOrgStrJobDM extends Data {
	public static className = 'TOrgStrJobDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XmlField?: string
	CreatedOn?: Date
	ChangedOn?: Date
	ValidTo?: Date
	ValidFrom?: Date
	RightGroupId?: number
	CompanyIdOld?: number
	Description?: string
	Name?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FieldHistoryDateCalc?: Date
	VersionedFieldDateCalc?: Date
	VersionFieldDateCalc?: Date
	PeriodIdCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	ManagementLevelId?: TTextDM
	@Type(() => TTextDM)
	GradeId?: TTextDM
	@Type(() => PrEmpWorksCatalog)
	WorkCatalogId?: PrEmpWorksCatalog
	@Type(() => TPrTariffDM)
	PayrollTariffRID?: TPrTariffDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1
	@Type(() => Group2)
	Group2Id?: Group2
	@Type(() => Group1)
	Group1Id?: Group1
	@Type(() => SkupPrac)
	WorkerGroupId?: SkupPrac
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1
	@Type(() => DegreeName)
	EducationLevelId?: DegreeName
	@Type(() => TTextDM)
	WorkTimeScheduleId?: TTextDM
	@Type(() => TTextDM)
	WorkingModeId?: TTextDM
	@Type(() => TPrMinimalTariffDM)
	MinTariffId?: TPrMinimalTariffDM
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM
	@Type(() => CatWork)
	WorkCategoryId?: CatWork
	@Type(() => TypWork)
	WorkTypeId?: TypWork
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	WorkTimeScheduleId_ver?: TTextDM
	@Type(() => TPrMinimalTariffDM)
	MinTariffId_ver?: TPrMinimalTariffDM
	@Type(() => MzdLAU1)
	LAU1Id_ver?: MzdLAU1
	@Type(() => PrEmpWorksCatalog)
	WorkCatalogId_ver?: PrEmpWorksCatalog
	@Type(() => TypWork)
	WorkTypeId_ver?: TypWork
	@Type(() => TTextDM)
	ManagementLevelId_ver?: TTextDM
	@Type(() => Kategorie2)
	Category2Id_ver?: Kategorie2
	@Type(() => DegreeName)
	EducationLevelId_ver?: DegreeName
	@Type(() => TPrTariffDM)
	PayrollTariffRID_ver?: TPrTariffDM
	@Type(() => Group1)
	Group1Id_ver?: Group1
	@Type(() => Kategorie1)
	Category1Id_ver?: Kategorie1
	@Type(() => TPrInternalTariffDM)
	InternalTariffId_ver?: TPrInternalTariffDM
	@Type(() => TTextDM)
	GradeId_ver?: TTextDM
	@Type(() => Group2)
	Group2Id_ver?: Group2
	@Type(() => SkupPrac)
	WorkerGroupId_ver?: SkupPrac
	@Type(() => ProfessionName)
	CZISCOId_ver?: ProfessionName
	@Type(() => CatWork)
	WorkCategoryId_ver?: CatWork
	@Type(() => TTextDM)
	WorkingModeId_ver?: TTextDM
	@Type(() => TTextDM)
	LegalSubrelation1Id_ver?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitChild?: TOrgStrTreeUnitDM[]
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}