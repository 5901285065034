import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TExternalDocumentDM from './TExternalDocumentDM'
import TTextDM from './TTextDM'

export default class TExternalDocumentItemDM extends Data {
	public static className = 'TExternalDocumentItemDM'
	public static systemFields = [
		'RecordID',
	]

	NodeId?: number
	Id?: number
	FileModuleNumber?: number
	DataModuleNumber?: number
	DocumentNumber?: number
	Book?: string
	TypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ExternalDocumentFileCalc?: string
	DataModuleNameCalc?: string
	DocumentIdentifierCalc?: string
	DocumentDescriptionCalc?: string
	K2PictureAbbrCalc?: string
	AbbrCalc?: string
	IsMyRevisionCalc?: string
	IsRevisionCalc?: string
	IsDMSCalc?: string
	@Type(() => TExternalDocumentDM)
	DocumentId?: TExternalDocumentDM
	@Type(() => TTextDM)
	ExternalDocumentStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	DMSStateIdCalc?: TTextDM
}