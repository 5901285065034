import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TPartnerJournalDM extends Data {
	public static className = 'TPartnerJournalDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	DecisiveDate?: Date
	ItemNumber?: number
	ChildType?: number
	HeaderRID?: number
	HeaderTypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IdentificationCalc?: string
	DescriptionCalc?: string
	AmountCalc?: number
	RecordPermissionCalc?: string
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
}