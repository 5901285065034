import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPriceGroupDM from './TPriceGroupDM'
import TRoutingVariantSimpleDM from './TRoutingVariantSimpleDM'
import TCurrencyDM from './TCurrencyDM'
import TArticleDM from './TArticleDM'

export default class TSpecialTradingPartnerNettoPriceItem extends Data {
	public static className = 'TSpecialTradingPartnerNettoPriceItem'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	NodeID?: number
	Price?: number
	MeasureUnitId?: number
	CustomerCode2?: string
	CustomerCode1?: string
	KeyCode?: string
	UniqID?: number
	DateTo?: Date
	Date?: Date
	Quantity?: number
	PriceGroupNo?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CFProc?: number
	CFCena?: number
	CFRelF?: string
	@Type(() => TPriceGroupDM)
	SuperiorPriceGroupId?: TPriceGroupDM
	@Type(() => TRoutingVariantSimpleDM)
	RoutingVariantId?: TRoutingVariantSimpleDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}