import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'

export default class TParameterSetFormulaDM extends Data {
	public static className = 'TParameterSetFormulaDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	OtherVariable?: string
	Formula?: string
	Id?: number
	HeaderId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FormulaErrorImgCalc?: string
	VariableErrorImgCalc?: string
	FormulaForCoefficientIdCalc?: number
	ForSaleIdCalc?: number
	ForPurchaseIdCalc?: number
	FormulaErrImgCalc?: number
	VariablesErrImgCalc?: number
	@Type(() => AlternativeUnit)
	MeasureUnit?: AlternativeUnit
}