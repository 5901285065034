import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TransportKind from './TransportKind'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TShippingMethodDM from './TShippingMethodDM'

export default class TSPSTransportMethodDM extends Data {
	public static className = 'TSPSTransportMethodDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsShippingMethodReserved?: string
	IsBlockedRecord?: string
	MaximumWeight?: number
	RID?: number
	TimeStamp?: string
	Description?: string
	RightGroupId?: number
	Name?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	ShippingMethodRIDCalc?: number
	LanguageTextCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TransportKind)
	TransportModeId?: TransportKind
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TShippingMethodDM)
	ShippingMethodChild?: TShippingMethodDM[]
}