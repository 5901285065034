import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TFragmentDM from './TFragmentDM'

export default class TUserRoleFragmentDM extends Data {
	public static className = 'TUserRoleFragmentDM'
	public static systemFields = [
		'RecordID',
	]

	Mandant?: string
	OwnerRID?: number
	Level?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TFragmentDM)
	FragmentId?: TFragmentDM
}