import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'

export default class TFindByPhoneDM extends Data {
	public static className = 'TFindByPhoneDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
}