import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TShippingMethodDM from './TShippingMethodDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TEshopOrderDM from './TEshopOrderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import JCDP20_a1 from './JCDP20_a1'
import TTransportMethodDM from './TTransportMethodDM'
import TSalesOrderDM from './TSalesOrderDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import TAddressDM from './TAddressDM'
import TPriceGroupDM from './TPriceGroupDM'
import QuotationStatus from './QuotationStatus'
import TCurrencyDM from './TCurrencyDM'
import TOpportunityDM from './TOpportunityDM'
import TPartnerDM from './TPartnerDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import MrktngDocItemGroup from './MrktngDocItemGroup'
import TTextDM from './TTextDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TQuotationRequestedItemDM from './TQuotationRequestedItemDM'
import TQuotationOfferedItemDM from './TQuotationOfferedItemDM'
import TMarketingPhaseDM from './TMarketingPhaseDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TPaymentConditionTradingPartnerSaleDM from './TPaymentConditionTradingPartnerSaleDM'
import TMarketingSalesOrderDM from './TMarketingSalesOrderDM'
import TProductCostingDM from './TProductCostingDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TQuotationDM extends Data {
	public static className = 'TQuotationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ImplementationToDate?: Date
	ImplementationFromDate?: Date
	IssueDate?: Date
	AmountVAT?: number
	TimeStamp?: string
	EstimatedPrice?: number
	CostPrice?: number
	ListPrice?: number
	OfferedPrice?: number
	RightGroupId?: number
	ConfirmedOn?: Date
	ChangedOn?: Date
	ExchangeRate?: number
	DateTo?: Date
	DateFrom?: Date
	Description?: string
	Variation?: string
	Number?: number
	MainRID?: number
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	SwitchCurrencyCalc?: string
	ExchangeRateCalc?: number
	OfferedUnitPriceNetCCalc?: number
	CatalogUnitPriceCCalc?: number
	CostPriceCCalc?: number
	EstimatedPriceCCalc?: number
	DiscountCalc?: number
	DiscountPctCalc?: number
	DiscountCCalc?: number
	OfferedTotalPriceCalc?: number
	CatalogTotalPriceCalc?: number
	TotalCostPriceCalc?: number
	EstimatedTotalPriceCalc?: number
	OfferedTotalPriceCCalc?: number
	CatalogTotalPriceCCalc?: number
	TotalCostPriceCCalc?: number
	EstimatedTotalPriceCCalc?: number
	TotalDiscountCalc?: number
	TotalDiscountPctCalc?: number
	TotalDiscountCCalc?: number
	ShowCurrencyCalc?: string
	OfferedTotalPriceGrossCalc?: number
	OfferedTotalPriceGrossCCalc?: number
	PriceVATCCalc?: number
	VATTotalPriceCalc?: number
	VATTotalPriceCCalc?: number
	TotalProfitCalc?: number
	TotalProfitCCalc?: number
	UserPartnerIdCalc?: number
	HasOrderCalc?: number
	TotalProfitPctCalc?: number
	IsReadyToRealizationFromEshopCalc?: string
	ImplementationDateFromDateCalc?: Date
	ImplementationDateFromTimeCalc?: Date
	ImplementationDateToDateCalc?: Date
	ImplementationDateToTimeCalc?: Date
	VATBitCalc?: number
	ShowVATPriceCalc?: string
	InvoiceAddressTrPartIdCalc?: number
	DeliveryAddressTrPartIdCalc?: number
	PostalAddressTrPartIdCalc?: number
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	CompanyCurrencyCalc?: string
	RIDForEShopCalc?: string
	ContactPersonFullNameCalc?: string
	ContactPersonNameCalc?: string
	ContactPersonSurnameCalc?: string
	ContactPersonEmailCalc?: string
	ContactPersonPhoneCalc?: string
	IsContactPersonSendInfoCalc?: string
	ExternalAddressCalc?: string
	CompanyRegNumberCalc?: string
	VATRegNumberNotFullCalc?: string
	TaxNumberCalc?: string
	AllowSendByPartCalc?: string
	DeliveryTermCalc?: Date
	DeliveryDateCalc?: Date
	FinalizeURLCalc?: string
	TransportMethodRIDCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TEshopOrderDM)
	EshopOrderRID?: TEshopOrderDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	AuthorizedById?: TContactPersonDM
	@Type(() => TContactPersonDM)
	CreatedById?: TContactPersonDM
	@Type(() => QuotationStatus)
	StatusId?: QuotationStatus
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => MrktngDocItemGroup)
	ShowItemsGroupIdCalc?: MrktngDocItemGroup
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TPartnerDM)
	PartnerByIdInputCalc?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerByAbbrInputCalc?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerByNameInputCalc?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerByAbbr2InputCalc?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerByCompanyRegNumberInputCalc?: TPartnerDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TQuotationRequestedItemDM)
	RequestedChild?: TQuotationRequestedItemDM[]
	@Type(() => TQuotationOfferedItemDM)
	OfferedChild?: TQuotationOfferedItemDM[]
	@Type(() => TMarketingPhaseDM)
	PhaseChild?: TMarketingPhaseDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TPaymentConditionTradingPartnerSaleDM)
	PaymentConditionChild?: TPaymentConditionTradingPartnerSaleDM[]
	@Type(() => TMarketingSalesOrderDM)
	SalesOrderChild?: TMarketingSalesOrderDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}