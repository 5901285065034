import Data from '../Api/Data'

export default class TOldNoteDM extends Data {
	public static className = 'TOldNoteDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	NodeId?: number
	RightGroupId?: number
	FormId?: number
	NoteId?: number
	NoteText?: string
	SequenceNumber?: number
	TypeId?: string
	DocumentRID?: number
	Book?: string
	DocumentTypeId?: number
	RecordStateImgCalc?: string
	NoteTextCalc?: string
	NoteTypeCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
}