import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TWorkingTimeAccountDM extends Data {
	public static className = 'TWorkingTimeAccountDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Description?: string
	RightGroupId?: number
	Id?: number
	ShortDescription?: string
	Abbr?: string
	TypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordImgCalc?: string
	DateFromCalc?: Date
	DateToCalc?: Date
	WeekCountCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	CurrentTypeRIDCalc?: TTextDM
}