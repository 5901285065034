import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCurrencyDM from './TCurrencyDM'
import TPriceListDM from './TPriceListDM'
import TLocationSimpleDM from './TLocationSimpleDM'
import TWarehouseDM from './TWarehouseDM'
import PromotionStatus from './PromotionStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TK2UserDM from './TK2UserDM'
import TSalesActionDM from './TSalesActionDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TArticleDM from './TArticleDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TPromotionRangeDM from './TPromotionRangeDM'
import TPromotionGiftDM from './TPromotionGiftDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TPromotionApplicationMemoryDM from './TPromotionApplicationMemoryDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPromotionDM extends Data {
	public static className = 'TPromotionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AmountTo?: number
	PriceTo?: number
	CouponRID?: number
	IsDiscountDistribution?: string
	MultiplyGifts?: string
	IsEnteredValue?: string
	GiftsValue?: number
	PageAnalytics?: string
	PageUrlCanonical?: string
	PageUrl?: string
	PageKeyWords?: string
	PageDescription?: string
	PageTitle?: string
	Name?: string
	IsGiftSameItem?: string
	IsGiftSelectable?: string
	IsPercentageInform?: string
	InformFrom?: number
	IsInformable?: string
	IsPercentageDiscount?: string
	Discount?: number
	PriceFrom?: number
	GiftsCount?: number
	AmountFrom?: number
	IsCombinable?: string
	IsDefinedCustomerKey?: string
	IsDefinedTransportMethod?: string
	IsDefinedPaymentMethod?: string
	Period?: number
	MaxCount?: number
	IsLimitedByCount?: string
	IsLimitedByWarehouse?: string
	IsCanceledRecord?: string
	IsDefinedCostCentre?: string
	IsDefinedBook?: string
	IsDefinedPriceGroup?: string
	IsDefinedCustomer?: string
	TimeStamp?: string
	ConfirmedOn?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	ValidToTime?: Date
	ValidToDate?: Date
	ValidFromTime?: Date
	ValidFromDate?: Date
	Description?: string
	Number?: number
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	PictureCalc?: string
	DocIdentifierDescriptionCalc?: string
	DescriptionCalc?: string
	SoldItemQuantityCalc?: number
	SoldItemPriceNetCalc?: number
	SoldItemPriceGrossCalc?: number
	GiftCountCalc?: number
	ApplicationCountCalc?: number
	ApplicationTradingPartnerCountCalc?: number
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	TranslatedNameCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedPageKeyWordsCalc?: string
	TranslatedPageUrlCalc?: string
	TranslatedPageAnalyticsCalc?: string
	@Type(() => TTextDM)
	OpenGraphTypeId?: TTextDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TTextDM)
	IsDefinedEshop?: TTextDM
	@Type(() => TTextDM)
	PromotionTypeId?: TTextDM
	@Type(() => TLocationSimpleDM)
	LocationId?: TLocationSimpleDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => PromotionStatus)
	StatusId?: PromotionStatus
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserDM)
	CreatedById?: TK2UserDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TArticleDM)
	DiscountArticleIdCalc?: TArticleDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPromotionRangeDM)
	PromotionRangeChild?: TPromotionRangeDM[]
	@Type(() => TPromotionGiftDM)
	GiftChild?: TPromotionGiftDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]
	@Type(() => TPromotionApplicationMemoryDM)
	DocumentApplicationChild?: TPromotionApplicationMemoryDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}