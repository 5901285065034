import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TParameterGroupItemDM from './TParameterGroupItemDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'

export default class TParameterGroupDM extends Data {
	public static className = 'TParameterGroupDM'
	public static primaryKey = 'ParameterGroupId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ParameterGroupId!
	}

	TimeStamp?: string
	ChangedOn?: Date
	Description?: string
	Name?: string
	ParameterGroupId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordBitCalc?: number
	TranslatedNameCalc?: string
	TranslatedDescriptionCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TParameterGroupItemDM)
	ParameterGroupItemChild?: TParameterGroupItemDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
}