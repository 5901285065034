import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'

export default class TAdvanceDeductionInvoiceDM extends Data {
	public static className = 'TAdvanceDeductionInvoiceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DeductedAmountVATC?: number
	DeductedAmountNetC?: number
	DeductedAmountGrossC?: number
	DocumentRID?: number
	RID?: number
	AdvanceItemRID?: number
	DeductionExchangeRate?: number
	DeductedAmountVAT?: number
	DeductedAmountNet?: number
	ExchangeDifference?: number
	DeductedAmountGross?: number
	AdvanceExchangeRate?: number
	DeductionDate?: Date
	AdvanceTypeId?: number
	ItemNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	OldDeductedAmountVATC?: number
	OldDeductedAmountNetC?: number
	OldDeductedAmountC?: number
	OldDeductedAmountVAT?: number
	OldDeductedAmountNet?: number
	OldDeductedAmount?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CFDeductedNetto?: number
	CFInvDeducted?: number
	InvoiceDeductedAmountGrossCCalc?: number
	CFInvDeductedNetto?: number
	CFInvDeductedNettoM?: number
	CFCoefVAT?: number
	CFCourseInv?: number
	DeductedAmountVATCalc?: number
	AdvanceExchangeRateCalc?: number
	AdvanceInvoiceCurrExchRateCalc?: number
	DeductedAmountNetCalc?: number
	ExtExchangeRateCalc?: number
	InvoiceIntDeductedAmountGrossCalc?: number
	InvoiceIntDeductedAmountGrossCCalc?: number
	@Type(() => TAdvanceReceivedDM)
	AdvanceRID?: TAdvanceReceivedDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
}