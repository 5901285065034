import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDiscountCouponDM from './TDiscountCouponDM'
import TPromotionDM from './TPromotionDM'
import TArticleDM from './TArticleDM'

export default class TEshopBasketItemDM extends Data {
	public static className = 'TEshopBasketItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	LoyaltyProgramPointCount?: number
	LoyaltyProgramRID?: number
	CouponErrId?: number
	IsVoucher?: string
	IsGift?: string
	PromotionGroupId?: number
	WebData?: string
	AdditionalInfoCRC?: string
	VATId?: number
	TimeStamp?: string
	SuperiorRID?: number
	ListPriceNet?: number
	ListPriceGross?: number
	IsPercentageDiscount?: string
	Discount?: number
	ItemDescription?: string
	ItemName?: string
	PriceNet?: number
	PriceGross?: number
	SequenceNumber?: number
	AdditionalInfo?: string
	RID?: number
	Quantity?: number
	BasketRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	MeasureUnitIdCalc?: number
	RatioUMCalc?: number
	QuantityUMCalc?: number
	DiscountCalc?: number
	PriceEditedCalc?: number
	PromotionTypeCalc?: number
	@Type(() => TDiscountCouponDM)
	CouponRID?: TDiscountCouponDM
	@Type(() => TPromotionDM)
	PromotionRID?: TPromotionDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}