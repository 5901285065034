import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopDM from './TEshopDM'

export default class TEshopCategoryListDM extends Data {
	public static className = 'TEshopCategoryListDM'
	public static systemFields = [
		'RecordID',
	]

	ArticleId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsArticleAssignedCalc?: string
	IsArticleAssignedImgCalc?: string
	@Type(() => TEshopCategoryInfoDM)
	CategoryRID?: TEshopCategoryInfoDM
	@Type(() => TEshopDM)
	EshopRIDCalc?: TEshopDM
}