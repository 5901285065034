import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TTextDM from './TTextDM'
import PrCalTypHours from './PrCalTypHours'

export default class TPrCalendarItemTimeDM extends Data {
	public static className = 'TPrCalendarItemTimeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	WorkshiftDate?: Date
	WorkshiftRID?: number
	IntervalRID?: number
	DefinitionRID?: number
	RID?: number
	BreakTIme?: number
	TotalTime?: number
	DateTo?: Date
	DateFrom?: Date
	StringValue3?: string
	StringValue2?: string
	LongValue?: number
	TimeTotal?: number
	ShortValue?: number
	Description?: string
	TimeTo?: number
	TimeFrom?: number
	Date?: Date
	DayTypeId?: number
	CodeCalendarId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AcceptChangeInDefBitCalc?: number
	TimeFromCalc?: Date
	TimeToCalc?: Date
	IntervalDaysCalc?: number
	IntervalDurationHourCalc?: number
	IntervalDurationMinuteCalc?: number
	TimeHoursCalc?: number
	TimeMinutesCalc?: number
	IntervalStartDateCalc?: Date
	IntervalEndDateCalc?: Date
	IntervalStartTimeCalc?: number
	IntervalEndTimeCalc?: number
	DateTimeFromCalc?: Date
	DateTimeToCalc?: Date
	IntervalCalc?: number
	TimeCalc?: number
	BreakDurationCalc?: number
	BreakShiftDurationCalc?: number
	BreakFromItemShiftDurationCalc?: number
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	PriorityId?: TTextDM
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours
	@Type(() => TTextDM)
	SourceTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	DayInWeekCalc?: TTextDM
}