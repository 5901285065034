import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPaymentMethodDM from './TPaymentMethodDM'
import TCompanyLinkDM from './TCompanyLinkDM'

export default class TPaymentConditionTradingPartnerPurchaseDM extends Data {
	public static className = 'TPaymentConditionTradingPartnerPurchaseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	HeaderRID?: number
	HeaderTypeId?: number
	RID?: number
	CashDiscountDate?: Date
	CashDiscountPercentage?: number
	PaymentMaxDay?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ConditionDescriptionCalc?: string
	PaymentTypeBitCalc?: number
	NetBitCalc?: number
	@Type(() => TPaymentMethodDM)
	PaymentTypeId?: TPaymentMethodDM
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}