import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'

export default class TWebTextPageTypeDM extends Data {
	public static className = 'TWebTextPageTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	TemplateName?: string
	UrlSuffix?: string
	Name?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	ModuleId?: TTextDM
}