import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCodeTypeDM from './TCodeTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TDeliveryLineDM from './TDeliveryLineDM'
import TTradingPartnerDM from './TTradingPartnerDM'

export default class TTradingPartnerTransportDM extends Data {
	public static className = 'TTradingPartnerTransportDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	IsHaulerRequired?: string
	Distance?: number
	UnloadingTime?: number
	ShippingTime?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	IsActive?: string
	TimeTo?: Date
	TimeFrom?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TCodeTypeDM)
	UnloadTypeId?: TCodeTypeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
}