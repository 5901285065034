import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCodeTypeDM from './TCodeTypeDM'
import TBookDM from './TBookDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTransferNoteDM from './TTransferNoteDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TWarehouseDM from './TWarehouseDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import KindAccounting from './KindAccounting'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'

export default class TAccountingFinanceTransferNoteItemDM extends Data {
	public static className = 'TAccountingFinanceTransferNoteItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	ReservationDate?: Date
	UnitCostPriceTo?: number
	UnitStockPriceTo?: number
	OriginalNo?: number
	UnitCostPriceFrom?: number
	NoteId?: number
	PostingKeyId?: number
	UnitStockPriceFrom?: number
	Quantity?: number
	ConfirmedOn?: Date
	ItemId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityUMCalc?: number
	UnitPriceWarehouseFromCalc?: number
	UnitPriceWarehouseToCalc?: number
	TotalPriceWarehouseFromCalc?: number
	TotalPriceWarehouseToCalc?: number
	NameCalc?: string
	PositiveQuantityCalc?: number
	QuantityRNCalc?: number
	FullNameCalc?: string
	BarcodeCalc?: string
	SNIncorrectCountImgCalc?: string
	BatchTypeCalc?: number
	BatchRatioCalc?: number
	BatchFromIdCalc?: number
	ContractCodeFromRIDCalc?: number
	LocationFromIdCalc?: number
	BatchToIdCalc?: number
	ContractCodeToRIDCalc?: number
	LocationToIdCalc?: number
	TotalCostPriceCalc?: number
	CostPriceWarehouseToCalc?: number
	IdenticalSourceDestinationCalc?: number
	IdenticalSourceDestinationImgCalc?: string
	HandlingUnitFromRIDCalc?: number
	HandlingUnitToRIDCalc?: number
	LockTypeCalc?: number
	ReservedBitCalc?: number
	ReleaseBitCalc?: number
	BatchParameterRatioCalc?: number
	QuantityByBatchTypeUMCalc?: number
	TotalWeightGrossCalc?: number
	ReservationDateCalc?: Date
	ReservationTimeCalc?: Date
	WarehouseZoneFromRIDCalc?: number
	WarehouseZoneToRIDCalc?: number
	TotalWeightNetCalc?: number
	IgnoreReceiptPriceCalc?: number
	ItemTextCalc?: string
	SerialNumberInputCalc?: string
	TotalVolumePARAMCalc?: number
	SerialNumberCountAsStringCalc?: string
	@Type(() => TCode6DocumentDM)
	Code6ToId?: TCode6DocumentDM
	@Type(() => TCode6DocumentDM)
	Code6FromId?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5ToId?: TCode5DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5FromId?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4ToId?: TCode4DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4FromId?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3ToId?: TCode3DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3FromId?: TCode3DocumentDM
	@Type(() => TCodeTypeDM)
	MobileTerminalStatusId?: TCodeTypeDM
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreToId?: TCostCentreCollectionDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreFromId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceToRID?: TDeviceDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceFromRID?: TDeviceDocumentDM
	@Type(() => TTransferNoteDM)
	HeaderRID?: TTransferNoteDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitToRID?: THandlingUnitArticleDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitFromRID?: THandlingUnitArticleDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeToRID?: TContractCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromRID?: TContractCodeDocumentDM
	@Type(() => TTradingPartnerDM)
	SupplierId?: TTradingPartnerDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TCode2DocumentDM)
	Code2ToId?: TCode2DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2FromId?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1ToId?: TCode1DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1FromId?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerToId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerFromId?: TOfficerDocumentDM
	@Type(() => TLocationArticleDM)
	LocationToId?: TLocationArticleDM
	@Type(() => TLocationArticleDM)
	LocationFromId?: TLocationArticleDM
	@Type(() => TBatchDM)
	BatchToId?: TBatchDM
	@Type(() => TBatchDM)
	BatchFromId?: TBatchDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TWarehouseDM)
	WarehouseToId?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseFromId?: TWarehouseDM
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	QuantitySignBitCalc?: TTextDM
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseFromIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseToByQuantitySignIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseFromByQuantitySignIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseToIdCalc?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
}