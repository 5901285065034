import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

export default class TEshopArticleListDM extends Data {
	public static className = 'TEshopArticleListDM'
	public static systemFields = [
		'RecordID',
	]

	CategoryRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ArticleNameCalc?: string
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}