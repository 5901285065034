import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TK2UserDM from './TK2UserDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TAssetMovementDM from './TAssetMovementDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAccountingJournalHistoryDM extends Data {
	public static className = 'TAccountingJournalHistoryDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CreatedOn?: Date
	CreatedHistoryOn?: Date
	Id?: number
	StatusId?: number
	IsManuallyChanged?: string
	IsCanceledRecord?: string
	Number?: number
	RID?: number
	DocumentTypeId?: number
	TimeStamp?: string
	ForeignCurrency?: string
	Currency?: string
	TotalCreditAmountC?: number
	TotalDebitAmountC?: number
	TotalAmounC?: number
	TotalCreditAmount?: number
	TotalDebitAmount?: number
	ItemIssueDate?: Date
	RightGroupId?: number
	ItemCount?: number
	AmountVAT?: number
	TotalAmount?: number
	AmountNet?: number
	ChangedOn?: Date
	PostedOn?: Date
	Text?: string
	Description?: string
	DocumentRID?: number
	DocumentBook?: string
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	GrossCalc?: number
	PrimaryDocumentNumberCalc?: number
	PurchaseOrSalesOrderRIDCalc?: string
	TextCalc?: string
	PrimaryDocPerOrNumRIDCalc?: number
	PrimaryDocumentBookIdCalc?: string
	InvoiceInRIDCalc?: number
	InvoiceOutRIDCalc?: number
	ReceiptCardRIDCalc?: number
	ReleaseNoteRIDCalc?: number
	TransferNoteRIDCalc?: number
	JobCardRIDCalc?: number
	AssetOldIdCalc?: number
	BankStatementRIDCalc?: number
	CashDocumentRIDCalc?: number
	ReservingCardRIDCalc?: number
	AdvanceProvidedRIDCalc?: number
	AdvanceReceivedRIDCalc?: number
	PostingPrimaryDocumentCalc?: string
	PrDocumentRIDCalc?: number
	OriginalDocumentPeriodCalc?: string
	InternalDocumentRIDCalc?: number
	PrimaryDocumentIdentificationCalc?: string
	OtherReceivableRIDCalc?: number
	OtherLiabilityRIDCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserDM)
	CreatedHistoryById?: TK2UserDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	PostedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TPeriodDM)
	PrimaryDocumentPeriodIdCalc?: TPeriodDM
	@Type(() => TAssetMovementDM)
	AssetMovementRIDCalc?: TAssetMovementDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}