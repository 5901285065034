import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TUserRoleRightDM from './TUserRoleRightDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TRightUserRoleDM from './TRightUserRoleDM'

export default class TUserRoleRightAsItemDM extends Data {
	public static className = 'TUserRoleRightAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ToPermit?: string
	HeaderId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightNumberCalc?: number
	CaptionCalc?: string
	ModuleNameCalc?: string
	FunctionalityCalc?: string
	IsSameInRoleCalc?: string
	InRoleCalc?: number
	@Type(() => TUserRoleRightDM)
	UserRightId?: TUserRoleRightDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TRightUserRoleDM)
	UserRoleChild?: TRightUserRoleDM[]
}