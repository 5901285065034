import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TElectronicAddressFormatDM extends Data {
	public static className = 'TElectronicAddressFormatDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Format?: string
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}