import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationArticleDM from './TLocationArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TOperationDM from './TOperationDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TRoutingVariantOperationSimpleDM extends Data {
	public static className = 'TRoutingVariantOperationSimpleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsTransferQtyByLot?: string
	OperationTextFormat?: number
	EnterRejectsInsteadOfYield?: string
	IsSupplement?: string
	Complete?: string
	IsCooperation?: string
	RoutingId?: number
	RoutingTypeId?: number
	RID?: number
	OperationText?: string
	TextId?: number
	ChangedOn?: Date
	Utilization?: number
	TransferQuantity?: number
	TeardownTime?: number
	TimeBefore?: number
	TimeAfter?: number
	SetupTime?: number
	RunTime?: number
	OperationCode?: string
	SequenceNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ProductionTimeUMCalc?: number
	SetupTimeUMCalc?: number
	TimeAfterUMCalc?: number
	TimeBeforeUMCalc?: number
	TeardownTimeUMCalc?: number
	DescriptionCalc?: string
	CompletionImgCalc?: string
	GlobalDefinitionIdCalc?: number
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TOperationDM)
	OperationId?: TOperationDM
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM
	@Type(() => TOperationDM)
	OperationByIdInputCalc?: TOperationDM
	@Type(() => TOperationDM)
	OperationByNameInputIdCalc?: TOperationDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}