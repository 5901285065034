import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAssetDM from './TAssetDM'

export default class TAssetConnectedDocumentDM extends Data {
	public static className = 'TAssetConnectedDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Description?: string
	Amount?: number
	DocumentDate?: Date
	DocumentItemRID?: number
	DocumentTypeItemId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentIdentifierCalc?: string
	SalesItemBindRIDCalc?: number
	PurchaseItemBindRIDCalc?: number
	PaymentItemBindRIDCalc?: number
	DocumentRIDCalc?: number
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TTextDM)
	AssetItemTypeId?: TTextDM
	@Type(() => TAssetDM)
	AssetRID?: TAssetDM
}