import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TWorkplaceDM from './TWorkplaceDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ResKind from './ResKind'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TRoutingVariantItemSimpleDM from './TRoutingVariantItemSimpleDM'
import TCapacityPlanDetailDM from './TCapacityPlanDetailDM'
import TJobCardScheduleQueueDM from './TJobCardScheduleQueueDM'
import TCapacityPlanDayDM from './TCapacityPlanDayDM'
import TSubordinateResourceReducedDM from './TSubordinateResourceReducedDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TSubordinateResourceDM from './TSubordinateResourceDM'

export default class TResourceDM extends Data {
	public static className = 'TResourceDM'
	public static primaryKey = 'ResourceId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ResourceId!
	}

	IsAuxiliaryResource?: string
	NoSplitOperationWorkShift?: string
	NotCheckJobCardTime?: string
	IsSingleOperation?: string
	IsSumming?: string
	ChangeTimeJobCard?: string
	IsGroup?: string
	ChangeTime?: string
	NoSplitOperations?: string
	IsInvalidRecord?: string
	AllowCollisions?: string
	CompanyId?: number
	TimeStamp?: string
	UtilizationRate?: number
	ChangedOn?: Date
	CreatedOn?: Date
	RightGroupid?: number
	CalendarId?: number
	Name?: string
	Abbr?: string
	ResourceId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SummingImgCalc?: number
	LanguageTextCalc?: string
	TotalCapacityCalc?: number
	TotalDelayedCalc?: number
	TotalRequirementsCalc?: number
	TotalUtilizationCalc?: number
	DateFromCalc?: Date
	DateToCalc?: Date
	ShiftDelayedBitCalc?: number
	ConfirmedOrCanceledImgCalc?: string
	ShowInHoursCalc?: string
	TotalDelayedUMCalc?: number
	TotalCapacityUMCalc?: number
	TotalRequirementsUMCalc?: number
	ManuallyAdjustedScheduleOnlyCalc?: string
	AllowCollisionsCalc?: string
	IncludingDelayedCalc?: string
	SplitByMembersCalc?: string
	TotalRequirementsUMParamCalc?: number
	TotalUtilizationParamCalc?: number
	HighUtilizationCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TWorkplaceDM)
	WorkplaceRID?: TWorkplaceDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => ResKind)
	ResourceTypeId?: ResKind
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => PlanType)
	PlannedTypeIdCalc?: PlanType
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TRoutingVariantItemSimpleDM)
	RoutingVariantItemChild?: TRoutingVariantItemSimpleDM[]
	@Type(() => TCapacityPlanDetailDM)
	CapacityPlanDetailChild?: TCapacityPlanDetailDM[]
	@Type(() => TJobCardScheduleQueueDM)
	JobCardScheduleChild?: TJobCardScheduleQueueDM[]
	@Type(() => TCapacityPlanDayDM)
	CapacityPlanDayChild?: TCapacityPlanDayDM[]
	@Type(() => TSubordinateResourceReducedDM)
	ResourceChild?: TSubordinateResourceReducedDM[]
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
	@Type(() => TSubordinateResourceDM)
	SubordinateResourceOldChild?: TSubordinateResourceDM[]
}