import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'

export default class TK2UserLookupDM extends Data {
	public static className = 'TK2UserLookupDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsServiceUser?: string
	UserRoleId?: number
	IsTwoFactorAuth?: string
	Center?: string
	Department?: string
	Email?: string
	Fax?: string
	Mobile?: string
	Phone?: string
	DefaultScript?: string
	CardNumber?: string
	IsWebLoginAllowed?: string
	PasswordLastBadDate?: Date
	PasswordTryLeft?: number
	PasswordHistory6?: string
	PasswordHistory5?: string
	PasswordHistory4?: string
	PasswordHistory3?: string
	PasswordHistory2?: string
	PasswordHistory1?: string
	PasswordValidDate?: Date
	PasswordPolicyId?: number
	Hash?: string
	TimeStamp?: string
	ActiveDirectorySID?: string
	Code?: string
	Note?: string
	NotUsed2?: number
	PriorityId?: number
	NotUsed1?: number
	ActiveRequest?: string
	Position?: string
	Name?: string
	Client?: string
	Id?: number
	Login?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTextDM)
	Active?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM
}