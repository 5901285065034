import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TTextDM from './TTextDM'
import TEshopDM from './TEshopDM'
import TContactPersonDM from './TContactPersonDM'
import TPartnerDM from './TPartnerDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TMarketingInformationKindDM from './TMarketingInformationKindDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TRegisteredEmailDM extends Data {
	public static className = 'TRegisteredEmailDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Username?: string
	SendNotifications?: string
	SendInformation?: string
	RID?: number
	FullElectronicAddress?: string
	CountryId?: number
	ElectronicAddress?: string
	Department?: number
	HeaderRID?: number
	ReferenceTypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	NewsLetterURLCalc?: string
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressTypeId?: TElectronicAddressTypeDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TEshopDM)
	EshopRIDCalc?: TEshopDM
	@Type(() => TContactPersonDM)
	ContactPersonRIDCalc?: TContactPersonDM
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TMarketingInformationKindDM)
	InformationKindChild?: TMarketingInformationKindDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}