import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import Personal from './Personal'
import OJCSSZ from './OJCSSZ'
import TPartnerDM from './TPartnerDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TAddressDM from './TAddressDM'
import TPrDeductionDM from './TPrDeductionDM'
import ZpusDoprClov from './ZpusDoprClov'
import DegreeName from './DegreeName'
import MaritalStatus from './MaritalStatus'
import DuvodZmPS from './DuvodZmPS'
import DruhDuchPD1 from './DruhDuchPD1'
import DruhDuchPD2 from './DruhDuchPD2'
import TContactPersonDM from './TContactPersonDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import EmpDepart from './EmpDepart'
import TaxNoType from './TaxNoType'
import TBookDM from './TBookDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TPrPeriodDM from './TPrPeriodDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTownDM from './TTownDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TLanguageSkillDM from './TLanguageSkillDM'
import TEducationDM from './TEducationDM'
import TEmployeeClassificationHistoryDM from './TEmployeeClassificationHistoryDM'
import TPreviousEmploymentDM from './TPreviousEmploymentDM'
import TFamilyDM from './TFamilyDM'
import TQualificationEmployedPersonDM from './TQualificationEmployedPersonDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TPrPersonDocumentDM from './TPrPersonDocumentDM'
import TPrDeductionAllDM from './TPrDeductionAllDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TWorkerEventAsItemDM from './TWorkerEventAsItemDM'
import TWorkerProtectionEquipmentAsItemDM from './TWorkerProtectionEquipmentAsItemDM'
import TElectronicSubmissionPersonDM from './TElectronicSubmissionPersonDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkEmployedPersonDM from './TCompanyLinkEmployedPersonDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEmployedPersonDM extends Data {
	public static className = 'TEmployedPersonDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsNightWorkingPerson?: string
	LastSurname?: string
	LuncheonVoucherNumber?: string
	CreatedOn?: Date
	XMLFieldScript?: string
	IdentityCardAuthority?: string
	IdentityCardIssuedOn?: Date
	IdentityCardExpiryDate?: Date
	RatingIsirDate?: Date
	RatingIsirValue?: string
	VATRegNumber?: string
	HealthInsurranceNumber?: string
	BroughtUpChildrenCount?: number
	RetirementSavingDateTo?: Date
	RetirementSavingDateFrom?: Date
	RetirementSavingMemberNumber?: string
	PensionDecisionNumber?: string
	Email?: string
	DisabilityDateTo?: Date
	DisabilityDateFrom?: Date
	XMLField?: string
	TimeStamp?: string
	EmailPassword?: string
	DegreeAfterName?: string
	NewFields?: string
	AdditionalInfo?: string
	PensionPayer?: string
	WorkingAbility?: string
	TemporaryPhone?: string
	PermanentPhone?: string
	DependentChildrenCount?: number
	PensionDate?: Date
	PensionTypeId?: string
	RightGroupId?: number
	ChangedOn?: Date
	BirthDate?: Date
	BirthName?: string
	Degree?: string
	Surname?: string
	Id?: number
	DriversLicence?: string
	PassportNumber?: string
	IdentityCardNumber?: string
	MaritalStatusDate?: Date
	Phone2?: string
	Phone1?: string
	BirthIdNumber?: string
	FirstName?: string
	EmployeePersonalNumber?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	VersionFieldDateCalc?: Date
	BirthIdNumberPart2Calc?: string
	BirthIdNumberPart1Calc?: string
	ForenameSurnameCalc?: string
	ForenameSurnameTitleCalc?: string
	FullNameCalc?: string
	FullNameWithTitleCalc?: string
	BirthIdNumberCalc?: string
	PensionDrawedBitCalc?: number
	TaxNonResidentBitCalc?: number
	NoCheckBirthIdNumberBitCalc?: number
	InvalidRecordBitCalc?: number
	PensionSavingMemberBitCalc?: number
	TaxHavenBitCalc?: number
	AgencyWorkerBitCalc?: number
	DuplicateBirthIdNumberBitCalc?: number
	LimitedProcessingBitCalc?: number
	IsItemCompleteCalc?: string
	SocialSecurityNumberCalc?: string
	SocialInsuranceNumberCalc?: string
	HealthInsuranceNumberCalc?: string
	IsirRank16ImgCalc?: string
	IsirRank32ImgCalc?: string
	PensionClaimDateCalc?: Date
	PassportAuthorityValueCalc?: string
	PassportValidityCalc?: Date
	ForeignerTypeCalc?: string
	InformationCardDateCalc?: Date
	WorkPermitNumberCalc?: string
	WorkPermitDateCalc?: Date
	WorkPermitExpirationDateCalc?: Date
	BlueCardNumberCalc?: string
	BlueCardDateCalc?: Date
	BlueCardExpiryDateCalc?: Date
	EmployeeCardNumberCalc?: string
	EmployeeCardIssueDateCalc?: Date
	EmployeeCardExpirationDateCalc?: Date
	InformationCardDateEndCalc?: Date
	DeathDateCalc?: Date
	BirthIdNumberWithoutSlashCalc?: string
	PersonalNumberCalc?: number
	FieldHistoryDateCalc?: Date
	ConfirmedPeriodIdCalc?: number
	ConfirmedOnCalc?: Date
	AgeCalc?: number
	FirstPictureFileNameCalc?: string
	PassportNumberCalc?: string
	PassportAuthorityCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	PermanentAddressId_ver?: number
	TemporaryAddressId_ver?: number
	ContactAddressId_ver?: number
	PensionPayerAddressId_ver?: number
	PermanentAddressIdStreetCalc?: string
	PermanentAddressIdOrientationNumberCalc?: string
	PermanentAddressIdHouseNumberCalc?: string
	PermanentAddressIdNameCalc?: string
	PermanentAddressIdChangedOnCalc?: Date
	PermanentAddressIdTownCodeCalc?: string
	PermanentAddressIdCoordinatesCalc?: string
	TemporaryAddressIdStreetCalc?: string
	TemporaryAddressIdOrientationNumberCalc?: string
	TemporaryAddressIdHouseNumberCalc?: string
	TemporaryAddressIdNameCalc?: string
	TemporaryAddressIdChangedOnCalc?: Date
	TemporaryAddressIdTownCodeCalc?: string
	TemporaryAddressIdCoordinatesCalc?: string
	ContactAddressIdStreetCalc?: string
	ContactAddressIdOrientationNumberCalc?: string
	ContactAddressIdHouseNumberCalc?: string
	ContactAddressIdNameCalc?: string
	ContactAddressIdChangedOnCalc?: Date
	ContactAddressIdTownCodeCalc?: string
	ContactAddressIdCoordinatesCalc?: string
	PensionPayerAddressIdStreetCalc?: string
	PensionPayerAddressIdOrientationNumberCalc?: string
	PensionPayerAddressIdHouseNumberCalc?: string
	PensionPayerAddressIdNameCalc?: string
	PensionPayerAddressIdChangedOnCalc?: Date
	PensionPayerAddressIdTownCodeCalc?: string
	PensionPayerAddressIdCoordinatesCalc?: string
	DependentChildrenCount_ver?: number
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	NativeLanguageId?: TTextDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => Personal)
	TypeId?: Personal
	@Type(() => OJCSSZ)
	OrganizationUnitPWDsId?: OJCSSZ
	@Type(() => TTextDM)
	SexId?: TTextDM
	@Type(() => TPartnerDM)
	RetirementSavingPartnerId?: TPartnerDM
	@Type(() => TTextDM)
	DisabilityId?: TTextDM
	@Type(() => TCountryDM)
	ResidentCountryId?: TCountryDM
	@Type(() => TCountryDM)
	NationalityCountryId?: TCountryDM
	@Type(() => TCountryDM)
	BirthCountryId?: TCountryDM
	@Type(() => TTownPartDM)
	BirthTownPartId?: TTownPartDM
	@Type(() => TAddressDM)
	PensionPayerAddressId?: TAddressDM
	@Type(() => TAddressDM)
	ContactAddressId?: TAddressDM
	@Type(() => TAddressDM)
	TemporaryAddressId?: TAddressDM
	@Type(() => TAddressDM)
	PermanentAddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPrDeductionDM)
	HealthInsuranceId?: TPrDeductionDM
	@Type(() => ZpusDoprClov)
	TransportMode?: ZpusDoprClov
	@Type(() => DegreeName)
	EducationLevel?: DegreeName
	@Type(() => MaritalStatus)
	MaritalStatus?: MaritalStatus
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => DuvodZmPS)
	WorkingAbility1Calc?: DuvodZmPS
	@Type(() => DruhDuchPD1)
	PensionType1Calc?: DruhDuchPD1
	@Type(() => DruhDuchPD2)
	PensionType2Calc?: DruhDuchPD2
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM
	@Type(() => TPrEmploymentSimpleDM)
	MainEmploymentIdCalc?: TPrEmploymentSimpleDM
	@Type(() => TCountryDM)
	DependantCountryIdCalc?: TCountryDM
	@Type(() => EmpDepart)
	EDBranchIdCalc?: EmpDepart
	@Type(() => TaxNoType)
	TaxNumberTypeIdCalc?: TaxNoType
	@Type(() => TCountryDM)
	PassportCountryIdCalc?: TCountryDM
	@Type(() => TCountryDM)
	TaxDocumentBitCalc?: TCountryDM
	@Type(() => TCountryDM)
	DriverLicenceCountryIdCalc?: TCountryDM
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM
	@Type(() => TPrEmploymentDM)
	MainPrEmploymentIdCalc?: TPrEmploymentDM
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	DisabilityLevelIdCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TCountryDM)
	PermanentAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	PermanentAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	PermanentAddressIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	TemporaryAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	TemporaryAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	TemporaryAddressIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	ContactAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	ContactAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	ContactAddressIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	PensionPayerAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	PensionPayerAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	PensionPayerAddressIdTownIdCalc?: TTownDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TPrDeductionDM)
	HealthInsuranceId_ver?: TPrDeductionDM
	@Type(() => TTextDM)
	DisabilityLevelIdCalc_ver?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageSkillDM)
	LanguageChild?: TLanguageSkillDM[]
	@Type(() => TEducationDM)
	EducationChild?: TEducationDM[]
	@Type(() => TEmployeeClassificationHistoryDM)
	PersonalHistoryChild?: TEmployeeClassificationHistoryDM[]
	@Type(() => TPreviousEmploymentDM)
	PreviousEmploymentChild?: TPreviousEmploymentDM[]
	@Type(() => TFamilyDM)
	FamilyMemberChild?: TFamilyDM[]
	@Type(() => TQualificationEmployedPersonDM)
	TrainingChild?: TQualificationEmployedPersonDM[]
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]
	@Type(() => TPrLiabilityDM)
	PrLiabilityChild?: TPrLiabilityDM[]
	@Type(() => TPrEmployeeDocumentDM)
	PrBookDocumentChild?: TPrEmployeeDocumentDM[]
	@Type(() => TPrPersonDocumentDM)
	PrPersonDocumentChild?: TPrPersonDocumentDM[]
	@Type(() => TPrDeductionAllDM)
	DeductionChild?: TPrDeductionAllDM[]
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanEmpCompleteChild?: TPrDefinitionComponentDM[]
	@Type(() => TWorkerEventAsItemDM)
	WorkerEventChild?: TWorkerEventAsItemDM[]
	@Type(() => TWorkerProtectionEquipmentAsItemDM)
	WorkerProtectiveEquipmentChild?: TWorkerProtectionEquipmentAsItemDM[]
	@Type(() => TElectronicSubmissionPersonDM)
	ElectronicSubmissionPersonChild?: TElectronicSubmissionPersonDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkEmployedPersonDM)
	CompanyChild?: TCompanyLinkEmployedPersonDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}