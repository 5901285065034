import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TShippingMethodDM from './TShippingMethodDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import FormOfOrder from './FormOfOrder'
import TAllowedTransportMethodDM from './TAllowedTransportMethodDM'
import TAllowedPaymentMethodDM from './TAllowedPaymentMethodDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import JCDP20_a1 from './JCDP20_a1'
import ABCClass from './ABCClass'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TPartnerDM from './TPartnerDM'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Domain from './Domain'
import AreaCode from './AreaCode'
import TPaymentMethodDM from './TPaymentMethodDM'
import TPriceGroupDM from './TPriceGroupDM'
import Code from './Code'
import CustomerGroup from './CustomerGroup'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TCountryDM from './TCountryDM'
import PartnerStatus from './PartnerStatus'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TCurrencyDM from './TCurrencyDM'
import TTaxTypeDM from './TTaxTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TServiceNoteDM from './TServiceNoteDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TOpenItemListDM from './TOpenItemListDM'
import TReminderDM from './TReminderDM'
import TOpportunityDM from './TOpportunityDM'
import TQuotationDM from './TQuotationDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TShipmentDM from './TShipmentDM'
import TParcelDM from './TParcelDM'
import TPostEnvelopeSentDM from './TPostEnvelopeSentDM'
import TPostEnvelopeReceivedDM from './TPostEnvelopeReceivedDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TReceiptCardDM from './TReceiptCardDM'
import TReservingCardDM from './TReservingCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TABCItemDM from './TABCItemDM'
import TPaymentConditionTradingPartnerPurchaseDM from './TPaymentConditionTradingPartnerPurchaseDM'
import TPaymentConditionTradingPartnerSaleDM from './TPaymentConditionTradingPartnerSaleDM'
import TRelatedAddressDM from './TRelatedAddressDM'
import TAdressKeyItemDM from './TAdressKeyItemDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TBankAccountTradingPartnerDM from './TBankAccountTradingPartnerDM'
import TPartnerContactPersonDM from './TPartnerContactPersonDM'
import TBranchDM from './TBranchDM'
import TTradingPartnerPriceGroupDM from './TTradingPartnerPriceGroupDM'
import TCustomerCreditDM from './TCustomerCreditDM'
import TOpenItemListTrendDM from './TOpenItemListTrendDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TABCItemChartSourceDM from './TABCItemChartSourceDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TProductCostingDM from './TProductCostingDM'
import TTradingPartnerTransportDM from './TTradingPartnerTransportDM'
import TVATRegNumberDM from './TVATRegNumberDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkTradingPartnerDM from './TCompanyLinkTradingPartnerDM'
import TLegislationLinkTradingPartnerDM from './TLegislationLinkTradingPartnerDM'
import TSpecialDistributionSettingsItem from './TSpecialDistributionSettingsItem'
import TSpecialTradingPartnerNettoPriceItem from './TSpecialTradingPartnerNettoPriceItem'
import TSpecialTTradingPartnerDMCustomerPrices from './TSpecialTTradingPartnerDMCustomerPrices'
import TSpecialTTradingPartnerDMArticleCustomerPrice from './TSpecialTTradingPartnerDMArticleCustomerPrice'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TTradingPartnerDM extends Data {
	public static className = 'TTradingPartnerDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TransportDescription?: string
	IsEnabledEdi?: string
	EdiGln?: string
	IsFictitious?: string
	CreatePurchaseReminders?: string
	CreateSaleReminders?: string
	AvgDelayPurchase?: number
	DataBoxId?: string
	PAPPartnerId?: string
	CreditExists?: string
	MaturityDayCount?: number
	PaymentShiftMonthCount?: number
	RatingCrpFromDate?: Date
	RatingCrpDate?: Date
	RatingCrpValue?: number
	TimeStamp?: string
	CreatedOn?: Date
	TaxNumber?: string
	BusinessRegister?: string
	DiscountPercentage?: number
	AvgDelaySale?: number
	Reserved3?: string
	Reserved2?: string
	Reserved1?: string
	RightGroupId?: number
	CurrentStateInvOut?: number
	CurrentStateInvIn?: number
	Abbr2?: string
	ChangedOn?: Date
	MaxLoan?: number
	MaxDebt?: number
	Penalty?: number
	VATRegNumber?: string
	CompanyRegNumber?: string
	Name?: string
	Abbr?: string
	Id?: number
	Ex_BulkSendConfEmail?: string
	Ex_EAN?: string
	Ex_DatumRKS?: Date
	Ex_MaxDaysOverdue?: number
	EX_TISKDLCENA?: number
	Ex_Flagy?: string
	EX_IDPROVOZ?: string
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	VersionFieldDateCalc?: Date
	CreditEvidenceBitCalc?: number
	CompanyCurrencyCalc?: string
	NameAndAddressCalc?: string
	BankIdCalc?: number
	BankAccountNumberCalc?: string
	ForeignBankBitCalc?: number
	AdvProvidedDeductedAmountCalc?: number
	ExistsTrPartAdvNotPaidBitCalc?: number
	AdvReceiveDeductedAmountCalc?: number
	AdvProvRemainsToDedcAmountCalc?: number
	CompanyRegNumberCalc?: string
	VATRegistrationNumberCalc?: string
	TradeRegAutomaticTaxNumberCalc?: string
	TaxNumberCalc?: string
	ElectronicAddress1Calc?: string
	ElectronicAddress2Calc?: string
	ElectronicAddress3Calc?: string
	ElectronicAddress4Calc?: string
	IBANCalc?: string
	ExistsAdvReceiveUnpaidBitCalc?: number
	ExistsAdvanceProvidedUnpaidBitCalc?: number
	ExistsAdvReceiveNotPaidImgCalc?: string
	ExistsAdvProvidedNotPaidImgCalc?: string
	ElectronicAddressCheckItemFieldCalc?: number
	ElectronicAddress1FormattedCalc?: string
	ElectronicAddress2FormattedCalc?: string
	ElectronicAddress3FormattedCalc?: string
	ElectronicAddress4FormattedCalc?: string
	BankSpecificSymbolCalc?: string
	InvoiceAddressNameCalc?: string
	DeliveryAddressNameCalc?: string
	PostalAddressNameCalc?: string
	ConfirmedInvoiceOutBitCalc?: number
	BlockingCreateInvoiceOutBitCalc?: number
	ConfirmedInvoiceInBitCalc?: number
	BlockingCreateInvoiceInBitCalc?: number
	ConfirmedReleaseNoteBitCalc?: number
	ConfirmedReceiptCardBitCalc?: number
	BlockingCreateReceiptCardBitCalc?: number
	ConfirmedReservingCardBitCalc?: number
	BlockingCreateReservingCardBitCalc?: number
	InvalidRecordBitCalc?: number
	CentralInvoiceAddressBitCalc?: number
	CentralDeliveryAddressBitCalc?: number
	CentralPostalAddressBitCalc?: number
	FloatingMaturityPurchaseBitCalc?: number
	FloatingMaturitySaleBitCalc?: number
	RedefineABCBitCalc?: number
	RecalculationByPeriodBitCalc?: number
	HealthHandicapIdCalc?: number
	CreditInStateCalc?: number
	CreditOutStateCalc?: number
	ConsolidatedGroupCompanyIdCalc?: number
	BankCodeNumberCalc?: string
	IsRatingCreditcheckEnabledCalc?: string
	IsRatingIsirEnabledCalc?: string
	IsRatingViesEnabledCalc?: string
	IsRatingCrpEnabledCalc?: string
	IsRatingEnabledCalc?: string
	ArticleFromPriceListIdCalc?: number
	IntrastatCountryIdCalc?: number
	CreditWarningCalc?: number
	IsNotifyCreditCalc?: string
	LimitedProcessingBitCalc?: number
	PhysicalPersonIdCalc?: number
	ActualMaturityDaysCalc?: number
	PlannedMaturityDaysCalc?: number
	GroupVATIdCalc?: number
	TransportMethodRIDCalc?: number
	ABCAnalysisDefaultConfigCalc?: string
	IntrastatOutsideEUBitCalc?: number
	EAddressPARAMCalc?: string
	InternalTextCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdChangedOnCalc?: Date
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	CompanyRegNumber_ver?: string
	Name_ver?: string
	BusinessRegister_ver?: string
	VATRegNumber_ver?: string
	TaxNumber_ver?: string
	IsVATPayerPurchaseCalc?: string
	IsVATPayerSaleCalc?: string
	IsStatutoryInterestPurchaseCalc?: string
	IsStatutoryInterestSaleCalc?: string
	CF_SaldoPoSpl?: number
	HasInvoiceOverdueCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TBookDM)
	PurchaseBankBookId?: TBookDM
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => FormOfOrder)
	OrderFormId?: FormOfOrder
	@Type(() => TAllowedTransportMethodDM)
	AllowedTransportMethodRID?: TAllowedTransportMethodDM
	@Type(() => TAllowedPaymentMethodDM)
	AllowedPaymentMethodRID?: TAllowedPaymentMethodDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TAccountingChartDM)
	DebitAccAdvPrvId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccAdvPrvId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	DebitAccAdvRecId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccAdvRecId?: TAccountingChartDM
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1
	@Type(() => ABCClass)
	ABCClassCalcId?: ABCClass
	@Type(() => ABCClass)
	ABCClassId?: ABCClass
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TTextDM)
	LangId?: TTextDM
	@Type(() => Domain)
	AreaId?: Domain
	@Type(() => AreaCode)
	TerritoryId?: AreaCode
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	PayablesAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	ReceivablesAccountId?: TAccountingChartDM
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => Code)
	CodeId?: Code
	@Type(() => CustomerGroup)
	GroupId?: CustomerGroup
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	BlockInvoiceOutBitCalc?: TTextDM
	@Type(() => TTextDM)
	BlockInvoiceInBitCalc?: TTextDM
	@Type(() => TTextDM)
	BlockReleaseNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	BlockReceiptCardBitCalc?: TTextDM
	@Type(() => TTextDM)
	BlockReservingCardBitCalc?: TTextDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM
	@Type(() => PartnerStatus)
	StatusIdCalc?: PartnerStatus
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TAddressDM)
	AddressId_ver?: TAddressDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCompanyDividedBookDM)
	BankBookIdCalc?: TCompanyDividedBookDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeIdCalc?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	SaleTaxTypeIdCalc?: TTaxTypeDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteSupplierChild?: TServiceNoteDM[]
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderChild?: TPurchaseOrderDM[]
	@Type(() => TOpenItemListDM)
	OpenItemChild?: TOpenItemListDM[]
	@Type(() => TReminderDM)
	ReminderChild?: TReminderDM[]
	@Type(() => TOpportunityDM)
	OpportunityChild?: TOpportunityDM[]
	@Type(() => TQuotationDM)
	QuotationChild?: TQuotationDM[]
	@Type(() => TRequestForQuotationDM)
	RequestForQuotationChild?: TRequestForQuotationDM[]
	@Type(() => TShipmentDM)
	ShipmentChild?: TShipmentDM[]
	@Type(() => TParcelDM)
	ParcelChild?: TParcelDM[]
	@Type(() => TPostEnvelopeSentDM)
	PostEnvelopeSentChild?: TPostEnvelopeSentDM[]
	@Type(() => TPostEnvelopeReceivedDM)
	PostEnvelopeReceivedChild?: TPostEnvelopeReceivedDM[]
	@Type(() => TPurchaseItemBookDM)
	PurchaseItemChild?: TPurchaseItemBookDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedChild?: TAdvanceReceivedDM[]
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedChild?: TAdvanceProvidedDM[]
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationChild?: TDeliveryConfirmationDM[]
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]
	@Type(() => TInvoiceInDM)
	InvoiceInChild?: TInvoiceInDM[]
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]
	@Type(() => TReceiptCardDM)
	ReceiptCardChild?: TReceiptCardDM[]
	@Type(() => TReservingCardDM)
	ReservingCardChild?: TReservingCardDM[]
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]
	@Type(() => TABCItemDM)
	ABCItemChild?: TABCItemDM[]
	@Type(() => TPaymentConditionTradingPartnerPurchaseDM)
	PaymentConditionPurchaseChild?: TPaymentConditionTradingPartnerPurchaseDM[]
	@Type(() => TPaymentConditionTradingPartnerSaleDM)
	PaymentConditionSaleChild?: TPaymentConditionTradingPartnerSaleDM[]
	@Type(() => TRelatedAddressDM)
	AddressInvoiceChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressDeliveryChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressPostalChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressAllChild?: TRelatedAddressDM[]
	@Type(() => TAdressKeyItemDM)
	AddressKeyChild?: TAdressKeyItemDM[]
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]
	@Type(() => TBankAccountTradingPartnerDM)
	BankAccountChild?: TBankAccountTradingPartnerDM[]
	@Type(() => TPartnerContactPersonDM)
	ContactPersonChild?: TPartnerContactPersonDM[]
	@Type(() => TPriceListDM)
	PriceListChild?: TPriceListDM[]
	@Type(() => TBranchDM)
	BranchChild?: TBranchDM[]
	@Type(() => TTradingPartnerPriceGroupDM)
	TradingPartnerPriceGroupChild?: TTradingPartnerPriceGroupDM[]
	@Type(() => TCustomerCreditDM)
	CreditChild?: TCustomerCreditDM[]
	@Type(() => TOpenItemListTrendDM)
	OpenItemListTrendChild?: TOpenItemListTrendDM[]
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]
	@Type(() => TABCItemChartSourceDM)
	ABCItemChartSourceChild?: TABCItemChartSourceDM[]
	@Type(() => TSupplierPriceDM)
	SupplierPriceChild?: TSupplierPriceDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TTradingPartnerTransportDM)
	TransportChild?: TTradingPartnerTransportDM[]
	@Type(() => TVATRegNumberDM)
	VATRegNumberChild?: TVATRegNumberDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkTradingPartnerDM)
	CompanyChild?: TCompanyLinkTradingPartnerDM[]
	@Type(() => TLegislationLinkTradingPartnerDM)
	LegislationChild?: TLegislationLinkTradingPartnerDM[]
	@Type(() => TSpecialDistributionSettingsItem)
	DistributionSettingsChild?: TSpecialDistributionSettingsItem[]
	@Type(() => TSpecialTradingPartnerNettoPriceItem)
	NettoPriceChild?: TSpecialTradingPartnerNettoPriceItem[]
	@Type(() => TSpecialTTradingPartnerDMCustomerPrices)
	CustomerPrices?: TSpecialTTradingPartnerDMCustomerPrices[]
	@Type(() => TSpecialTTradingPartnerDMArticleCustomerPrice)
	ArticleCustomerPrice?: TSpecialTTradingPartnerDMArticleCustomerPrice[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}