import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'

export default class TArticleBarCodeDM extends Data {
	public static className = 'TArticleBarCodeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Description?: string
	Barcode?: string
	Id?: number
	TypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	EDIBitCalc?: number
	DefaultBitCalc?: number
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM
}