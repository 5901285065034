import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TQualificationModelDM from './TQualificationModelDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TContactPersonDM from './TContactPersonDM'
import TAddressDM from './TAddressDM'
import TRNDesignate from './TRNDesignate'
import TRNAction from './TRNAction'
import TRNContent from './TRNContent'
import TRNGoal from './TRNGoal'
import TRNScreening from './TRNScreening'
import TTextDM from './TTextDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TRNState from './TRNState'
import TRNForm from './TRNForm'
import TPartnerDM from './TPartnerDM'
import TRNCourse from './TRNCourse'
import TRNTyp from './TRNTyp'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TQualificationContactPersonDM from './TQualificationContactPersonDM'
import TQualificationEmployedPersonDM from './TQualificationEmployedPersonDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TQualificationDM extends Data {
	public static className = 'TQualificationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ConfirmedOn?: Date
	IsRecordCanceled?: string
	RID?: number
	RightGroupId?: number
	XMLField?: string
	ActionMemo?: string
	ContentMemo?: string
	EvaluationMemo?: string
	DesignateMemo?: string
	GoalMemo?: string
	TimeStamp?: string
	DesignateAbbr?: string
	ActionAbbr?: string
	ContentAbbr?: string
	GoalAbbr?: string
	EvaluationAbbr?: string
	DesignateDate?: Date
	ActionDate?: Date
	ContentDate?: Date
	GoalDate?: Date
	EvaluationDate?: Date
	URL?: string
	MaxCapacity?: number
	MinCapacity?: number
	Period?: number
	Position?: string
	CreatedOn?: Date
	ChangedOn?: Date
	Place?: string
	NextDate?: Date
	ValidTo?: Date
	Duration?: number
	EndDate?: Date
	StartDate?: Date
	Costs?: number
	Name?: string
	Description?: string
	Abbr?: string
	Number?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	HourNumberCalc?: number
	TimeFromCalc?: Date
	TimeToCalc?: Date
	DateFromCalc?: Date
	DateToCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TQualificationModelDM)
	QualificationModelRID?: TQualificationModelDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TContactPersonDM)
	Tutor2Id?: TContactPersonDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TContactPersonDM)
	DesignateById?: TContactPersonDM
	@Type(() => TRNDesignate)
	DesignateId?: TRNDesignate
	@Type(() => TContactPersonDM)
	ActionById?: TContactPersonDM
	@Type(() => TRNAction)
	ActionId?: TRNAction
	@Type(() => TContactPersonDM)
	ContentById?: TContactPersonDM
	@Type(() => TRNContent)
	ContentId?: TRNContent
	@Type(() => TContactPersonDM)
	GoalById?: TContactPersonDM
	@Type(() => TRNGoal)
	GoalId?: TRNGoal
	@Type(() => TContactPersonDM)
	EvaluationById?: TContactPersonDM
	@Type(() => TRNScreening)
	EvaluationId?: TRNScreening
	@Type(() => TTextDM)
	PeriodUnitId?: TTextDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TEmployedPersonSimpleDM)
	ApprovedById?: TEmployedPersonSimpleDM
	@Type(() => TRNState)
	StatusId?: TRNState
	@Type(() => TContactPersonDM)
	TutorId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TRNForm)
	ExamFormId?: TRNForm
	@Type(() => TPartnerDM)
	OrganizedById?: TPartnerDM
	@Type(() => TRNCourse)
	CourseId?: TRNCourse
	@Type(() => TRNTyp)
	TypeId?: TRNTyp
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TQualificationContactPersonDM)
	ContactPersonTrainingChild?: TQualificationContactPersonDM[]
	@Type(() => TQualificationEmployedPersonDM)
	PersonnelDataTrainingChild?: TQualificationEmployedPersonDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}