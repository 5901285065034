import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TAttributeValueDM extends Data {
	public static className = 'TAttributeValueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	Value?: string
	AttributeId?: number
	HeaderRID?: number
	HeaderDM?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IconCalc?: number
	ValueCalc?: string
	ValueLongCalc?: number
	ValueDecimalCalc?: number
	ValueDateCalc?: Date
	ValueTimeCalc?: Date
	ValueListCalc?: string
	CheckedBindListValueCalc?: string
	@Type(() => TTextDM)
	ValueBoolCalc?: TTextDM
	@Type(() => TTextDM)
	ValueBoolEmptyCalc?: TTextDM
}