import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TPriceListCalculationDM from './TPriceListCalculationDM'

export default class TPriceListItemDM extends Data {
	public static className = 'TPriceListItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	PriceListBookId?: number
	ValidToDate?: Date
	ValidFromDate?: Date
	IsOnlyForMultipleOfQuantity?: string
	ExchangeRate?: number
	IsEdited?: string
	IsOnlyForMeasureUnit?: string
	IsFinalPrice?: string
	PriceGross?: number
	OriginalPriceNet?: number
	DefinitionRID?: number
	PriceNet?: number
	Quantity?: number
	ItemName?: string
	PriceListRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsByDefinitionCalc?: string
	VATCoefficientCalc?: number
	CurrencyCodeCalc?: string
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TPriceListCalculationDM)
	PriceCalculationChild?: TPriceListCalculationDM[]
}