import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TSpecialStringListDM from './TSpecialStringListDM'
import TCurrencyDM from './TCurrencyDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

export default class TArticleSupplierDatePriceDM extends Data {
	public static className = 'TArticleSupplierDatePriceDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	NodeId?: number
	Overheads?: number
	ItemNumber?: number
	DateTo?: Date
	SellingPrice?: number
	ProductGroupId?: number
	MarginId?: number
	CustomsTariffId?: number
	StockPrice?: number
	PurchaseCost?: number
	PurchasePrice?: number
	ExchangeRate?: number
	DateFrom?: Date
	RecordStateImgCalc?: string
	ExchangeRateCalc?: number
	PurchasePriceCalc?: number
	PurchaseCostCalc?: number
	StockPriceCalc?: number
	SalePriceCalc?: number
	VATCoefficientCalc?: number
	TotalCalc?: number
	AmountVATCalc?: number
	MarginPctCalc?: number
	DateCalc?: Date
	OverheadsCalc?: number
	PurchaseCostPctImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => TSpecialStringListDM)
	Name?: TSpecialStringListDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	PurchaseCostsPctCalc?: TTextDM
}