import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TWebContactPersonDM from './TWebContactPersonDM'
import TWebBranchDM from './TWebBranchDM'

export default class TWebAppointmentMemoryDM extends Data {
	public static className = 'TWebAppointmentMemoryDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	FreePlaces?: number
	Capacity?: number
	Description?: string
	DateTo?: Date
	DateFrom?: Date
	Place?: string
	WebLinkRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DateFromCalc?: Date
	DateToCalc?: Date
	TimeFromCalc?: Date
	TimeToCalc?: Date
	@Type(() => TWebContactPersonDM)
	LectorRID?: TWebContactPersonDM
	@Type(() => TWebBranchDM)
	BranchRID?: TWebBranchDM
}