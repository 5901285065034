import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import WarrantyB from './WarrantyB'
import TBatchDM from './TBatchDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMarketingPhaseDM from './TMarketingPhaseDM'
import TVATRateDM from './TVATRateDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TRequestForQuotationRequestedItemDM from './TRequestForQuotationRequestedItemDM'
import ReqForQuotOfrItemStat from './ReqForQuotOfrItemStat'
import TArticleDM from './TArticleDM'
import MrktngDocItemGroup from './MrktngDocItemGroup'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TQuotationOfferedItemAloneDM from './TQuotationOfferedItemAloneDM'

export default class TRequestForQuotationOfferedItemDM extends Data {
	public static className = 'TRequestForQuotationOfferedItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ImplementationDate?: Date
	QuotationItemRID?: number
	InternalText?: string
	BusinessText?: string
	EstimatedPrice?: number
	CostPrice?: number
	ListPrice?: number
	OfferedPrice?: number
	MeasureUnitId?: number
	Quantity?: number
	ArticleName?: string
	SequenceNumber?: number
	ItemTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityUMCalc?: number
	CurrencyCalc?: string
	ExchangeRateCalc?: number
	DiscountCalc?: number
	UnitDiscountCalc?: number
	TotalDiscountCalc?: number
	DiscountCCalc?: number
	TotalDiscountCCalc?: number
	EstimatedUnitPriceCalc?: number
	EstimatedTotalPriceCalc?: number
	EstimatedPriceCCalc?: number
	EstimatedTotalPriceCCalc?: number
	CatalogUnitPriceCalc?: number
	CatalogTotalPriceCalc?: number
	CatalogUnitPriceCCalc?: number
	CatalogTotalPriceCCalc?: number
	OfferedUnitPriceNetCalc?: number
	OfferedTotalPriceNetCalc?: number
	OfferedUnitPriceNetCCalc?: number
	OfferedTotalPriceNetCCalc?: number
	UnitCostPriceCalc?: number
	TotalCostPriceCalc?: number
	CostPriceCCalc?: number
	TotalCostPriceCCalc?: number
	MeasureUnitCalc?: string
	MeasureUnitCoefficientCalc?: number
	RequiredItemNameCalc?: string
	ArticleNameCalc?: string
	UseForDocumentTotalAmountBitCalc?: number
	DiscountPctCalc?: number
	OfferedUnitPriceGrossCalc?: number
	OfferedTotalPriceGrossCalc?: number
	OfferedUnitPriceGrossCCalc?: number
	OfferedTotalPriceGrossCCalc?: number
	MainCurrencyCalc?: string
	CoefficientVATCalc?: number
	ImplementationDateCalc?: Date
	NotContainsArticleCalc?: string
	DispatchCalc?: number
	DispatchUMCalc?: number
	ArticleTypeIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => WarrantyB)
	WarrantyId?: WarrantyB
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TMarketingPhaseDM)
	PhaseRID?: TMarketingPhaseDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TRequestForQuotationRequestedItemDM)
	RequiredItemRID?: TRequestForQuotationRequestedItemDM
	@Type(() => ReqForQuotOfrItemStat)
	ItemStatusId?: ReqForQuotOfrItemStat
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => MrktngDocItemGroup)
	GroupId?: MrktngDocItemGroup
	@Type(() => TRequestForQuotationDM)
	HeaderRID?: TRequestForQuotationDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitByArticleIdCalc?: TMeasureUnitDM
	@Type(() => AlternativeUnit)
	GeneralMueasureUnitIdCalc?: AlternativeUnit
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => TQuotationOfferedItemAloneDM)
	QuotationOfferedChild?: TQuotationOfferedItemAloneDM[]
}