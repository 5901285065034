import Data from '../Api/Data'

export default class TTextDM extends Data {
	public static className = 'TTextDM'
	public static systemFields = [
	]

	Abbr?: string
	Description?: string
	Id?: number
	TypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ImageCalc?: string
}