import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ElectroGroup from './ElectroGroup'
import TCurrencyDM from './TCurrencyDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'

export default class TArticleElectricalWasteDM extends Data {
	public static className = 'TArticleElectricalWasteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Koeficient?: number
	Price?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionFieldDateCalc?: Date
	Price_ver?: number
	@Type(() => ElectroGroup)
	ElectroGroupId?: ElectroGroup
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => GoodsName)
	ArticleName?: GoodsName
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TArticleDM)
	HeaderId?: TArticleDM
	@Type(() => ElectroGroup)
	ElectroGroupId_ver?: ElectroGroup
}