import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import FamilyMemberType from './FamilyMemberType'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TMarketingFamilyMemberDM extends Data {
	public static className = 'TMarketingFamilyMemberDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	BirthDate?: Date
	Name?: string
	SequenceNumber?: number
	ContactPersonId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => FamilyMemberType)
	RelationshipId?: FamilyMemberType
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}