import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TUserRoleDM from './TUserRoleDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TFragmentAssignDM extends Data {
	public static className = 'TFragmentAssignDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	Mandant?: string
	OwnerRID?: number
	FragmentId?: number
	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => TTextDM)
	Level?: TTextDM
	@Type(() => TUserRoleDM)
	UserGroupRIDCalc?: TUserRoleDM
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM
}