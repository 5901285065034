import React, { SyntheticEvent } from 'react'
import { Table, Button, Form, FormControl } from 'react-bootstrap'
import { instanceToInstance } from 'class-transformer'
import Api from 'react-k2-api/Api'
import Item from 'components/Item'
import TSalesOrderDM from 'react-k2-api/Data/TSalesOrderDM'
import TSalesItemDM from 'react-k2-api/Data/TSalesItemDM'
import TArticleDM from 'react-k2-api/Data/TArticleDM'
import DocStatus from 'react-k2-api/Data/ContractStatus'
import TBookDM from 'react-k2-api/Data/TBookDM'
import TTradingPartnerDM from 'react-k2-api/Data/TTradingPartnerDM'
import FormOfOrder from 'react-k2-api/Data/FormOfOrder'
import TWarehouseDM from 'react-k2-api/Data/TWarehouseDM'

export type OrderProps = {
    statusId: {
        open: number,
        free: number,
        pending: number
    },
    orderForm: string,
    tradingPartnerId: number,
    bookId: number,
    plannedWarehouseId: number
}

export type OrderState = {
    order: TSalesOrderDM,
    article: string
}

export default class Order extends React.Component<OrderProps, OrderState> {
    constructor(props: OrderProps) {
        super(props)

        this.state = {
            order: new TSalesOrderDM(),
            article: ''
        }

        this.handleArticleChange = this.handleArticleChange.bind(this)
        this.handleArticleSubmit = this.handleArticleSubmit.bind(this)
        this.handleCancelClick = this.handleCancelClick.bind(this)
        this.handleFinishClick = this.handleFinishClick.bind(this)
    }

    private api = new Api()
    private articleInput: HTMLInputElement | null = null

    private static fields = [
        'StatusId.Id',
        'Currency.Abbr',
        'SalesItemChild.ItemNumber',
        'SalesItemChild.ArticleId.LanguageTextCalc',
        'SalesItemChild.Quantity',
        'SalesItemChild.MeasureUnitId.Abbr'
    ]

    componentDidMount() {
        const orderRID = localStorage.getItem('orderRID')
        if (orderRID) {
            this.api.getData(TSalesOrderDM, orderRID, Order.fields).then((order: TSalesOrderDM) => {
                if (order.StatusId?.Id === this.props.statusId.free || order.StatusId?.Id === this.props.statusId.open) {
                    this.setState({ order })
                } else {
                    localStorage.removeItem('orderRID')
                }
            }).catch(() => {
                localStorage.removeItem('orderRID')
            })
        }

        this.articleInput?.focus()
    }

    async initOrder(): Promise<TSalesOrderDM> {
        return this.api.getDataList(TSalesOrderDM, Order.fields, [
            `BookId.Id;EQ;${this.props.bookId}`,
            `StatusId.Id;EQ;${this.props.statusId.free}`,
            `OrderForm.Abbr;EQ;${this.props.orderForm}`
        ]).then(orderList => {
            if (orderList.length > 0) {
                const order = orderList[0] as TSalesOrderDM
                order.StatusId = new DocStatus()
                order.StatusId.Id = this.props.statusId.open
                return this.api.putData(order, Order.fields)
            } else {
                const order = new TSalesOrderDM()
                order.BookId = new TBookDM()
                order.BookId.Id = this.props.bookId
                order.TradingPartnerId = new TTradingPartnerDM()
                order.TradingPartnerId.Id = this.props.tradingPartnerId
                order.Description = 'Samoobsluha'
                order.StatusId = new DocStatus()
                order.StatusId.Id = this.props.statusId.open
                order.OrderForm = new FormOfOrder()
                order.OrderForm.Abbr = this.props.orderForm

                return this.api.postData(order, Order.fields)
            }
        })
    }

    removeItem(salesItem: TSalesItemDM) {
        if (!window.confirm('Opravdu chcete smazat položku?')) return
        const order = instanceToInstance(this.state.order)
        order.SalesItemChild?.forEach((item: TSalesItemDM) => {
            if (item.RID === salesItem.RID) {
                item._isDeleted = true
            }
        })

        if (order.SalesItemChild?.length === 1) {
            this.setState({ order }, () => {
                this.cancelOrder()
            })
        } else {
            this.api.putData(order, Order.fields).then(order => this.setState({ order }))
        }

        this.articleInput?.focus()
    }

    handleCancelClick() {
        if (!window.confirm('Opravdu chcete zrušit objednávku?')) return
        this.cancelOrder()
        this.articleInput?.focus()
    }

    handleFinishClick() {
        if (!window.confirm(`Opravdu chcete dokončit objednávku?`)) return
        this.finishOrder()
        this.articleInput?.focus()
    }

    cancelOrder() {
        const order = instanceToInstance(this.state.order)
        order.StatusId = new DocStatus()
        order.StatusId.Id = this.props.statusId.free
        order.SalesItemChild?.forEach((item: TSalesItemDM) => item._isDeleted = true)
        this.api.putData(order, Order.fields).then(() => {
            this.setState({
                order: new TSalesOrderDM()
            })
            localStorage.removeItem('orderRID')

        })
    }

    finishOrder() {
        const order = instanceToInstance(this.state.order)
        order.StatusId = new DocStatus()
        order.StatusId.Id = this.props.statusId.pending
        this.api.putData(order, Order.fields).then(() => {
            this.setState({ order: new TSalesOrderDM() })
            localStorage.removeItem('orderRID')
            this.setState({ order: new TSalesOrderDM() })
        })
    }

    addItem(article: TArticleDM) {
        const order = instanceToInstance(this.state.order)
        let item: TSalesItemDM | null = null
        let amount: string | null = '1'
        for (let i = 0; i < order.SalesItemChild!.length; i++) {
            if (order.SalesItemChild![i].ArticleId?.Id === article.Id) {
                item = order.SalesItemChild![i]
            }
        }

        if (item) amount = item.Quantity!.toString()

        amount = prompt('Zadejte množství:', amount)
        if (!amount) return

        if (!item) {
            item = new TSalesItemDM()
            item.ArticleId = new TArticleDM()
            item.ArticleId.Id = article.Id
            item.PlannedWarehouseId = new TWarehouseDM()
            item.PlannedWarehouseId.Id = this.props.plannedWarehouseId
            order.SalesItemChild!.push(item)
        }

        item.Quantity = parseFloat(amount)

        this.api.putData(order, Order.fields).then(order => this.setState({ order }))
    }

    handleArticleSubmit(event: SyntheticEvent) {
        event.preventDefault()

        const articleKey = this.state.article.split('#')
        if (articleKey.length > 1) {
            switch (articleKey[0]) {
                case 'ArticleId':
                    this.api.getData(TArticleDM, articleKey[1], []).then(article => {
                        if (this.state.order.RID) {
                            this.addItem(article)
                        } else {
                            this.initOrder().then((order: TSalesOrderDM) => {
                                this.setState({ order }, () => this.addItem(article))
                                localStorage.setItem('orderRID', order.RID!.toString())
                            })
                        }

                        this.setState({ article: '' })
                    })
                        .catch(() => {
                            alert('Zboží nebylo nalezeno.')
                            this.setState({ article: '' })
                        })
                    break
                default:
                    alert('Zboží nebylo nalezeno.')
                    this.setState({ article: '' })
                    break
            }
        } else {
            this.api.getDataList(TArticleDM, [], [`Abbr;EQ;${this.state.article}`]).then(articleList => {
                if (articleList.length === 1) {
                    if (this.state.order.RID) {
                        this.addItem(articleList[0])
                    } else {
                        this.initOrder().then((order: TSalesOrderDM) => {
                            this.setState({ order }, () => this.addItem(articleList[0]))
                            localStorage.setItem('orderRID', order.RID!.toString())
                        })
                    }
                } else {
                    alert('Zboží nebylo nalezeno.')
                }

                this.setState({ article: '' })
            })
        }

        this.articleInput?.focus()
    }

    handleArticleChange(event: SyntheticEvent) {
        this.setState({
            article: (event.target as HTMLInputElement).value
        })
    }

    changeItemAmount(salesItem: TSalesItemDM, event: SyntheticEvent) {
        const order = instanceToInstance(this.state.order)
        order.SalesItemChild?.forEach((item: TSalesItemDM) => {
            if (item.RID === salesItem.RID) {
                item.Quantity = (event.target as HTMLInputElement).value as unknown as number
            }
        })

        this.setState({ order })
    }

    saveItemAmount(event: SyntheticEvent) {
        if ((event as unknown as KeyboardEvent).key !== 'Enter') return
        this.api.putData(this.state.order, Order.fields).then(order => this.setState({ order }))
        this.articleInput?.focus()
    }

    render() {
        const sumAmount = this.state.order.SalesItemChild?.length ? this.state.order.SalesItemChild?.map((item: TSalesItemDM) => item.Quantity!).reduce((sum, amount) => sum + amount) : 0
        return (
            <Table className="order">
                <tbody>
                    {this.state.order.SalesItemChild && this.state.order.SalesItemChild.length ? this.state.order.SalesItemChild.map((salesItem: TSalesItemDM) => {
                        return <Item key={salesItem.RID} salesItem={salesItem} onEnter={this.saveItemAmount.bind(this)} onChange={this.changeItemAmount.bind(this, salesItem)} onRemove={this.removeItem.bind(this, salesItem)} />
                    }) : (<tr>
                        <td>Objednávka neobsahuje žádné položky.</td>
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div className="d-flex justify-content-between">
                                <span>Celkem</span>
                                <span>{sumAmount} {sumAmount === 0 || sumAmount! >= 5 ? 'položek' : (sumAmount === 1 ? 'položka' : 'položky')} </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Form onSubmit={this.handleArticleSubmit}>
                                <FormControl
                                    type="text"
                                    ref={(input: HTMLInputElement) => { this.articleInput = input }}
                                    value={this.state.article}
                                    onChange={this.handleArticleChange}
                                    placeholder="Načtěte čárový kód" />
                            </Form>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="d-flex justify-content-between ml-5">
                                <Button variant="danger" onClick={this.handleCancelClick} disabled={this.state.order.RID === undefined}>Zrušit</Button>
                                <Button variant="success" onClick={this.handleFinishClick} disabled={this.state.order.RID === undefined}>Dokončit</Button>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        )
    }
}