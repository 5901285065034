import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TOldNoteTypeDM extends Data {
	public static className = 'TOldNoteTypeDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Description?: string
	RightGroupId?: number
	BankCodeNumber?: string
	Bank?: string
	TypeId?: number
	Id?: number
	ex_dpz?: Date
	ActualRecordNumberCalc?: string
	MinRecordNumberCalc?: string
	MaxRecordNumberCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NoteTypeCalc?: string
	FormIdCalc?: number
	LanguageTextCalc?: string
	RightGroupIdCalc?: number
}