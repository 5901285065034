import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import AlternativeUnit from './AlternativeUnit'
import TTextDM from './TTextDM'
import TPriceListDM from './TPriceListDM'
import TProductKeyDM from './TProductKeyDM'
import TArticleDM from './TArticleDM'
import TPriceListCategoryDM from './TPriceListCategoryDM'
import TCurrencyDM from './TCurrencyDM'
import TPriceListItemDM from './TPriceListItemDM'

export default class TPriceListDefinitionDM extends Data {
	public static className = 'TPriceListDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TakeAllQuantityPrices?: string
	IsOnlyForMultipleOfQuantity?: string
	ExchangeRate?: number
	IsFinalPrice?: string
	IsLocked?: string
	PriceGross?: number
	PriceNet?: number
	IsGrossPriceRounding?: string
	IsRelative?: string
	IsOnlyForMeasureUnit?: string
	PriceSourceRID?: number
	ArticleSourceRID?: number
	ArticleSourceTypeId?: number
	IsPercentage?: string
	RoundingCorrection?: number
	RoundingConstant?: number
	RoundingOrder?: number
	Reduction?: number
	Quantity?: number
	PriceListRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ArticleSrcProductGrRIDCalc?: number
	PriceSourceMarginRIDCalc?: number
	ArticleSourceDescriptionCalc?: string
	PriceSourceDescriptionCalc?: string
	QuantityMeasureUnitDescriptionCalc?: string
	DiscountTypeSuffixCalc?: string
	VATCoefficientCalc?: number
	ExchangeRateCalc?: number
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
	@Type(() => TTextDM)
	MeasureUnitTypeId?: TTextDM
	@Type(() => TTextDM)
	PriceSourceTypeId?: TTextDM
	@Type(() => TPriceListDM)
	ArticleSourcePriceListRIDCalc?: TPriceListDM
	@Type(() => TProductKeyDM)
	ArticleSourceProductKeyRIDCalc?: TProductKeyDM
	@Type(() => TArticleDM)
	ArticleSourceArticleRIDCalc?: TArticleDM
	@Type(() => TPriceListDM)
	PriceSourcePriceListRIDCalc?: TPriceListDM
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory1RIDCalc?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory2RIDCalc?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory3RIDCalc?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory4RIDCalc?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	ArticleSourceCategory5RIDCalc?: TPriceListCategoryDM
	@Type(() => TCurrencyDM)
	ExchangeRateToCurrencyCalc?: TCurrencyDM
	@Type(() => TCurrencyDM)
	ExchangeRateFromCurrencyCalc?: TCurrencyDM
	@Type(() => TPriceListItemDM)
	ItemChild?: TPriceListItemDM[]
}