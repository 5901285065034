import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrPeriodDM from './TPrPeriodDM'
import TPrConstantDM from './TPrConstantDM'
import TPrConstantGroupDM from './TPrConstantGroupDM'
import TTextDM from './TTextDM'
import TPrDeductionDM from './TPrDeductionDM'
import TPrConstantGroupEmploymentBaseDM from './TPrConstantGroupEmploymentBaseDM'

export default class TPrConstantGroupItemDM extends Data {
	public static className = 'TPrConstantGroupItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	StringValue?: string
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ConstantValueStringCalc?: string
	ActualTextCalc?: string
	ConstantValueDateCalc?: Date
	ConstantValueCurrencyCalc?: number
	ConstantValueIntegerCalc?: number
	ConstantValueRealCalc?: number
	ConstantValueTextCalc?: string
	ConstantValueTimeCalc?: Date
	ConstantValueComponentIdCalc?: number
	ConstantValueCalendarIdCalc?: number
	@Type(() => TPrPeriodDM)
	PeriodToId?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	PeriodFromId?: TPrPeriodDM
	@Type(() => TPrConstantDM)
	PrConstantId?: TPrConstantDM
	@Type(() => TPrConstantGroupDM)
	PrConstantGroupRID?: TPrConstantGroupDM
	@Type(() => TTextDM)
	ConstantValueBooleanIdCalc?: TTextDM
	@Type(() => TPrDeductionDM)
	ConstantValueBankDetailIdCalc?: TPrDeductionDM
	@Type(() => TPrConstantGroupEmploymentBaseDM)
	PrConstantGroupEmploymentBaseChild?: TPrConstantGroupEmploymentBaseDM[]
}