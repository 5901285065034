import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import EmploymentName from './EmploymentName'
import TPartnerDM from './TPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TReductionTypeDM from './TReductionTypeDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TPrEmployeeWorkExperienceAsItemDM extends Data {
	public static className = 'TPrEmployeeWorkExperienceAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TotalYearCount?: number
	ReductedYearCount?: number
	TimeStamp?: string
	OrganizationName?: string
	RightGroupId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	XmlField?: string
	ReductedDayCount?: number
	TotalDayCount?: number
	DateTo?: Date
	DateFrom?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SubtractCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => EmploymentName)
	JobId?: EmploymentName
	@Type(() => TPartnerDM)
	OrganizationRID?: TPartnerDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TReductionTypeDM)
	ReductionType?: TReductionTypeDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}