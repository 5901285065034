import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAddressDM from './TAddressDM'
import TContactPersonDM from './TContactPersonDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import EnvelopeStatus from './EnvelopeStatus'
import TTradingPartnerDM from './TTradingPartnerDM'
import TPartnerDM from './TPartnerDM'
import LetterKind from './LetterKind'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TPostLetterReceivedDM from './TPostLetterReceivedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPostEnvelopeReceivedDM extends Data {
	public static className = 'TPostEnvelopeReceivedDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DocumentRID?: number
	SenderTypeId?: number
	SenderOrganizationUnit?: string
	SenderReferenceNumber?: string
	SenderIdent?: string
	SenderDatabox?: string
	RecipientOrganizationUnit?: string
	RecipientReferenceNumber?: string
	RecipientIdent?: string
	RecipientDatabox?: string
	IsPersonalDelivery?: string
	ForAttentionOf?: string
	LegalTitle?: string
	RighGroupId?: number
	Number?: number
	RID?: number
	TimeStamp?: string
	Description?: string
	ReceivedDate?: Date
	CreatedOn?: Date
	ConfirmedOn2?: Date
	ConfirmedOn1?: Date
	ChangedOn?: Date
	PostingNumber?: string
	PaidInCash?: number
	ValueStated?: number
	ServicePrice?: number
	CashOnDelivery?: number
	Weight?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	IsDataboxMessageCalc?: string
	DocumentIdentifierCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AddressFromIdStreetCalc?: string
	AddressFromIdOrientationNumberCalc?: string
	AddressFromIdHouseNumberCalc?: string
	AddressFromIdNameCalc?: string
	AddressFromIdTownCodeCalc?: string
	AddressFromIdCoordinatesCalc?: string
	AddressToIdStreetCalc?: string
	AddressToIdOrientationNumberCalc?: string
	AddressToIdHouseNumberCalc?: string
	AddressToIdNameCalc?: string
	AddressToIdTownCodeCalc?: string
	AddressToIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TAddressDM)
	AddressFromId?: TAddressDM
	@Type(() => TContactPersonDM)
	AcceptedById?: TContactPersonDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => EnvelopeStatus)
	StatusId?: EnvelopeStatus
	@Type(() => TAddressDM)
	AddressToId?: TAddressDM
	@Type(() => TContactPersonDM)
	ContactPersonToId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ContactPersonFromId?: TContactPersonDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM
	@Type(() => TPartnerDM)
	PartnerToId?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerFromId?: TPartnerDM
	@Type(() => LetterKind)
	KindId?: LetterKind
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCountryDM)
	AddressFromIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressFromIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressFromIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	AddressToIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressToIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressToIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TPostLetterReceivedDM)
	PostLetterChild?: TPostLetterReceivedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}