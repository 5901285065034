import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TTextDM from './TTextDM'
import TPredictionItemDM from './TPredictionItemDM'

export default class TPredictionModelDM extends Data {
	public static className = 'TPredictionModelDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Settings?: string
	PredictionLastDate?: Date
	PredictionFirstDate?: Date
	ReorderDate?: Date
	ServiceLevel?: number
	LeadTime?: number
	LastSalesDate?: Date
	Aggregation?: string
	DataType?: string
	InventoryConfig?: number
	TimeStamp?: number
	CreatedOn?: Date
	ArchiveDate?: Date
	Evaluation?: number
	Proposal?: string
	Assessment?: string
	Parameter?: string
	Description?: string
	BasicModel?: string
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ConfigAbbrCalc?: string
	ConfigNameCalc?: string
	SafetyStockCalc?: number
	ReorderPointCalc?: number
	OrderQuantityCalc?: number
	StockoutDateCalc?: Date
	StockoutDurationCalc?: number
	PredictionModelTypeImgCalc?: string
	PredictionChartDateFromCalc?: Date
	ModelSetupDateCalc?: Date
	PredictionDateCalc?: Date
	InventoryDateCalc?: Date
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TTextDM)
	PredictionStyleIdCalc?: TTextDM
	@Type(() => TPredictionItemDM)
	PredictionItemChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemPredictionChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemInvPropChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictItemDailySalesHistoryChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredicItemDailyStockLevHistChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemNativePredictionChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemLowerUpperChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemNativeSalesChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemOrderedChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemSupposedOrderedChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemSSChild?: TPredictionItemDM[]
	@Type(() => TPredictionItemDM)
	PredictionItemROPChild?: TPredictionItemDM[]
}