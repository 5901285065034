import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TLanguageMemoTranslationDM extends Data {
	public static className = 'TLanguageMemoTranslationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	TimeStamp?: string
	DetailDataModuleNumber?: number
	Memo?: string
	Format?: number
	FieldNumber?: number
	ItemId?: number
	MasterRID?: number
	HeaderTypeId?: number
	HeaderBook?: string
	HeaderDataModuleNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTextDM)
	LanguageId?: TTextDM
}