import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTransportMethodDM from './TTransportMethodDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TSpecialDSDistributionDaysItem from './TSpecialDSDistributionDaysItem'

export default class TSpecialDistributionSettingsItem extends Data {
	public static className = 'TSpecialDistributionSettingsItem'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	DeliveryAddressHash?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	DeliveryAddressIdCoordinates?: string
	DeliveryAddressIdTownCode?: string
	DeliveryAddressIdChanged?: number
	DeliveryAddressIdName?: string
	DeliveryAddressIdHouseNumber?: string
	DeliveryAddressIdOrientationNumber?: string
	DeliveryAddressIdStreet?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TAddressDM)
	DeliveryAddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	ChangedBy?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedBy?: TK2UserLookupDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TTownDM)
	DeliveryAddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	DeliveryAddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	DeliveryAddressIdCountryId?: TCountryDM
	@Type(() => TSpecialDSDistributionDaysItem)
	DistributionDaysChild?: TSpecialDSDistributionDaysItem[]
}