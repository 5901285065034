import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationDm from './TLegislationDm'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TCompanyDividedBookDM extends Data {
	public static className = 'TCompanyDividedBookDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CompanyId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	BookModuleId?: number
	RightGroupId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageDescriptionCalc?: string
	BankAccountCalc?: string
	BankCodeNumberCalc?: string
	BankNameCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}