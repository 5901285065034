import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

export default class TInventoryTurnoverDM extends Data {
	public static className = 'TInventoryTurnoverDM'
	public static systemFields = [
	]

	TotalSalePrice?: number
	TotalStockPrice?: number
	Quantity?: number
	AverageBookValue?: number
	AverageAvailable?: number
	DateTo?: Date
	ConfigurationRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastComputeDateBitCalc?: number
	ConfigurationAbbrCalc?: string
	ConfigurationNameCalc?: string
	TurnoverRatioCalc?: number
	SalesInventoryAverageDaysCalc?: number
	ProfitCalc?: number
	QuantityUMCalc?: number
	EffectiveComputeQuantityUMCalc?: number
	DayCountCalc?: number
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}