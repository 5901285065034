import Data from '../Api/Data'

export default class TDocumentItemDM extends Data {
	public static className = 'TDocumentItemDM'
	public static systemFields = [
		'RecordID',
	]

	NodeId?: number
	Id?: number
	FileModuleNumber?: number
	DataModuleNumber?: number
	DocumentId?: number
	DocumentNumber?: number
	Book?: string
	TypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DataModuleNameCalc?: string
	DocumentIdentifierCalc?: string
	DocumentDescriptionCalc?: string
	K2PictureAbbrCalc?: string
}