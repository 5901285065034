import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCalculationModelItemDM from './TCalculationModelItemDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TCalculationModelDM extends Data {
	public static className = 'TCalculationModelDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsProductSurcharge?: string
	TimeStamp?: string
	RightGroupId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageTextCalc?: string
	ProductSurchargeImgCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCalculationModelItemDM)
	CalculationModelItemChild?: TCalculationModelItemDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
}