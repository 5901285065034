import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TPrTariffLevelDM extends Data {
	public static className = 'TPrTariffLevelDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	PrTariffDateRID?: number
	XmlField?: string
	RightGroupId?: number
	WorkExperienceYearCount?: number
	Level?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	Grade01Calc?: number
	Grade02Calc?: number
	Grade03Calc?: number
	Grade04Calc?: number
	Grade05Calc?: number
	Grade06Calc?: number
	Grade07Calc?: number
	Grade08Calc?: number
	Grade09Calc?: number
	Grade10Calc?: number
	Grade11Calc?: number
	Grade12Calc?: number
	Grade13Calc?: number
	Grade14Calc?: number
	Grade15Calc?: number
	Grade16Calc?: number
	Grade17Calc?: number
	Grade18Calc?: number
	Grade19Calc?: number
	Grade20Calc?: number
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}