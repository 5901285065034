import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ServiceOperationType from './ServiceOperationType'
import TMaintenanceFrequencyDefinitionTemplateDM from './TMaintenanceFrequencyDefinitionTemplateDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TTextDM from './TTextDM'
import TBookDM from './TBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServiceOperationOfficerDM from './TServiceOperationOfficerDM'

export default class TMaintenanceFrequencyDefinitionDM extends Data {
	public static className = 'TMaintenanceFrequencyDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	GenerateNextOperation?: string
	ServiceParameterGroupId?: number
	GenerationHorizon?: number
	GenerationHorizonUnit?: number
	OperationInstructions?: string
	PlannedOperationDuration?: number
	Description?: string
	RepetitionCount?: number
	StartDate?: Date
	Frequency?: number
	HeaderRID?: number
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastGeneratedOperationDateCalc?: Date
	@Type(() => ServiceOperationType)
	ServiceTypeId?: ServiceOperationType
	@Type(() => TMaintenanceFrequencyDefinitionTemplateDM)
	DefinitionTemplateId?: TMaintenanceFrequencyDefinitionTemplateDM
	@Type(() => TOfficerDocumentDM)
	PlannedServicemanId?: TOfficerDocumentDM
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TTextDM)
	FrequencyUnitId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TScheduledServiceOperationDM)
	ScheduledServiceOperationChild?: TScheduledServiceOperationDM[]
	@Type(() => TServiceOperationOfficerDM)
	DefinitionOfficerChild?: TServiceOperationOfficerDM[]
}