import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TrackingStatus from './TrackingStatus'

export default class TParcelStatusDM extends Data {
	public static className = 'TParcelStatusDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Name?: string
	EventDate?: Date
	SequenceNumber?: number
	ParcelId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TrackingStatus)
	StatusId?: TrackingStatus
}