import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import DegreeName from './DegreeName'
import SchoolName from './SchoolName'
import EducationFieldName from './EducationFieldName'
import TypeOfTestName from './TypeOfTestName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TContactPersonEducationDM extends Data {
	public static className = 'TContactPersonEducationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	CompletedDate?: Date
	SequenceNumber?: number
	ContactPersonId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => DegreeName)
	Degree?: DegreeName
	@Type(() => SchoolName)
	SchoolId?: SchoolName
	@Type(() => EducationFieldName)
	Field?: EducationFieldName
	@Type(() => TypeOfTestName)
	ExamType?: TypeOfTestName
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}