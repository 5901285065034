import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCurrencyDM from './TCurrencyDM'
import TCompanyLinkBankAccountDM from './TCompanyLinkBankAccountDM'

export default class TPrDeductionBankAccountDM extends Data {
	public static className = 'TPrDeductionBankAccountDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInvalidRecord?: string
	IsForeign?: string
	BankCodeNumber?: string
	RID?: number
	SpecificSymbol?: string
	ChargeCode?: string
	ExtendedInformation?: string
	ChangedOn?: Date
	PaymentItem?: string
	IBAN?: string
	AccountNumber?: string
	BankId?: number
	SequenceNumber?: number
	Id?: number
	TableId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IBANCalc?: string
	VATPaymentsAccountRegCalc?: number
	CreditCheckVerifiedOnCalc?: Date
	CreditCheckRegDateFromCalc?: Date
	CreditCheckRegDateToCalc?: Date
	AccountRegForVATPaymentImgCalc?: string
	AccountSwiftCalc?: string
	BankAddress1Calc?: string
	BankAddress2Calc?: string
	BankAddress3Calc?: string
	BankAddress4Calc?: string
	BankCountryCodeCalc?: string
	RecipientAddress1Calc?: string
	RecipientAddress2Calc?: string
	RecipientAddress3Calc?: string
	RecipientAddress4Calc?: string
	ContactPersonCalc?: string
	IsDefaultAccountCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TCompanyLinkBankAccountDM)
	CompanyChild?: TCompanyLinkBankAccountDM[]
}