import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreAccountingDM from './TCostCentreAccountingDM'
import GroupAccounts from './GroupAccounts'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAccountingChartDM extends Data {
	public static className = 'TAccountingChartDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsStatCode6Dimension?: string
	IsStatCode5Dimension?: string
	IsStatCode4Dimension?: string
	IsStatCode3Dimension?: string
	IsCode6Dimension?: string
	IsCode5Dimension?: string
	IsCode4Dimension?: string
	IsCode3Dimension?: string
	IsLongTerm?: string
	RecalculateStatistics?: string
	IsDirected?: string
	IsNonAllowable?: string
	IsInPartsReading?: string
	IsOpenItem?: string
	IsSummary?: string
	CreatedOn?: Date
	IsStatDeviceDimension?: string
	IsStatTradingPDimension?: string
	IsStatOfficerDimension?: string
	IsStatCode2Dimension?: string
	IsStatCode1Dimension?: string
	IsStatArticleCodeDimension?: string
	IsStatContractCodeDimension?: string
	IsStatCostCentreDimension?: string
	IsDeviceDimension?: string
	IsTradingPartnerDimension?: string
	IsOfficerDimension?: string
	IsCode2Dimension?: string
	IsCode1Dimension?: string
	IsArticleCodeDimension?: string
	IsContractCodeDimension?: string
	IsCostCentreDimension?: string
	TimeStamp?: string
	ChangedOn?: Date
	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	LanguageTextCalc?: string
	DimensionCostCentreCalc?: string
	DimensionContractCodeCalc?: string
	DimensionTradingPartnerCalc?: string
	DimensionDeviceCalc?: string
	DimensionArticleCodeCalc?: string
	DimensionCode1Calc?: string
	DimensionCode2Calc?: string
	DimensionOfficerCalc?: string
	DimensionCode3Calc?: string
	DimensionCode4Calc?: string
	DimensionCode5Calc?: string
	DimensionCode6Calc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	TradingPartnerInputId?: TTextDM
	@Type(() => TTextDM)
	Code6InputId?: TTextDM
	@Type(() => TTextDM)
	Code5InputId?: TTextDM
	@Type(() => TTextDM)
	Code4InputId?: TTextDM
	@Type(() => TTextDM)
	Code3InputId?: TTextDM
	@Type(() => TCode6DocumentDM)
	Code6ToId?: TCode6DocumentDM
	@Type(() => TCode6DocumentDM)
	Code6FromId?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5ToId?: TCode5DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5FromId?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4ToId?: TCode4DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4FromId?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3ToId?: TCode3DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3FromId?: TCode3DocumentDM
	@Type(() => TTextDM)
	Code6OperatorId?: TTextDM
	@Type(() => TTextDM)
	Code5OperatorId?: TTextDM
	@Type(() => TTextDM)
	Code4OperatorId?: TTextDM
	@Type(() => TTextDM)
	Code3OperatorId?: TTextDM
	@Type(() => TTextDM)
	MatchingSymbolInputId?: TTextDM
	@Type(() => TTextDM)
	DeviceInputId?: TTextDM
	@Type(() => TTextDM)
	OfficerInputId?: TTextDM
	@Type(() => TTextDM)
	Code2InputId?: TTextDM
	@Type(() => TTextDM)
	Code1InputId?: TTextDM
	@Type(() => TTextDM)
	ArticleCodeInputId?: TTextDM
	@Type(() => TTextDM)
	ContractCodeInputId?: TTextDM
	@Type(() => TTextDM)
	CostCentreInputId?: TTextDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TOfficerDocumentDM)
	OfficerToId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerFromId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2ToId?: TCode2DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2FromId?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1ToId?: TCode1DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1FromId?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeToId?: TArticleCodeDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeFromId?: TArticleCodeDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceToRID?: TDeviceDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceFromRID?: TDeviceDocumentDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeToRID?: TContractCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreAccountingDM)
	CostCentreToId?: TCostCentreAccountingDM
	@Type(() => TCostCentreAccountingDM)
	CostCentreFromId?: TCostCentreAccountingDM
	@Type(() => TTextDM)
	OfficerOperatorId?: TTextDM
	@Type(() => TTextDM)
	Code2OperatorId?: TTextDM
	@Type(() => TTextDM)
	Code1OperatorId?: TTextDM
	@Type(() => TTextDM)
	ArticleCodeOperatorId?: TTextDM
	@Type(() => TTextDM)
	DeviceOperatorId?: TTextDM
	@Type(() => TTextDM)
	TradingPartnerOperatorId?: TTextDM
	@Type(() => TTextDM)
	ContractCodeOperatorId?: TTextDM
	@Type(() => TTextDM)
	CostCentreOperatorId?: TTextDM
	@Type(() => GroupAccounts)
	AccountGroupId?: GroupAccounts
	@Type(() => TPeriodListDM)
	ValidToPeriodId?: TPeriodListDM
	@Type(() => TPeriodListDM)
	ValidFromPeriodId?: TPeriodListDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
	@Type(() => TPeriodListDM)
	PerFromCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	PerToCalc?: TPeriodListDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}