import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TypeOfTestName from './TypeOfTestName'
import EducationFieldName from './EducationFieldName'
import DegreeName from './DegreeName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TEducationDM extends Data {
	public static className = 'TEducationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XMLField?: string
	EducationDomain?: string
	RID?: number
	Description?: string
	DateFrom?: Date
	DateTo?: Date
	SchoolName?: string
	SequenceNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	StudentAllowanceBitCalc?: number
	ExchangeRateCalc?: number
	LastCommentParamCalc?: string
	@Type(() => TypeOfTestName)
	ExamKind?: TypeOfTestName
	@Type(() => EducationFieldName)
	Specialization?: EducationFieldName
	@Type(() => DegreeName)
	EducationLevel?: DegreeName
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}