import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TShippingMethodDM from './TShippingMethodDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TEshopOrderDM from './TEshopOrderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import JCDP20_a1 from './JCDP20_a1'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TContactPersonDM from './TContactPersonDM'
import TTaxTypeDM from './TTaxTypeDM'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import ContractStatus from './ContractStatus'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import FormOfOrder from './FormOfOrder'
import TPaymentMethodDM from './TPaymentMethodDM'
import TermOfDelivery from './TermOfDelivery'
import TCurrencyDM from './TCurrencyDM'
import TPriceGroupDM from './TPriceGroupDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TQuotationDM from './TQuotationDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TEdiQueueDM from './TEdiQueueDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TReservingCardDM from './TReservingCardDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TPaymentConditionTradeDM from './TPaymentConditionTradeDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TTaxSummaryDM from './TTaxSummaryDM'
import TSalesItemDM from './TSalesItemDM'
import TAccountingSalesItemDM from './TAccountingSalesItemDM'
import TTransferNoteDM from './TTransferNoteDM'
import TAppliedCouponDM from './TAppliedCouponDM'
import TAppliedPromotionDM from './TAppliedPromotionDM'
import TProductCostingDM from './TProductCostingDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TSpecialTSalesOrderDMCustomersInfo from './TSpecialTSalesOrderDMCustomersInfo'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TSalesOrderDM extends Data {
	public static className = 'TSalesOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DispatchDate?: Date
	DeliveryConfirmedForDate?: Date
	NotIncludeIntoFinancialMngmt?: string
	SpecificSymbol?: string
	CreatedOn?: Date
	Settings?: string
	TaxNumber?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	Number?: number
	AmountNetC?: number
	RID?: number
	TimeStamp?: string
	OrderNumber?: string
	RightGroupId?: number
	ItemCount?: number
	ChangedOn?: Date
	ConfirmedOn?: Date
	IssueDate?: Date
	DesiredDeliveryDate?: Date
	ContactInformation?: string
	Description?: string
	ExchangeRate?: number
	AmountNet?: number
	ReferenceNumber?: string
	RecordID?: number
	Ex_FictitiousOrder?: string
	Ex_OfferValidTo?: Date
	ex_KontOs?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	AmountVATCalc?: number
	AmountGrossCalc?: number
	AmountVATCCalc?: number
	AmountGrossCCalc?: number
	ExchangeRateCalc?: number
	CompanyCurrencyCalc?: string
	CurrencySwitchCalc?: number
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	TotalItemPlannedPriceNetCalc?: number
	TotalItemPlStockPriceCalc?: number
	TotalItemPlStockPriceCCalc?: number
	ProfitCalc?: number
	ProfitCCalc?: number
	MarginCalc?: number
	CurrencyBySwitchCalc?: string
	AssignedContractRIDCalc?: number
	DocumentAmountGrossCalc?: number
	DocumentAmountGrossCCalc?: number
	ReductionValueCalc?: number
	ReductionTypeBitCalc?: number
	ReductionAmountCalc?: number
	ReductionSignCalc?: number
	ReductionSignImgCalc?: string
	DeliveryDateCalc?: Date
	DeliveryTimeCalc?: Date
	PlannedSurchargeAmountCalc?: number
	TotalItemPlStockBySettPriceCalc?: number
	DeliveryOrderDateCalc?: Date
	AdvanceDeductedAmountCalc?: number
	AdvDeductTrPartAmountCalc?: number
	AdvanceRemainsToDedcAmountCalc?: number
	AdvRemainsToDedcTrPartAmountCalc?: number
	AdvanceDeductedAmountCCalc?: number
	AdvDeductedTrPartAmountCCalc?: number
	AdvRemainsToDeductAmountCCalc?: number
	AdvRemainsToDeductTrPartAmountCCalc?: number
	ExistsNotDeductedAdvanceBitCalc?: number
	PayConditionsMaturityDaysCalc?: number
	ExistsAdvanceUnpaidBitCalc?: number
	ExistsTrPartAdvNotPaidBitCalc?: number
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	TotalItemActualPriceNetCalc?: number
	TotalItemActualStockPriceCalc?: number
	TotalItemActualStockPriceCCalc?: number
	ActualProfitCalc?: number
	ActualProfitCCalc?: number
	ActualMarginAmountCalc?: number
	ActualSurchargeAmountCalc?: number
	TotalItemActStockSettPriceCalc?: number
	ExistsNotDedcAdvTrPartBitCalc?: number
	QRCodeCZBankOrderStandardCalc?: string
	QRCodeCZBankOrderLargeCalc?: string
	QRCodeSKBankOrderPayBySquareCalc?: string
	QRSKBankOrderCSOBCalc?: string
	InternalTextCalc?: string
	ExternalAddressCalc?: string
	TradingPartnerInternalTextCalc?: string
	ForeignCurrencyBitCalc?: number
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	IncorrectAmountBitCalc?: number
	VATTurnedOnBitCalc?: number
	VATGrossCalcMethodBitCalc?: number
	ItemTaxTypeCalc?: string
	QRCZInvoiceCalc?: string
	VATRegNumberCalc?: string
	TaxTradingPartnerIdCalc?: number
	ArticleFromPriceListIdCalc?: number
	TransportMethodRIDCalc?: number
	TotalWeightNetCalc?: number
	TotalWeightGrossCalc?: number
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TotalVolumePARAMCalc?: number
	RIDForEShopCalc?: string
	BankAccountCalc?: string
	BankCodeCalc?: string
	SWIFTCalc?: string
	IBANCalc?: string
	CoverageTimeStampCalc?: Date
	ContactPersonFullNameCalc?: string
	ContactPersonNameCalc?: string
	ContactPersonSurnameCalc?: string
	ContactPersonEmailCalc?: string
	ContactPersonPhoneCalc?: string
	IsContactPersonSendInfoCalc?: string
	FacultativeCompensationBitCalc?: number
	EshopReferenceNumberCalc?: string
	IsEshopPaidCalc?: string
	DeliveryConfirmedForDateCalc?: Date
	DeliveryConfirmedForTimeCalc?: Date
	DispatchDateCalc?: Date
	DispatchTimeCalc?: Date
	EshopToPaymentAmountCalc?: number
	TotalPromotionPriceCalc?: number
	CombPromotionTotalPriceCalc?: number
	PromotionDiscountCalc?: number
	OrderConfirmationUrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	CompanyRegNumberCalc?: string
	VATRegNumberNotFullCalc?: string
	TaxNumberCalc?: string
	IsReversedExchangeRateViewCalc?: string
	DistributionStatePictureCalc?: number
	DistributionStateCalc?: number
	DistributionDeliveryAddressHashCalc?: string
	DeliveryAddressCalc?: string
	InvoiceAddressCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TEshopOrderDM)
	EshopOrderRID?: TEshopOrderDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => ContractStatus)
	StatusId?: ContractStatus
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => FormOfOrder)
	OrderForm?: FormOfOrder
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TermOfDelivery)
	DeliveryTime?: TermOfDelivery
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	EditedAmountBitCalc?: TTextDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTextDM)
	DeliveryNoteDocExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	InvoiceDocumentExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	PurchaseOrderDocumentExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	ReservingCardDocumentExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	ReleaseNoteDocumentExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	JobCardDocumentExistsIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TQuotationDM)
	MarketingDocumentRIDCalc?: TQuotationDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	EdiQueueMessageInStatusIdCalc?: TTextDM
	@Type(() => TEdiQueueDM)
	EdiQueueMessageInRIDCalc?: TEdiQueueDM
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM
	@Type(() => TTextDM)
	SendByPartsBitCalc?: TTextDM
	@Type(() => TEshopOrderDM)
	EshopOrderRIDCalc?: TEshopOrderDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceiveForDocumentChild?: TAdvanceReceivedDM[]
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]
	@Type(() => TReservingCardDM)
	ReservingCardChild?: TReservingCardDM[]
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]
	@Type(() => TSalesItemBookDM)
	ItemChild?: TSalesItemBookDM[]
	@Type(() => TPaymentConditionTradeDM)
	PaymentConditionChild?: TPaymentConditionTradeDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TTaxSummaryDM)
	TaxSummaryChild?: TTaxSummaryDM[]
	@Type(() => TSalesItemDM)
	SalesItemChild?: TSalesItemDM[]
	@Type(() => TAccountingSalesItemDM)
	AccountingSalesItemChild?: TAccountingSalesItemDM[]
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]
	@Type(() => TAdvanceReceivedDM)
	TradingPartnerAdvanceChild?: TAdvanceReceivedDM[]
	@Type(() => TAppliedCouponDM)
	CouponApplicationChild?: TAppliedCouponDM[]
	@Type(() => TAppliedPromotionDM)
	PromotionApplicationChild?: TAppliedPromotionDM[]
	@Type(() => TCommentDM)
	CommentChildCustomer?: TCommentDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TSpecialTSalesOrderDMCustomersInfo)
	CustomersInfo?: TSpecialTSalesOrderDMCustomersInfo[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}