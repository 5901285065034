import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEshopDM from './TEshopDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'

export default class TEshopCategoryAsItemDM extends Data {
	public static className = 'TEshopCategoryAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInvisible?: string
	PatternCategoryRID?: number
	IsVirtual?: string
	OpenGraphImage?: number
	OpenGraphTypeId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	RightGroupId?: number
	CategoryPath?: string
	TimeStamp?: string
	SequenceNumber?: number
	ImageFileName?: string
	IconFileName?: string
	ParameterSetId?: number
	PageAnalytics?: string
	PageUrlCanonical?: string
	PageUrl?: string
	PageKeyWord?: string
	PageDescription?: string
	PageTitle?: string
	Description?: string
	Title?: string
	Name?: string
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordNo?: number
	RecordID?: number
	RecordState?: number
	Ex_Rab?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NotShowBitCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TEshopCategoryInfoDM)
	ParentRID?: TEshopCategoryInfoDM
}