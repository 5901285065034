import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import AlternativeUnit from './AlternativeUnit'

export default class TPaymentDocumentItemAnalyticalDimensionDM extends Data {
	public static className = 'TPaymentDocumentItemAnalyticalDimensionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ExternalReference?: string
	BankCodeNumber?: string
	BookId?: number
	PaidDocumentRID?: number
	BankId?: number
	AddressId?: number
	RID?: number
	HeaderRID?: number
	AdditionalInfo?: string
	PaymentCalendarId?: number
	RightGroupId?: number
	PostingKeyId?: number
	ConstantSymbolId?: string
	BankOrderItemId?: number
	RowId?: number
	AmountPaid?: number
	AmountPaidC?: number
	AmountVAT?: number
	AmountVatC?: number
	AmountNet?: number
	AmountNetC?: number
	PaidDocumentExchangeRate?: number
	PaidItemExchangeRate?: number
	ExchangeRate?: number
	TaxPointDate?: Date
	AccountingTransactionDate?: Date
	ReferenceNumber?: string
	Description2?: string
	Description?: string
	PaidDocumentCardId?: number
	PaidDocumentIdOld?: number
	PaidDocumentBookOld?: string
	PaidDocumentTypeId?: number
	ItemTypeId?: number
	SequenceNumber?: number
	ItemId?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ReferenceNumberInvoiceInCalc?: string
	ReferenceNumberInvoiceOutCalc?: string
	ReferenceNumberAdvProvidedCalc?: string
	ReferenceNumberAdvReceivedCalc?: string
	ExchangeRateCalc?: number
	DocumentExchangeRateCalc?: number
	PaidDocumentExchangeRateCalc?: number
	ReferenceNumberPayrollCalc?: string
	RemainingAmountCCalc?: number
	RemainingAmountCalc?: number
	OtherReceivableRIDCalc?: number
	OtherLiabilityRIDCalc?: number
	ReferenceNumberOtherReceivableCalc?: string
	ReferenceNumberPurchaseOrderCalc?: string
	PaidDocumentNameCalc?: string
	CompanyDateCalc?: Date
	AccountingTransactionDateCalc?: Date
	DocumentIdentificationCalc?: string
	ItemTypeCalc?: string
	AmountNetCCalc?: number
	AmountNetCalc?: number
	BankStatementRIDCalc?: number
	CashDocumentRIDCalc?: number
	InternalDocumentRIDCalc?: number
	PostingKeyInternalDocumentIdCalc?: number
	PostingKeyBankStatementIdCalc?: number
	PostingKeyCashDocumentIdCalc?: number
	PostingKeyAbbrCalc?: string
	QuantityCalc?: number
	AmountGrossCCalc?: number
	AmountGrossCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TAccountingChartDM)
	Account2Id?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	Account1Id?: TAccountingChartDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TCurrencyDM)
	PaidDocumentCurrency?: TCurrencyDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TTextDM)
	HeaderTypeId?: TTextDM
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedRIDCalc?: TAdvanceProvidedDM
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedRIDCalc?: TAdvanceReceivedDM
	@Type(() => TPrLiabilityDM)
	PayrollDocumentRIDCalc?: TPrLiabilityDM
	@Type(() => TAccountingChartDM)
	VATAccountIdCalc?: TAccountingChartDM
	@Type(() => TTextDM)
	CashDocumentItemTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	InternalDocumentItemTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	BankStatementItemTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => AlternativeUnit)
	MeasureUnitIdCalc?: AlternativeUnit
	@Type(() => TAccountingChartDM)
	DebitAccountIdCalc?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccountIdCalc?: TAccountingChartDM
}