import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TCustomerCreditViolationDM extends Data {
	public static className = 'TCustomerCreditViolationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreditRID?: number
	ViolatedOn?: Date
	DocumentRID?: number
	RID?: number
	Amount?: number
	DocumentTypeId?: number
	MasterRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentCalc?: string
	@Type(() => TK2UserLookupDM)
	ViolatedById?: TK2UserLookupDM
}