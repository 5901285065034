import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWarehouseDM from './TWarehouseDM'

export default class TWarehouseItemDM extends Data {
	public static className = 'TWarehouseItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SupplementId?: number
	ArticleId?: number
	Percentage?: number
	Book2?: string
	DocumentRID1?: number
	Book1?: string
	RecordTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TWarehouseDM)
	DocumentRID2?: TWarehouseDM
}