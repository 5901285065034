import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWebBrandDM from './TWebBrandDM'
import TEshopDM from './TEshopDM'

export default class TWebBrandAsItemDM extends Data {
	public static className = 'TWebBrandAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SlaveModuleId?: number
	MasterModuleId?: number
	SequenceNumber?: number
	SlaveDataModuleNumber?: number
	MasterRID?: number
	MasterDataModuleNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	@Type(() => TWebBrandDM)
	SlaveRID?: TWebBrandDM
	@Type(() => TEshopDM)
	WebRIDCalc?: TEshopDM
}