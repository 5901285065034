import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import OrgTreeUnit from './OrgTreeUnit'
import TOrgStrTreeDM from './TOrgStrTreeDM'
import TOrgStrDeputyDM from './TOrgStrDeputyDM'
import TPrEmploymentDM from './TPrEmploymentDM'

export default class TOrgStrTreeUnitDM extends Data {
	public static className = 'TOrgStrTreeUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInvalidRecord?: string
	PlannedTime?: number
	PlannedCount?: number
	Name?: string
	Abbr?: string
	SequenceNumber?: number
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LevelIdCalc?: number
	MeasureUnitItemsCountCalc?: number
	SuperiorUnitItemCountCalc?: number
	SequenceNumberInSuperiorUnitCalc?: number
	IsLastItemInSuperiorUnitCalc?: string
	ActualCountCalc?: number
	DifferenceCalc?: number
	ActualValidToDateCalc?: Date
	ShowInvalidEmployeeCalc?: string
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM
	@Type(() => TEmployedPersonSimpleDM)
	OfficerId?: TEmployedPersonSimpleDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => OrgTreeUnit)
	TypeId?: OrgTreeUnit
	@Type(() => TOrgStrTreeDM)
	OrgStrTreeRID?: TOrgStrTreeDM
	@Type(() => TOrgStrTreeDM)
	MasterRID?: TOrgStrTreeDM
	@Type(() => TOrgStrDeputyDM)
	OrgDeputyChild?: TOrgStrDeputyDM[]
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]
}