import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TProductionSchedulingVariantDM from './TProductionSchedulingVariantDM'
import TWorkplaceDM from './TWorkplaceDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TWorkshiftDM from './TWorkshiftDM'

export default class TJobCardVariantScheduleDM extends Data {
	public static className = 'TJobCardVariantScheduleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Member?: string
	SequenceNumber?: number
	Duration?: number
	Quantity?: number
	CalendarId?: number
	Description?: string
	WorkshiftDate?: Date
	TimeFrom?: Date
	Date?: Date
	JobCardResourceRID?: number
	JobCardOperationRID?: number
	JobCardRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PlannerMessageCalc?: string
	DateFromCalc?: Date
	DateToCalc?: Date
	DurationCalc?: number
	PlannedTimeToCalc?: Date
	ChangeTimeQuantityCalc?: number
	@Type(() => TProductionSchedulingVariantDM)
	SchedulingVariantId?: TProductionSchedulingVariantDM
	@Type(() => TWorkplaceDM)
	WorkPlaceRID?: TWorkplaceDM
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TWorkshiftDM)
	WorkshiftRID?: TWorkshiftDM
}