import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOldNoteTypeDM from './TOldNoteTypeDM'

export default class TItemNoteDM extends Data {
	public static className = 'TItemNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	RightGroupId?: number
	FormId?: number
	Note?: string
	SequenceNumber?: number
	TypeId?: string
	NoteRID?: number
	DocumentRID?: number
	Book?: string
	DocumentTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NoteTextCalc?: string
	NoteTypeCalc?: string
	@Type(() => TOldNoteTypeDM)
	TypeNumberIdCalc?: TOldNoteTypeDM
}