import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'
import TArticleDM from './TArticleDM'

export default class TMeasureUnitDM extends Data {
	public static className = 'TMeasureUnitDM'
	public static systemFields = [
	]

	IsBlockedRecord?: string
	Area?: number
	WeightNet?: number
	WeightGross?: number
	SizeC?: number
	SizeB?: number
	SizeA?: number
	Volume?: number
	Ratio?: number
	RecordNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BasicOrBlockedImgCalc?: string
	ComputedWeightNetCalc?: number
	ComputedWeightGrossCalc?: number
	BlockedCalc?: number
	DefaultForJobCardCalc?: number
	DefaultForPurchaseCalc?: number
	DefaultForSaleCalc?: number
	DefaultForTransferNoteCalc?: number
	IntegerUnitCalc?: number
	DefaultForEshopCalc?: number
	ShowOnEshopBitCalc?: number
	StocktakingsDefaultBitCalc?: number
	MeasureUnitIdCalc?: number
	DefaultForRoutingVariantBitCalc?: number
	@Type(() => TWMSEquipmentDM)
	EquipmentId?: TWMSEquipmentDM
	@Type(() => TTextDM)
	AreaUnitId?: TTextDM
	@Type(() => TTextDM)
	SizeMeasureUnit?: TTextDM
	@Type(() => TTextDM)
	CapacityMeasureUnitId?: TTextDM
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}