import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCalculationModelDM from './TCalculationModelDM'

export default class TProductCostingConfigurationDM extends Data {
	public static className = 'TProductCostingConfigurationDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsBlockedRecord?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Quantity?: number
	SaveSumItems?: string
	SaveTree?: string
	AggregationField5?: string
	AggregationField4?: string
	AggregationField3?: string
	AggregationField2?: string
	AggregationField1?: string
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TCalculationModelDM)
	WeightCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	PriceCalculationModelId?: TCalculationModelDM
}