import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import ArticleCode2 from './ArticleCode2'
import ArticleCode1 from './ArticleCode1'
import TRoutingVariantDM from './TRoutingVariantDM'
import TCurrencyDM from './TCurrencyDM'
import TPriceGroupDM from './TPriceGroupDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'

export default class TArticleSellingPriceDM extends Data {
	public static className = 'TArticleSellingPriceDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	NodeID?: number
	KeyCode?: string
	DateTo?: Date
	UniqID?: number
	Date?: Date
	Price?: number
	Quantity?: number
	PriceGroupNo?: number
	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	PriceGroupCalc?: string
	PricePctCalc?: number
	PriceCalc?: number
	PriceNetClientCCalc?: number
	PriceNetCCalc?: number
	PriceNetCalc?: number
	PriceGrossClientCCalc?: number
	PriceGrossCCalc?: number
	PriceGrossCalc?: number
	BasicPriceCalc?: number
	PriceGroupBitCalc?: number
	TradingPartnerPriceBitCalc?: number
	RelativePriceBitCalc?: number
	CurrencyCalc?: string
	SortingCalc?: string
	ExchangeRateCalc?: number
	VATCoefficientCalc?: number
	FromAmountCalc?: number
	QuantityCalc?: number
	MeasureUnitCoefficientCalc?: number
	RecordModeCalc?: number
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => ArticleCode2)
	CustomerCode2?: ArticleCode2
	@Type(() => ArticleCode1)
	CustomerCode1?: ArticleCode1
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TPriceGroupDM)
	SuperiorPriceGroupId?: TPriceGroupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TPriceGroupDM)
	PriceGroup1IdCalc?: TPriceGroupDM
	@Type(() => TTradingPartnerDM)
	PriceGroup2IdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	NotRecalculateBitCalc?: TTextDM
	@Type(() => TTextDM)
	ActionBitCalc?: TTextDM
}