import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import AreaCode from './AreaCode'
import TAddressDM from './TAddressDM'
import TWarehouseDM from './TWarehouseDM'
import TPartnerDM from './TPartnerDM'
import TTextDM from './TTextDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TCountryDM from './TCountryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TOpeningHoursDM from './TOpeningHoursDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TBranchDM extends Data {
	public static className = 'TBranchDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	WebRID?: number
	WebModuleCategoryRID?: number
	ChangedOn?: Date
	CreatedOn?: Date
	ImportDate?: Date
	ExternalLabel?: string
	ExternalId?: string
	LongDescription?: string
	TimeStamp?: string
	HeurekaId?: string
	Description?: string
	Name?: string
	Abbr?: string
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	ElectronicAddress1Calc?: string
	ElectronicAddress2Calc?: string
	ElectronicAddress3Calc?: string
	ElectronicAddress4Calc?: string
	ElectronicAddress1FormattedCalc?: string
	ElectronicAddress2FormattedCalc?: string
	ElectronicAddress3FormattedCalc?: string
	ElectronicAddress4FormattedCalc?: string
	EAddressPARAMCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressId_ver?: number
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdChangedOnCalc?: Date
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	ElectronicAddressCheckItemFieldCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => AreaCode)
	RegionId?: AreaCode
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]
	@Type(() => TOpeningHoursDM)
	OpeningHoursChild?: TOpeningHoursDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}