import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterSetDM from './TParameterSetDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TBookDM from './TBookDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import FormOfOrder from './FormOfOrder'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TOrgStrTreeDM from './TOrgStrTreeDM'
import TCountryDM from './TCountryDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TShippingMethodDM from './TShippingMethodDM'
import TPriceGroupDM from './TPriceGroupDM'
import TCurrencyDM from './TCurrencyDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TEshopDeliveryPaymentDM from './TEshopDeliveryPaymentDM'
import TEshopCalculatedStatisticsDM from './TEshopCalculatedStatisticsDM'
import TEshopParameterValueDM from './TEshopParameterValueDM'
import TEshopCategoryAsItemDM from './TEshopCategoryAsItemDM'
import TEshopExposedArticleDM from './TEshopExposedArticleDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopDM extends Data {
	public static className = 'TEshopDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CompanyId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	PageHeaderAnalyticsId?: string
	PageAnalyticsId?: string
	RightGroupId?: number
	TimeStamp?: string
	XMLData?: string
	Name?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	UrlCalc?: string
	UseTradingPartnerPriceCalc?: string
	PriceGroupToUseCalc?: string
	CurrencyToUseCalc?: string
	LanguagesToUseCalc?: string
	DeliveryCountriesCalc?: string
	InvoiceCountriesCalc?: string
	ConfigDefaultOptionsCalc?: string
	SearchedFieldCalc?: string
	EmailAddressCalc?: string
	EmailNameCalc?: string
	PhoneNumberCalc?: string
	MobileNumberCalc?: string
	BranchesCalc?: string
	ConfigPaymentGatewaysCalc?: string
	ConfigStatusesCalc?: string
	ConfigSpecificPagesCalc?: string
	ConfigAnalyticToolsCalc?: string
	ConfigUsersCalc?: string
	ConfigPagesCalc?: string
	ConfigArticleListCalc?: string
	ConfigArticleDetailCalc?: string
	ConfigBasketCalc?: string
	ConfigTestingCalc?: string
	ConfigInsertedContentCalc?: string
	ConfigRecordsTypesCalc?: string
	ConfigBookmarksCalc?: string
	ShowServiceCategoryCalc?: string
	ConfigDocumentFilterCalc?: string
	UrlAbsCalc?: string
	ConfigEmailActionsCalc?: string
	ConfigQuotationsCalc?: string
	ConfigFulltextSearchCalc?: string
	CompanyIdDeprecatedCalc?: number
	WebVersionIdCalc?: number
	TransportMethodRIDCalc?: number
	ConfigImmediatePaymentCalc?: string
	ConfigOtherSettingsCalc?: string
	OwnSortListCalc?: string
	AdminListCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TParameterSetDM)
	ParameterSetId?: TParameterSetDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TBookDM)
	SalesOrderBookIdCalc?: TBookDM
	@Type(() => TTradingPartnerDM)
	SalesOrderTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => FormOfOrder)
	OrderFormCalc?: FormOfOrder
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TWarehouseDM)
	WarehouseOutIdCalc?: TWarehouseDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressEmailTypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressPhoneTypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TOrgStrTreeDM)
	OrgStrRIDCalc?: TOrgStrTreeDM
	@Type(() => TCountryDM)
	DefaultDeliveryCountryIdCalc?: TCountryDM
	@Type(() => TCountryDM)
	DefaultIvoiceCountryIdCalc?: TCountryDM
	@Type(() => TEshopCategoryInfoDM)
	DefaultTreeCategoryRIDCalc?: TEshopCategoryInfoDM
	@Type(() => TEshopCategoryInfoDM)
	ServiceCategoryRIDCalc?: TEshopCategoryInfoDM
	@Type(() => TEshopCategoryInfoDM)
	CalcStatisticByCategoryRIDCalc?: TEshopCategoryInfoDM
	@Type(() => TShippingMethodDM)
	ShippingMethodIdCalc?: TShippingMethodDM
	@Type(() => TTradingPartnerDM)
	ShownTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPriceGroupDM)
	ShownPriceGroupIdCalc?: TPriceGroupDM
	@Type(() => TCurrencyDM)
	ShownCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TEshopCategoryInfoDM)
	CategoryChild?: TEshopCategoryInfoDM[]
	@Type(() => TEshopDeliveryPaymentDM)
	DeliveryPaymentChild?: TEshopDeliveryPaymentDM[]
	@Type(() => TEshopCalculatedStatisticsDM)
	CalculatedStatisticsChild?: TEshopCalculatedStatisticsDM[]
	@Type(() => TEshopParameterValueDM)
	ParameterValueChild?: TEshopParameterValueDM[]
	@Type(() => TEshopCategoryAsItemDM)
	CategoryTreeChild?: TEshopCategoryAsItemDM[]
	@Type(() => TEshopExposedArticleDM)
	ExposedArticleChild?: TEshopExposedArticleDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}