import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

export default class TEshopExposedArticleDM extends Data {
	public static className = 'TEshopExposedArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PreferGrossPrice?: string
	IsFinalPrice?: string
	IsSlaveVariation?: string
	Currency?: string
	RID?: number
	PriceGross?: number
	PriceNet?: number
	TradingPartnerId?: number
	PriceGroupId?: number
	ShopRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}