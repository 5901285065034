import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWebModuleCategoryDM from './TWebModuleCategoryDM'
import TTextDM from './TTextDM'
import TextLayerStyle from './TextLayerStyle'
import TK2UserLookupDM from './TK2UserLookupDM'
import ShopGroupId from './ShopGroupId'
import TEshopTextPageDM from './TEshopTextPageDM'
import esBannerPosition from './esBannerPosition'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopDM from './TEshopDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TWebModuleLinkSuperiorDM from './TWebModuleLinkSuperiorDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopBannerDM extends Data {
	public static className = 'TEshopBannerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ButtonForeground?: string
	ButtonBackground?: string
	PriceForeground?: string
	PriceBackground?: string
	DescriptionForeground?: string
	DescriptionBackground?: string
	NameForeground?: string
	NameBackground?: string
	ButtonValue?: string
	PriceValue?: string
	DescriptionValue?: string
	NameValue?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RightGroupId?: number
	TimeStamp?: string
	SumClick?: number
	SumView?: number
	ShowTime?: number
	EndDate?: Date
	StartDate?: Date
	ImageFileName?: string
	Priority?: number
	PageAnalytics?: string
	TargetUrl?: string
	Description?: string
	ShortDescription?: string
	Title?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	DetailUrlCalc?: string
	DetailUrlAbsCalc?: string
	BackgroundImageFullPathCalc?: string
	MainImageFullPathCalc?: string
	ShowTimeInSecondsCalc?: number
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedTitleCalc?: string
	TranslatedShortDescriptionCalc?: string
	TranslatedPageAnalyticsCalc?: string
	TranslatedImageFileNameCalc?: string
	TranslatedTargetUrlCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedNameValueCalc?: string
	TranslatedDescriptionValueCalc?: string
	TranslatedPriceValueCalc?: string
	TranslatedButtonValueCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TWebModuleCategoryDM)
	WebModuleCategoryRID?: TWebModuleCategoryDM
	@Type(() => TTextDM)
	UserAccessId?: TTextDM
	@Type(() => TextLayerStyle)
	TextLayerStyleId?: TextLayerStyle
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId
	@Type(() => TEshopTextPageDM)
	TextPageRID?: TEshopTextPageDM
	@Type(() => esBannerPosition)
	PositionId?: esBannerPosition
	@Type(() => TEshopCategoryInfoDM)
	CategoryRID?: TEshopCategoryInfoDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TWebModuleLinkSuperiorDM)
	SuperiorWebModuleLinkChild?: TWebModuleLinkSuperiorDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}