import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTownDM from './TTownDM'
import Region from './Region'
import TCountryDM from './TCountryDM'

export default class TTownPartDM extends Data {
	public static className = 'TTownPartDM'
	public static primaryKey = 'TownPartId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.TownPartId!
	}

	TimeStamp?: string
	Longitude?: number
	Latitude?: number
	TownCode?: string
	ChangedOn?: Date
	TownPartId?: number
	Name?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTownDM)
	TownId?: TTownDM
	@Type(() => Region)
	RegionId?: Region
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
}