import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import MrktWinningReason from './MrktWinningReason'

export default class TOpportunityStateReasonDM extends Data {
	public static className = 'TOpportunityStateReasonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AuxiliaryRID?: number
	MasterRID?: number
	LinkTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => MrktWinningReason)
	SlaveRID?: MrktWinningReason
}