import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantOperationSimpleDM from './TRoutingVariantOperationSimpleDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TRoutingVariantResourceSubstitutionDM from './TRoutingVariantResourceSubstitutionDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TRoutingVariantResourceSimpleDM extends Data {
	public static className = 'TRoutingVariantResourceSimpleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RoutingId?: number
	RoutingTypeId?: number
	RID?: number
	SequenceNumber?: number
	Quantity?: number
	NoteId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	OriginalRID?: number
	OperationCode?: string
	OperationSequenceNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	OriginalRIDCalc?: number
	@Type(() => TRoutingVariantOperationSimpleDM)
	RoutingOperationRID?: TRoutingVariantOperationSimpleDM
	@Type(() => TResourceDM)
	GroupId?: TResourceDM
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TResourceDM)
	ResourceByIdInputCalc?: TResourceDM
	@Type(() => TResourceDM)
	ResourceByNameInputCalc?: TResourceDM
	@Type(() => TRoutingVariantResourceSubstitutionDM)
	RoutingVariantResSubstChild?: TRoutingVariantResourceSubstitutionDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
}