import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import JobKind from './JobKind'
import JobGroup from './JobGroup'
import JobCategory from './JobCategory'
import JobStatus from './JobStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobHistoryInstructionDM from './TJobHistoryInstructionDM'
import TJobHistoryResolutionDM from './TJobHistoryResolutionDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TJobDM extends Data {
	public static className = 'TJobDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	RighGroupId?: number
	TimeStamp?: string
	DocumentNumber?: number
	DocumentTypeId?: number
	DocumentBook?: string
	XMLField?: string
	TermFulfillmentDate?: Date
	Solution?: string
	Instruction?: string
	Complete?: number
	CreatedOn?: Date
	ChangedOn?: Date
	ConfirmedOn?: Date
	NotificationDate?: Date
	FulfillmentDate?: Date
	InitationDate?: Date
	InstructionDate?: Date
	PlannedTimeSolution?: number
	ActualTimeSolution?: number
	Description?: string
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	InstructionDateCalc?: Date
	InstructionTimeCalc?: Date
	StartDateCalc?: Date
	StartTimeCalc?: Date
	TermFulfillmentDateCalc?: Date
	FulfillmentTimeCalc?: Date
	InstructionCalc?: string
	TargetDateCalc?: Date
	TermFulfillmentTimeCalc?: Date
	NotificationDateCalc?: Date
	NotificationTimeCalc?: Date
	InstructionTimeDayCountCalc?: number
	SubordinateJobExistBitCalc?: number
	SubJobExistImgCalc?: string
	DocumentIdentificationCalc?: string
	RemainingTimeCalc?: number
	DelayDaysCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	DataModuleNumber?: TTextDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => JobKind)
	KindId?: JobKind
	@Type(() => JobGroup)
	GroupId?: JobGroup
	@Type(() => JobCategory)
	CategoryId?: JobCategory
	@Type(() => JobStatus)
	StatusId?: JobStatus
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	OwnerId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ClientId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobHistoryInstructionDM)
	InstructionHistoryChild?: TJobHistoryInstructionDM[]
	@Type(() => TJobHistoryResolutionDM)
	JobHistoryResolutionChild?: TJobHistoryResolutionDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}