import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCodeCalendarItemVisualDM from './TCodeCalendarItemVisualDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TWorkplaceDM from './TWorkplaceDM'
import TPrCalendarDM from './TPrCalendarDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TContactPersonDM from './TContactPersonDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAttendanceScheduleDM extends Data {
	public static className = 'TAttendanceScheduleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsHoliday?: string
	IsOvertimeShift?: string
	ConfirmedOn?: Date
	DateTo?: Date
	DateFrom?: Date
	Date?: Date
	Number?: number
	StatusId?: number
	IsInvalidRecord?: string
	IsCanceledRecord?: string
	IsBlockedRecord?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCodeCalendarItemVisualDM)
	CodeCalendarDateItemRID?: TCodeCalendarItemVisualDM
	@Type(() => TTextDM)
	OvertimeTypeId?: TTextDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkshiftTypeRID?: TAttendanceWorkShiftTypeDM
	@Type(() => TWorkplaceDM)
	WorkplaceRID?: TWorkplaceDM
	@Type(() => TPrCalendarDM)
	WHModelId?: TPrCalendarDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}