import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ZarStatus from './ZarStatus'
import PurchaseWay from './PurchaseWay'
import TypDeprGr from './TypDeprGr'
import AccKindPurch from './AccKindPurch'
import FunctType from './FunctType'
import TypPlanuZar from './TypPlanuZar'
import TypVyr from './TypVyr'
import MajSkup from './MajSkup'
import ConstrType from './ConstrType'
import TOldBookDM from './TOldBookDM'
import TPeriodDM from './TPeriodDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TPeriodListDM from './TPeriodListDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import ZarUmisteni from './ZarUmisteni'
import Pracoviste from './Pracoviste'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import KindBuild from './KindBuild'
import KindParcel from './KindParcel'
import CadTerritory from './CadTerritory'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TJobDM from './TJobDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAssetLinkItemDM from './TAssetLinkItemDM'
import TD_ZarPC from './TD_ZarPC'
import TD_ZarPM1 from './TD_ZarPM1'
import TD_ZarPM2 from './TD_ZarPM2'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TD_Zar extends Data {
	public static className = 'TD_Zar'
	public static systemFields = [
	]

	ProductionClassificationRID?: number
	CreationDate?: Date
	RID?: number
	UADeprSK2?: number
	UADeprSK1?: number
	OldDeprY2?: number
	OldDeprY1?: number
	RegistrationNo?: string
	ContractNo?: string
	Grant?: number
	SpaceProcessId?: number
	PreselectionId2?: number
	PreselectionId1?: number
	EAN?: string
	DAT_TO?: Date
	YearBefore2?: number
	YearBefore1?: number
	PlaCis?: number
	SouCis?: number
	InvCis?: string
	Crc2?: number
	Poc_LPolA?: number
	Poc_LPol?: number
	Poc_PolA?: number
	Poc_Pol?: number
	PohybV?: number
	PohybO?: number
	PohybZ?: number
	TRIDA?: number
	OPR2_ST?: number
	OPR1_ST?: number
	PROD_C?: number
	VST_CENA?: number
	DBD?: Date
	DPO?: Date
	DZO?: Date
	DAT_ZAR?: Date
	DAT_VYR?: Date
	DPZ?: Date
	DAT_POR?: Date
	VYR_CIS?: string
	POPIS?: string
	NAZEV?: string
	Volny?: number
	CISLO?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	VersionFieldDateCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	CFPotvr?: string
	CFOpr1?: number
	CFOpr2?: number
	CFZust1?: number
	CFZust2?: number
	CFAktC1?: number
	CFAktC2?: number
	CFAktD1?: Date
	CFAktD2?: Date
	BMOdepsan?: string
	BMPotStoVyr?: string
	BMSoubor?: string
	BMDalPlan?: string
	CFLikvid?: number
	CFDLikvid?: Date
	CFHlDatP?: Date
	CFKnOpr1?: number
	CFKnOpr2?: number
	CFKnAktC1?: number
	CFKnAktC2?: number
	CFKnAktD1?: Date
	CFKnAktD2?: Date
	CFHoldCislo?: number
	CFKnRoc1?: number
	CFKnRoc2?: number
	CFKnMes1?: number
	CFKnMes2?: number
	LanguageTextCalc?: string
	CFInvCis?: string
	CFOprSt1?: number
	CFOprSt2?: number
	CFKnPri?: string
	CFTypPla1?: number
	CFTypPla2?: number
	CFYear1?: number
	CFYear2?: number
	CFPers?: string
	BMChange1?: string
	BMChange2?: string
	CFKnAcc1?: number
	CFKnAcc2?: number
	CFDPO1?: Date
	CFDPO2?: Date
	CFDLA1?: Date
	CFDLA2?: Date
	CompanyCurrencyCalc?: string
	BalanceAcceptDisp1Calc?: number
	BalanceAcceptDisp2Calc?: number
	TaxCalc?: number
	TaxInfoCalc?: string
	ProductionClassificationRID_ver?: number
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	CreationWho?: TK2UserLookupDM
	@Type(() => ZarStatus)
	Status?: ZarStatus
	@Type(() => PurchaseWay)
	PurchaseWayId?: PurchaseWay
	@Type(() => TypDeprGr)
	DeprGrId?: TypDeprGr
	@Type(() => AccKindPurch)
	AccKindId?: AccKindPurch
	@Type(() => FunctType)
	FTypeId?: FunctType
	@Type(() => TypPlanuZar)
	Typ2?: TypPlanuZar
	@Type(() => TypPlanuZar)
	Typ1?: TypPlanuZar
	@Type(() => TypVyr)
	TypVyr?: TypVyr
	@Type(() => TK2UserLookupDM)
	DPZ_Kdo?: TK2UserLookupDM
	@Type(() => MajSkup)
	Skup?: MajSkup
	@Type(() => ConstrType)
	Kod?: ConstrType
	@Type(() => TOldBookDM)
	MAJETEK?: TOldBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	CFRok1?: TTextDM
	@Type(() => TTextDM)
	CFRok2?: TTextDM
	@Type(() => TAccountingJournalDM)
	CFUcDok?: TAccountingJournalDM
	@Type(() => TTextDM)
	CFPotStorno?: TTextDM
	@Type(() => TPeriodListDM)
	CFPerOd?: TPeriodListDM
	@Type(() => TPeriodListDM)
	CFPerDo?: TPeriodListDM
	@Type(() => TTextDM)
	CFOdep1?: TTextDM
	@Type(() => TTextDM)
	CFOdep2?: TTextDM
	@Type(() => TTextDM)
	CFOdep?: TTextDM
	@Type(() => TOfficerDocumentDM)
	CFCPrij?: TOfficerDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CFKnStr?: TCostCentreCollectionDocumentDM
	@Type(() => ZarUmisteni)
	CFKnUmi?: ZarUmisteni
	@Type(() => Pracoviste)
	CFKnPra?: Pracoviste
	@Type(() => TContractCodeDocumentDM)
	CFKnZak?: TContractCodeDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	CFKnZbo?: TArticleCodeDocumentDM
	@Type(() => TCode1DocumentDM)
	CFKnKod1?: TCode1DocumentDM
	@Type(() => TCode2DocumentDM)
	CFKnKod2?: TCode2DocumentDM
	@Type(() => TMatchingSymbolDM)
	CFKnPS?: TMatchingSymbolDM
	@Type(() => TTradingPartnerDM)
	CFKnCdo?: TTradingPartnerDM
	@Type(() => TDeviceDocumentDM)
	DeviceCalc?: TDeviceDocumentDM
	@Type(() => TAccountingChartDM)
	CFAccountPurch?: TAccountingChartDM
	@Type(() => KindBuild)
	KindBuildCalc?: KindBuild
	@Type(() => KindParcel)
	KindParcelCalc?: KindParcel
	@Type(() => CadTerritory)
	CadTerritoryCalc?: CadTerritory
	@Type(() => TCode3DocumentDM)
	CFKnKod3?: TCode3DocumentDM
	@Type(() => TCode4DocumentDM)
	CFKnKod4?: TCode4DocumentDM
	@Type(() => TCode5DocumentDM)
	CFKnKod5?: TCode5DocumentDM
	@Type(() => TCode6DocumentDM)
	CFKnKod6?: TCode6DocumentDM
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAssetLinkItemDM)
	AssetLinkItemChild?: TAssetLinkItemDM[]
	@Type(() => TD_ZarPC)
	AssetAccountingItemOldChild?: TD_ZarPC[]
	@Type(() => TD_ZarPM1)
	AssetPlan1ItemOldChild?: TD_ZarPM1[]
	@Type(() => TD_ZarPM2)
	AssetPlan2ItemOldChild?: TD_ZarPM2[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}