import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import Availability from './Availability'
import TEshopStockTextItemAloneDM from './TEshopStockTextItemAloneDM'
import TLinkDM from './TLinkDM'
import TEshopRelatedArticleDM from './TEshopRelatedArticleDM'
import TEshopMeasureUnitDM from './TEshopMeasureUnitDM'

export default class TEshopBasketArticleDM extends Data {
	public static className = 'TEshopBasketArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsPayment?: string
	IsTransport?: string
	ArticleShopValueRID?: number
	IsQuotationRequest?: string
	IsSlaveVariation?: string
	IsBasketDeniedNotLogged?: string
	StockTextRID?: number
	VariationSetId?: number
	Currency?: string
	LanguageId?: number
	PriceGroupId?: number
	TradingPartnerId?: number
	IsBasketDenied?: string
	AvailabilityLength?: number
	AvailabilityAbbr?: string
	Available?: number
	BasePriceInt?: number
	NewsPriorityId?: number
	RatingId?: number
	VisitCount?: number
	SoldCount?: number
	EshopOriginalPriceNet?: number
	BasePriceNet?: number
	ShopRID?: number
	IsCampaign?: string
	IsComingSoon?: string
	IsSecondHand?: string
	IsSellOut?: string
	IsDiscount?: string
	IsTip?: string
	IsFreeShipping?: string
	IsNewArticle?: string
	WarrantyCompanyLength?: number
	WarrantyCompanyAbbr?: string
	WarrantyCustomerLength?: number
	WarrantyCustomerAbbr?: string
	EshopOrigPrice?: number
	EshopXmlCategoryTextHeureka?: number
	EshopXmlCategoryText?: number
	EshopXmlDescription?: string
	EshopXmlTitle?: string
	EshopPageKeyWords?: string
	EshopPageDescription?: string
	EshopPageTitle?: string
	EshopNote?: string
	EshopShortDescription?: string
	EshopArticleGroupId?: number
	StatusId?: number
	VATRateId?: number
	CustomsTariffId?: number
	Description?: string
	ParameterSetId?: number
	ArticleCategoryId?: number
	OriginCountryId?: number
	RightGroupId?: number
	ArticleTypeId?: number
	PackingId?: number
	BasePrice?: number
	Producer?: string
	Name?: string
	Abbr2?: string
	Abbr1?: string
	ArticleGroupId?: string
	ArticleId?: number
	CouponErrId?: number
	IsVoucher?: string
	CouponRID?: number
	SuperiorRID?: number
	ListPriceNet?: number
	ListPriceGross?: number
	IsPercentageDiscount?: string
	Discount?: number
	ItemDescription?: string
	ItemName?: string
	PriceNet?: number
	PriceGross?: number
	SequenceNumber?: number
	AdditionalInfo?: string
	RID?: number
	Quantity?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	SEONameCalc?: string
	DefaultSaleMeasureUnitCalc?: string
	DefaultSaleUnitIntegerIdCalc?: number
	DefaultEshopMeasureUnitCalc?: string
	DefaultEshopUnitIntegerIdCalc?: number
	EshopSaleMeasureUnitRatioCalc?: number
	DetailUrlCalc?: string
	DetailUrlAbsCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedKeyWordsCalc?: string
	ComputedPriceNetCalc?: number
	GrossPriceCalc?: number
	OriginPriceNetCalc?: number
	OriginPriceGrossCalc?: number
	EshopMeasureUnitIdCalc?: number
	EshopMeasureUnitRatioCalc?: number
	AvailableInEShopUnitQuantityCalc?: number
	EshopMeasureUnitStepQuantityCalc?: number
	IsAddedCalc?: string
	AddedQuantityCalc?: number
	AddedPriceNetCalc?: number
	AddedPriceGrossCalc?: number
	QuantityUMCalc?: number
	EshopBasketPriceNetCalc?: number
	EshopBasketPriceGrossCalc?: number
	EshopBasketTotalPriceNetCalc?: number
	EshopBasketTotalPriceGrossCalc?: number
	ComputedDiscountCalc?: number
	EshopBasketReducedPriceCalc?: number
	EshopBasketReducedTotalPriceNetCalc?: number
	EshopBasketReducedTotalPriceGrossCalc?: number
	@Type(() => TArticleDM)
	MasterId?: TArticleDM
	@Type(() => TEshopCategoryInfoDM)
	MainCategoryRID?: TEshopCategoryInfoDM
	@Type(() => TArticleDM)
	ArticleBindId?: TArticleDM
	@Type(() => Availability)
	AvailabilityId?: Availability
	@Type(() => TEshopStockTextItemAloneDM)
	StockTextItemRIDCalc?: TEshopStockTextItemAloneDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticleDetailChild?: TEshopRelatedArticleDM[]
	@Type(() => TEshopMeasureUnitDM)
	MeasureUnitChild?: TEshopMeasureUnitDM[]
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticlePreBasketChild?: TEshopRelatedArticleDM[]
	@Type(() => TEshopRelatedArticleDM)
	RelatedArticleBasketChild?: TEshopRelatedArticleDM[]
}