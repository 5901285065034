import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'
import TCustomerCreditItemDM from './TCustomerCreditItemDM'
import TCustomerCreditViolationDM from './TCustomerCreditViolationDM'

export default class TCustomerCreditDM extends Data {
	public static className = 'TCustomerCreditDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsOutdated?: string
	TimeStamp?: string
	RID?: number
	IsInactive?: string
	NotCheckCashPayments?: string
	Percentage?: number
	Limit?: number
	CompanyId?: number
	CreditRID?: number
	TradingPartnerId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AmountCalc?: number
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TCustomerCreditItemDM)
	CreditItemChild?: TCustomerCreditItemDM[]
	@Type(() => TCustomerCreditViolationDM)
	CreditTradingPartnerViolationChild?: TCustomerCreditViolationDM[]
}