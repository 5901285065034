import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class ESSearchWordSps extends Data {
	public static className = 'ESSearchWordSps'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Description?: string
	RightGroupId?: number
	Id?: number
	ShortDescription?: string
	Abbr?: string
	TypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordImgCalc?: string
	StatusCalc?: string
	BlockedRecordBitCalc?: number
	NotifyBitCalc?: number
	IsScrappedWhenDisposalCalc?: string
	LanguageTextCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	CurrentTypeRIDCalc?: TTextDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageShortDescriptionChild?: TLanguageTextTranslationDM[]
}