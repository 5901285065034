import axios, { AxiosRequestHeaders } from 'axios'
import { plainToInstance, instanceToInstance } from 'class-transformer'
import HmacMD5 from 'crypto-js/hmac-md5'
import encBase64 from 'crypto-js/enc-base64'
import Data from './Data'
import DataList from './DataList'
import PrimaryKey from './PrimaryKey'
import Serializer from './Serializer'
import IDataObjectWrapper from './IDataObjectWrapper'
import IPagedList from './IPagedList'

export default class Api {
    constructor() {
        const apiUrl = this.getApiUrl()
        if (apiUrl === undefined || apiUrl === '') {
            throw new Error('env variable REACT_APP_K2_API_URL is not defined.')
        }
    }

    private serializer = new Serializer()

    public async getData(c: typeof Data, primaryKey: PrimaryKey, fields?: string[]): Promise<Data> {
        const url = this.getDataUrl(c.className, primaryKey, this.addSystemFields(c, fields))
        return axios.get(url, {
            headers: this.getAuthorizationHeader(this.getApiLogin(), this.getApiPassword(), url)
        })
            .then(response => {
                return this.createDataInstance(c, response.data)
            })
    }

    public async getDataList(c: typeof Data, fields?: Array<string>, conditions?: Array<string>, orderBy?: Array<string>): Promise<DataList> {
        const url = this.getDataListUrl(c.className, this.addSystemFields(c, fields), conditions, orderBy)
        return axios.get(url, {
            headers: this.getAuthorizationHeader(this.getApiLogin(), this.getApiPassword(), url)
        })
            .then(response => {
                return this.createDataListInstance(c, response.data)
            })
    }

    public async putData(data: Data, fields?: string[]): Promise<Data> {
        const url = this.getDataUrl((data.constructor as typeof Data).className, data.getPrimaryKey(), this.addSystemFields(data.constructor as typeof Data, fields))
        return axios.put(url, this.serializer.unserializeDataObject(data.getChangedData()), {
            headers: this.getAuthorizationHeader(this.getApiLogin(), this.getApiPassword(), url)
        })
            .then(response => {
                return this.createDataInstance(data.constructor as typeof Data, response.data)
            })
    }

    public async postData(data: Data, fields?: string[]): Promise<Data> {
        const url = this.getDataUrl((data.constructor as typeof Data).className, data.getPrimaryKey(), this.addSystemFields(data.constructor as typeof Data, fields))
        return axios.post(url, this.serializer.unserializeDataObject(data), {
            headers: this.getAuthorizationHeader(this.getApiLogin(), this.getApiPassword(), url)
        })
            .then(response => {
                return this.createDataInstance(data.constructor as typeof Data, response.data)
            })
    }

    public getDataUrl(className: string, primaryKey: PrimaryKey, fields?: string[]): string {
        const url = new URL(`${this.getApiUrl()}Data/${className}` + (primaryKey ? `/${Array.isArray(primaryKey) ? primaryKey.join('/') : primaryKey}` : ''))
        if (fields && fields.length) url.searchParams.append('fields', fields.join(','))
        return url.href
    }

    public getDataListUrl(className: string, fields?: string[], conditions?: string[], orderBy?: string[]): string {
        const url = new URL(`${this.getApiUrl()}Data/${className}`)
        if (fields && fields.length) url.searchParams.append('fields', fields.join(','))
        if (conditions && conditions.length) url.searchParams.append('conditions', conditions.join(','))
        if (orderBy && orderBy.length) url.searchParams.append('orderBy', orderBy.join(','))
        return url.href
    }

    public getAuthorizationHeader(login: string | undefined, password: string, url: string): AxiosRequestHeaders {
        if (!login) return {}
        return {
            Authorization: `${login}:${HmacMD5(decodeURIComponent(url.replace(/\+/g, ' ').toUpperCase()), password).toString(encBase64)}`
        }
    }

    public getApiUrl(): string {
        if (!process.env.REACT_APP_K2_API_URL) throw new Error('env variable REACT_APP_K2_API_URL is not defined.')
        if (process.env.REACT_APP_K2_API_URL.slice(-1) !== '/') return `${process.env.REACT_APP_K2_API_URL}/`
        return process.env.REACT_APP_K2_API_URL
    }

    public getApiLogin(): string {
        if (!process.env.REACT_APP_K2_API_LOGIN) throw new Error('env variable REACT_APP_K2_API_LOGIN is not defined.')
        return process.env.REACT_APP_K2_API_LOGIN
    }

    public getApiPassword(): string {
        return process.env.REACT_APP_K2_API_PASSWORD!
    }

    private createDataInstance(c: typeof Data, dataObject: IDataObjectWrapper): Data {
        let data = plainToInstance(c, this.serializer.serializeDataObject(dataObject))
        data.originalData = instanceToInstance(data)

        return data
    }

    private createDataListInstance(c: typeof Data, pagedList: IPagedList): DataList {
        let dataList = new DataList()
        pagedList.Items.forEach(dataObject => {
            dataList.push(this.createDataInstance(c, dataObject))
        })

        return dataList
    }

    private addSystemFields(c: typeof Data, fields?: string[]): string[] | undefined {
        if (Array.isArray(fields)) {
            const newFields = fields.slice()
            c.systemFields.forEach(systemField => {
                if (!newFields.includes(systemField)) newFields.push(systemField)
            })

            return newFields
        }

        return fields
    }
}