import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TJobCardFieldDM from './TJobCardFieldDM'

export default class TJobCardConfigFieldDM extends Data {
	public static className = 'TJobCardConfigFieldDM'
	public static systemFields = [
		'RecordID',
	]

	Value?: string
	HeaderId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ValueCalc?: string
	@Type(() => TJobCardFieldDM)
	FieldId?: TJobCardFieldDM
}