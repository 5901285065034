import moment from 'moment'
import Data from './Data'
import IChildDataObjectWrapper from './IChildDataObjectWrapper'
import IDataObjectWrapper from './IDataObjectWrapper'
import IPagedList from './IPagedList'
import ISimpleObject from './ISimpleObject'

export default class Serializer {
    static dataObjectWrapperType = 'DataObjectWrapper:K2.Data'
    static childDataObjectWrapperType = 'ChildDataObjectWrapper:K2.Data'

    serializeDataObject(dataObject: IDataObjectWrapper): ISimpleObject {
        let object: ISimpleObject = {}

        dataObject.FieldValues.forEach(fieldValue => {
            if (typeof fieldValue.Value === 'object') {
                switch (fieldValue.Value['__type']) {
                    case Serializer.dataObjectWrapperType:
                        object[fieldValue.Name] = this.serializeDataObject(fieldValue.Value)
                        break
                    case Serializer.childDataObjectWrapperType:
                        object[fieldValue.Name] = [] as Array<IDataObjectWrapper>
                        (fieldValue.Value as IChildDataObjectWrapper).Items.forEach(childDataObject => {
                            object[fieldValue.Name].push(this.serializeDataObject(childDataObject))
                        })
                        break
                    default:
                        throw new Error('Unknown data type.')
                }

            } else if (typeof fieldValue.Value === 'string' && fieldValue.Value.match(/^\/?Date\((-?\d+)/i)) {
                object[fieldValue.Name] = moment(fieldValue.Value).toDate()
            } else {
                object[fieldValue.Name] = fieldValue.Value
            }
        })

        return object
    }

    serializeDataObjectList(pagedList: IPagedList): Array<ISimpleObject> {
        let objectList: Array<ISimpleObject> = []
        pagedList.Items.forEach(dataObject => {
            objectList.push(this.serializeDataObject(dataObject))
        })

        return objectList
    }

    unserializeDataObject<T extends Data>(data: T, typed: boolean = false): IDataObjectWrapper {       
        let object: IDataObjectWrapper = {
            DOClassName: (data.constructor as typeof Data).className,
            FieldValues: []
        }

        if (typed) {
            object = Object.assign({__type: Serializer.dataObjectWrapperType}, object)
        }

        if (data._isDeleted) {
            object.Deleted = true
        }

        for (const [key, value] of Object.entries(data)) {
            if (key === '_isDeleted') continue

            if (value !== undefined) {
                if (Array.isArray(value)) {
                    object.FieldValues.push({
                        Name: key,
                        Value: {
                            __type: Serializer.childDataObjectWrapperType,
                            Items: value.map(item => {
                                return this.unserializeDataObject(item, false)
                            })
                        }
                    })
                } else if (typeof value === 'object') {
                    object.FieldValues.push({
                        Name: key,
                        Value: this.unserializeDataObject(value, true)
                    })
                } else {
                    object.FieldValues.push({
                        Name: key,
                        Value: value
                    })
                }
            }
        }

        return object
    }
}