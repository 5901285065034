import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrPeriodDM from './TPrPeriodDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAttendanceWTRUnliqOvertimesDM extends Data {
	public static className = 'TAttendanceWTRUnliqOvertimesDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ToTransfer?: number
	Liquidated?: number
	NewlyCounted?: number
	Transferred?: number
	HoursCount?: number
	BookId?: number
	TypeId?: number
	WorkTimeReportRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TPrPeriodDM)
	PayrollPeriodId?: TPrPeriodDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}