import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import Priority from './Priority'
import TWMSQueueDM from './TWMSQueueDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TTextDM from './TTextDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TTransportMethodDM from './TTransportMethodDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TransferStatusCalc from './TransferStatusCalc'
import PlanType from './PlanType'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TWarehouseDM from './TWarehouseDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TBatchDM from './TBatchDM'
import TSalesOrderDM from './TSalesOrderDM'
import TJobCardDM from './TJobCardDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TTransferNoteItemDM from './TTransferNoteItemDM'
import TAccountingFinanceTransferNoteItemDM from './TAccountingFinanceTransferNoteItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TTransferNoteDM extends Data {
	public static className = 'TTransferNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	EndDateTo?: Date
	EndDateFrom?: Date
	StartDateTo?: Date
	StartDateFrom?: Date
	HandlingEquipmentToId?: number
	PriorityToId?: number
	QueueToId?: number
	CreatedOn?: Date
	Settings?: string
	SequenceNumber?: number
	Number?: number
	SuperiorRID?: number
	RID?: number
	TimeStamp?: string
	OrderNo?: string
	ReservationDate?: Date
	TransferTime2?: Date
	TransferDate2?: Date
	PostedById?: number
	PostedOn?: Date
	TransferTime?: Date
	RightGroupId?: number
	AccountingItemsCount?: number
	ItemCount?: number
	ChangedOn?: Date
	TransferDate?: Date
	LastPaymentDate?: Date
	IssueDate?: Date
	PostingKeyId?: number
	AmountPaidByArchive?: number
	AmountPaid?: number
	Amount?: number
	PenaltyAmount?: number
	Description?: string
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	PriceCalc?: number
	CorrectionTransferNoteImgCalc?: string
	TotalPriceWarehouseToCalc?: number
	ReleaseNoteBitCalc?: number
	ReservedBitCalc?: number
	DateFromCalc?: Date
	StartTimeCalc?: Date
	DateToCalc?: Date
	EndTimeCalc?: Date
	ReservationDateCalc?: Date
	ReservationTimeCalc?: Date
	SuperiorDocumentIdentifierCalc?: string
	TotalWeightNetCalc?: number
	TotalWeightGrossCalc?: number
	TotalVolumePARAMCalc?: number
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TCode6DocumentDM)
	Code6ToId?: TCode6DocumentDM
	@Type(() => TCode6DocumentDM)
	Code6FromId?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5ToId?: TCode5DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5FromId?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4ToId?: TCode4DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4FromId?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3ToId?: TCode3DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3FromId?: TCode3DocumentDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentFromId?: TWMSEquipmentDM
	@Type(() => Priority)
	PriorityFromId?: Priority
	@Type(() => TWMSQueueDM)
	QueueFromId?: TWMSQueueDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreToId?: TCostCentreCollectionDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreFromId?: TCostCentreCollectionDocumentDM
	@Type(() => TOfficerDocumentDM)
	WarehousemanToId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	WarehousemanFromId?: TOfficerDocumentDM
	@Type(() => TTextDM)
	SuperiorDocumentTypeId?: TTextDM
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneToRID?: TWarehouseZoneDM
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneFromRID?: TWarehouseZoneDM
	@Type(() => TDeviceDocumentDM)
	DeviceToRID?: TDeviceDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeToRID?: TContractCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromRID?: TContractCodeDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceFromRID?: TDeviceDocumentDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TransferStatusCalc)
	StatusId?: TransferStatusCalc
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TK2UserLookupDM)
	TransferredBy2Id?: TK2UserLookupDM
	@Type(() => TCode2DocumentDM)
	Code2ToId?: TCode2DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2FromId?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1ToId?: TCode1DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1FromId?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerToId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerFromId?: TOfficerDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TK2UserLookupDM)
	TransferredById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TWarehouseDM)
	WarehouseToId?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseFromId?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTextDM)
	IgnoreIncomeCostsBitCalc?: TTextDM
	@Type(() => TTextDM)
	SerialNumberInconsistencyBitCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectionTransferNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
	@Type(() => TJobCardDM)
	JobCardRIDCalc?: TJobCardDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TTransferNoteItemDM)
	TransferNoteItemChild?: TTransferNoteItemDM[]
	@Type(() => TAccountingFinanceTransferNoteItemDM)
	AccFinTransferNoteItemChild?: TAccountingFinanceTransferNoteItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}