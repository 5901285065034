import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEkoKomPackagingUsageDM from './TEkoKomPackagingUsageDM'

export default class TEkoKomPackagingDM extends Data {
	public static className = 'TEkoKomPackagingDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ColoringId?: number
	FormId?: number
	ContainsBeverage?: string
	ValidFrom?: Date
	IsEURPallet?: string
	IsPallet?: string
	Weight?: number
	IsReportStatistics?: string
	ValidTo?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsCompositeEvaluationCalc?: string
	MainMaterialIdCalc?: number
	MainSubordinateKindIdCalc?: number
	RecycledPctCalc?: number
	@Type(() => TTextDM)
	OriginId?: TTextDM
	@Type(() => TTextDM)
	PackKindId?: TTextDM
	@Type(() => TTextDM)
	PackDirectId?: TTextDM
	@Type(() => TTextDM)
	PackTypeId?: TTextDM
	@Type(() => TTextDM)
	PackModeId?: TTextDM
	@Type(() => TTextDM)
	CompositeIdCalc?: TTextDM
	@Type(() => TTextDM)
	FormIdCalc?: TTextDM
	@Type(() => TTextDM)
	BeverageCartonFormIdCalc?: TTextDM
	@Type(() => TTextDM)
	ColorIdCalc?: TTextDM
	@Type(() => TTextDM)
	PETSaleColoringIdCalc?: TTextDM
	@Type(() => TEkoKomPackagingUsageDM)
	ArticleItemChild?: TEkoKomPackagingUsageDM[]
}