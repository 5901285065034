import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TCampaignDM from './TCampaignDM'
import TOpportunityDM from './TOpportunityDM'
import TPartnerDM from './TPartnerDM'
import TypCallCentrum from './TypCallCentrum'
import TActivityDM from './TActivityDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TFindByPhoneDM from './TFindByPhoneDM'
import TOldNoteDM from './TOldNoteDM'

export default class TCallHistoryDM extends Data {
	public static className = 'TCallHistoryDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	RightGroupId?: number
	TimeStamp?: string
	RingingTime?: number
	CallId?: string
	Comment?: string
	ChangedOn?: Date
	CreatedOn?: Date
	ConfirmedOn?: Date
	Description?: string
	ForwardedTo?: number
	DateTo?: Date
	DateFrom?: Date
	PhoneNumberTo?: string
	PhoneNumberFrom?: string
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CommentCalc?: string
	DateFromCalc?: Date
	StartTimeCalc?: Date
	DateToCalc?: Date
	EndTimeCalc?: Date
	IsInternalCallCalc?: string
	CallLenghtCalc?: Date
	CallLenghtMSecCalc?: number
	IsBusyCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TypCallCentrum)
	CallTypeId?: TypCallCentrum
	@Type(() => TActivityDM)
	ActivityId?: TActivityDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TFindByPhoneDM)
	DataModuleFindByPhoneOldChild?: TFindByPhoneDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}