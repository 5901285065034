import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import Region from './Region'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCountryDM from './TCountryDM'

export default class TTownDM extends Data {
	public static className = 'TTownDM'
	public static primaryKey = 'TownId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.TownId!
	}

	TimeStamp?: string
	Longitude?: number
	Latitude?: number
	TownNumberId?: number
	TownId?: number
	ChangedOn?: Date
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => Region)
	RegionId?: Region
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
}