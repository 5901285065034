import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'
import TEshopDM from './TEshopDM'
import TArticleDM from './TArticleDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TEshopTextPageDM from './TEshopTextPageDM'
import TEshopBasketDM from './TEshopBasketDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopFavoriteDM extends Data {
	public static className = 'TEshopFavoriteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	SequenceNumber?: number
	Title?: string
	Name?: string
	URL?: string
	DataModuleRecordRID?: number
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	FavoriteTypeId?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TArticleDM)
	ArticleRIDCalc?: TArticleDM
	@Type(() => TEshopCategoryInfoDM)
	ArticleCategoryRIDCalc?: TEshopCategoryInfoDM
	@Type(() => TEshopTextPageDM)
	TextPageRIDCalc?: TEshopTextPageDM
	@Type(() => TEshopBasketDM)
	EshopBasketRIDCalc?: TEshopBasketDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}