import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TTextDM from './TTextDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import ProductCostingStatus from './ProductCostingStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TProductCostingConfigurationDM from './TProductCostingConfigurationDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TCalculationModelDM from './TCalculationModelDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TQuotationOfferedItemAloneDM from './TQuotationOfferedItemAloneDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TProductCostingSummaryDM from './TProductCostingSummaryDM'
import TProductCostingTreeDM from './TProductCostingTreeDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TProductCostingDM extends Data {
	public static className = 'TProductCostingDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	MeasureUnitId?: number
	UserDefinedPrice?: number
	CalculatedPrice?: number
	UserDefinedPriceC?: number
	RedefinePrice?: string
	CalculatedPriceC?: number
	SaveSumItems?: string
	SaveTree?: string
	ConfirmedOn?: Date
	IsCanceledRecord?: string
	CostingItemId?: number
	DocumentRID?: number
	ExchangeRate?: number
	Weight?: number
	Quantity?: number
	Name?: string
	TimeStamp?: string
	RightGroupId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	Number?: number
	Description?: string
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	DocumentIdentificationNumberCalc?: string
	DocumentItemIdentifierCalc?: string
	DocumentItemRIDCalc?: number
	CalculatedUnitPriceCCalc?: number
	UserDefinedPriceCUMCalc?: number
	ClientCurrencyCalc?: string
	ExchangeRateCalc?: number
	WeightUMCalc?: number
	CalculatedUnitPriceCalc?: number
	UserDefinedUnitPriceCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TTextDM)
	SpecificationId?: TTextDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => ProductCostingStatus)
	StatusId?: ProductCostingStatus
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TProductCostingConfigurationDM)
	ProductCostingConfigurationRID?: TProductCostingConfigurationDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TCalculationModelDM)
	WeightCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	PriceCalculationModelId?: TCalculationModelDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TQuotationOfferedItemAloneDM)
	QuotationItemRIDCalc?: TQuotationOfferedItemAloneDM
	@Type(() => TSalesItemBookDM)
	SalesOrderItemRIDCalc?: TSalesItemBookDM
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => AlternativeUnit)
	GeneralMeasureUnitIdCalc?: AlternativeUnit
	@Type(() => AlternativeUnit)
	MeasureUnitGridIdCalc?: AlternativeUnit
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TProductCostingSummaryDM)
	SummaryChild?: TProductCostingSummaryDM[]
	@Type(() => TProductCostingTreeDM)
	TreeChild?: TProductCostingTreeDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}