import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRNCourse from './TRNCourse'
import TRNTyp from './TRNTyp'
import TPeriodicityDM from './TPeriodicityDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

export default class TWorkerEventAsItemDM extends Data {
	public static className = 'TWorkerEventAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CompletionDate?: Date
	Description?: string
	ValidTo?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	RightGroupid?: number
	Date?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TRNCourse)
	CourseId?: TRNCourse
	@Type(() => TRNTyp)
	TypeId?: TRNTyp
	@Type(() => TPeriodicityDM)
	PeriodicityRID?: TPeriodicityDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TEmployedPersonDM)
	WorkerId?: TEmployedPersonDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}