import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import PresentName from './PresentName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TMarketingGiftDM extends Data {
	public static className = 'TMarketingGiftDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	AttachDataModuleNumber?: number
	AttachRowNumber?: number
	AttachId?: number
	AttachBook?: string
	AttachTypeId?: number
	Value?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	Date?: Date
	LanguageId?: string
	SequenceNumber?: number
	ContactPersonId?: number
	TypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => PresentName)
	Text?: PresentName
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}