import Data from '../Api/Data'

export default class TPredictionItemDM extends Data {
	public static className = 'TPredictionItemDM'
	public static systemFields = [
	]

	QuantityY?: number
	ValueY?: number
	DateX?: Date
	TimeSerie?: number
	PredictionModelRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}