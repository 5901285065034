import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TAssetDM from './TAssetDM'
import TResourceDM from './TResourceDM'
import ServicedDeviceCategory from './ServicedDeviceCategory'
import ServicedDeviceGroup from './ServicedDeviceGroup'
import TAddressDM from './TAddressDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import ServicedDeviceType from './ServicedDeviceType'
import ServicedDeviceStatus from './ServicedDeviceStatus'
import TBatchDM from './TBatchDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TArticleDM from './TArticleDM'
import TContactPersonDM from './TContactPersonDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import ServiceOperationType from './ServiceOperationType'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TServicedDeviceItemDM from './TServicedDeviceItemDM'
import TJobCardDM from './TJobCardDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TServiceNoteDM from './TServiceNoteDM'
import TServiceContractDM from './TServiceContractDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TServiceOperationDM from './TServiceOperationDM'
import TMaintenanceFrequencyDefinitionDM from './TMaintenanceFrequencyDefinitionDM'
import TAttributeValueDM from './TAttributeValueDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TServicedDeviceDM extends Data {
	public static className = 'TServicedDeviceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CustomerIdentification?: string
	LegallyControlled?: string
	IsInvalidRecord?: string
	ServiceParameterGroupId?: number
	VehicleId?: number
	WarrantyEndDate?: Date
	LaunchDate?: Date
	SalesDate?: Date
	ProductionNumber?: string
	SerialNumber?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NextOperationDateCalc?: Date
	LastUnconfirmedOperationDateCalc?: Date
	LastConfirmedOperationDateCalc?: Date
	LastConfirmedOperationDateByTypeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	LocationAddressIdStreetCalc?: string
	LocationAddressIdOrientationNumberCalc?: string
	LocationAddressIdHouseNumberCalc?: string
	LocationAddressIdNameCalc?: string
	LocationAddressIdTownCodeCalc?: string
	LocationAddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTradingPartnerDM)
	OptimalSupplierId?: TTradingPartnerDM
	@Type(() => TAssetDM)
	AssetRID?: TAssetDM
	@Type(() => TTradingPartnerDM)
	CustomerForInvoicingId?: TTradingPartnerDM
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => ServicedDeviceCategory)
	CategoryId?: ServicedDeviceCategory
	@Type(() => ServicedDeviceGroup)
	GroupId?: ServicedDeviceGroup
	@Type(() => TAddressDM)
	LocationAddressId?: TAddressDM
	@Type(() => TTradingPartnerDM)
	CustomerLocationId?: TTradingPartnerDM
	@Type(() => TOfficerDocumentDM)
	ServiceWorkerId?: TOfficerDocumentDM
	@Type(() => ServicedDeviceType)
	TypeId?: ServicedDeviceType
	@Type(() => ServicedDeviceStatus)
	StatusId?: ServicedDeviceStatus
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TScheduledServiceOperationDM)
	LastConfirmedOperationRIDByTypeCalc?: TScheduledServiceOperationDM
	@Type(() => ServiceOperationType)
	ServiceTypeIdCalc?: ServiceOperationType
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCountryDM)
	LocationAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	LocationAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	LocationAddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TServicedDeviceItemDM)
	ServicedDeviceItemChild?: TServicedDeviceItemDM[]
	@Type(() => TJobCardDM)
	JobCardChild?: TJobCardDM[]
	@Type(() => TPurchaseItemBookDM)
	PurchaseItemBookChild?: TPurchaseItemBookDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemBookChild?: TSalesItemBookDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]
	@Type(() => TServiceContractDM)
	ServiceContractChild?: TServiceContractDM[]
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderChild?: TPurchaseOrderDM[]
	@Type(() => TServiceOperationDM)
	ServiceOperationChild?: TServiceOperationDM[]
	@Type(() => TMaintenanceFrequencyDefinitionDM)
	MaintenanceFrequencyDefChild?: TMaintenanceFrequencyDefinitionDM[]
	@Type(() => TScheduledServiceOperationDM)
	ScheduledServiceOperationChild?: TScheduledServiceOperationDM[]
	@Type(() => TAttributeValueDM)
	AttributeValueChild?: TAttributeValueDM[]
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}