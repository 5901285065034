import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import ShopGroupId from './ShopGroupId'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TContactPersonGroupDM from './TContactPersonGroupDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TitleName from './TitleName'
import TK2UserLookupDM from './TK2UserLookupDM'
import PositionName from './PositionName'
import FunctionName from './FunctionName'
import AcquisitionName from './AcquisitionName'
import SatisfactionName from './SatisfactionName'
import TTextDM from './TTextDM'
import PriorityName from './PriorityName'
import DepartmentName from './DepartmentName'
import TransportTypeName from './TransportTypeName'
import OfficeName from './OfficeName'
import TCountryDM from './TCountryDM'
import MaritalStatus from './MaritalStatus'
import Nationality from './Nationality'
import TAddressDM from './TAddressDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TPartnerDM from './TPartnerDM'
import TEshopRegistrationDM from './TEshopRegistrationDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TQualificationContactPersonDM from './TQualificationContactPersonDM'
import TContactPersonPartnerDM from './TContactPersonPartnerDM'
import TContactPersonAddressDM from './TContactPersonAddressDM'
import TEshopFavoriteDM from './TEshopFavoriteDM'
import TDiscountCouponDM from './TDiscountCouponDM'
import TOrgStrTreeChildPersonDM from './TOrgStrTreeChildPersonDM'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TWorkflowProcedureRespPersonDM from './TWorkflowProcedureRespPersonDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TMarketingFamilyMemberDM from './TMarketingFamilyMemberDM'
import TContactPersonEducationDM from './TContactPersonEducationDM'
import TWorkOfficialPositionDM from './TWorkOfficialPositionDM'
import TMarketingSubsumptionDM from './TMarketingSubsumptionDM'
import TEmploymentHistoryDM from './TEmploymentHistoryDM'
import TMarketingLanguageDM from './TMarketingLanguageDM'
import TContactPersonNatureDM from './TContactPersonNatureDM'
import TMarketingHobbyDM from './TMarketingHobbyDM'
import TMarketingGiftDM from './TMarketingGiftDM'
import TContactPersonAddressItemDM from './TContactPersonAddressItemDM'
import TAttendancePersonIdentifyItemDM from './TAttendancePersonIdentifyItemDM'
import TAttendancePersonWHModelItemDM from './TAttendancePersonWHModelItemDM'
import TAttendancePersonWorkplaceItemDM from './TAttendancePersonWorkplaceItemDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TContactPersonDM extends Data {
	public static className = 'TContactPersonDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	HasAttendanceWHModel?: string
	IsAttendancePerson?: string
	ForeignFoodAllowanceRate?: number
	SequenceNumber?: number
	IsBlockedRecord?: string
	NodeId?: number
	NotInsertIntoDocument?: string
	NotLoadItemsAutomatically?: string
	NotEvaluateAutomatically?: string
	Abbr?: string
	TimeStamp?: string
	FormatId?: number
	Born?: string
	RightGroupId?: number
	Comment?: string
	NextPhoneCallDate?: Date
	LastPhoneCallDate?: Date
	NextVisitDate?: Date
	LastVisitDate?: Date
	PhoneToTime?: Date
	PhoneSinceTime?: Date
	VisitToTime?: Date
	VisitSinceTime?: Date
	CreatedOn?: Date
	ChangedOn?: Date
	BirthDate?: Date
	BirthIdNumber?: string
	Name?: string
	Surname?: string
	Id?: number
	RecordID?: number
	Ex_SaleOrderDesc?: string
	EX_BitZakMail?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	BirthIdNumberPart1Calc?: string
	BirthIdNumberPart2Calc?: string
	SurenameForenameCalc?: string
	AsInternalBitCalc?: number
	CommentActivityTreeCalc?: string
	ActivityCountCalc?: number
	ElectronicAddress1Calc?: string
	ElectronicAddress2Calc?: string
	ElectronicAddress3Calc?: string
	ElectronicAddress4Calc?: string
	FullNameCalc?: string
	ElectronicAddressCheckItemFieldCalc?: number
	FullNameTitleCalc?: string
	IsAdministratorCalc?: string
	LoginNameCalc?: string
	ElectronicAddress1FormattedCalc?: string
	ElectronicAddress2FormattedCalc?: string
	ElectronicAddress3FormattedCalc?: string
	ElectronicAddress4FormattedCalc?: string
	MultiContactPersonImgCalc?: string
	IsExchangeSyncCalc?: string
	IsExchangeActiveCalc?: string
	EshopPhoneCalc?: string
	EshopEmailCalc?: string
	AllowEshopEmailSendInformationCalc?: string
	ListPartnerCountCalc?: number
	FirstPictureFileNameCalc?: string
	PartnerAsStringCountCalc?: string
	LimitedProcessingBitCalc?: number
	PlainCommentMarketingCalc?: string
	BrowseWebDataCalc?: string
	EAddressPARAMCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOfficerDocumentDM)
	PersonTypeId?: TOfficerDocumentDM
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TContactPersonGroupDM)
	ContactPersonGroupRID?: TContactPersonGroupDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonId?: TEmployedPersonSimpleDM
	@Type(() => TitleName)
	TitleAfterName?: TitleName
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM
	@Type(() => PositionName)
	PositionId?: PositionName
	@Type(() => FunctionName)
	FunctionId?: FunctionName
	@Type(() => AcquisitionName)
	AcquisitionId?: AcquisitionName
	@Type(() => SatisfactionName)
	SatisfactionId?: SatisfactionName
	@Type(() => TTextDM)
	PhoneDay?: TTextDM
	@Type(() => TTextDM)
	VisitingDay?: TTextDM
	@Type(() => PriorityName)
	PriorityId?: PriorityName
	@Type(() => DepartmentName)
	DepartmentId?: DepartmentName
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TransportTypeName)
	TransportTypeId?: TransportTypeName
	@Type(() => OfficeName)
	OfficeId?: OfficeName
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => MaritalStatus)
	MaritalStatus?: MaritalStatus
	@Type(() => Nationality)
	Nationality?: Nationality
	@Type(() => TitleName)
	Title?: TitleName
	@Type(() => TAddressDM)
	Ex_DeliveryAddressId?: TAddressDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	BirthMonthIdCalc?: TTextDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM
	@Type(() => TPartnerDM)
	PartnerRIDCalc?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerForEshopRIDCalc?: TPartnerDM
	@Type(() => TEshopRegistrationDM)
	CurrentRegistrationRIDCalc?: TEshopRegistrationDM
	@Type(() => TAttendanceMovementDM)
	AttendanceLastMovementRIDCalc?: TAttendanceMovementDM
	@Type(() => TTextDM)
	AttendancePresenceIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TQualificationContactPersonDM)
	TrainingChild?: TQualificationContactPersonDM[]
	@Type(() => TContactPersonPartnerDM)
	PartnerChild?: TContactPersonPartnerDM[]
	@Type(() => TActivityDM)
	ListActivityChild?: TActivityDM[]
	@Type(() => TContactPersonAddressDM)
	AddressForEshopChild?: TContactPersonAddressDM[]
	@Type(() => TEshopFavoriteDM)
	EShopFavoriteChild?: TEshopFavoriteDM[]
	@Type(() => TDiscountCouponDM)
	CouponChild?: TDiscountCouponDM[]
	@Type(() => TOrgStrTreeChildPersonDM)
	ContactPersonChild?: TOrgStrTreeChildPersonDM[]
	@Type(() => TOnePersonAbsenceDM)
	AbsenceChild?: TOnePersonAbsenceDM[]
	@Type(() => TWorkflowProcedureRespPersonDM)
	WorkflowProcedureRespPersonChild?: TWorkflowProcedureRespPersonDM[]
	@Type(() => TEshopRegistrationDM)
	EshopRegistrationChild?: TEshopRegistrationDM[]
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]
	@Type(() => TMarketingFamilyMemberDM)
	MarketingFamilyMemberChild?: TMarketingFamilyMemberDM[]
	@Type(() => TContactPersonEducationDM)
	EducationChild?: TContactPersonEducationDM[]
	@Type(() => TWorkOfficialPositionDM)
	WorkOfficialPositionChild?: TWorkOfficialPositionDM[]
	@Type(() => TMarketingSubsumptionDM)
	MarketingSubsumptionChild?: TMarketingSubsumptionDM[]
	@Type(() => TEmploymentHistoryDM)
	EmploymentHistoryChild?: TEmploymentHistoryDM[]
	@Type(() => TMarketingLanguageDM)
	LanguageChild?: TMarketingLanguageDM[]
	@Type(() => TContactPersonNatureDM)
	ContactPersonNatureChild?: TContactPersonNatureDM[]
	@Type(() => TMarketingHobbyDM)
	MarketingHobbyChild?: TMarketingHobbyDM[]
	@Type(() => TMarketingGiftDM)
	GiftChild?: TMarketingGiftDM[]
	@Type(() => TContactPersonAddressItemDM)
	AddressChild?: TContactPersonAddressItemDM[]
	@Type(() => TAttendancePersonIdentifyItemDM)
	IdentificationChild?: TAttendancePersonIdentifyItemDM[]
	@Type(() => TAttendancePersonWHModelItemDM)
	WHModelChild?: TAttendancePersonWHModelItemDM[]
	@Type(() => TAttendancePersonWorkplaceItemDM)
	WorkPlaceChild?: TAttendancePersonWorkplaceItemDM[]
	@Type(() => TAttendanceMovementDM)
	AttendanceMovementChild?: TAttendanceMovementDM[]
	@Type(() => TAttendanceWorkTimeReportDM)
	AttendanceWorkTimeReportChild?: TAttendanceWorkTimeReportDM[]
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}