import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TVATRateDM from './TVATRateDM'
import GoodsName from './GoodsName'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

export default class TArticleBreakdownDM extends Data {
	public static className = 'TArticleBreakdownDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Price?: number
	Quantity?: number
	SequenceNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityUMCalc?: number
	PositiveQuantityCalc?: number
	QuantitySignCalc?: number
	QuantitySignImgCalc?: string
	ArticleCodeTextCalc?: string
	TaxTextCalc?: string
	UnitPriceCalc?: number
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => GoodsName)
	NameId?: GoodsName
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TArticleDM)
	MasterArticleId?: TArticleDM
}