import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrDeductionDM from './TPrDeductionDM'
import TPrPeriodDM from './TPrPeriodDM'

export default class TPrUnionDM extends Data {
	public static className = 'TPrUnionDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CompanyId?: number
	ValueD?: string
	ValueC?: string
	ValueB?: string
	ValueA?: string
	DChangedOn?: Date
	CChangedOn?: Date
	BChangedOn?: Date
	AChangedOn?: Date
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	ConstantValueBooleanACalc?: number
	ConstantValueDateACalc?: Date
	ConstantValueCurrencyACalc?: number
	ConstantValueIntegerACalc?: number
	ConstantValueRealACalc?: number
	ConstantValueTextACalc?: string
	ConstantValueTimeACalc?: Date
	ConstantValueComponentACalc?: number
	ConstantValueBooleanBCalc?: number
	ConstantValueDateBCalc?: Date
	ConstantValueCurrencyBCalc?: number
	ConstantValueIntegerBCalc?: number
	ConstantValueRealBCalc?: number
	ConstantValueTextBCalc?: string
	ConstantValueTimeBCalc?: Date
	ConstantValueBankDetailBCalc?: number
	ConstantValueComponentBCalc?: number
	ConstantValueBooleanCCalc?: number
	ConstantValueDateCCalc?: Date
	ConstantValueCurrencyCCalc?: number
	ConstantValueIntegerCCalc?: number
	ConstantValueRealCCalc?: number
	ConstantValueTextCCalc?: string
	ConstantValueTimeCCalc?: Date
	ConstantValueBankDetailCCalc?: number
	ConstantValueComponentCCalc?: number
	ConstantValueBooleanDCalc?: number
	ConstantValueDateDCalc?: Date
	ConstantValueCurrencyDCalc?: number
	ConstantValueIntegerDCalc?: number
	ConstantValueRealDCalc?: number
	ConstantValueTextDCalc?: string
	ConstantValueTimeDCalc?: Date
	ConstantValueBankDetailDCalc?: number
	ConstantValueComponentDCalc?: number
	WarningCalc?: string
	@Type(() => TK2UserLookupDM)
	DChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	BChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	AChangedById?: TK2UserLookupDM
	@Type(() => TPrDeductionDM)
	ConstantValueBankDetailACalc?: TPrDeductionDM
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM
}