import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'

export default class TOrgStrDeputyDM extends Data {
	public static className = 'TOrgStrDeputyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XMLField?: string
	SequenceNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TOrgStrTreeUnitDM)
	DeputyOrgStrUnitRID?: TOrgStrTreeUnitDM
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitRID?: TOrgStrTreeUnitDM
}