import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import PriceListStatus from './PriceListStatus'
import TK2UserDM from './TK2UserDM'
import TCurrencyDM from './TCurrencyDM'
import TSalesActionDM from './TSalesActionDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TPriceListValidityTradingPartnerDM from './TPriceListValidityTradingPartnerDM'
import TPriceListValidityPriceGroupDM from './TPriceListValidityPriceGroupDM'
import TPriceListItemDM from './TPriceListItemDM'
import TPriceListDefinitionDM from './TPriceListDefinitionDM'
import TPriceListValidityBookDM from './TPriceListValidityBookDM'
import TPriceListValidityCostCentreDM from './TPriceListValidityCostCentreDM'
import TPriceListSuperiorDM from './TPriceListSuperiorDM'
import TPriceListSubalternDM from './TPriceListSubalternDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TPriceListArticleDM from './TPriceListArticleDM'
import TPriceListTradingPartnerDM from './TPriceListTradingPartnerDM'
import TPriceListValidityContractCodeDM from './TPriceListValidityContractCodeDM'
import TPriceListValidityEshopDM from './TPriceListValidityEshopDM'
import TPriceListItemBookDM from './TPriceListItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPriceListDM extends Data {
	public static className = 'TPriceListDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PreferGrossPriceForSale?: string
	RightGroupId?: number
	Code6Id?: number
	Code5Id?: number
	Code4Id?: number
	Code3Id?: number
	AllowDirectInput?: string
	IsCatalog?: string
	IsActive?: string
	IsDefinedContractCode?: string
	Priority?: number
	OfficerId?: number
	Code2Id?: number
	Code1Id?: number
	CostCentreId?: number
	DeviceRID?: number
	ContractCodeRID?: number
	IsCanceledRecord?: string
	IsLocked?: string
	RecalculatedOn?: Date
	IsDefinedCostCentre?: string
	IsDefinedBook?: string
	IsDefinedPriceGroup?: string
	IsDefinedCustomer?: string
	TimeStamp?: string
	ConfirmedById?: number
	ConfirmedOn?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	ValidToTime?: Date
	ValidToDate?: Date
	ValidFromTime?: Date
	ValidFromDate?: Date
	Description?: string
	Number?: number
	RID?: number
	RecordID?: number
	Ex_IsBasicPriceList?: string
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	DocIdentifierDescriptionCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	EshopDefinitionMeaningId?: TTextDM
	@Type(() => TTextDM)
	CurrencyUseId?: TTextDM
	@Type(() => TK2UserLookupDM)
	RecalculatedById?: TK2UserLookupDM
	@Type(() => PriceListStatus)
	DocumentStatusId?: PriceListStatus
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserDM)
	CreatedById?: TK2UserDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPriceListValidityTradingPartnerDM)
	ValidityForTradingPartnerChild?: TPriceListValidityTradingPartnerDM[]
	@Type(() => TPriceListValidityPriceGroupDM)
	ValidityForPriceGroupChild?: TPriceListValidityPriceGroupDM[]
	@Type(() => TPriceListItemDM)
	ItemChild?: TPriceListItemDM[]
	@Type(() => TPriceListDefinitionDM)
	PriceDefinitionChild?: TPriceListDefinitionDM[]
	@Type(() => TPriceListValidityBookDM)
	ValidityForBookChild?: TPriceListValidityBookDM[]
	@Type(() => TPriceListValidityCostCentreDM)
	ValidityForCostCentreChild?: TPriceListValidityCostCentreDM[]
	@Type(() => TPriceListSuperiorDM)
	SuperiorChild?: TPriceListSuperiorDM[]
	@Type(() => TPriceListSubalternDM)
	SubalternChild?: TPriceListSubalternDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]
	@Type(() => TPriceListArticleDM)
	ArticleChild?: TPriceListArticleDM[]
	@Type(() => TPriceListTradingPartnerDM)
	TradingPartnerChild?: TPriceListTradingPartnerDM[]
	@Type(() => TPriceListValidityContractCodeDM)
	ValidityForContractCodeChild?: TPriceListValidityContractCodeDM[]
	@Type(() => TPriceListValidityEshopDM)
	ValidityForEshopChild?: TPriceListValidityEshopDM[]
	@Type(() => TPriceListItemBookDM)
	ItemSAChild?: TPriceListItemBookDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}