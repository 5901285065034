import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TypClaim from './TypClaim'
import TPaymentMethodDM from './TPaymentMethodDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import PrDedMand from './PrDedMand'
import TPartnerDM from './TPartnerDM'
import TPrDeductionDM from './TPrDeductionDM'
import PrInsCode from './PrInsCode'
import TAccountingChartDM from './TAccountingChartDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTextDM from './TTextDM'
import TPrPeriodDM from './TPrPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import KonstSymb from './KonstSymb'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TPrDeductionOutputComponentDM from './TPrDeductionOutputComponentDM'
import TPrDeductionBankAccountDM from './TPrDeductionBankAccountDM'
import TPrOutputComponentDM from './TPrOutputComponentDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkPrDeductionDM from './TCompanyLinkPrDeductionDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrDeductionAllDM extends Data {
	public static className = 'TPrDeductionAllDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RightGroupId?: number
	XMLField?: string
	Description?: string
	AmountPaid?: number
	TotalAmount?: number
	PeriodAmount?: number
	ValidTo?: Date
	ValidFrom?: Date
	LegalDate?: Date
	ContractNumber?: string
	CostCentreOldId?: number
	RID?: number
	ChangedOn?: Date
	ReferenceNumberOld?: string
	ConstantSymbolOld?: string
	Name?: string
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BankIdCalc?: number
	AccountNumberCalc?: string
	IBANCalc?: string
	PaymentItemCalc?: string
	ChargeCodeCalc?: string
	SpecificSymbolCalc?: string
	DescriptionCalc?: string
	BulkPaymentBitCalc?: number
	IndividualPaymentIdCalc?: number
	NotCreatePrLiabilityBitCalc?: number
	ShowInReportBitCalc?: number
	BankCodeNumberCalc?: string
	IncludeOtherIncomeCalc?: string
	BalanceCalc?: number
	DeductedTotalAmountCalc?: number
	DeliveryDateCalc?: Date
	AmountCCalc?: number
	PrComponentPeriodAmountCalc?: number
	DeductedAmountCalc?: number
	ErrorCalc?: number
	PrLiabilityAmountCalc?: number
	WageDeductionCurrencyCalc?: string
	DecodedReferenceNumberCalc?: string
	SpecificSymbolDecodedCalc?: string
	PrLiabilityTotalAmountCalc?: number
	PrOutComAllPerTotalAmountCalc?: number
	PrOutComPrevPerTotalAmountCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	ReferenceNumberCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TypClaim)
	ClaimTypeId?: TypClaim
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => PrDedMand)
	DeductionTypeId?: PrDedMand
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TPrDeductionDM)
	OrganizationDeductionId?: TPrDeductionDM
	@Type(() => PrInsCode)
	HealthInsuranceCodeId?: PrInsCode
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TK2UserLookupDM)
	IssuedByCalc?: TK2UserLookupDM
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolIdCalc?: TMatchingSymbolDM
	@Type(() => KonstSymb)
	ConstantSymbolCalc?: KonstSymb
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrLiabilityDM)
	PrLiabilityChild?: TPrLiabilityDM[]
	@Type(() => TPrDeductionOutputComponentDM)
	PrComponentOutInPeriodChild?: TPrDeductionOutputComponentDM[]
	@Type(() => TPrDeductionBankAccountDM)
	BankAccountChild?: TPrDeductionBankAccountDM[]
	@Type(() => TPrLiabilityDM)
	PrLiabilityPeriodChild?: TPrLiabilityDM[]
	@Type(() => TPrOutputComponentDM)
	PrComponentOutChild?: TPrOutputComponentDM[]
	@Type(() => TPrEmploymentDM)
	PrEmploymentChild?: TPrEmploymentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkPrDeductionDM)
	CompanyChild?: TCompanyLinkPrDeductionDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}