import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import WkfProcessState from './WkfProcessState'
import TWorkflowProcedureDM from './TWorkflowProcedureDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TWorkflowWorkStepInProcessDM from './TWorkflowWorkStepInProcessDM'
import TWorkflowProcessVariableDM from './TWorkflowProcessVariableDM'
import TTaskHistoryDM from './TTaskHistoryDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TWorkflowProcessDM extends Data {
	public static className = 'TWorkflowProcessDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	ChangedOn?: Date
	ActualCompletionDate?: Date
	PlannedCompletionDate?: Date
	CreatedByText?: string
	CreatedOn?: Date
	Name?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	StartDateCalc?: Date
	StartTimeCalc?: Date
	PlannedEndDateCalc?: Date
	PlannedEndTimeCalc?: Date
	EndDateCalc?: Date
	EndTimeCalc?: Date
	DescriptionCalc?: string
	OpenStepNameCalc?: string
	ProductNameCalc?: string
	ListingCommentsCalc?: string
	TaskCalc?: string
	CommentCalc?: string
	DiagramSVGCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM
	@Type(() => TTextDM)
	StatusId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => WkfProcessState)
	PhaseId?: WkfProcessState
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TWorkflowProcedureDM)
	ProcedureId?: TWorkflowProcedureDM
	@Type(() => TContactPersonDM)
	OpenStepOwnerIdCalc?: TContactPersonDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TWorkflowWorkStepInProcessDM)
	WorkStepInProcessChild?: TWorkflowWorkStepInProcessDM[]
	@Type(() => TWorkflowProcessVariableDM)
	VariableChild?: TWorkflowProcessVariableDM[]
	@Type(() => TWorkflowProcessVariableDM)
	VariableForHeaderChild?: TWorkflowProcessVariableDM[]
	@Type(() => TTaskHistoryDM)
	TaskHistoryChild?: TTaskHistoryDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}