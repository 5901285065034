import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWebBrandDM from './TWebBrandDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import esCategoryMerchantCenter from './esCategoryMerchantCenter'
import TPriceListCategoryDM from './TPriceListCategoryDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TPackageTypeDM from './TPackageTypeDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TEshopStockTextDM from './TEshopStockTextDM'
import ABCClass from './ABCClass'
import esXmlCategoryTextHeureka from './esXmlCategoryTextHeureka'
import esXmlCategoryText from './esXmlCategoryText'
import esIdGoodsGroup from './esIdGoodsGroup'
import WarrantyB from './WarrantyB'
import GoodsStatus from './GoodsStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TParameterSetDM from './TParameterSetDM'
import TArticleDM from './TArticleDM'
import Availability from './Availability'
import TNomenclatureDM from './TNomenclatureDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import PlanGroup from './PlanGroup'
import KindAccounting from './KindAccounting'
import TArticleKindDM from './TArticleKindDM'
import TCountryDM from './TCountryDM'
import TTextDM from './TTextDM'
import ProductGroup from './ProductGroup'
import TWarehouseDM from './TWarehouseDM'
import TPriceGroupDM from './TPriceGroupDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TPeriodListDM from './TPeriodListDM'
import TCurrencyDM from './TCurrencyDM'
import PlanType from './PlanType'
import AlternativeUnit from './AlternativeUnit'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TCoverageConfigurationDM from './TCoverageConfigurationDM'
import TPriceListDM from './TPriceListDM'
import TEshopDM from './TEshopDM'
import TParameterDM from './TParameterDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TAccountingChartDM from './TAccountingChartDM'
import TJobCardConfigDM from './TJobCardConfigDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TServiceNoteDM from './TServiceNoteDM'
import TArticleNumberDM from './TArticleNumberDM'
import TEshopSearchWordDM from './TEshopSearchWordDM'
import TEshopCategoryListDM from './TEshopCategoryListDM'
import TABCItemDM from './TABCItemDM'
import TInventoryTurnoverDM from './TInventoryTurnoverDM'
import TInventoryTurnoverChartSourceDM from './TInventoryTurnoverChartSourceDM'
import TPredictionModelDM from './TPredictionModelDM'
import TArticleWarehouseDM from './TArticleWarehouseDM'
import TWarehouseZoneStockLevelDM from './TWarehouseZoneStockLevelDM'
import TEshopArticleShopValueTextDM from './TEshopArticleShopValueTextDM'
import TArticleBarCodeDM from './TArticleBarCodeDM'
import TArticleMeasureUnitDM from './TArticleMeasureUnitDM'
import TArticleRoutingVariantDM from './TArticleRoutingVariantDM'
import TArticleBreakdownDM from './TArticleBreakdownDM'
import TArticleRelatedArticleDM from './TArticleRelatedArticleDM'
import TArticleParameterValueDM from './TArticleParameterValueDM'
import TProductKeyItemDM from './TProductKeyItemDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TArticleSuccessorDM from './TArticleSuccessorDM'
import TStockCardDM from './TStockCardDM'
import TPriceListArticleDM from './TPriceListArticleDM'
import TEkoKomArticlePackDM from './TEkoKomArticlePackDM'
import TArticleElectricalWasteDM from './TArticleElectricalWasteDM'
import TEshopArticleCategorySummaryDM from './TEshopArticleCategorySummaryDM'
import TPriceListItemDirectInputDM from './TPriceListItemDirectInputDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TABCItemChartSourceDM from './TABCItemChartSourceDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TProductCostingDM from './TProductCostingDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkArticleDM from './TCompanyLinkArticleDM'
import TLegislationLinkArticleDM from './TLegislationLinkArticleDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TArticleSellingPriceDM from './TArticleSellingPriceDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TArticleSupplierDatePriceDM from './TArticleSupplierDatePriceDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

export default class TArticleVariantDM extends Data {
	public static className = 'TArticleVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsUsedByEkokom?: string
	ProductionClassificationRID?: number
	EkokomPurchase?: string
	esPayment?: string
	esTransport?: string
	IsLocationRecordsAutomat?: string
	CheckAmountForPDP?: string
	RedefineABC?: string
	NotCreateIntrastat?: string
	BatchSellingPrice?: string
	IsInvalidRecord?: string
	PercentForOverHead?: string
	IsBatchRecordsAutomat?: string
	NoSAD?: string
	RecordContractCodes?: string
	RecordLocations?: string
	CurrencyPurchase?: string
	CustPriceProdGroup?: string
	IsNegativeLevelAllowed?: string
	ElectronicWeight?: string
	IsSerialNumberRecordsAutomat?: string
	PurchaseCostPercent?: string
	IgnoreArticleName?: string
	IsSaleInForeignCurrency?: string
	RecordBatches?: string
	RecordSerialNumbers?: string
	EvaluateAsDeadStock?: string
	RecordMaxQuantity?: string
	RecordMinQuantity?: string
	OpenGraphImage?: number
	OpenGraphType?: number
	ESPageURL?: string
	MasterCardId?: number
	InventorySalesDays?: number
	StockCover?: number
	PredictionSettings?: string
	ServiceLevel?: number
	LeadTime?: number
	VariationSetRID?: number
	ESPageAnalytics?: string
	ESOriginalPrice?: number
	ESFeedDescription?: string
	ESFeedTitle?: string
	ESPageKeyWords?: string
	ESPageDescription?: string
	ESPageTitle?: string
	ESDescription?: string
	ESNote?: string
	ESShortDescription?: string
	ESName?: string
	IntrastatUnitRatio?: number
	TimeStamp?: string
	Description?: string
	DeadStockMaxDays?: number
	DeadStockMinDays?: number
	Concentration?: number
	CreatedOn?: Date
	Overheads?: number
	RightGroupId?: number
	SaleCashDesk?: number
	PriceItemCount?: number
	ChangedOn?: Date
	CurrentTurnover?: number
	MaxTurnover?: number
	MinTurnover?: number
	MaxQuantity?: number
	MinQuantity?: number
	ProductGroupId?: number
	AdditionalCode?: string
	TradeMark_old?: string
	Name?: string
	Abbr2?: string
	Abbr?: string
	Id?: number
	RecordID?: number
	Ex_Misto?: string
	Ex_NJAbbr?: string
	Ex_MDSalePrice?: number
	Ex_MDPurchasePrice?: number
	Ex_StockArticleMD?: string
	Ex_LocationMD?: number
	EX_EDIHornbachCode?: string
	Ex_MDStockPrice?: number
	Ex_MDAbbr?: string
	Ex_Flagy?: number
	EX_Dopravne?: number
	ex_Popis2?: number
	EX_IO_BIT1?: number
	EX_NazIO?: string
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	PictureCalc?: string
	VersionFieldDateCalc?: Date
	ExchangeRateBySettingsCalc?: number
	ComputedPurchasePriceCalc?: number
	PurchaseCostCalc?: number
	TotalCalc?: number
	StockPriceCalc?: number
	LastPriceCalc?: number
	ActualPriceCalc?: number
	SlidingPriceCalc?: number
	SalePriceCalc?: number
	CompanyCurrencyCalc?: string
	VATCoefficientCalc?: number
	AmountVATPctCalc?: number
	MarginPctCalc?: number
	VATIdCalc?: number
	OverheadsCalc?: number
	SupplierWarehouseAvailableCalc?: number
	SupplierCodeCalc?: string
	SupplierPriceCalc?: number
	PriceGroupPriceCalc?: number
	ReceivedCalc?: number
	AvailableCalc?: number
	AssignedCalc?: number
	DispatchCalc?: number
	Available2Calc?: number
	AccountingValueCalc?: number
	LanguageTextCalc?: string
	ReservedUMCalc?: number
	OrderedUMCalc?: number
	ReceivedUMCalc?: number
	AvailableUMCalc?: number
	AssignedUMCalc?: number
	DispatchUMCalc?: number
	Available2UMCalc?: number
	SupplierDescriptionCalc?: string
	DefaultPurchaseMeasureUnitCalc?: string
	DefaultSaleMeasureUnitCalc?: string
	PriceGroupSellingPriceUMCalc?: number
	MinQuantityUMCalc?: number
	MaxQuantityUMCalc?: number
	OrderedInPurchaseQuantityUMCalc?: number
	InReceiptQuantityCalc?: number
	InReceiptQuantityUMCalc?: number
	DateDependencyCurrencyCalc?: string
	DateDependentExchangeRateCalc?: number
	DateDependencyPurchasePriceCalc?: number
	DateDependPurchaseCostPriceCalc?: number
	DateDependencyStockPriceCalc?: number
	DateDependencyMarginCalc?: number
	DateDependencySellingPriceCalc?: number
	ProductGroupIdCalc?: number
	DateDependencyVATCoefficientCalc?: number
	DateDependencyOverheadsCalc?: number
	FilterSupplierPricesBitCalc?: string
	FilterStockCardBitCalc?: number
	FilterInternalTransferBitCalc?: string
	InfWarehouseMinLevelCalc?: number
	InfWarehouseMaxLevelCalc?: number
	FreeAvailableQuantityCalc?: number
	AssignedCCalc?: number
	OrderedCalc?: number
	ReservedCalc?: number
	StockLevelArticleSlidingPriceCalc?: number
	StockLevelArticleQuantityCalc?: number
	StockLevelArticleTotalPriceCalc?: number
	LastPriceCCalc?: number
	LastPurchaseDateCalc?: Date
	InfWarehouseMinLevelUMCalc?: number
	InfWarehouseMaxLevelUMCalc?: number
	WeightNetCalc?: number
	WeightGrossCalc?: number
	InfWarehouseDeadStockMinCalc?: number
	InfWarehouseDeadStockMaxCalc?: number
	BasicMeasureUnitCalc?: string
	UMCoeficientCalc?: number
	ReverseCoefficientUMCalc?: number
	MeasureUnitIdCalc?: number
	LanguageDescriptionCalc?: string
	PurchasePriceCCalc?: number
	PurchasePriceNetCalc?: number
	BatchTypeIdCalc?: number
	LastIncidentalCostsCalc?: number
	SlidingPriceUMCalc?: number
	SellingPriceUMCalc?: number
	IsAvailableCalc?: string
	IsAvailableImgCalc?: string
	AvailableQuantityUMCalc?: number
	EshopDescriptionCalc?: string
	DailyStockNowCalc?: number
	DailyStockMinCalc?: number
	DailyStockMaxCalc?: number
	OriginPriceCCalc?: number
	OperationCurrencyCalc?: string
	IsSelfVariationCalc?: string
	SelfVariationImgCalc?: string
	IsAvailableInStockCalc?: string
	CheckRCSModeAmountImgCalc?: string
	DefaultBarcodeCalc?: string
	OrderedInPurchaseQuantityCalc?: number
	PredictionChartDateFromCalc?: Date
	OrderCalendarIdCalc?: number
	RelatedArticleCountCalc?: number
	AlternateArticleCountCalc?: number
	FirstPictureFileNameCalc?: string
	DetailUrlCalc?: string
	ValidToCalc?: Date
	PeriodFromDateCalc?: Date
	PeriodToDateCalc?: Date
	IsPriceListCategoryAct1Calc?: string
	IsPriceListCategoryAct2Calc?: string
	IsPriceListCategoryAct3Calc?: string
	IsPriceListCategoryAct4Calc?: string
	IsPriceListCategoryAct5Calc?: string
	SellingPriceGrossCalc?: number
	OfferedCalc?: number
	OfferedUMCalc?: number
	SalePriceNetCalc?: number
	IsOnStockCalc?: string
	AvailableParamQuantityUMCalc?: number
	DetailUrlWebCalc?: string
	CoverageLevelCalc?: number
	CoverageLevelUMCalc?: number
	InternalReservationQuantityCalc?: number
	InternalReservationQuantityUMCalc?: number
	AvailableParamQuantityCalc?: number
	AssignedParamQuantityCalc?: number
	ReserverParamQuantityCalc?: number
	OrderedParamQuantityCalc?: number
	InReceiptParamQuantityCalc?: number
	AssignedParamQuantityUMCalc?: number
	ReserverParamQuantityUMCalc?: number
	OrderedParamQuantityUMCalc?: number
	InReceiptParamQuantityUMCalc?: number
	IsPurchaseCurrencyCalc?: string
	TotalCapacityCalc?: number
	InventoryTurnoverDefConfigCalc?: string
	ABCAnalysisDefaultConfigCalc?: string
	EvaluateAsDeadStockCalc?: string
	UrlCalc?: string
	AvailableParamTotalQuantityCalc?: number
	AvailableZonePARAMCalc?: number
	AvailableZoneUMPARAMCalc?: number
	AssignedZonePARAMCalc?: number
	AssignedZoneUMPARAMCalc?: number
	InReceiptZonePARAMCalc?: number
	InReceiptZoneUMPARAMCalc?: number
	BasicUnitVolumePARAMCalc?: number
	Parameter1ValueCalc?: string
	Parameter2ValueCalc?: string
	Parameter3ValueCalc?: string
	Parameter4ValueCalc?: string
	Parameter5ValueCalc?: string
	ParameterCompareValue1Calc?: number
	ParameterCompareValue2Calc?: number
	ParameterCompareValue3Calc?: number
	ParameterCompareValue4Calc?: number
	ParameterCompareValue5Calc?: number
	SortVariableParameterValue1Calc?: string
	SortVariableParameterValue2Calc?: string
	SortVariableParameterValue3Calc?: string
	SortVariableParameterValue4Calc?: string
	SortVariableParameterValue5Calc?: string
	SortVarParamCompareValue1Calc?: number
	SortVarParamCompareValue2Calc?: number
	SortVarParamCompareValue3Calc?: number
	SortVarParamCompareValue4Calc?: number
	SortVarParamCompareValue5Calc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedESNameCalc?: string
	TranslatedESShortDescriptionCalc?: string
	TranslatedESNoteCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedESPageTitleCalc?: string
	TranslatedESPageDescriptionCalc?: string
	TranslatedESPageKeyWordsCalc?: string
	TranslatedESPageAnalyticsCalc?: string
	TranslatedESPageURLCalc?: string
	PlannedStockPriceCalc?: number
	PurchaseCostsCalc?: number
	PurchasePriceCalc?: number
	SellingPriceCalc?: number
	MarginIdCalc?: number
	ExchangeRateCalc?: number
	ProductionClassificationRID_ver?: number
	RightGroupIdCalc?: number
	@Type(() => TWebBrandDM)
	BrandRID?: TWebBrandDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => esCategoryMerchantCenter)
	ESMerchantCenterCategoryId?: esCategoryMerchantCenter
	@Type(() => TPriceListCategoryDM)
	PriceListCategory5RID?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	PriceListCategory4RID?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	PriceListCategory3RID?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	PriceListCategory2RID?: TPriceListCategoryDM
	@Type(() => TPriceListCategoryDM)
	PriceListCategory1RID?: TPriceListCategoryDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TPackageTypeDM)
	PackageTypeRID?: TPackageTypeDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TEshopStockTextDM)
	ESStockTextRID?: TEshopStockTextDM
	@Type(() => ABCClass)
	ABCClassCalculatedId?: ABCClass
	@Type(() => ABCClass)
	ABCClassId?: ABCClass
	@Type(() => esXmlCategoryTextHeureka)
	ESFeedCategoryTextHeurekaId?: esXmlCategoryTextHeureka
	@Type(() => esXmlCategoryText)
	ESFeedCategoryTextId?: esXmlCategoryText
	@Type(() => esIdGoodsGroup)
	ESArticleGroupId?: esIdGoodsGroup
	@Type(() => WarrantyB)
	WarrantyB2BId?: WarrantyB
	@Type(() => WarrantyB)
	WarrantyB2CId?: WarrantyB
	@Type(() => GoodsStatus)
	StatusId?: GoodsStatus
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TParameterSetDM)
	ServiceParameterSetId?: TParameterSetDM
	@Type(() => TArticleDM)
	ServiceCardId?: TArticleDM
	@Type(() => Availability)
	ESZeroStockTextId?: Availability
	@Type(() => TParameterSetDM)
	ParameterSetId?: TParameterSetDM
	@Type(() => TNomenclatureDM)
	NomenclatureId?: TNomenclatureDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => PlanGroup)
	PlanningGroupId?: PlanGroup
	@Type(() => KindAccounting)
	PostingKindId?: KindAccounting
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	ArticleTypeId?: TTextDM
	@Type(() => TArticleDM)
	PackagingId?: TArticleDM
	@Type(() => ProductGroup)
	Group?: ProductGroup
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TWarehouseDM)
	InformationWarehouseIdCalc?: TWarehouseDM
	@Type(() => TPriceGroupDM)
	PriceGroupIdCalc?: TPriceGroupDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	SupplierIdCalc?: TTradingPartnerDM
	@Type(() => TWarehouseDM)
	Warehouse2IdCalc?: TWarehouseDM
	@Type(() => TPeriodListDM)
	BookPeriodFromIdCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	BookPeriodToIdCalc?: TPeriodListDM
	@Type(() => TCurrencyDM)
	UserCurrencyCalc?: TCurrencyDM
	@Type(() => PlanType)
	PlannedTypeIdCalc?: PlanType
	@Type(() => TTextDM)
	ShowMeasureUnitBitCalc?: TTextDM
	@Type(() => AlternativeUnit)
	OtherMeasureUnitCalc?: AlternativeUnit
	@Type(() => TTextDM)
	PriceTypeIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	FilterTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TContractCodeDocumentDM)
	FilterContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TBatchDM)
	FilterBatchBitCalc?: TBatchDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TCurrencyDM)
	PurchaseCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	ParameterLanguageIdCalc?: TTextDM
	@Type(() => TTextDM)
	MultidimensionalQuantityBitCalc?: TTextDM
	@Type(() => TTextDM)
	PredictionStyleIdCalc?: TTextDM
	@Type(() => TTextDM)
	DailyInventoryOverviewIdCalc?: TTextDM
	@Type(() => TMeasureUnitDM)
	StockCardMeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	BaseMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	DefaultPurchaseMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	DefaultSaleMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM
	@Type(() => TCoverageConfigurationDM)
	CoverageConfigCalc?: TCoverageConfigurationDM
	@Type(() => TArticleDM)
	SuccessorIdCalc?: TArticleDM
	@Type(() => TMeasureUnitDM)
	ImplicitMeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TMeasureUnitDM)
	DefaultBarcodeMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TCurrencyDM)
	ActualCompanyCurrencyCalc?: TCurrencyDM
	@Type(() => TPriceListDM)
	PriceListForPriceRIDCalc?: TPriceListDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TParameterDM)
	Parameter1IdCalc?: TParameterDM
	@Type(() => TParameterDM)
	Parameter2IdCalc?: TParameterDM
	@Type(() => TParameterDM)
	Parameter3IdCalc?: TParameterDM
	@Type(() => TParameterDM)
	Parameter4IdCalc?: TParameterDM
	@Type(() => TParameterDM)
	Parameter5IdCalc?: TParameterDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OptimalSupplierIdCalc?: TTradingPartnerDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantIdCalc?: TRoutingVariantDM
	@Type(() => TAccountingChartDM)
	PurchaseAccountIdCalc?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	SalesAccountIdCalc?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	ConsolidationPurchaseAccountIdCalc?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	ConsolidationSalesAccountIdCalc?: TAccountingChartDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TJobCardConfigDM)
	JobCardConfigIdCalc?: TJobCardConfigDM
	@Type(() => TVATRateDM)
	VATRateIdCalc?: TVATRateDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeIdCalc?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	SaleTaxTypeIdCalc?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeRCSIdCalc?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	SaleTaxTypeRCSIdCalc?: TTaxTypeDM
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffIdCalc?: TIntrastatTariffDutyDM
	@Type(() => TVATRateDM)
	VATRateId_Ver?: TVATRateDM
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId_Ver?: TIntrastatTariffDutyDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]
	@Type(() => TArticleNumberDM)
	ArticleNumberChild?: TArticleNumberDM[]
	@Type(() => TEshopSearchWordDM)
	EshopSearchWordChild?: TEshopSearchWordDM[]
	@Type(() => TEshopCategoryListDM)
	ArticleCategoryChild?: TEshopCategoryListDM[]
	@Type(() => TABCItemDM)
	ABCItemChild?: TABCItemDM[]
	@Type(() => TInventoryTurnoverDM)
	InventoryTurnoverChild?: TInventoryTurnoverDM[]
	@Type(() => TInventoryTurnoverChartSourceDM)
	InventoryTurnOverChartSourceChild?: TInventoryTurnoverChartSourceDM[]
	@Type(() => TPredictionModelDM)
	PredictionModelChild?: TPredictionModelDM[]
	@Type(() => TArticleWarehouseDM)
	ArticleWarehouseChild?: TArticleWarehouseDM[]
	@Type(() => TWarehouseZoneStockLevelDM)
	WarehouseZoneStockLevelChild?: TWarehouseZoneStockLevelDM[]
	@Type(() => TEshopArticleShopValueTextDM)
	EshopFlagChild?: TEshopArticleShopValueTextDM[]
	@Type(() => TArticleBarCodeDM)
	BarCodeChild?: TArticleBarCodeDM[]
	@Type(() => TArticleMeasureUnitDM)
	MeasureUnitChild?: TArticleMeasureUnitDM[]
	@Type(() => TArticleRoutingVariantDM)
	AlternateRoutingChild?: TArticleRoutingVariantDM[]
	@Type(() => TArticleBreakdownDM)
	ArticleSupplierPriceChild?: TArticleBreakdownDM[]
	@Type(() => TArticleRelatedArticleDM)
	RelatedArticleChild?: TArticleRelatedArticleDM[]
	@Type(() => TArticleParameterValueDM)
	ParameterValueChild?: TArticleParameterValueDM[]
	@Type(() => TProductKeyItemDM)
	ProductKeyChild?: TProductKeyItemDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TArticleRelatedArticleDM)
	RelatedMasterArticleChild?: TArticleRelatedArticleDM[]
	@Type(() => TArticleSuccessorDM)
	ArticlePredecessorChild?: TArticleSuccessorDM[]
	@Type(() => TArticleSuccessorDM)
	ArticleSuccessorChild?: TArticleSuccessorDM[]
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]
	@Type(() => TPriceListArticleDM)
	PriceListArticleChild?: TPriceListArticleDM[]
	@Type(() => TEkoKomArticlePackDM)
	EkokomChild?: TEkoKomArticlePackDM[]
	@Type(() => TArticleElectricalWasteDM)
	ElectricalWasteChild?: TArticleElectricalWasteDM[]
	@Type(() => TEshopArticleCategorySummaryDM)
	AssignedEshopCategoryChild?: TEshopArticleCategorySummaryDM[]
	@Type(() => TPriceListItemDirectInputDM)
	PriceListDirectInputChild?: TPriceListItemDirectInputDM[]
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]
	@Type(() => TABCItemChartSourceDM)
	ABCItemChartSourceChild?: TABCItemChartSourceDM[]
	@Type(() => TSupplierPriceDM)
	SupplierPriceChild?: TSupplierPriceDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkArticleDM)
	CompanyChild?: TCompanyLinkArticleDM[]
	@Type(() => TLegislationLinkArticleDM)
	LegislationChild?: TLegislationLinkArticleDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TArticleSellingPriceDM)
	ArticleSellingPriceOldChild?: TArticleSellingPriceDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TArticleSupplierDatePriceDM)
	DatePriceOldChild?: TArticleSupplierDatePriceDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
	@Type(() => TStockCardOldDM)
	StockCardOldChild?: TStockCardOldDM[]
}