import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import WorkerProtectiveEquipmentReasons from './WorkerProtectiveEquipmentReasons'
import TReceiptCardDM from './TReceiptCardDM'
import TPeriodicityDM from './TPeriodicityDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

export default class TWorkerProtectionEquipmentAsItemDM extends Data {
	public static className = 'TWorkerProtectionEquipmentAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ReturnDate?: Date
	ReturnAssumedDate?: Date
	IssueDate?: Date
	Amount?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => WorkerProtectiveEquipmentReasons)
	ReasonId?: WorkerProtectiveEquipmentReasons
	@Type(() => TReceiptCardDM)
	ReceiptCardRID?: TReceiptCardDM
	@Type(() => TPeriodicityDM)
	PeriodicityRID?: TPeriodicityDM
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRID?: TReleaseNoteDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TEmployedPersonDM)
	WorkerId?: TEmployedPersonDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonSimpleIdCalc?: TEmployedPersonSimpleDM
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentSimpleIdCalc?: TPrEmploymentSimpleDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}