import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPriceGroupDM from './TPriceGroupDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'

export default class TSpecialTTradingPartnerDMCustomerPrices extends Data {
	public static className = 'TSpecialTTradingPartnerDMCustomerPrices'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	RealProc?: number
	Description?: string
	RID?: number
	BaseProc?: number
	RabatHId?: number
	Price?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CF_BaseProc?: string
	CF_AssignedToEshop?: string
	@Type(() => TPriceGroupDM)
	SuperiorPriceGroupId?: TPriceGroupDM
	@Type(() => TEshopCategoryInfoDM)
	esCategoryRIDCalc?: TEshopCategoryInfoDM
}