import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCalculationModelDM from './TCalculationModelDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TJobCardConfigDM from './TJobCardConfigDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkArticleCategoryDM from './TCompanyLinkArticleCategoryDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TArticleKindDM extends Data {
	public static className = 'TArticleKindDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	NotCreateJobCard?: string
	IsLocationRecordsAutomat?: string
	IsBatchRecordsAutomat?: string
	IsSerialNumberRecordsAutomat?: string
	SeparateInReport?: string
	NotCreateTransferNote?: string
	NotCreateInvoiceOut?: string
	NotCreateDeliveryNote?: string
	NotCreateReleaseNote?: string
	NotCreateReservingCard?: string
	NotCreateOrderIn?: string
	NotCreateInvoiceIn?: string
	NotCreateReceiptCard?: string
	NotCreateDeliveryConf?: string
	IsIncidentalCost?: string
	IsBillOfMaterialItem?: string
	NotAddToPlanJobCard?: string
	LinkTransferNoteAndJobCard?: string
	NotCreateSubJobCards?: string
	IsProductOrSubassembly?: string
	IsPeriodRecalc?: string
	NoPhysicalRecalc?: string
	NoAccountingRecalc?: string
	MultiplyByTime?: string
	PresetRelease?: string
	PresetReservation?: string
	IsIndependentOnYield?: string
	IsIndependentOnProducedQty?: string
	NotRecordActualQuantity?: string
	TimeStamp?: string
	RightGroupId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageTextCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	RecordContractCodes?: TTextDM
	@Type(() => TTextDM)
	RecordLocations?: TTextDM
	@Type(() => TTextDM)
	RecordBatches?: TTextDM
	@Type(() => TTextDM)
	RecordSerialNumbers?: TTextDM
	@Type(() => TCalculationModelDM)
	WeightCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	CostPriceCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	StockPriceCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	PlannedPriceCalculationModelId?: TCalculationModelDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TJobCardConfigDM)
	JobCardConfigIdCalc?: TJobCardConfigDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkArticleCategoryDM)
	CompanyChild?: TCompanyLinkArticleCategoryDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}