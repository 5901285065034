import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TAccountingChartDM from './TAccountingChartDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import InvoiceOutStatus from './InvoiceOutStatus'
import InvoiceInStatus from './InvoiceInStatus'
import AdvanceProvidedStatus from './AdvanceProvidedStatus'
import AdvanceReceivedStatus from './AdvanceReceivedStatus'
import OtherLiabilityStatus from './OtherLiabilityStatus'
import OtherReceivableStatus from './OtherReceivableStatus'
import TAdvanceDeductionAdvanceDM from './TAdvanceDeductionAdvanceDM'

export default class TOpenItemListDM extends Data {
	public static className = 'TOpenItemListDM'
	public static systemFields = [
	]

	DelayDaysToDate?: number
	OtherReceivableRID?: number
	OtherLiabilityRID?: number
	AdvanceReceivedRID?: number
	AdvanceProvidedRID?: number
	InvoiceInRID?: number
	InvoiceOutRID?: number
	AmountGrossC?: number
	Description?: string
	ReferenceNumber?: string
	TaxPointDate?: Date
	AccountingTransactionDate?: Date
	ItemRID?: number
	DocumentAbbr?: string
	DocumentStatusId?: number
	AmountGross?: number
	AmountPaid?: number
	AmountPaidC?: number
	Id?: number
	RemainingAmount?: number
	RemainingAmountC?: number
	BankOrderAmountGrossC?: number
	DueDate?: Date
	DocumentRID?: number
	DocumentBookId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	InvoiceOutRIDCalc?: number
	InvoiceInRIDCalc?: number
	AdvanceProvidedRIDCalc?: number
	AdvanceReceivedRIDCalc?: number
	RemainingAmountCalc?: number
	RemainingAmountCCalc?: number
	AccountingTransactionDateCalc?: Date
	TaxPointDateCalc?: Date
	ReferenceNumberCalc?: string
	DescriptionCalc?: string
	DelayDaysCalc?: number
	LateChargesCCalc?: number
	LateChargesCalc?: number
	DocumentStatusCalc?: string
	OldRemainingAmountCCalc?: number
	OldRemainingAmountCalc?: number
	AmountDeductedPaidCCalc?: number
	AmountDeductedPaidCalc?: number
	PriceGrossCCalc?: number
	PaidTypeIdCalc?: number
	OtherLiabilityRIDCalc?: number
	OtherReceivableRIDCalc?: number
	DocumentIdentificationCalc?: string
	RemindersNumberCalc?: number
	ReminderIssueDateCalc?: Date
	DocumentBookCompanyIdCalc?: number
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TOfficerDocumentDM)
	Officer2Id?: TOfficerDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TTextDM)
	DocumentTableId?: TTextDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => InvoiceOutStatus)
	InvoiceOutStatusIdCalc?: InvoiceOutStatus
	@Type(() => InvoiceInStatus)
	InvoiceInStatusIdCalc?: InvoiceInStatus
	@Type(() => AdvanceProvidedStatus)
	AdvanceProvidedStatusIdCalc?: AdvanceProvidedStatus
	@Type(() => AdvanceReceivedStatus)
	AdvanceReceivedStatusIdCalc?: AdvanceReceivedStatus
	@Type(() => TTextDM)
	ConfirmedBitCalc?: TTextDM
	@Type(() => OtherLiabilityStatus)
	OtherLiabilityStatusIdCalc?: OtherLiabilityStatus
	@Type(() => OtherReceivableStatus)
	OtherReceivableStatusIdCalc?: OtherReceivableStatus
	@Type(() => TPaymentMethodDM)
	PaymentMethodCalc?: TPaymentMethodDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerIdCalc?: TOfficerDocumentDM
	@Type(() => TAdvanceDeductionAdvanceDM)
	AdvanceDeductionChild?: TAdvanceDeductionAdvanceDM[]
}