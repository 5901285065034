import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrEmploymentDM from './TPrEmploymentDM'
import TAttendanceIdentificationDM from './TAttendanceIdentificationDM'
import TContactPersonDM from './TContactPersonDM'

export default class TAttendancePersonIdentifyItemDM extends Data {
	public static className = 'TAttendancePersonIdentifyItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DateTo?: Date
	DateFrom?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TAttendanceIdentificationDM)
	IdentificationRID?: TAttendanceIdentificationDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
}