import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TProductKeyDM from './TProductKeyDM'

export default class TProductKeyItemDM extends Data {
	public static className = 'TProductKeyItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceOrder?: number
	MainDataModuleNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TArticleDM)
	MainValue?: TArticleDM
	@Type(() => TProductKeyDM)
	SortingKeyRID?: TProductKeyDM
}