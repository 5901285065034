import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TShippingMethodDM from './TShippingMethodDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import JCDP20_a1 from './JCDP20_a1'
import TPeriodDM from './TPeriodDM'
import TContactPersonDM from './TContactPersonDM'
import TTaxTypeDM from './TTaxTypeDM'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import OrderOutStatus from './OrderOutStatus'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TermOfDelivery from './TermOfDelivery'
import FormOfOrder from './FormOfOrder'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TEdiQueueDM from './TEdiQueueDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TInvoiceInDM from './TInvoiceInDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TReceiptCardDM from './TReceiptCardDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TPaymentConditionTradeDM from './TPaymentConditionTradeDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TTaxSummaryDM from './TTaxSummaryDM'
import TPurchaseItemDM from './TPurchaseItemDM'
import TAccountingPurchaseItemDM from './TAccountingPurchaseItemDM'
import TTransferNoteDM from './TTransferNoteDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPurchaseOrderDM extends Data {
	public static className = 'TPurchaseOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsForEdi?: string
	OrderingDate?: Date
	NotIncludeIntoFinancialMngmt?: string
	SpecificSymbol?: string
	CreatedOn?: Date
	Settings?: string
	TaxNumber?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	Number?: number
	AmountNetC?: number
	RID?: number
	TimeStamp?: string
	ContactInformation?: string
	RightGroupId?: number
	ItemCount?: number
	ChangedOn?: Date
	ConfirmedOn?: Date
	DesiredDeliveryDate?: Date
	IssueDate?: Date
	SalesOrderAcceptedBy?: string
	Description?: string
	ExchangeRate?: number
	AmountNet?: number
	PurchaseItemType?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	AmountVATCalc?: number
	AmountGrossCalc?: number
	AmountVATCCalc?: number
	AmountGrossCCalc?: number
	ExchangeRateCalc?: number
	CompanyCurrencyCalc?: string
	CurrencySwitchCalc?: number
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	TotalItemPlannedPriceNetCalc?: number
	TotalItemPlStockPriceCalc?: number
	TotalItemPlStockPriceCCalc?: number
	ProfitCalc?: number
	ProfitCCalc?: number
	MarginCalc?: number
	CurrencyBySwitchCalc?: string
	OrderingDateCalc?: Date
	OrderingTimeCalc?: Date
	AssignedContractRIDCalc?: number
	DocumentAmountGrossCalc?: number
	DocumentAmountGrossCCalc?: number
	ReductionValueCalc?: number
	ReductionTypeBitCalc?: number
	ReductionAmountCalc?: number
	ReductionSignCalc?: number
	ReductionSignImgCalc?: string
	DeliveryDateCalc?: Date
	DeliveryTimeCalc?: Date
	PlannedSurchargeAmountCalc?: number
	TotalItemPlStockBySettPriceCalc?: number
	DeliveryOrderDateCalc?: Date
	AdvanceDeductedAmountCalc?: number
	AdvDeductTrPartAmountCalc?: number
	AdvanceRemainsToDedcAmountCalc?: number
	AdvRemainsToDedcTrPartAmountCalc?: number
	AdvanceDeductedAmountCCalc?: number
	AdvDeductedTrPartAmountCCalc?: number
	AdvRemainsToDeductAmountCCalc?: number
	AdvRemainsToDeductTrPartAmountCCalc?: number
	ExistsNotDeductedAdvanceBitCalc?: number
	PayConditionsMaturityDaysCalc?: number
	ExistsAdvanceUnpaidBitCalc?: number
	ExistsTrPartAdvNotPaidBitCalc?: number
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	TotalItemActualPriceNetCalc?: number
	TotalItemActualStockPriceCalc?: number
	TotalItemActualStockPriceCCalc?: number
	ActualProfitCalc?: number
	ActualProfitCCalc?: number
	ActualMarginAmountCalc?: number
	ActualSurchargeAmountCalc?: number
	TotalItemActStockSettPriceCalc?: number
	ExistsNotDedcAdvTrPartBitCalc?: number
	QRCodeCZBankOrderStandardCalc?: string
	QRCodeCZBankOrderLargeCalc?: string
	QRCodeSKBankOrderPayBySquareCalc?: string
	QRSKBankOrderCSOBCalc?: string
	ExternalAddressCalc?: string
	TradingPartnerInternalTextCalc?: string
	ForeignCurrencyBitCalc?: number
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	IncorrectAmountBitCalc?: number
	VATTurnedOnBitCalc?: number
	ItemTaxTypeCalc?: string
	QRCZInvoiceCalc?: string
	VATRegNumberCalc?: string
	TaxTradingPartnerIdCalc?: number
	TransportMethodRIDCalc?: number
	TotalWeightNetCalc?: number
	TotalWeightGrossCalc?: number
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TotalVolumePARAMCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	CompanyRegNumberCalc?: string
	VATRegNumberNotFullCalc?: string
	TaxNumberCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => OrderOutStatus)
	StatusId?: OrderOutStatus
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TermOfDelivery)
	DeliveryTime?: TermOfDelivery
	@Type(() => FormOfOrder)
	OrderForm?: FormOfOrder
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	EditedAmountBitCalc?: TTextDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTextDM)
	DeliveryNoteDocExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	InvoiceDocumentExistsIdCalc?: TTextDM
	@Type(() => TTextDM)
	ReceiptCardDocumentExistsIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TRequestForQuotationDM)
	MarketingDocumentRIDCalc?: TRequestForQuotationDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	EdiQueueMessageOutStatusIdCalc?: TTextDM
	@Type(() => TEdiQueueDM)
	EdiQueueMessageOutRIDCalc?: TEdiQueueDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TCommentDM)
	CommentChildCustomer?: TCommentDM[]
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedForDocumentChild?: TAdvanceProvidedDM[]
	@Type(() => TInvoiceInDM)
	InvoiceInChild?: TInvoiceInDM[]
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationChild?: TDeliveryConfirmationDM[]
	@Type(() => TReceiptCardDM)
	ReceiptCardChild?: TReceiptCardDM[]
	@Type(() => TPurchaseItemBookDM)
	ItemChild?: TPurchaseItemBookDM[]
	@Type(() => TPaymentConditionTradeDM)
	PaymentConditionChild?: TPaymentConditionTradeDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TTaxSummaryDM)
	TaxSummaryChild?: TTaxSummaryDM[]
	@Type(() => TPurchaseItemDM)
	PurchaseItemChild?: TPurchaseItemDM[]
	@Type(() => TAccountingPurchaseItemDM)
	AccountingPurchaseItemChild?: TAccountingPurchaseItemDM[]
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]
	@Type(() => TAdvanceProvidedDM)
	TradingPartnerAdvanceChild?: TAdvanceProvidedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}