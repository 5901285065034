import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TTextDM from './TTextDM'
import TCountryDM from './TCountryDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TTransportMethodDM from './TTransportMethodDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TShipmentServiceDM extends Data {
	public static className = 'TShipmentServiceDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	URL?: string
	Warning?: number
	ExpirationDate?: Date
	NumberCurrent?: number
	NumberTo?: number
	NumberFrom?: number
	Length?: number
	Width?: number
	Height?: number
	Weight?: number
	ConfirmedOn?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	Prefix?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	ConfirmedImgCalc?: string
	EmailContentCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TPartnerDM)
	TransportCompanyId?: TPartnerDM
	@Type(() => TTextDM)
	WeightUnit?: TTextDM
	@Type(() => TTextDM)
	DimensionUnit?: TTextDM
	@Type(() => TTextDM)
	ShipmentTypeId?: TTextDM
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}