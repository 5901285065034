import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardDM from './TJobCardDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TJobCardScheduleDM from './TJobCardScheduleDM'
import TJobCardVariantScheduleDM from './TJobCardVariantScheduleDM'

export default class TJobCardResourceDM extends Data {
	public static className = 'TJobCardResourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RoutingResourceRID?: number
	RID?: number
	OperationConfirmationDate?: Date
	SequenceNumber?: number
	Quantity?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	OperationCode?: string
	OperationSequenceNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PlannedTimeCalc?: number
	ActualTimeCalc?: number
	DurationCalc?: number
	CalItemTotalDurationCalc?: number
	OperationTimeRemainingCalc?: number
	IsManuallyAdjustedScheduleCalc?: string
	@Type(() => TJobCardOperationDM)
	JobCardOperationRID?: TJobCardOperationDM
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TResourceDM)
	GroupId?: TResourceDM
	@Type(() => TTextDM)
	InconsistentCalItemBitCalc?: TTextDM
	@Type(() => TResourceDM)
	ResourceByIdInputCalc?: TResourceDM
	@Type(() => TResourceDM)
	ResourceByNameInputCalc?: TResourceDM
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleChild?: TJobCardScheduleDM[]
	@Type(() => TJobCardVariantScheduleDM)
	JobCardVariantScheduleChild?: TJobCardVariantScheduleDM[]
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleArchivedChild?: TJobCardScheduleDM[]
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleScheduledChild?: TJobCardScheduleDM[]
}