import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'

export default class TArticleMeasureUnitDM extends Data {
	public static className = 'TArticleMeasureUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Area?: number
	WeightNet?: number
	WeightGross?: number
	Depth?: number
	Height?: number
	Width?: number
	Volume?: number
	Ratio?: number
	ArticleId?: number
	Id?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	InverseRateCalc?: number
	BasicBlockedIdCalc?: number
	WeightNetCalc?: number
	CaptionRatioCalc?: string
	CaptionNotRatioCalc?: string
	CaptionBasicCalc?: number
	LanguageTextCalc?: string
	MeasureUnitIdCalc?: number
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM
	@Type(() => TTextDM)
	AreaUnitId?: TTextDM
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM
	@Type(() => TTextDM)
	VolumeUnitId?: TTextDM
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit
	@Type(() => TTextDM)
	BlockedRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForJobCardBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForPurchaseBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForSaleBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForTransferNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	IntegerUnitIdCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForEshopBitCalc?: TTextDM
	@Type(() => TTextDM)
	ShowOnEshopBitCalc?: TTextDM
	@Type(() => TTextDM)
	EshopQuantityBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForStockTakingBitCalc?: TTextDM
	@Type(() => TTextDM)
	DefaultForRoutingVariantBitCalc?: TTextDM
}