import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TArticleKindDM from './TArticleKindDM'

export default class TCalculationModelItemDM extends Data {
	public static className = 'TCalculationModelItemDM'
	public static systemFields = [
		'RecordID',
	]

	AddOverheads?: string
	SequenceNumber?: number
	Id?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	OverheadImgCalc?: string
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM
}