import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPStatus from './TPStatus'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TRoutingVariantResourceDM from './TRoutingVariantResourceDM'
import TRoutingVariantItemDM from './TRoutingVariantItemDM'
import TRoutingVariantOperationDM from './TRoutingVariantOperationDM'
import TRoutingVariantItemDataFilterDM from './TRoutingVariantItemDataFilterDM'
import TRoutingVariantOperationDataFilterDM from './TRoutingVariantOperationDataFilterDM'
import TRoutingVariantResourceDataFilterDM from './TRoutingVariantResourceDataFilterDM'
import TProductCostingDM from './TProductCostingDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TRoutingVariantSimpleDM extends Data {
	public static className = 'TRoutingVariantSimpleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	BlockTradeDocuments?: string
	BlockJobCards?: string
	IsInvalidRecord?: string
	IsIncompleteRouting?: string
	SerieTo?: number
	SerieFrom?: number
	TimeStamp?: string
	ArticleName?: string
	ArticleAbbr2?: string
	ArticleAbbr1?: string
	CreatedOn?: Date
	RightGroupId?: number
	LeadTime?: number
	StockPrice?: number
	ProductionLot?: number
	CalculationLot?: number
	Id?: number
	ValidTo?: Date
	ValidFrom?: Date
	Weight?: number
	Description?: string
	ItemCount?: number
	ChangedOn?: Date
	Drawing?: string
	Quantity?: number
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	TotalWeightCalc?: number
	TotalPriceCalc?: number
	FilterKindsBitCalc?: number
	DefaultVariantImgCalc?: string
	NameCalc?: string
	ActualRoutingVariantNameCalc?: string
	QuantityCalc?: number
	CalculationLotCalc?: number
	BatchQuantityCalc?: number
	IsImplicitVariantCalc?: string
	OperationTextCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPStatus)
	StatusId?: TPStatus
	@Type(() => TOfficerDocumentDM)
	BOMOfficerId?: TOfficerDocumentDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TMeasureUnitDM)
	StockMeasureUnitAbbrCalc?: TMeasureUnitDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TRoutingVariantResourceDM)
	RoutingVariantResourceChild?: TRoutingVariantResourceDM[]
	@Type(() => TRoutingVariantItemDM)
	RoutingVariantItemChild?: TRoutingVariantItemDM[]
	@Type(() => TRoutingVariantOperationDM)
	RoutingVariantOperationChild?: TRoutingVariantOperationDM[]
	@Type(() => TRoutingVariantItemDataFilterDM)
	RoutingVariantItemDataFilterChild?: TRoutingVariantItemDataFilterDM[]
	@Type(() => TRoutingVariantOperationDataFilterDM)
	RoutingVarOpDataFilterChild?: TRoutingVariantOperationDataFilterDM[]
	@Type(() => TRoutingVariantResourceDataFilterDM)
	RoutingVariantResFilterChild?: TRoutingVariantResourceDataFilterDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}