import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWMSEquipmentDM from './TWMSEquipmentDM'
import LocationsStatus from './LocationsStatus'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TPeriodListDM from './TPeriodListDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TStockCardDM from './TStockCardDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'
import TStockCardOldDM from './TStockCardOldDM'

export default class TLocationArticleDM extends Data {
	public static className = 'TLocationArticleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IgnoreSumCalculation?: string
	Position?: string
	Shelf?: string
	Bay?: string
	Row?: string
	Aisle?: string
	Area?: string
	AbbrFormatRID?: number
	Settings?: string
	Coordinates?: string
	TimeStamp?: string
	LocationTypeId?: number
	ChangedOn?: Date
	MinQuantity?: number
	MaxQuantity?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AssignedCalc?: number
	AvailableQuantityCalc?: number
	ReceivedCalc?: number
	BlockedRecordImgCalc?: string
	AllNullBitCalc?: number
	AssignedUMCalc?: number
	AvailableQuantityUMCalc?: number
	ReceivedUMCalc?: number
	DispatchCalc?: number
	DispatchUMCalc?: number
	IsAvailableCalc?: string
	IsAvailableImgCalc?: string
	AvailableTotalQuantityCalc?: number
	TotalAssignedCalc?: number
	TotalWeightCalc?: number
	ArticleCountCalc?: number
	FormatAbbrCalc?: string
	InvalidRecordBitCalc?: number
	BlockedRecordBitCalc?: number
	TotalReceivedCalc?: number
	TotalDispatchCalc?: number
	InReceiptTotalQuantityCalc?: number
	TotalInTransferCalc?: number
	IsFormShowEmptyCalc?: string
	IsFormShowInUsedCalc?: string
	IsFormShowSkipNullCalc?: string
	NotReleaseAutomaticallyBitCalc?: number
	FillingPercentageWeightCalc?: number
	FillingPercentageVolumeCalc?: number
	TotalVolumePARAMCalc?: number
	InReceiptQuantityCalc?: number
	InReceiptQuantityUMCalc?: number
	InTransferCalc?: number
	InTransferUMCalc?: number
	MeasureUnitCoefficientCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TWMSEquipmentDM)
	HandlingEquipmentId?: TWMSEquipmentDM
	@Type(() => LocationsStatus)
	StatusId?: LocationsStatus
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	FilterArticleIdCalc?: TArticleDM
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRIDCalc?: TWarehouseZoneDM
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
	@Type(() => TStockCardOldDM)
	OldStockCardOldChild?: TStockCardOldDM[]
}