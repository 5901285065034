import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

export default class TCapacityPlanResourceDM extends Data {
	public static className = 'TCapacityPlanResourceDM'
	public static primaryKey = 'Resource'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Resource!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	PlannedDateTimeTo?: Date
	PlannedDateTimeFrom?: Date
	Utilization?: number
	RequestedQuantity?: number
	Capacity?: number
	Resource?: string
	RecordNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ShowInHoursCalc?: string
	CapacityUMCalc?: number
	RequestedQuantityUMCalc?: number
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
}