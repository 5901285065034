import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'

export default class TMarketingActivityItemP2PDM extends Data {
	public static className = 'TMarketingActivityItemP2PDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	MasterRID?: number
	LinkTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DefaultValueImgCalc?: string
	@Type(() => TPartnerDM)
	AuxiliaryRID?: TPartnerDM
	@Type(() => TContactPersonDM)
	SlaveRID?: TContactPersonDM
}