import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrCalendarDM from './TPrCalendarDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import DegreeName from './DegreeName'
import CZNACE from './CZNACE'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TPrDeductionDM from './TPrDeductionDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import DuvUkoncPP2 from './DuvUkoncPP2'
import TCountryDM from './TCountryDM'
import SkupPrac from './SkupPrac'
import MzdZUJ from './MzdZUJ'
import CZICSE from './CZICSE'
import TTownPartDM from './TTownPartDM'
import ProfessionName from './ProfessionName'
import MzdLAU1 from './MzdLAU1'
import NUTS4 from './NUTS4'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Group2 from './Group2'
import Group1 from './Group1'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import TPrUnionDM from './TPrUnionDM'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import EmployeeFunction from './EmployeeFunction'
import DruhCinnosti from './DruhCinnosti'
import RelWork from './RelWork'
import TypWork from './TypWork'
import DuvUkoncPP1 from './DuvUkoncPP1'
import DuvVznikuPP1 from './DuvVznikuPP1'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrTariffDM from './TPrTariffDM'
import PrempReasons from './PrempReasons'
import PrEmpWorksCatalog from './PrEmpWorksCatalog'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TPrConstantGroupEmploymentDM from './TPrConstantGroupEmploymentDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TPrEmployeeWorkExperienceAsItemDM from './TPrEmployeeWorkExperienceAsItemDM'
import TPrEmployeeWorkTaskAsItemDM from './TPrEmployeeWorkTaskAsItemDM'
import TPrLongTermAbsenceDM from './TPrLongTermAbsenceDM'
import TPrEmployeeSickNoteDM from './TPrEmployeeSickNoteDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TElectronicSubmissionPersonDM from './TElectronicSubmissionPersonDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrEmploymentDM extends Data {
	public static className = 'TPrEmploymentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	SocialInsuranceIdentifier?: string
	NotCopyAnalyticalDimensions?: string
	FirstName?: string
	Surname?: string
	EmployeePersonNumber?: string
	Abbr?: string
	CreatedOn?: Date
	IsShortRangeEmployment?: string
	IsExecutive?: string
	IsImmediateStart?: string
	IsExemption?: string
	IsInCurrency?: string
	IsNoPaidOverTime?: string
	IsAdditionalLeave?: string
	IsExtendedLeave?: string
	IsNormalLeave?: string
	CalculateWageAutomatically?: string
	CheckMinWage?: string
	XMLFieldScript?: string
	IsInvalidRecord?: string
	IsFirstEmploymentInCZ?: string
	ChangeContractDate?: Date
	SignatureDate?: Date
	ProbationaryPeriodMonthCount?: number
	WorkPlaceId?: string
	ExpectedTerminationDate?: Date
	NoticePeriodFrom?: Date
	ProbationaryPeriodEndDate?: Date
	RedundancyPaymentMultiple?: number
	RightGroupId?: number
	ChangedOn?: Date
	Automat4PrComponentId?: number
	Automat3PrComponentId?: number
	Automat2PrComponentId?: number
	Automat1PrComponentId?: number
	AdvanceWagePayment?: number
	WageBonus?: number
	WagePersonalBonus2?: number
	WagePersonalBonus?: number
	BasicWage2?: number
	BasicWage?: number
	ContractDate?: Date
	TerminationDate?: Date
	EntryDate?: Date
	CompanyId?: number
	TimeStamp?: string
	XMLField?: string
	SequenceNumber?: number
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FieldHistoryDateCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionFieldDateCalc?: Date
	IsItemCompleteCalc?: string
	ProbationaryPeriodInDaysCalc?: number
	EmploymentDayCountCalc?: number
	IsMainEmploymentCalc?: string
	LegalRelationDescriptionCalc?: string
	IsEmploymentValidityDateCalc?: string
	DifficultEnvironmentCalc?: string
	AllowSickDaysCalc?: string
	PersonalBonusAmountCalc?: number
	ExtraBonusCalc?: number
	ManagementBonusAmountCalc?: number
	IsExceptionallyGradeSetCalc?: string
	IsSpecialLevelSetCalc?: string
	IsLeadingPositionCalc?: string
	DifficultEnvironmentRateCalc?: number
	DateFrom?: Date
	DateTo?: Date
	PrEmploymentId?: number
	LegalRelationSequenceDescriptionCalc?: string
	ActivationDateCalc?: Date
	ConfirmedPeriodIdCalc?: number
	ConfirmedOnCalc?: Date
	CreditablePrevExpYearsCalc?: number
	CreditablePrevExpDaysCalc?: number
	ActualWorkExperienceYearCalc?: number
	ActualWorkExperienceDayCalc?: number
	WorkExperienceDaysDateCalc?: number
	WorkExperienceYearsDateCalc?: number
	SalaryTariffCalc?: number
	SalaryTariffShortCalc?: number
	WorkExperienceLevelDateCalc?: number
	WorkExperienceDateCalc?: Date
	NextLevelWorkExperienceYearsCalc?: number
	NextLevelDateCalc?: Date
	PersonalBonusShortAmountCalc?: number
	ExtraBonusShortCalc?: number
	ManagementBonusShortAmountCalc?: number
	DifficultEnvironmentRateShortCalc?: number
	ContactPersonIdCalc?: number
	ContractPeriodBeginDateCalc?: Date
	AgreedWorkloadWeekCalc?: number
	AgreedWorkloadDayCalc?: number
	StatedWorkloadWeekCalc?: number
	StatedWorkloadDayCalc?: number
	ShortenedWorkTimeCalc?: string
	ShortenedWorkTimePctCalc?: number
	ShortenedWorkTimeRatioCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	ExtraBonusCalc_ver?: number
	EntryDate_ver?: Date
	IsFirstEmploymentInCZ_ver?: string
	BasicWage_ver?: number
	WagePersonalBonus_ver?: number
	ManagementBonusAmountCalc_ver?: number
	IsNoPaidOverTime_ver?: string
	AllowSickDaysCalc_ver?: string
	IsInCurrency_ver?: string
	AdvanceWagePayment_ver?: number
	WageBonus_ver?: number
	IsShortRangeEmployment_ver?: string
	Automat2PrComponentId_ver?: number
	WorkPlaceId_ver?: string
	PersonalBonusAmountCalc_ver?: number
	ExpectedTerminationDate_ver?: Date
	RedundancyPaymentMultiple_ver?: number
	Automat1PrComponentId_ver?: number
	IsExecutive_ver?: string
	IsExceptionallyGradeSetCalc_ver?: string
	TerminationDate_ver?: Date
	ProbationaryPeriodEndDate_ver?: Date
	SignatureDate_ver?: Date
	IsImmediateStart_ver?: string
	NoticePeriodFrom_ver?: Date
	IsExemption_ver?: string
	DifficultEnvironmentRateCalc_ver?: number
	IsAdditionalLeave_ver?: string
	BasicWage2_ver?: number
	DifficultEnvironmentCalc_ver?: string
	WagePersonalBonus2_ver?: number
	IsSpecialLevelSetCalc_ver?: string
	IsNormalLeave_ver?: string
	Automat3PrComponentId_ver?: number
	ProbationaryPeriodMonthCount_ver?: number
	IsLeadingPositionCalc_ver?: string
	CalculateWageAutomatically_ver?: string
	CheckMinWage_ver?: string
	Automat4PrComponentId_ver?: number
	NotCopyAnalyticalDimensions_ver?: string
	ChangeContractDate_ver?: Date
	IsExtendedLeave_ver?: string
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId?: TPrCalendarDM
	@Type(() => TBookDM)
	AttendanceBookId?: TBookDM
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId?: TTextDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrTreeUnitRID?: TOrgStrTreeUnitDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => DegreeName)
	RequiredEducationLevelId?: DegreeName
	@Type(() => CZNACE)
	CZNACEId?: CZNACE
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM
	@Type(() => TPrDeductionDM)
	WageDeductionId?: TPrDeductionDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TPrDeductionDM)
	TaxOfficeId?: TPrDeductionDM
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId?: TPrCalendarDM
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId?: TAccountingChartDM
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId?: DuvUkoncPP2
	@Type(() => TCountryDM)
	WorkCountryId?: TCountryDM
	@Type(() => SkupPrac)
	GroupId?: SkupPrac
	@Type(() => MzdZUJ)
	AdministrativeDivisionId?: MzdZUJ
	@Type(() => CZICSE)
	CZICSEId?: CZICSE
	@Type(() => TTownPartDM)
	WorkTownPartId?: TTownPartDM
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1
	@Type(() => NUTS4)
	NUTS4Id?: NUTS4
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => Group2)
	Group2Id?: Group2
	@Type(() => Group1)
	Group1Id?: Group1
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1
	@Type(() => TTextDM)
	SocialActivityTypeId?: TTextDM
	@Type(() => TTextDM)
	TaxDeductionTypeId?: TTextDM
	@Type(() => TTextDM)
	DeductionSocialSecurityId?: TTextDM
	@Type(() => TTextDM)
	DeductionHealthInsuranceId?: TTextDM
	@Type(() => TTextDM)
	TaxPayerTypeId?: TTextDM
	@Type(() => TPrUnionDM)
	UnionId?: TPrUnionDM
	@Type(() => TTextDM)
	WageTypeId?: TTextDM
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage?: TPrMinimalTariffDM
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM
	@Type(() => TPrCalendarDM)
	LegalCalendarId?: TPrCalendarDM
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId?: TPrCalendarDM
	@Type(() => EmployeeFunction)
	JobClassificationId?: EmployeeFunction
	@Type(() => TTextDM)
	DurationTypeId?: TTextDM
	@Type(() => DruhCinnosti)
	EmploymentTypeId?: DruhCinnosti
	@Type(() => TTextDM)
	LegalRelationId?: TTextDM
	@Type(() => RelWork)
	LegalSubrelation2Id?: RelWork
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM
	@Type(() => TypWork)
	WorkTypeId?: TypWork
	@Type(() => DuvUkoncPP1)
	TerminationReasonId?: DuvUkoncPP1
	@Type(() => DuvVznikuPP1)
	CommencementReasonId?: DuvVznikuPP1
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	ProcessPeriodIdCalc?: TPrPeriodDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM
	@Type(() => TTextDM)
	EmploymentValidityOFBitCalc?: TTextDM
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	PeriodToIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	LastPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrTariffDM)
	PayrollTariffRIDCalc?: TPrTariffDM
	@Type(() => TTextDM)
	GradeIdCalc?: TTextDM
	@Type(() => TTextDM)
	LevelIdCalc?: TTextDM
	@Type(() => PrempReasons)
	PersonalBonusReasonIdCalc?: PrempReasons
	@Type(() => PrempReasons)
	ExtraBonusReasonIdCalc?: PrempReasons
	@Type(() => PrempReasons)
	ManagementBonusReasonIdCalc?: PrempReasons
	@Type(() => TTextDM)
	ManagementLevelIdCalc?: TTextDM
	@Type(() => TTextDM)
	ExtraBonusGroupIdCalc?: TTextDM
	@Type(() => PrempReasons)
	DifficultEnvironmentRateReasonCalc?: PrempReasons
	@Type(() => PrEmpWorksCatalog)
	WorksCatalogIdCalc?: PrEmpWorksCatalog
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrEmployeeDocumentDM)
	ActualPrEmployeeDocumentRIDCalc?: TPrEmployeeDocumentDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TPrInternalTariffDM)
	InternalTariffId_ver?: TPrInternalTariffDM
	@Type(() => DuvUkoncPP1)
	TerminationReasonId_ver?: DuvUkoncPP1
	@Type(() => TCode1DocumentDM)
	Code1Id_ver?: TCode1DocumentDM
	@Type(() => TypWork)
	WorkTypeId_ver?: TypWork
	@Type(() => TTextDM)
	LegalRelationId_ver?: TTextDM
	@Type(() => PrEmpWorksCatalog)
	WorksCatalogIdCalc_ver?: PrEmpWorksCatalog
	@Type(() => TTextDM)
	DurationTypeId_ver?: TTextDM
	@Type(() => PrempReasons)
	ExtraBonusReasonIdCalc_ver?: PrempReasons
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId_ver?: TTextDM
	@Type(() => TAccountingChartDM)
	DebitAccountId_ver?: TAccountingChartDM
	@Type(() => DuvVznikuPP1)
	CommencementReasonId_ver?: DuvVznikuPP1
	@Type(() => TTextDM)
	DeductionHealthInsuranceId_ver?: TTextDM
	@Type(() => TCode4DocumentDM)
	Code4Id_ver?: TCode4DocumentDM
	@Type(() => TTextDM)
	ExtraBonusGroupIdCalc_ver?: TTextDM
	@Type(() => EmployeeFunction)
	JobClassificationId_ver?: EmployeeFunction
	@Type(() => PrempReasons)
	PersonalBonusReasonIdCalc_ver?: PrempReasons
	@Type(() => Group1)
	Group1Id_ver?: Group1
	@Type(() => DruhCinnosti)
	EmploymentTypeId_ver?: DruhCinnosti
	@Type(() => Kategorie1)
	Category1Id_ver?: Kategorie1
	@Type(() => TTextDM)
	SocialActivityTypeId_ver?: TTextDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId_ver?: TMatchingSymbolDM
	@Type(() => ProfessionName)
	CZISCOId_ver?: ProfessionName
	@Type(() => TTextDM)
	WageTypeId_ver?: TTextDM
	@Type(() => TTextDM)
	DeductionSocialSecurityId_ver?: TTextDM
	@Type(() => RelWork)
	LegalSubrelation2Id_ver?: RelWork
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrTreeUnitRID_ver?: TOrgStrTreeUnitDM
	@Type(() => TTextDM)
	TaxDeductionTypeId_ver?: TTextDM
	@Type(() => Kategorie2)
	Category2Id_ver?: Kategorie2
	@Type(() => DegreeName)
	RequiredEducationLevelId_ver?: DegreeName
	@Type(() => TPrDeductionDM)
	WageDeductionId_ver?: TPrDeductionDM
	@Type(() => TTextDM)
	GradeIdCalc_ver?: TTextDM
	@Type(() => TCountryDM)
	WorkCountryId_ver?: TCountryDM
	@Type(() => TTextDM)
	TaxPayerTypeId_ver?: TTextDM
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId_ver?: TPrCalendarDM
	@Type(() => TCode6DocumentDM)
	Code6Id_ver?: TCode6DocumentDM
	@Type(() => PrempReasons)
	DifficultEnvironmentRateReasonCalc_ver?: PrempReasons
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId_ver?: TArticleCodeDocumentDM
	@Type(() => TTextDM)
	LevelIdCalc_ver?: TTextDM
	@Type(() => TTextDM)
	LegalSubrelation1Id_ver?: TTextDM
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId_ver?: TPrCalendarDM
	@Type(() => TAccountingChartDM)
	CreditAccountId_ver?: TAccountingChartDM
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId_ver?: TPrCalendarDM
	@Type(() => TPrTariffDM)
	PayrollTariffRIDCalc_ver?: TPrTariffDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id_ver?: TCostCentreCollectionDocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id_ver?: TCode3DocumentDM
	@Type(() => NUTS4)
	NUTS4Id_ver?: NUTS4
	@Type(() => TTownPartDM)
	WorkTownPartId_ver?: TTownPartDM
	@Type(() => TPrDeductionDM)
	TaxOfficeId_ver?: TPrDeductionDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID_ver?: TDeviceDocumentDM
	@Type(() => MzdLAU1)
	LAU1Id_ver?: MzdLAU1
	@Type(() => TCode5DocumentDM)
	Code5Id_ver?: TCode5DocumentDM
	@Type(() => TPrCalendarDM)
	LegalCalendarId_ver?: TPrCalendarDM
	@Type(() => MzdZUJ)
	AdministrativeDivisionId_ver?: MzdZUJ
	@Type(() => CZICSE)
	CZICSEId_ver?: CZICSE
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId_ver?: TAccountingChartDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID_ver?: TContractCodeDocumentDM
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId_ver?: DuvUkoncPP2
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id_ver?: TCostCentreCollectionDocumentDM
	@Type(() => Group2)
	Group2Id_ver?: Group2
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId_ver?: TCostCentreCollectionDocumentDM
	@Type(() => SkupPrac)
	GroupId_ver?: SkupPrac
	@Type(() => TCode2DocumentDM)
	Code2Id_ver?: TCode2DocumentDM
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId_ver?: TAccountingChartDM
	@Type(() => TPrUnionDM)
	UnionId_ver?: TPrUnionDM
	@Type(() => TCurrencyDM)
	Currency_ver?: TCurrencyDM
	@Type(() => TTextDM)
	ManagementLevelIdCalc_ver?: TTextDM
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage_ver?: TPrMinimalTariffDM
	@Type(() => PrempReasons)
	ManagementBonusReasonIdCalc_ver?: PrempReasons
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID_ver?: TOrgStrJobDM
	@Type(() => TBookDM)
	AttendanceBookId_ver?: TBookDM
	@Type(() => CZNACE)
	CZNACEId_ver?: CZNACE
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrConstantGroupEmploymentDM)
	PrConstantGroupEmploymentChild?: TPrConstantGroupEmploymentDM[]
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanentCompleteChild?: TPrDefinitionComponentDM[]
	@Type(() => TPrEmployeeDocumentDM)
	PrBookDocumentChild?: TPrEmployeeDocumentDM[]
	@Type(() => TPrEmployeeWorkExperienceAsItemDM)
	PrEmployeeWorkExperienceChild?: TPrEmployeeWorkExperienceAsItemDM[]
	@Type(() => TPrEmployeeWorkTaskAsItemDM)
	PrEmployeeWorkTaskChild?: TPrEmployeeWorkTaskAsItemDM[]
	@Type(() => TPrLongTermAbsenceDM)
	PrLongTermAbsenceChild?: TPrLongTermAbsenceDM[]
	@Type(() => TPrEmployeeSickNoteDM)
	PrEmployeeSickNoteChild?: TPrEmployeeSickNoteDM[]
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]
	@Type(() => TOnePersonAbsenceDM)
	PersonAbsenceChild?: TOnePersonAbsenceDM[]
	@Type(() => TAttendanceWorkTimeReportDM)
	AttendanceWorkTimeReportChild?: TAttendanceWorkTimeReportDM[]
	@Type(() => TAttendanceMovementDM)
	AttendanceMovementChild?: TAttendanceMovementDM[]
	@Type(() => TElectronicSubmissionPersonDM)
	ElectronicSubmissionPersonChild?: TElectronicSubmissionPersonDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}