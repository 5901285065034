import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSpecialStringListDM from './TSpecialStringListDM'

export default class TSpecialTSalesOrderDMCustomersInfo extends Data {
	public static className = 'TSpecialTSalesOrderDMCustomersInfo'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	InformationStatus?: string
	RID?: number
	Note?: string
	DataValue?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TSpecialStringListDM)
	ValueType?: TSpecialStringListDM
}