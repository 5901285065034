import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrPeriodDM from './TPrPeriodDM'
import TBookDM from './TBookDM'
import TPrDeductionDM from './TPrDeductionDM'
import TPrConstantGroupItemDM from './TPrConstantGroupItemDM'

export default class TPrConstantDM extends Data {
	public static className = 'TPrConstantDM'
	public static primaryKey = 'ConstantId'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ConstantId!
	}

	Comment?: string
	IsForBooks?: string
	CompanyId?: number
	StandardId?: number
	Description?: string
	Abbr?: string
	ConstantId?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ConstantValueStringCalc?: string
	ValuePictureCalc?: string
	ValueCalc?: string
	ChangedOnCalc?: Date
	ActualTextCalc?: string
	ActualPictureCalc?: string
	ImportWarningIdCalc?: number
	BlockImportIdCalc?: number
	InvalidConstantBitCalc?: number
	GroupAllowedBitCalc?: number
	ConstantValueDateCalc?: Date
	ConstantValueCurrencyCalc?: number
	ConstantValueIntegerCalc?: number
	ConstantValueRealCalc?: number
	ConstantValueTextCalc?: string
	ConstantValueTimeCalc?: Date
	ConstantValueComponentIdCalc?: number
	ConstantValueCalendarIdCalc?: number
	@Type(() => TTextDM)
	ImportTypeId?: TTextDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
	@Type(() => TTextDM)
	ValueTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	ValueMeasureUnitIdCalc?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedByIdCalc?: TK2UserLookupDM
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	ConstantValidPeridodFromIdCalc?: TPrPeriodDM
	@Type(() => TBookDM)
	BookIdCalc?: TBookDM
	@Type(() => TBookDM)
	PrDocumentBookIdCalc?: TBookDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	UserPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	EditTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	PrComponentRestrictionIdCalc?: TTextDM
	@Type(() => TTextDM)
	ChangedValueInPeriodBitCalc?: TTextDM
	@Type(() => TTextDM)
	ConstantValueBooleanIdCalc?: TTextDM
	@Type(() => TPrDeductionDM)
	ConstantValueBankDetailIdCalc?: TPrDeductionDM
	@Type(() => TPrConstantGroupItemDM)
	PrConstantGroupItemChild?: TPrConstantGroupItemDM[]
}