import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTransportMethodDM from './TTransportMethodDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TShippingMethodDM extends Data {
	public static className = 'TShippingMethodDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	ServiceCode?: string
	IsBlockedRecord?: string
	TimeStamp?: string
	RightGroupId?: number
	Description?: string
	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	LanguageTextCalc?: string
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}