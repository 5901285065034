import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrPeriodDM from './TPrPeriodDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TPrConstantGroupDM from './TPrConstantGroupDM'

export default class TPrConstantGroupEmploymentBaseDM extends Data {
	public static className = 'TPrConstantGroupEmploymentBaseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TPrPeriodDM)
	PeriodToId?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	PeriodFromId?: TPrPeriodDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TPrConstantGroupDM)
	PrConstantGroupRID?: TPrConstantGroupDM
}