import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ServiceOperationType from './ServiceOperationType'
import TTextDM from './TTextDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TServiceRequirementDM from './TServiceRequirementDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ScheduledServiceOperationStatus from './ScheduledServiceOperationStatus'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TJobCardDM from './TJobCardDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TServiceNoteDM from './TServiceNoteDM'
import TServiceContractDM from './TServiceContractDM'
import TAttributeValueDM from './TAttributeValueDM'
import TServiceOperationOfficerDM from './TServiceOperationOfficerDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TScheduledServiceOperationDM extends Data {
	public static className = 'TScheduledServiceOperationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RealOperationEndDate?: Date
	RealOperationStartDate?: Date
	RealOperationDuration?: number
	ServiceParameterGroupId?: number
	DetailedDescription?: string
	OperationDuration?: number
	OperationEndDate?: Date
	OperationStartDate?: Date
	DefinitionRID?: number
	ConfirmedOn?: Date
	Description?: string
	IsGeneratedRecord?: string
	IsCanceledRecord?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Number?: number
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	OperationStartDateCalc?: Date
	OperationStartTimeCalc?: Date
	OperationEndDateCalc?: Date
	OperationEndTimeCalc?: Date
	RealOperationStartDateCalc?: Date
	RealOperationStartTimeCalc?: Date
	RealOperationEndDateCalc?: Date
	RealOperationEndTimeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => ServiceOperationType)
	ServiceTypeId?: ServiceOperationType
	@Type(() => TTextDM)
	RealTimeUnitId?: TTextDM
	@Type(() => TOfficerDocumentDM)
	ServicemanId?: TOfficerDocumentDM
	@Type(() => TServiceRequirementDM)
	ServiceRequirementRID?: TServiceRequirementDM
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => ScheduledServiceOperationStatus)
	StatusId?: ScheduledServiceOperationStatus
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderChild?: TPurchaseOrderDM[]
	@Type(() => TJobCardDM)
	JobCardChild?: TJobCardDM[]
	@Type(() => TPurchaseItemBookDM)
	PurchaseItemBookChild?: TPurchaseItemBookDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemBookChild?: TSalesItemBookDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]
	@Type(() => TServiceContractDM)
	ServiceContractChild?: TServiceContractDM[]
	@Type(() => TAttributeValueDM)
	AttributeValueChild?: TAttributeValueDM[]
	@Type(() => TServiceOperationOfficerDM)
	OperationOfficerChild?: TServiceOperationOfficerDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}