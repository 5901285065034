import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ABCClass from './ABCClass'
import TABCAnalysisDM from './TABCAnalysisDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'

export default class TABCItemDM extends Data {
	public static className = 'TABCItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	DataModuleNumber?: number
	DateTo?: Date
	ABCConfigRID?: number
	PercentageSum?: number
	Percentage?: number
	ItemValue?: number
	ItemNumber?: number
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TrendCalc?: number
	AbbrCalc?: string
	NameCalc?: string
	@Type(() => ABCClass)
	ABCClassId?: ABCClass
	@Type(() => TABCAnalysisDM)
	ABCAnalysisRID?: TABCAnalysisDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
}