import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TEshopDM from './TEshopDM'
import TParameterSetDM from './TParameterSetDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TEshopCategoryArticleDM from './TEshopCategoryArticleDM'
import TEshopCategoryArticleGroupDM from './TEshopCategoryArticleGroupDM'
import TEshopArticleListDM from './TEshopArticleListDM'
import TEshopCategoryArticleSummaryDM from './TEshopCategoryArticleSummaryDM'
import TVirtualCategoryParameterDM from './TVirtualCategoryParameterDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopCategoryInfoDM extends Data {
	public static className = 'TEshopCategoryInfoDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInvisible?: string
	PatternCategoryRID?: number
	IsVirtual?: string
	OpenGraphImage?: number
	OpenGraphTypeId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	RightGroupId?: number
	CategoryPath?: string
	TimeStamp?: string
	SequenceNumber?: number
	ImageFileName?: string
	IconFileName?: string
	PageAnalytics?: string
	PageUrlCanonical?: string
	PageUrl?: string
	PageKeyWord?: string
	PageDescription?: string
	PageTitle?: string
	Description?: string
	Title?: string
	Name?: string
	RID?: number
	Ex_Rab?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	DescriptionCalc?: string
	CategoryPathNameCalc?: string
	CategoryPathTitleCalc?: string
	NotShowImgCalc?: string
	DetailUrlCalc?: string
	DetailUrlAbsCalc?: string
	MainImageFullPathCalc?: string
	UrlCalc?: string
	DetailUrlWebCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedNameCalc?: string
	TranslatedTitleCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedPageKeyWordCalc?: string
	TranslatedPageUrlCalc?: string
	TranslatedPageAnalyticsCalc?: string
	TranslatedDescriptionCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TParameterSetDM)
	ParameterSetId?: TParameterSetDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	NotShowBitCalc?: TTextDM
	@Type(() => TEshopDM)
	ShownEshopRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TEshopCategoryArticleDM)
	ArticleChild?: TEshopCategoryArticleDM[]
	@Type(() => TEshopCategoryArticleGroupDM)
	EshopCategoryArticleGroupChild?: TEshopCategoryArticleGroupDM[]
	@Type(() => TEshopArticleListDM)
	AllArticleChild?: TEshopArticleListDM[]
	@Type(() => TEshopCategoryArticleSummaryDM)
	AssignedArticleChild?: TEshopCategoryArticleSummaryDM[]
	@Type(() => TVirtualCategoryParameterDM)
	ParameterValueChild?: TVirtualCategoryParameterDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}