import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCapacityPlanResourceDM from './TCapacityPlanResourceDM'

export default class TCapacityPlanWorkshiftDM extends Data {
	public static className = 'TCapacityPlanWorkshiftDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	PlannedDateTimeTo?: Date
	PlannedDateTimeFrom?: Date
	WorkshiftRID?: number
	Utilization?: number
	RequestedQuantity?: number
	Capacity?: number
	WorkshiftAbbr?: string
	RecordNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ShowInHoursCalc?: string
	CapacityUMCalc?: number
	RequestedQuantityUMCalc?: number
	@Type(() => TCapacityPlanResourceDM)
	CapacityPlanResourceChild?: TCapacityPlanResourceDM[]
}