import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TMarketingActivityTypeDM extends Data {
	public static className = 'TMarketingActivityTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	RightGroupId?: number
	Rating?: number
	ChangedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageDescriptionCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
}