import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TProductionOrderDM from './TProductionOrderDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import DocStatus from './DocStatus'
import Workshift from './Workshift'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCalculationModelDM from './TCalculationModelDM'
import Priority from './Priority'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import GoodsName from './GoodsName'
import TLocationArticleDM from './TLocationArticleDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TWarehouseDM from './TWarehouseDM'
import TSalesOrderDM from './TSalesOrderDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TParameterSetDM from './TParameterSetDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TTransferNoteDM from './TTransferNoteDM'
import TWarehouseTransferDM from './TWarehouseTransferDM'
import TJobCardScheduleDM from './TJobCardScheduleDM'
import TJobCardItemDM from './TJobCardItemDM'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardResourceDM from './TJobCardResourceDM'
import TJobCardBindDM from './TJobCardBindDM'
import TJobCardSubordinateDocumentDM from './TJobCardSubordinateDocumentDM'
import TAccountingFinanceJobCardItemDM from './TAccountingFinanceJobCardItemDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TProductCostingDM from './TProductCostingDM'
import TJobCardVariantScheduleDM from './TJobCardVariantScheduleDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TJobCardDM extends Data {
	public static className = 'TJobCardDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	NotRecordActualQuantity?: string
	IsCanceledRecord?: string
	IsScheduledWithoutCoverage?: string
	IsUnconfirmedWithoutStockRec?: string
	PostZeroAmount?: string
	IsFollowUpLot?: string
	IgnoreReceiptPrice?: string
	IsAllItemsPreparedForRelease?: string
	IsRelease?: string
	IsSomeOperationConfirmed?: string
	IsCorrectPosting?: string
	IsCorrectAssignment?: string
	IsAllItemsReleased?: string
	IsIncompleteSerialNumbers?: string
	IsAllReserved?: string
	IsReservation?: string
	IsIncompleteRouting?: string
	IsProduct?: string
	IsRootToPO?: string
	Number?: number
	LastPlannedDateTo?: Date
	LastPlannedDateFrom?: Date
	IsTimeByQuantity?: string
	IsExecution?: string
	IsFixated?: string
	IsManuallyAdjustedSchedule?: string
	RID?: number
	TimeStamp?: string
	RoutingQuantity?: number
	ActualDateTo?: Date
	ActualDateFrom?: Date
	UnitCostPrice?: number
	SchedulingSequenceNumber?: number
	Drawing?: string
	IncreasedQuantity?: number
	PlannedUnitPrice?: number
	UnitPrice?: number
	PostingKeyId?: number
	PlannedProductionDateTo?: Date
	PlannedProductionDateFrom?: Date
	CreatedOn?: Date
	Quantity?: number
	PlannedQuantity?: number
	RightGroupId?: number
	AccountingItemsCount?: number
	ItemCount?: number
	PostedById?: number
	PostedOn?: Date
	ChangedOn?: Date
	ConfirmationTime?: Date
	ConfirmedOn?: Date
	Description?: string
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	ConfirmedImgCalc?: number
	PlannedQuantityCalc?: number
	ActualQuantityUMCalc?: number
	ActualTotalPriceCalc?: number
	PlannedProductionDaysCalc?: number
	ActualProductionDaysCalc?: number
	IncreasedQuantityUMCalc?: number
	PlannedTotalPriceCalc?: number
	ActualQuantityRNCalc?: number
	DescriptionCalc?: string
	ActualItemTotalPriceCalc?: number
	PlannedItemTotalPriceCalc?: number
	TotalCostPriceCalc?: number
	PlannedProductionDateFromCalc?: Date
	PlannedTimeFromCalc?: Date
	PlannedProductionDateToCalc?: Date
	PlannedTimeToCalc?: Date
	BatchTypeIdCalc?: number
	RootJobCardRIDCalc?: number
	FilterItemsBitCalc?: number
	SerialNumberProductFilterCalc?: string
	BatchRatioCalc?: number
	BlTestPlannedActualDateCalc?: Date
	BlTestActualDateCalc?: Date
	BlTestPlannedConfirmedDateCalc?: Date
	BlTestActualConfirmedDateCalc?: Date
	PlannedTimeCalc?: number
	ActualDurationCalc?: number
	SpentTimePercentageCalc?: number
	PlannedValueCalc?: number
	ActualValueCalc?: number
	PercentageCompleteCalc?: number
	ProducedQuantityCalc?: number
	CoverageTimeStampCalc?: Date
	RemainingQuantityCalc?: number
	ShowAllItemCalc?: string
	OperationTextCalc?: string
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	CorrectPostingBitCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TProductionOrderDM)
	ProductionOrderRID?: TProductionOrderDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => DocStatus)
	StatusId?: DocStatus
	@Type(() => Workshift)
	WorkshiftId?: Workshift
	@Type(() => TTextDM)
	JobCardTypeId?: TTextDM
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TCalculationModelDM)
	CostPriceCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	StockPriceCalculationModelId?: TCalculationModelDM
	@Type(() => TCalculationModelDM)
	PlannedPriceCalculationModelId?: TCalculationModelDM
	@Type(() => Priority)
	PriorityId?: Priority
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TTradingPartnerDM)
	CooperatorId?: TTradingPartnerDM
	@Type(() => TOfficerDocumentDM)
	WorkerId?: TOfficerDocumentDM
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTextDM)
	ReservedBitCalc?: TTextDM
	@Type(() => TTextDM)
	ReleasedBitCalc?: TTextDM
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
	@Type(() => TTextDM)
	OrderedBitCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	SerialNumberInconsistencyBitCalc?: TTextDM
	@Type(() => TTextDM)
	InconsistentCalItemBitCalc?: TTextDM
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM
	@Type(() => TSerialNumberItemDM)
	SerialNumberProductFilterRIDCalc?: TSerialNumberItemDM
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeIdCalc?: TArticleCodeDocumentDM
	@Type(() => TSalesItemBookDM)
	SalesOrderOldestItemRIDCalc?: TSalesItemBookDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]
	@Type(() => TWarehouseTransferDM)
	WarehouseTransferChild?: TWarehouseTransferDM[]
	@Type(() => TJobCardScheduleDM)
	JobCardScheduleChild?: TJobCardScheduleDM[]
	@Type(() => TJobCardItemDM)
	JobCardItemChild?: TJobCardItemDM[]
	@Type(() => TJobCardOperationDM)
	JobCardOperationChild?: TJobCardOperationDM[]
	@Type(() => TJobCardResourceDM)
	JobCardResourceChild?: TJobCardResourceDM[]
	@Type(() => TJobCardBindDM)
	JobCardBindChild?: TJobCardBindDM[]
	@Type(() => TJobCardSubordinateDocumentDM)
	JobCardSubordinateDocChild?: TJobCardSubordinateDocumentDM[]
	@Type(() => TSerialNumberItemDM)
	SuperiorSerialNumberChild?: TSerialNumberItemDM[]
	@Type(() => TAccountingFinanceJobCardItemDM)
	AccFinanceJobCardItemChild?: TAccountingFinanceJobCardItemDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]
	@Type(() => TProductCostingDM)
	ProductCostingChild?: TProductCostingDM[]
	@Type(() => TJobCardOperationDM)
	JCOperationReadOnlyChild?: TJobCardOperationDM[]
	@Type(() => TJobCardVariantScheduleDM)
	VariantScheduleChild?: TJobCardVariantScheduleDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}