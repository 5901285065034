import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDiscountCouponDM from './TDiscountCouponDM'

export default class TAppliedCouponDM extends Data {
	public static className = 'TAppliedCouponDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AppliedDate?: Date
	AppliedCount?: number
	DocumentRID?: number
	DocumentTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TDiscountCouponDM)
	CouponRID?: TDiscountCouponDM
}