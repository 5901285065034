import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContactPersonDM from './TContactPersonDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'

export default class TWorkflowProcessAsItemDM extends Data {
	public static className = 'TWorkflowProcessAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	InvalidRecordById?: number
	InvalidRecordOn?: Date
	RID?: number
	ProductHeaderId?: number
	SolverWorkStepId?: number
	CreatedOn?: Date
	RegisteredPointId?: number
	ProductId?: number
	DocumentTypeId?: number
	DocumentNumber?: number
	DocumentBook?: string
	DocumentTableNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TContactPersonDM)
	CreatedById?: TContactPersonDM
	@Type(() => TWorkflowProcessDM)
	ProcessId?: TWorkflowProcessDM
}