import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ClassificationName from './ClassificationName'
import ProfessionName from './ProfessionName'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TMarketingSubsumptionDM extends Data {
	public static className = 'TMarketingSubsumptionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	EmployedToDate?: Date
	Employer?: string
	EmployedFromDate?: Date
	SequenceNumber?: number
	ContactPersonId?: number
	TypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedbyId?: TK2UserLookupDM
	@Type(() => ClassificationName)
	Employment?: ClassificationName
	@Type(() => ProfessionName)
	Profession?: ProfessionName
	@Type(() => TLanguageMemoTranslationDM)
	CommentChild?: TLanguageMemoTranslationDM[]
}