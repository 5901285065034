import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TFragmentRasterDM extends Data {
	public static className = 'TFragmentRasterDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	Description?: string
	ColumnCount?: number
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}