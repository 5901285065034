import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TJobCardFieldDM extends Data {
	public static className = 'TJobCardFieldDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	Name?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
}