import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TTextDM from './TTextDM'
import TAttendanceMovementIntervalDM from './TAttendanceMovementIntervalDM'
import TAttendanceWorkTimeReportItemDM from './TAttendanceWorkTimeReportItemDM'
import TAttendanceWorkTimeReportDM from './TAttendanceWorkTimeReportDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAttendanceProcessedIntervalDM extends Data {
	public static className = 'TAttendanceProcessedIntervalDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	HoursCount?: number
	MovementDateTo?: Date
	MovementDateFrom?: Date
	BookId?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	MovementDateFromCalc?: Date
	MovementDateToCalc?: Date
	TooltipCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TOnePersonAbsenceDM)
	PersonAbsenceRID?: TOnePersonAbsenceDM
	@Type(() => TTextDM)
	OriginTypeId?: TTextDM
	@Type(() => TAttendanceMovementIntervalDM)
	MovementSubTypeRID?: TAttendanceMovementIntervalDM
	@Type(() => TAttendanceWorkTimeReportItemDM)
	WorkTimeReportItemRID?: TAttendanceWorkTimeReportItemDM
	@Type(() => TAttendanceWorkTimeReportDM)
	WorkTimeReportRID?: TAttendanceWorkTimeReportDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}