import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import OpKind from './OpKind'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TRoutingVariantResourceSimpleDM from './TRoutingVariantResourceSimpleDM'
import TRoutingVariantOperationSimpleDM from './TRoutingVariantOperationSimpleDM'
import TRoutingVariantItemSimpleDM from './TRoutingVariantItemSimpleDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TOperationDM extends Data {
	public static className = 'TOperationDM'
	public static primaryKey = 'OperationId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.OperationId!
	}

	SetupTimeInputNotRequired?: string
	IsShutdown?: string
	NotInterrupt?: string
	OperationTextFormatId?: number
	EnterRejectsInsteadOfYield?: string
	CopyItems?: string
	NotShowOperationText?: string
	IsCooperation?: string
	IsInvalidRecord?: string
	CompanyId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	OperationText?: string
	RightGroupId?: number
	Name?: string
	Abbr?: string
	OperationId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageTextCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TOfficerDocumentDM)
	ResponsiblePersonBOMId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	ResponsiblePersonRoutingId?: TOfficerDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => OpKind)
	OperationKindId?: OpKind
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TRoutingVariantResourceSimpleDM)
	RoutingVariantResourceChild?: TRoutingVariantResourceSimpleDM[]
	@Type(() => TRoutingVariantOperationSimpleDM)
	RoutingVariantOperationChild?: TRoutingVariantOperationSimpleDM[]
	@Type(() => TRoutingVariantItemSimpleDM)
	RoutingVariantItemChild?: TRoutingVariantItemSimpleDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}