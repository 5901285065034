import Data from '../Api/Data'
import { Type } from 'class-transformer'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TJobCardDM from './TJobCardDM'
import TSalesOrderDM from './TSalesOrderDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TReceiptCardDM from './TReceiptCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TTransferNoteDM from './TTransferNoteDM'

export default class TStockCardOldDM extends Data {
	public static className = 'TStockCardOldDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	RID?: number
	UMCoeficient?: number
	IncidentalCost?: number
	SequenceNumber?: number
	UnitCostPrice?: number
	RoutingVariantId?: number
	OfficerId?: number
	Code2Id?: number
	Code1Id?: number
	OrderDocumentRID?: number
	SequenceNumber1?: number
	ItemId?: number
	PurchaseBook?: string
	ProductionDateFrom?: Date
	Assigned?: number
	Available?: number
	InformationWarehouseId?: number
	LevelAmount?: number
	LevelQuantity?: number
	ReleaseAmount?: number
	ReleaseQuantity?: number
	ReceiptAmount?: number
	ReceiptQuantity?: number
	StockPrice?: number
	DocumentRID?: number
	ConfirmedOn?: Date
	SelectionImgCalc?: string
	RecordStateImgCalc?: string
	ReceiptCalc?: number
	ReleaseCalc?: number
	LevelStateCalc?: number
	StockPriceCalc?: number
	DocumentTypeCalc?: string
	CostPriceUMCalc?: number
	SuperiorDocumentCalc?: string
	Confirmation2DateCalc?: Date
	DocumentIdentificationCalc?: string
	DescriptionCalc?: string
	BookAbbrCalc?: string
	ItemRIDCalc?: number
	InitialStateQuantityCalc?: number
	InitialStateAmountCalc?: number
	ReceiptTurnoverCalc?: number
	ReleaseTurnoverCalc?: number
	PositiveBalanceCalc?: number
	NegativeBalanceCalc?: number
	EndStateLevelQuantityCalc?: number
	EndStateLevelAmountCalc?: number
	RecordModeCalc?: number
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TWarehouseDM)
	TransferToWarehouseId?: TWarehouseDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeFromHeaderRIDCalc?: TContractCodeDocumentDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartner1IdCalc?: TTradingPartnerDM
	@Type(() => TJobCardDM)
	JCRIDCalc?: TJobCardDM
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRIDCalc?: TPurchaseOrderDM
	@Type(() => TReceiptCardDM)
	ReceiptCardRIDCalc?: TReceiptCardDM
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRIDCalc?: TReleaseNoteDM
	@Type(() => TTransferNoteDM)
	TransferNoteRIDCalc?: TTransferNoteDM
	@Type(() => TJobCardDM)
	JobCardRIDCalc?: TJobCardDM
	@Type(() => TJobCardDM)
	JobCardCalc?: TJobCardDM
}