import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import Urgency from './Urgency'
import Competency from './Competency'
import TPartnerDM from './TPartnerDM'
import MrktWinningProduct from './MrktWinningProduct'
import PartnerOrigin from './PartnerOrigin'
import OpportAndActiStatus from './OpportAndActiStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import OfferType from './OfferType'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TQuotationDM from './TQuotationDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TQuotationRequestedItemAloneDM from './TQuotationRequestedItemAloneDM'
import TQuotationOfferedItemAloneDM from './TQuotationOfferedItemAloneDM'
import TReqForQuoRequestedItemAloneDM from './TReqForQuoRequestedItemAloneDM'
import TReqForQuoOfferedItemAloneDM from './TReqForQuoOfferedItemAloneDM'
import TOpportunityStateReasonDM from './TOpportunityStateReasonDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TOpportunityDM extends Data {
	public static className = 'TOpportunityDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Number?: number
	RID?: number
	TimeStamp?: string
	SubjectId?: number
	TopActivityId?: number
	ParentOpportunityId?: number
	CostPrice?: number
	ConfirmedOn?: Date
	FormatId?: number
	RightGroupId?: number
	Volume?: number
	ExecutionDate?: Date
	ExecutionProbability?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	DateTo?: Date
	DateFrom?: Date
	Description?: string
	Abbr?: string
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	CommentActivityTreeCalc?: string
	ActivityCountCalc?: number
	ActualQuotationRIDCalc?: number
	ActualRequestForQuotationRIDCalc?: number
	ProbabilityTrendCalc?: number
	ProbabilityTrendImgCalc?: string
	VolumeTrendCalc?: number
	VolumeTrendImgCalc?: string
	QualityCalc?: number
	OpportunityValueCalc?: number
	PlainCommentMarketingCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => Urgency)
	Quality2RID?: Urgency
	@Type(() => Competency)
	Quality1RID?: Competency
	@Type(() => TPartnerDM)
	WinningCompanyId?: TPartnerDM
	@Type(() => MrktWinningProduct)
	WinningProductId?: MrktWinningProduct
	@Type(() => PartnerOrigin)
	Origin?: PartnerOrigin
	@Type(() => OpportAndActiStatus)
	StatusId?: OpportAndActiStatus
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => OfferType)
	TypeId?: OfferType
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	FinishMethodIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TQuotationDM)
	QuotationChild?: TQuotationDM[]
	@Type(() => TRequestForQuotationDM)
	RequestForQuotationChild?: TRequestForQuotationDM[]
	@Type(() => TQuotationRequestedItemAloneDM)
	QuotationRequestedItemChild?: TQuotationRequestedItemAloneDM[]
	@Type(() => TQuotationOfferedItemAloneDM)
	QuotationItemOfferChild?: TQuotationOfferedItemAloneDM[]
	@Type(() => TReqForQuoRequestedItemAloneDM)
	ActualRequestForQuotReqItemChild?: TReqForQuoRequestedItemAloneDM[]
	@Type(() => TReqForQuoOfferedItemAloneDM)
	ActualRequestForQuotOfferItemChild?: TReqForQuoOfferedItemAloneDM[]
	@Type(() => TOpportunityStateReasonDM)
	OpportunityStateReasonChild?: TOpportunityStateReasonDM[]
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}