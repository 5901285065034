import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAddressDM from './TAddressDM'

export default class TEshopRegistrationAddressDM extends Data {
	public static className = 'TEshopRegistrationAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	TaxNumber?: string
	Description?: string
	AddressName?: string
	AddressRID?: number
	VATRegNumber?: string
	CompanyRegNumber?: string
	Email?: string
	Phone?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTextDM)
	SourceId?: TTextDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
}