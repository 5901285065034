import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'

export default class TPriceListValidityContractCodeDM extends Data {
	public static className = 'TPriceListValidityContractCodeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	RelationTypeId?: number
	HeaderRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TContractCodeDocumentDM)
	RelationValue?: TContractCodeDocumentDM
}