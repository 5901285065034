import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TCommentDM from './TCommentDM'
import TLoyaltyProgramItemHistoryDM from './TLoyaltyProgramItemHistoryDM'

export default class TLoyaltyProgramItemDM extends Data {
	public static className = 'TLoyaltyProgramItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PurchaseDate?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	RemainingPoints?: number
	PointsCount?: number
	CollectorType?: number
	CollectorId?: number
	LoyaltyProgramId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CollectorNameCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TInvoiceOutDM)
	DocumentId?: TInvoiceOutDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLoyaltyProgramItemHistoryDM)
	ItemHistoryChild?: TLoyaltyProgramItemHistoryDM[]
}