import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TTownDM from './TTownDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TAddressDM from './TAddressDM'

export default class TRelatedAddressDM extends Data {
	public static className = 'TRelatedAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AddressDataModuleExtId?: number
	AddressDataModuleRID?: number
	HeaderRID?: number
	RID?: number
	AddressDataModuleNumber?: number
	HeaderDataModuleNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	PickUpPoint?: string
	Comment?: string
	Fax?: string
	Email?: string
	Phone?: string
	TownCode?: string
	Longitude?: number
	Latitude?: number
	Street?: string
	NameText?: string
	OrientationNumber?: string
	HouseNumber?: string
	AddressId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	SimpleAddressBindRIDCalc?: number
	VerificationDateCalc?: Date
	SimpleAddressPhoneCalc?: string
	SimpleAddressEmailCalc?: string
	SimpleAddressFaxCalc?: string
	SimpleAddressCommentCalc?: string
	CoordinatesCalc?: string
	ImplicitIdCalc?: number
	SimpleAddressPickUpPointCalc?: string
	@Type(() => TTextDM)
	AddressTypeId?: TTextDM
	@Type(() => TTownDM)
	TownId?: TTownDM
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TTownPartDM)
	TownPartId?: TTownPartDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TAddressDM)
	AddressIdCalc?: TAddressDM
}