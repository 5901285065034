import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWorkplaceDM from './TWorkplaceDM'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TWorkshiftDM from './TWorkshiftDM'
import TJobCardDM from './TJobCardDM'

export default class TJobCardScheduleDM extends Data {
	public static className = 'TJobCardScheduleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	JobCardBindRID?: number
	Member?: string
	SequenceNumber?: number
	IsManuallyAdjustedSchedule?: string
	Duration?: number
	Quantity?: number
	CalendarId?: number
	IsArchived?: string
	Description?: string
	WorkshiftDate?: Date
	TimeFrom?: Date
	Date?: Date
	JobCardResourceRID?: number
	JobCardOperationRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PlannerMessageCalc?: string
	DocumentCalc?: string
	DateFromCalc?: Date
	DateToCalc?: Date
	DurationCalc?: number
	PlannedTimeToCalc?: Date
	ChangeTimeQuantityCalc?: number
	@Type(() => TWorkplaceDM)
	WorkPlaceRID?: TWorkplaceDM
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TWorkshiftDM)
	WorkshiftRID?: TWorkshiftDM
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
}