import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import esIdGoodsGroup from './esIdGoodsGroup'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'
import TArticleDM from './TArticleDM'

export default class TEshopCategoryArticleGroupDM extends Data {
	public static className = 'TEshopCategoryArticleGroupDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	ArticleId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => esIdGoodsGroup)
	ArticleGroupId?: esIdGoodsGroup
	@Type(() => TEshopCategoryInfoDM)
	CategoryRID?: TEshopCategoryInfoDM
	@Type(() => TArticleDM)
	ArticleChild?: TArticleDM[]
}