import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import Code3Status from './Code3Status'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemAnalyticalDimensionDM from './TSalesItemAnalyticalDimensionDM'
import TPurchaseItemAnalyticalDimensionDM from './TPurchaseItemAnalyticalDimensionDM'
import TPaymentDocumentItemAnalyticalDimensionDM from './TPaymentDocumentItemAnalyticalDimensionDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TAccountingJournalItemAnalyticalDimensionDM from './TAccountingJournalItemAnalyticalDimensionDM'
import TAssetMovementAnalyticalDimensionDM from './TAssetMovementAnalyticalDimensionDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TCode3DM extends Data {
	public static className = 'TCode3DM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	NotLoadItemsAutomatically?: string
	NotEvaluateAutomatically?: string
	NotInsertIntoDocument?: string
	IsBlockedRecord?: string
	SequenceNumber?: number
	Text?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	LanguageTextCalc?: string
	ShowItemCalc?: number
	SummationEvaluationField1Calc?: number
	SummationEvaluationField2Calc?: number
	SummationEvaluationField3Calc?: number
	SummationEvaluationField4Calc?: number
	SummationEvaluationField5Calc?: number
	SummationEvaluationField6Calc?: number
	EvaluationConfigCalc?: string
	UseAnalyticalDimensionCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => Code3Status)
	StatusId?: Code3Status
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSalesItemAnalyticalDimensionDM)
	SalesItemChild?: TSalesItemAnalyticalDimensionDM[]
	@Type(() => TPurchaseItemAnalyticalDimensionDM)
	PurchaseItemChild?: TPurchaseItemAnalyticalDimensionDM[]
	@Type(() => TPaymentDocumentItemAnalyticalDimensionDM)
	FinanceItemChild?: TPaymentDocumentItemAnalyticalDimensionDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TAccountingJournalItemAnalyticalDimensionDM)
	AccountingJournaItemBookChild?: TAccountingJournalItemAnalyticalDimensionDM[]
	@Type(() => TAssetMovementAnalyticalDimensionDM)
	AssetMovementChild?: TAssetMovementAnalyticalDimensionDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}