import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TTextDM from './TTextDM'
import ActivityStatus from './ActivityStatus'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TMarketingSpecificationDM from './TMarketingSpecificationDM'
import OpportAndActiStatus from './OpportAndActiStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TOpportunityDM from './TOpportunityDM'
import TActivityDM from './TActivityDM'
import TCampaignDM from './TCampaignDM'
import TPartnerDM from './TPartnerDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TMarketingActivityItemP2PDM from './TMarketingActivityItemP2PDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TActivityTreeDM extends Data {
	public static className = 'TActivityTreeDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	MessageId?: string
	IsGlobalFolder?: number
	TimeStamp?: string
	DocumentNumber?: number
	DocumentBookId?: string
	CallCenterCallDate?: Date
	CallCenterId?: number
	OriginalActivityId?: number
	Subject?: number
	CostPrice?: number
	ConfirmedOn?: Date
	FormatId?: number
	RightGroupId?: number
	ExecutionDate?: Date
	ExecutionProbability?: number
	Volume?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	DateTo?: Date
	DateFrom?: Date
	Name?: string
	Id?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	ExecutionPropabilitySCalc?: number
	SuperiorActivityRealizDateCalc?: Date
	SuperiorActivityVolumeCalc?: number
	MonitorCalc?: string
	HideCalc?: string
	IsCommentCalc?: string
	CommentActivityTreeCalc?: string
	ActivityCountCalc?: number
	LanguageCommentCalc?: string
	CommentCalc?: string
	DateFromCalc?: Date
	StartTimeCalc?: Date
	DateToCalc?: Date
	EndTimeCalc?: Date
	TimeSpanCalc?: Date
	CallHistoryIdCalc?: number
	IsJobExistBitCalc?: number
	JobExistImgCalc?: string
	MultiActivityImgCalc?: string
	DocumentIdentificationCalc?: string
	GlobalFolderIdCalc?: number
	ListP2PCountCalc?: number
	PartnerAsStringCountCalc?: string
	PersonAsStringCountCalc?: string
	ShowActivityTreeCalc?: string
	NodeNameCalc?: string
	SubActivityCountCalc?: number
	ActivityValueCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TTextDM)
	RatingId?: TTextDM
	@Type(() => ActivityStatus)
	StatusActivityId?: ActivityStatus
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRID?: TMarketingActivityTypeDM
	@Type(() => TMarketingSpecificationDM)
	SpecificationRID?: TMarketingSpecificationDM
	@Type(() => OpportAndActiStatus)
	OpportunityStatusId?: OpportAndActiStatus
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM
	@Type(() => TActivityDM)
	SuperiorActivityId?: TActivityDM
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => OpportAndActiStatus)
	AcquisitionIdCalc?: OpportAndActiStatus
	@Type(() => TTextDM)
	ConfirmedBitCalc?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonRIDCalc?: TContactPersonDM
	@Type(() => TPartnerDM)
	PartnerRIDCalc?: TPartnerDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TLanguageMemoTranslationDM)
	CommentMarketChild?: TLanguageMemoTranslationDM[]
	@Type(() => TMarketingActivityItemP2PDM)
	ContactPersonsPartnerItemChild?: TMarketingActivityItemP2PDM[]
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}