import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEmployedPersonDM from './TEmployedPersonDM'

export default class TSickLeaveDM extends Data {
	public static className = 'TSickLeaveDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PreviousEmplymentRID?: number
	RID?: number
	NewField?: string
	Description?: string
	DayCount?: number
	DateTo?: Date
	DateFrom?: Date
	SequenceNumber?: number
	PreviousEmploymentId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DescriptionCalc?: string
	ShortDescriptionCalc?: string
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
}