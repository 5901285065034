import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ProjectStatus from './ProjectStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TContractCodeDM from './TContractCodeDM'
import TSalesItemAnalyticalDimensionDM from './TSalesItemAnalyticalDimensionDM'
import TPurchaseItemAnalyticalDimensionDM from './TPurchaseItemAnalyticalDimensionDM'
import TPaymentDocumentItemAnalyticalDimensionDM from './TPaymentDocumentItemAnalyticalDimensionDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TSummationContractCodeDM extends Data {
	public static className = 'TSummationContractCodeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	ConfirmationDate?: Date
	TimeStamp?: string
	Text?: string
	RighGroupId?: number
	RID?: number
	Description?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	LanguageTextCalc?: string
	ConfirmedRecordImgCalc?: string
	SummationEvaluationField1Calc?: number
	SummationEvaluationField2Calc?: number
	SummationEvaluationField3Calc?: number
	SummationEvaluationField4Calc?: number
	SummationEvaluationField5Calc?: number
	SummationEvaluationField6Calc?: number
	EvaluationConfigCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => ProjectStatus)
	StatusId?: ProjectStatus
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TContractCodeDM)
	ContractCodeChild?: TContractCodeDM[]
	@Type(() => TSalesItemAnalyticalDimensionDM)
	SalesItemChild?: TSalesItemAnalyticalDimensionDM[]
	@Type(() => TPurchaseItemAnalyticalDimensionDM)
	PurchaseItemChild?: TPurchaseItemAnalyticalDimensionDM[]
	@Type(() => TPaymentDocumentItemAnalyticalDimensionDM)
	FinanceItemChild?: TPaymentDocumentItemAnalyticalDimensionDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}