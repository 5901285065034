import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRightUserRoleDM from './TRightUserRoleDM'

export default class TUserRoleRightDM extends Data {
	public static className = 'TUserRoleRightDM'
	public static primaryKey = 'UserRightId'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.UserRightId!
	}

	UserRightId?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	RightNumberCalc?: number
	CaptionCalc?: string
	ModuleNameCalc?: string
	FunctionalityCalc?: string
	@Type(() => TRightUserRoleDM)
	UserRoleChild?: TRightUserRoleDM[]
}