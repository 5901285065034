import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCodeTypeDM from './TCodeTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class AlternateStockText extends Data {
	public static className = 'AlternateStockText'
	public static primaryKey = 'CodeTypeId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.CodeTypeId!
	}

	TimeStamp?: string
	TypeId?: number
	RightGroupId?: number
	PropertyId?: number
	ChangedOn?: Date
	Description?: string
	CodeTypeId?: number
	Code?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageCodeCalc?: string
	LanguageDescriptionCalc?: string
	NotifyBitCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TCodeTypeDM)
	OwnerId?: TCodeTypeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	CurrentTypeRIDCalc?: TTextDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageCodeChild?: TLanguageTextTranslationDM[]
}