import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import DegreeName from './DegreeName'
import CZNACE from './CZNACE'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOrgStrJobDM from './TOrgStrJobDM'
import TPrDeductionDM from './TPrDeductionDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TPrCalendarDM from './TPrCalendarDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingChartDM from './TAccountingChartDM'
import DuvUkoncPP2 from './DuvUkoncPP2'
import TCountryDM from './TCountryDM'
import SkupPrac from './SkupPrac'
import MzdZUJ from './MzdZUJ'
import CZICSE from './CZICSE'
import TTownPartDM from './TTownPartDM'
import ProfessionName from './ProfessionName'
import MzdLAU1 from './MzdLAU1'
import NUTS4 from './NUTS4'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import Group2 from './Group2'
import Group1 from './Group1'
import Kategorie2 from './Kategorie2'
import Kategorie1 from './Kategorie1'
import TPrUnionDM from './TPrUnionDM'
import TPrMinimalTariffDM from './TPrMinimalTariffDM'
import TPrInternalTariffDM from './TPrInternalTariffDM'
import EmployeeFunction from './EmployeeFunction'
import DruhCinnosti from './DruhCinnosti'
import RelWork from './RelWork'
import TypWork from './TypWork'
import DuvUkoncPP1 from './DuvUkoncPP1'
import DuvVznikuPP1 from './DuvVznikuPP1'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TPrTransferComponentDM from './TPrTransferComponentDM'
import TPrDefinitionComponentDM from './TPrDefinitionComponentDM'
import TPrInputComponentDM from './TPrInputComponentDM'
import TPrOutputComponentDM from './TPrOutputComponentDM'
import TPrHistoryComponentDM from './TPrHistoryComponentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TPrCalendarEmploymentItemTimeDM from './TPrCalendarEmploymentItemTimeDM'
import TPrCalendarEmploymentItemDayDM from './TPrCalendarEmploymentItemDayDM'
import TPrCalendarEmploymentItemVisualDM from './TPrCalendarEmploymentItemVisualDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrEmployeeDocumentDM extends Data {
	public static className = 'TPrEmployeeDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	NotCopyAnalyticalDimensions?: string
	FirstName?: string
	Surname?: string
	EmployeePersonNumber?: string
	ConfirmationLogId?: number
	CreationPrComponentLogId?: number
	CalculationLogId?: number
	PrecalculationLogId?: number
	IsShortRangeEmployment?: string
	IsExecutive?: string
	IsImmediateStart?: string
	IsExemption?: string
	IsInCurrency?: string
	IsNoPaidOverTime?: string
	IsAdditionalLeave?: string
	IsExtendedLeave?: string
	IsNormalLeave?: string
	CalculateWageAutomatically?: string
	CheckMinWage?: string
	XMLFieldScript?: string
	Number?: number
	IsFirstEmploymentInCZ?: string
	ChangeContractDate?: Date
	SignatureDate?: Date
	ExchangeRate?: number
	ConfirmedOn?: Date
	PrComponentCreatedOn?: Date
	CalculatedOn?: Date
	PrecalculatedOn?: Date
	ProbationaryPeriodMonthCount?: number
	WorkPlaceId?: string
	ExpectedTerminationDate?: Date
	NoticePeriodFromDate?: Date
	ProbationaryPeriodEndDate?: Date
	RedundancyPaymentMultiple?: number
	RighGroupId?: number
	ChangedOn?: Date
	Automat4PrComponentId?: number
	Automat3PrComponentId?: number
	Automat2PrComponentId?: number
	Automat1PrComponentId?: number
	AdvanceWagePayment?: number
	WageBonus?: number
	WagePersonalBonus2?: number
	WagePersonalBonus?: number
	BasicWage2?: number
	BasicWage?: number
	ContractDate?: Date
	DateTo?: Date
	DateFrom?: Date
	SequenceNumber?: number
	TimeStamp?: string
	XMLField?: string
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	IsItemCompleteCalc?: string
	ProbationaryPeriodInDaysCalc?: number
	EmploymentDayCountCalc?: number
	IsMainEmploymentCalc?: string
	LegalRelationDescriptionCalc?: string
	PreviousPeriodCloseIdCalc?: number
	PrecalculationPassedIdCalc?: number
	FirstPrecalculationDoneIdCalc?: number
	EditAfterPrecalculationIdCalc?: number
	PrecalculationConfirmationIdCalc?: number
	AdvanceGeneratePassedIdCalc?: number
	GenerateAdvanceConfirmIdCalc?: number
	ComputeBitCalc?: number
	EditAfterCalculationIdCalc?: number
	PeriodIsClosedBitCalc?: number
	NewPrDocumentBitCalc?: number
	PTFEmplPerWorkTimeFundDaysCalc?: number
	PTFEmplPerPublicHlDaysCalc?: number
	PTFEmploymentPeriodTimeCalc?: number
	CalendarDayCountCalc?: number
	CalendarContractDayCountCalc?: number
	PTFTotalPerWrkTimeFundDaysCalc?: number
	PTFTotalPeriodPublicHlDaysCalc?: number
	PTFTotalPeriodTimeCalc?: number
	EmployeeChildrenCalc?: number
	EmployeeChildrenDeductionCalc?: number
	EmployeeChildrenDisabilityCalc?: number
	UnionsPrComponentCalc?: number
	SchoolCalc?: string
	AverageWageRefundsAmountCalc?: number
	ClaimNormHlLastYearAmountCalc?: number
	TakenNormHlLastYearAmountCalc?: number
	ClaimNormHlActYearAmountCalc?: number
	TakenNormHolActYearAmountCalc?: number
	ClaimAddHlActYearAmountCalc?: number
	TakenAddHlActYearAmountCalc?: number
	RemHlPrevYearAmountCalc?: number
	RemHlActYearAmountCalc?: number
	WorkedDaysCalc?: number
	WageAmountGrossCalc?: number
	WageAmountNetCalc?: number
	IncomeNetCalc?: number
	WageAmountCalc?: number
	HIAssessmentBaseWageAmountCalc?: number
	HIWageEmployeeInsuranceAmountCalc?: number
	HIAssessmentBaseMinWageAmountCalc?: number
	HIEmployInsUnpaidHolAmountCalc?: number
	HIAddPaymentToMinAmountCalc?: number
	HIEmployeeInsuranceAmountCalc?: number
	HIOrganizationAmountCalc?: number
	HITotalPaymentAmountCalc?: number
	AssessmentBaseAmountCalc?: number
	EmployeeInsuranceAmountCalc?: number
	SocialOrgPremiumAmountCalc?: number
	SocialTotalPaymentAmountCalc?: number
	TaxTotatIncomesAmountCalc?: number
	TaxInsuranceAmountCalc?: number
	AdvanceTaxBaseAmountCalc?: number
	AdvanceTaxAmountCalc?: number
	TaxPayerDeductionCalc?: number
	TaxDisabilityDiscountCalc?: number
	TaxStudentAllowanceCalc?: number
	AdvanceTaxAfterDiscountsCalc?: number
	TaxClaimCalc?: number
	TaxAbatementCalc?: number
	TaxBonusCalc?: number
	ResultingTaxAdvanceCalc?: number
	TaxDeductionCalc?: number
	TaxAdvanceCorrectionCalc?: number
	TaxDeductionCorrectionCalc?: number
	TaxBonusCorrectionCalc?: number
	AchieAssessBaseSocAmountCalc?: number
	AchieAssessBaseHlthAmountCalc?: number
	AchievContribPensionAmountCalc?: number
	ClaimExtHlLastYearAmountCalc?: number
	TakenExtHolLastYearAmountCalc?: number
	ClaimExtHlActYearAmountCalc?: number
	TakenExtHlActYearAmountCalc?: number
	ReductionOrdinaryHlBalanceCalc?: number
	ReductionAddHlBalanceCalc?: number
	ReductionExtendedHlBalanceCalc?: number
	RemNormHlActYearAmountCalc?: number
	RemAddHlActYearAmountCalc?: number
	RemExtHlActYearAmountCalc?: number
	RemNormHlPrevYearAmountCalc?: number
	RemExtHlPrevYearAmountCalc?: number
	RemTotalHlClaimAmountCalc?: number
	RemTotalHlTakingAmountCalc?: number
	RemTotalHlReductionsAmountCalc?: number
	RemTotalHlRemainingAmountCalc?: number
	WorkedHoursCalc?: number
	PTFEmplPerWrkTimeFundHoursCalc?: number
	PTFTotalPerWorkTimeFuHoursCalc?: number
	HIAssessmentBaseAmountCalc?: number
	HIAssessBaseAddPaymToMinAmountCalc?: number
	HIAssessBsUnpaidHolAmountCalc?: number
	HIAssessBaseUnexAbsAmountCalc?: number
	HealthEmpUnpaidHlAmountCalc?: number
	HIUnexcusedAbsenceAmountCalc?: number
	AdvanceTaxTotalAmountCalc?: number
	AverageIncomeGrossCalc?: number
	AverageMonthlyEarningsNetCalc?: number
	UnworkedShortHlDaysAmountCalc?: number
	BeginningYearOvertimeHoursCalc?: number
	AgreedWorkloadWeekCalc?: number
	AgreedWorkloadDayCalc?: number
	StatedWorkloadWeekCalc?: number
	StatedWorkloadDayCalc?: number
	TaxTotalDeductIncomesAmountCalc?: number
	TaxDeductionInsuranceAmountCalc?: number
	TaxDeductionBaseAmountCalc?: number
	TaxAfterDiscountDifferenceCalc?: number
	TaxBonusDifferenceCalc?: number
	TotalCompensationCalc?: number
	ActualAssessBaseSocialAmountCalc?: number
	ActualAssessBaseHealthAmountCalc?: number
	HlAssessmentBaseAmountCalc?: number
	HlExcludedDaysAmountCalc?: number
	PensInsAssessBasisAmountCalc?: number
	PensionInsurExcDaysAmountCalc?: number
	InputValueForAverageHourCalc?: number
	InputValueForAverageAmountCalc?: number
	NotDiscardedOvertimeHoursCalc?: number
	ExchangeRateCalc?: number
	WageAmountGrossClientCCalc?: number
	WageAmountNetClientCCalc?: number
	IncomeNetClientCCalc?: number
	WagePaidAmountClientCCalc?: number
	FundHourDifferenceCalc?: number
	TotalPlannedShiftHoursCalc?: number
	PlannedHolidayHourCountCalc?: number
	TotalPlannedShiftDaysCalc?: number
	PlannedHolidayDayCountCalc?: number
	TotalActualShiftHoursCalc?: number
	ActualHolidayHourCountCalc?: number
	TotalActualShiftDaysCalc?: number
	ActualHolidayDayCountCalc?: number
	DistraintsIncomeNetCalc?: number
	CompClaiNormHlLastYearAmountCalc?: number
	RemNormHlPrevYearCompAmountCalc?: number
	ComTakenNrmHlLastYearAmountCalc?: number
	CompClaiNormHlActYearAmountCalc?: number
	RemNormHlActYearCompAmountCalc?: number
	CompReductOrdinaryHlBalanceCalc?: number
	CompTakenNormHlActYearAmountCalc?: number
	CompClaimAddHlActYearAmountCalc?: number
	RemAddHlActYearCompAmountCalc?: number
	CompReductAddHlBalanceCalc?: number
	CompTakenAddHlActYearAmountCalc?: number
	CompClaimExtHlActYearAmountCalc?: number
	RemExtHlActYearCompAmountCalc?: number
	CompReductExtHlBalanceCalc?: number
	ComTakenExtHlActYearAmountCalc?: number
	CompClaiExtHlLastYearAmountCalc?: number
	RemExtHlPrevYearCompAmountCalc?: number
	CompTakenExtHlLastYearAmountCalc?: number
	RemTotalHlRemCompAmountCalc?: number
	AverageEvidenceNumberCalc?: number
	AverageRecalcEvidenceNumberCalc?: number
	WorkHoursStateCalc?: number
	DaysForEvidenceNumberCalc?: number
	ExamptionDaysCalc?: number
	ShortenedWorkTimeCalc?: string
	OvertimeHoursCalc?: number
	TotalHourGroupIni16Calc?: number
	CalendarDayCalc?: string
	PTFHoursSetCalc?: number
	SickDaysClaimCalc?: number
	SickDaysUsedCalc?: number
	SickDaysBalanceCalc?: number
	DependentPersonCountCalc?: number
	SickDaysBalanceComputeCalc?: number
	IsHolidayPerCalendarYearCalc?: string
	OtherIncomesCalc?: number
	DifficultEnvironmentCalc?: string
	DayWorkedInDifficultEnvCalc?: number
	CompWorkInDifficultEnvDaysCalc?: number
	AllowSickDaysCalc?: string
	ShortenedWorkTimePctCalc?: number
	ShortenedWorkTimeRatioCalc?: number
	AgreementHourCalc?: number
	IsLastDayInEvidenceNumberCalc?: string
	CompNotDiscardOvertimeHoursCalc?: number
	CompBeginYearOvertimeHoursCalc?: number
	WTFHourDifferenceCalc?: number
	AssessmentBaseReductedAmountCalc?: number
	OrgPaymentsReductedAmountCalc?: number
	AssessmentBaseReductedCalc?: string
	EmploymentValidityOFBitCalc?: number
	DoTaxStatementBitCalc?: number
	PrComponentCreatedBitCalc?: number
	NotReachedIncomeHeaInsBitCalc?: number
	NotReachedPenInsuranceBitCalc?: number
	HasAverageForWorkingTimeAccountCalc?: number
	OverlapIncomeBitCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	IsChangedCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	WorkedHoursForHolidayAmountCalc?: number
	AltTimeFullCompHourHolidayAmountCalc?: number
	AltTimePartCompHourHolidayAmountCalc?: number
	WorkedHoursForHolidayCompAmountCalc?: number
	AltTimeFullCompHourHolidayCompAmountCalc?: number
	AltTimePartCompHourHolidayCompAmountCalc?: number
	AverageAgreedWeeklyTimeCalc?: number
	WorkedHoursForAddHolidayAmountCalc?: number
	WorkedHoursForAddHolidayCompAmountCalc?: number
	IsDifferentHolidayUnitCalc?: string
	RemNormHlActYearCompDaysAmountCalc?: number
	RemExtHlActYearCompDaysAmountCalc?: number
	RemAddHlActYearCompDaysAmountCalc?: number
	RemNormHlPrevYearCompDaysAmountCalc?: number
	RemExtHlPrevYearCompDaysAmountCalc?: number
	RemTotalHlRemCompDaysAmountCalc?: number
	RemTotalHlClaimCompAmountCalc?: number
	RemTotalHlTakingCompAmountCalc?: number
	RemTotalHlReductionsCompAmountCalc?: number
	TillClaimNormHlActYearCalc?: number
	TillClaimExtHlActYearCalc?: number
	TillClaimAddHlActYearCalc?: number
	IsPartCompHourConditionCalc?: string
	PartCompHourMaxCalc?: number
	IdPartCompHourMaxReachedCalc?: string
	PartCompHourConditionCalc?: number
	IsClaimHlConditionCalc?: string
	CountedHoursHlCalc?: number
	CountedWeeksHlCalc?: number
	CountedHoursAddHlCalc?: number
	CountedWeeksAddHlCalc?: number
	HasHoliday2020Calc?: string
	ClaimHoliday2020Calc?: number
	TakenHoliday2020Calc?: number
	RemHoliday2020Calc?: number
	CompClaimHoliday2020Calc?: number
	CompTakenHoliday2020Calc?: number
	CompRemHoliday2020Calc?: number
	RemNormHlActYearDaysAmountCalc?: number
	RemExtHlActYearDaysAmountCalc?: number
	RemAddHlActYearDaysAmountCalc?: number
	RemNormHlPrevYearDaysAmountCalc?: number
	RemExtHlPrevYearDaysAmountCalc?: number
	RemTotalHlRemDaysAmountCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	RedundancyPaymentParagraphId?: TTextDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => DegreeName)
	RequiredEducationLevelId?: DegreeName
	@Type(() => CZNACE)
	CZNACEId?: CZNACE
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOrgStrJobDM)
	OrgStrJobRID?: TOrgStrJobDM
	@Type(() => TPrDeductionDM)
	WageDeductionId?: TPrDeductionDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TPrDeductionDM)
	TaxOfficeId?: TPrDeductionDM
	@Type(() => TPrCalendarDM)
	WorkFundCalendarId?: TPrCalendarDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	PrComponentCreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CalculatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	PrecalculatedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	HealthInsuranceAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	SocialSecurityAccountId?: TAccountingChartDM
	@Type(() => DuvUkoncPP2)
	AditionalTerminationReasonId?: DuvUkoncPP2
	@Type(() => TCountryDM)
	WorkCountryId?: TCountryDM
	@Type(() => SkupPrac)
	GroupId?: SkupPrac
	@Type(() => MzdZUJ)
	AdministrativeDivisionId?: MzdZUJ
	@Type(() => CZICSE)
	CZICSEId?: CZICSE
	@Type(() => TTownPartDM)
	WorkTownPartId?: TTownPartDM
	@Type(() => TCostCentreCollectionDocumentDM)
	PayrollCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => ProfessionName)
	CZISCOId?: ProfessionName
	@Type(() => MzdLAU1)
	LAU1Id?: MzdLAU1
	@Type(() => NUTS4)
	NUTS4Id?: NUTS4
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => Group2)
	Group2Id?: Group2
	@Type(() => Group1)
	Group1Id?: Group1
	@Type(() => Kategorie2)
	Category2Id?: Kategorie2
	@Type(() => Kategorie1)
	Category1Id?: Kategorie1
	@Type(() => TTextDM)
	SocialActivityTypeId?: TTextDM
	@Type(() => TTextDM)
	TaxDeductionTypeId?: TTextDM
	@Type(() => TTextDM)
	DeductionSocialSecurityId?: TTextDM
	@Type(() => TTextDM)
	DeductionHealthInsuranceId?: TTextDM
	@Type(() => TTextDM)
	TaxPayerTypeId?: TTextDM
	@Type(() => TPrUnionDM)
	UnionId?: TPrUnionDM
	@Type(() => TTextDM)
	WageTypeId?: TTextDM
	@Type(() => TPrMinimalTariffDM)
	GuaranteedWage?: TPrMinimalTariffDM
	@Type(() => TPrInternalTariffDM)
	InternalTariffId?: TPrInternalTariffDM
	@Type(() => TPrCalendarDM)
	LegalCalendarId?: TPrCalendarDM
	@Type(() => TPrCalendarDM)
	WorkShiftCalendarId?: TPrCalendarDM
	@Type(() => EmployeeFunction)
	JobClassificationId?: EmployeeFunction
	@Type(() => TTextDM)
	DurationTypeId?: TTextDM
	@Type(() => DruhCinnosti)
	EmploymentTypeId?: DruhCinnosti
	@Type(() => TTextDM)
	LegalRelationId?: TTextDM
	@Type(() => RelWork)
	LegalSubrelation2Id?: RelWork
	@Type(() => TTextDM)
	LegalSubrelation1Id?: TTextDM
	@Type(() => TypWork)
	WorkTypeId?: TypWork
	@Type(() => DuvUkoncPP1)
	TerminationReasonId?: DuvUkoncPP1
	@Type(() => DuvVznikuPP1)
	CommencementReasonId?: DuvVznikuPP1
	@Type(() => TTextDM)
	EmploymentValidity?: TTextDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedPeriodIdCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	AdvanceGenerateStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	PrecalculationStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	ComputeStateIdCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	DisabilityTypeIdCalc?: TTextDM
	@Type(() => TPrDeductionDM)
	HealthInsuranceCompanyIdCalc?: TPrDeductionDM
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM
	@Type(() => TTextDM)
	EditTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	CurrentYearHolidayUnitIdCalc?: TTextDM
	@Type(() => TTextDM)
	PreviousYearHolidayUnitIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrTransferComponentDM)
	CalculationPrComPeriodChild?: TPrTransferComponentDM[]
	@Type(() => TPrTransferComponentDM)
	CalculationPrComPerEmpChild?: TPrTransferComponentDM[]
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanentCompleteChild?: TPrDefinitionComponentDM[]
	@Type(() => TPrDefinitionComponentDM)
	PrComPermanEmpCompleteChild?: TPrDefinitionComponentDM[]
	@Type(() => TPrInputComponentDM)
	PrComponentInputCompleteChild?: TPrInputComponentDM[]
	@Type(() => TPrInputComponentDM)
	PrComInputEmpCompleteChild?: TPrInputComponentDM[]
	@Type(() => TPrOutputComponentDM)
	PrComponentOutputCompleteChild?: TPrOutputComponentDM[]
	@Type(() => TPrOutputComponentDM)
	PrComOutEmployeeCompleteChild?: TPrOutputComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryProcessingPerChild?: TPrHistoryComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistEmpProcessPeriodChild?: TPrHistoryComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryValidPeriodChild?: TPrHistoryComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistEmpValidPeriodChild?: TPrHistoryComponentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmploymItemTimeInputChild?: TPrCalendarEmploymentItemTimeDM[]
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmplItemTimeOutputChild?: TPrCalendarEmploymentItemTimeDM[]
	@Type(() => TPrCalendarEmploymentItemTimeDM)
	PrCalEmplItemTimeWorkingChild?: TPrCalendarEmploymentItemTimeDM[]
	@Type(() => TPrCalendarEmploymentItemDayDM)
	PrCalendarEmploymentItemDayChild?: TPrCalendarEmploymentItemDayDM[]
	@Type(() => TPrCalendarEmploymentItemVisualDM)
	PrCalEmploymItemFrgInputChild?: TPrCalendarEmploymentItemVisualDM[]
	@Type(() => TPrCalendarEmploymentItemVisualDM)
	PrCalendarEmplItemFrgOutChild?: TPrCalendarEmploymentItemVisualDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}