import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import ShopGroupId from './ShopGroupId'
import PartnerStatus from './PartnerStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TAddressDM from './TAddressDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import UserCustomValue from './UserCustomValue'
import TContactPersonDM from './TContactPersonDM'
import UserCodeType6 from './UserCodeType6'
import UserCodeType5 from './UserCodeType5'
import UserCodeType4 from './UserCodeType4'
import UserCodeType3 from './UserCodeType3'
import UserCodeType2 from './UserCodeType2'
import UserCodeType1 from './UserCodeType1'
import TK2UserLookupDM from './TK2UserLookupDM'
import Circuit from './Circuit'
import Care from './Care'
import Solvency from './Solvency'
import PartnerOrigin from './PartnerOrigin'
import FirmType from './FirmType'
import SatisfactionName from './SatisfactionName'
import AcquisitionName from './AcquisitionName'
import PriorityName from './PriorityName'
import PartnerClass from './PartnerClass'
import Domain from './Domain'
import AreaCode from './AreaCode'
import TPaymentMethodDM from './TPaymentMethodDM'
import TPriceGroupDM from './TPriceGroupDM'
import Code from './Code'
import CustomerGroup from './CustomerGroup'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import TCountryDM from './TCountryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TBookDM from './TBookDM'
import TCurrencyDM from './TCurrencyDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TPartnerBusinessLineDM from './TPartnerBusinessLineDM'
import TOpportunityDM from './TOpportunityDM'
import TQuotationDM from './TQuotationDM'
import TRequestForQuotationDM from './TRequestForQuotationDM'
import TCallHistoryDM from './TCallHistoryDM'
import TPaymentConditionTradingPartnerPurchaseDM from './TPaymentConditionTradingPartnerPurchaseDM'
import TPaymentConditionTradingPartnerSaleDM from './TPaymentConditionTradingPartnerSaleDM'
import TOpenItemListDM from './TOpenItemListDM'
import TElectronicAddressDM from './TElectronicAddressDM'
import TBankAccountTradingPartnerDM from './TBankAccountTradingPartnerDM'
import TRelatedAddressDM from './TRelatedAddressDM'
import TAdressKeyItemDM from './TAdressKeyItemDM'
import TPartnerContactPersonDM from './TPartnerContactPersonDM'
import TDepartmentElectronicAddressDM from './TDepartmentElectronicAddressDM'
import TMarketingSubsumptionDM from './TMarketingSubsumptionDM'
import TBranchDM from './TBranchDM'
import TPartnerJournalDM from './TPartnerJournalDM'
import TActivityTreeGroupedDM from './TActivityTreeGroupedDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkPartnerDM from './TCompanyLinkPartnerDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPartnerDM extends Data {
	public static className = 'TPartnerDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	DataBoxId?: string
	TaxNumber?: string
	RatingVIESKey?: string
	RatingViesDate?: Date
	RatingViesValue?: number
	RatingIsirDate?: Date
	RatingIsirValue?: string
	TimeStamp?: string
	RatingOctDate?: Date
	RatingOctValue?: number
	RatingDate?: Date
	RatingValue?: number
	SuperiorPartnerId?: number
	FormatId?: number
	Status2Value?: string
	StatusValue?: string
	User1Date?: Date
	UserString1?: string
	VerifedOn?: Date
	RightGroupId?: number
	CreatedOn?: Date
	ChangedOn?: Date
	MaxCredit?: number
	MinCredit?: number
	Penalty?: number
	MaturityDays?: number
	Comment?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	Name?: string
	Abbr2?: string
	Abbr1?: string
	Id?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	CommentActivityTreeCalc?: string
	ActivityCountCalc?: number
	VATPayerPurchaseCalc?: number
	ArticleRecordsBitCalc?: number
	CreditsEvidenceBitCalc?: number
	LinkedBitCalc?: number
	VATSaleCalc?: number
	ElectronicAddress1Calc?: string
	ElectronicAddress2Calc?: string
	ElectronicAddress3Calc?: string
	ElectronicAddress4Calc?: string
	ElectronicAddressCheckItemFieldCalc?: number
	ElectronicAddress1FormattedCalc?: string
	ElectronicAddress2FormattedCalc?: string
	ElectronicAddress3FormattedCalc?: string
	ElectronicAddress4FormattedCalc?: string
	IsRatingCreditcheckEnabledCalc?: string
	IsRatingIsirEnabledCalc?: string
	IsRatingViesEnabledCalc?: string
	IsRatingEnabledCalc?: string
	IsRegistryVerificationEnabledCalc?: string
	LimitedProcessingBitCalc?: number
	PhysicalPersonIdCalc?: number
	PlainCommentMarketingCalc?: string
	CentralInvoiceAddressBitCalc?: string
	CentralDeliveryAddressBitCalc?: string
	CentralPostalAddressBitCalc?: string
	NameAndAddressCalc?: string
	EAddressPARAMCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => ShopGroupId)
	EshopGroupId?: ShopGroupId
	@Type(() => PartnerStatus)
	StatusId?: PartnerStatus
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => UserCustomValue)
	UserAppellation1Id?: UserCustomValue
	@Type(() => TContactPersonDM)
	UserContactPerson2Id?: TContactPersonDM
	@Type(() => TContactPersonDM)
	UserContactPerson1Id?: TContactPersonDM
	@Type(() => UserCodeType6)
	UserCodeType6Id?: UserCodeType6
	@Type(() => UserCodeType5)
	UserCodeType5Id?: UserCodeType5
	@Type(() => UserCodeType4)
	UserCodeType4Id?: UserCodeType4
	@Type(() => UserCodeType3)
	UserCodeType3Id?: UserCodeType3
	@Type(() => UserCodeType2)
	UserCodeType2Id?: UserCodeType2
	@Type(() => UserCodeType1)
	UserCodeType1Id?: UserCodeType1
	@Type(() => TK2UserLookupDM)
	VerifedById?: TK2UserLookupDM
	@Type(() => Circuit)
	Volume?: Circuit
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => Care)
	CareId?: Care
	@Type(() => Solvency)
	SolvencyId?: Solvency
	@Type(() => PartnerOrigin)
	OriginId?: PartnerOrigin
	@Type(() => FirmType)
	CompanyTypeId?: FirmType
	@Type(() => SatisfactionName)
	SatisfactionId?: SatisfactionName
	@Type(() => AcquisitionName)
	AcquisitionId?: AcquisitionName
	@Type(() => PriorityName)
	PriorityId?: PriorityName
	@Type(() => PartnerClass)
	Classification?: PartnerClass
	@Type(() => Domain)
	Area?: Domain
	@Type(() => AreaCode)
	TerritoryId?: AreaCode
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => Code)
	Code?: Code
	@Type(() => CustomerGroup)
	GroupId?: CustomerGroup
	@Type(() => TTradingPartnerDM)
	SuperiorTradingPartnerId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress1TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress2TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress3TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddress4TypeIdCalc?: TElectronicAddressTypeDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry1IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry2IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry3IdCalc?: TCountryDM
	@Type(() => TCountryDM)
	ElectronicAddressCountry4IdCalc?: TCountryDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TBookDM)
	BankBookIdCalc?: TBookDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TPartnerBusinessLineDM)
	PartnerBusinessLineChild?: TPartnerBusinessLineDM[]
	@Type(() => TContactPersonDM)
	ContactPersonListChild?: TContactPersonDM[]
	@Type(() => TOpportunityDM)
	OpportunityChild?: TOpportunityDM[]
	@Type(() => TQuotationDM)
	QuotationChild?: TQuotationDM[]
	@Type(() => TRequestForQuotationDM)
	RequestForQuotationChild?: TRequestForQuotationDM[]
	@Type(() => TCallHistoryDM)
	CallHistoryChild?: TCallHistoryDM[]
	@Type(() => TPaymentConditionTradingPartnerPurchaseDM)
	PaymentConditionPurchaseChild?: TPaymentConditionTradingPartnerPurchaseDM[]
	@Type(() => TPaymentConditionTradingPartnerSaleDM)
	PaymentConditionSaleChild?: TPaymentConditionTradingPartnerSaleDM[]
	@Type(() => TOpenItemListDM)
	OpenItemListChild?: TOpenItemListDM[]
	@Type(() => TElectronicAddressDM)
	ElectronicAddressChild?: TElectronicAddressDM[]
	@Type(() => TBankAccountTradingPartnerDM)
	BankAccountChild?: TBankAccountTradingPartnerDM[]
	@Type(() => TRelatedAddressDM)
	AddressInvoiceChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressDeliveryChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressPostalChild?: TRelatedAddressDM[]
	@Type(() => TRelatedAddressDM)
	AddressAllChild?: TRelatedAddressDM[]
	@Type(() => TAdressKeyItemDM)
	AddressKeyChild?: TAdressKeyItemDM[]
	@Type(() => TPartnerContactPersonDM)
	ContactPersonChild?: TPartnerContactPersonDM[]
	@Type(() => TDepartmentElectronicAddressDM)
	DepartmentChild?: TDepartmentElectronicAddressDM[]
	@Type(() => TMarketingSubsumptionDM)
	MarketingInclusionChild?: TMarketingSubsumptionDM[]
	@Type(() => TBranchDM)
	BranchChild?: TBranchDM[]
	@Type(() => TPartnerJournalDM)
	JournalChild?: TPartnerJournalDM[]
	@Type(() => TActivityTreeGroupedDM)
	ActivityTreeGroupedChild?: TActivityTreeGroupedDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkPartnerDM)
	CompanyChild?: TCompanyLinkPartnerDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}