import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrPeriodDM from './TPrPeriodDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TPrOutputComponentDM from './TPrOutputComponentDM'
import TPrHistoryComponentDM from './TPrHistoryComponentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrBookDocumentDM extends Data {
	public static className = 'TPrBookDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ConfirmationLogId?: number
	CalculationLogId?: number
	Number?: number
	ConfirmedOn?: Date
	CalculatedOn?: Date
	ChangedOn?: Date
	TimeStamp?: string
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	IsItemCompleteCalc?: string
	PreviousPeriodCloseIdCalc?: number
	PrecalculationPassedIdCalc?: number
	FirstPrecalculationDoneIdCalc?: number
	EditAfterPrecalculationIdCalc?: number
	PrecalculationConfirmationIdCalc?: number
	AdvanceGeneratePassedIdCalc?: number
	GenerateAdvanceConfirmIdCalc?: number
	ComputeBitCalc?: number
	EditAfterCalculationIdCalc?: number
	PeriodIsClosedBitCalc?: number
	NewPrDocumentBitCalc?: number
	ComputeFlagsDateCalc?: Date
	ForEmptyPrEmploymentCalc?: number
	ForEmptyPersonCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CalculatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedPeriodIdCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	ComputeStateIdCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	ProcessedPeriodIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrOutputComponentDM)
	PrComponentOutputCompleteChild?: TPrOutputComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryProcessingPerChild?: TPrHistoryComponentDM[]
	@Type(() => TPrHistoryComponentDM)
	PrComHistoryValidPeriodChild?: TPrHistoryComponentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}