import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TParameterPossibleValueDM extends Data {
	public static className = 'TParameterPossibleValueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ComparisonValue?: number
	RID?: number
	ArticleId?: number
	Description?: string
	Abbr?: string
	ParameterId?: number
	Id?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}