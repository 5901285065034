import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import OpportAndActiStatus from './OpportAndActiStatus'
import TPartnerDM from './TPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TMarketingSpecificationDM from './TMarketingSpecificationDM'
import ActivityStatus from './ActivityStatus'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOpportunityDM from './TOpportunityDM'
import TCampaignDM from './TCampaignDM'
import TActivityDM from './TActivityDM'

export default class TActivityTreeGroupedDM extends Data {
	public static className = 'TActivityTreeGroupedDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	MessageId?: string
	RatingId?: number
	Id?: number
	YearFrom?: number
	IsGlobalFolder?: number
	TimeStamp?: string
	DocumentNumber?: number
	DocumentBookId?: string
	DocumentTypeId?: number
	CallCenterCallDate?: Date
	CallCentreId?: number
	ArticleCodeId?: number
	OriginalActivityId?: number
	Subject?: number
	CostPrice?: number
	ConfirmedById?: number
	ConfirmedOn?: Date
	RightGroupId?: number
	ExecutionDate?: Date
	ExecutionProbability?: number
	Volume?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	DateTo?: Date
	DateFrom?: Date
	SuperiorActivityId?: number
	Name?: string
	RecordMod?: number
	RecordES?: number
	RecordNo?: number
	RecordID?: number
	RecordState?: number
	CountChild?: number
	IsGroupNode?: string
	IsNode?: string
	HeaderRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NodeNameCalc?: string
	CommentCalc?: string
	IsMultiActivityCalc?: string
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => OpportAndActiStatus)
	OpportunityStatusId?: OpportAndActiStatus
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRID?: TMarketingActivityTypeDM
	@Type(() => TMarketingSpecificationDM)
	SpecificationRID?: TMarketingSpecificationDM
	@Type(() => ActivityStatus)
	StatusActivityId?: ActivityStatus
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TContactPersonDM)
	ResponsiblePersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TOpportunityDM)
	OpportunityRID?: TOpportunityDM
	@Type(() => TCampaignDM)
	CampaignRID?: TCampaignDM
	@Type(() => TActivityDM)
	ActivityId?: TActivityDM
}