import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import EmployeeFunction from './EmployeeFunction'
import EmploymentName from './EmploymentName'
import ProfessionName from './ProfessionName'
import FullfilRequirements from './FullfilRequirements'
import DegreeName from './DegreeName'
import EducationFieldName from './EducationFieldName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCompanyLinkDM from './TCompanyLinkDM'

export default class TEmployeeClassificationHistoryDM extends Data {
	public static className = 'TEmployeeClassificationHistoryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	PrEploymentId?: number
	DateFrom?: Date
	ExperienceDayCount?: number
	ExperienceYearCount?: number
	RequiredExperienceId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => EmployeeFunction)
	EmployeePosition?: EmployeeFunction
	@Type(() => EmploymentName)
	EmployeeOccupation?: EmploymentName
	@Type(() => ProfessionName)
	EmployeeProfession?: ProfessionName
	@Type(() => FullfilRequirements)
	Qualification?: FullfilRequirements
	@Type(() => DegreeName)
	EducationDegree?: DegreeName
	@Type(() => DegreeName)
	RequiredEducationDegree?: DegreeName
	@Type(() => EducationFieldName)
	AccomplishedEducationField?: EducationFieldName
	@Type(() => EducationFieldName)
	RequiredEducationField?: EducationFieldName
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}