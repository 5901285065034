import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TShippingMethodDM from './TShippingMethodDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TransportKind from './TransportKind'
import TCountryDM from './TCountryDM'
import TPeriodDM from './TPeriodDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TTaxTypeDM from './TTaxTypeDM'
import TTransportMethodDM from './TTransportMethodDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import JCDObchod from './JCDObchod'
import JCDP20_a1 from './JCDP20_a1'
import InvoiceInStatus from './InvoiceInStatus'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TAccountingChartDM from './TAccountingChartDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import FormOfOrder from './FormOfOrder'
import KonstSymb from './KonstSymb'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TEdiQueueDM from './TEdiQueueDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TReceiptCardDM from './TReceiptCardDM'
import TPurchaseItemBookDM from './TPurchaseItemBookDM'
import TInternalDocumentDM from './TInternalDocumentDM'
import TPaymentConditionTradeDM from './TPaymentConditionTradeDM'
import TExciseTaxSummaryDM from './TExciseTaxSummaryDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TSuperiorDocumentDM from './TSuperiorDocumentDM'
import TAdvanceDeductionInvoiceDM from './TAdvanceDeductionInvoiceDM'
import TTaxSummaryDM from './TTaxSummaryDM'
import TDocumentReminderDM from './TDocumentReminderDM'
import TPurchaseItemDM from './TPurchaseItemDM'
import TAccountingPurchaseItemDM from './TAccountingPurchaseItemDM'
import TDeliveryOrderItemBookDM from './TDeliveryOrderItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TInvoiceInDM extends Data {
	public static className = 'TInvoiceInDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreateReminders?: string
	LateChargesCalculationEndDate?: Date
	PlannedPaymentDate?: Date
	NotIncludeIntoFinancialMngmt?: string
	SpecificSymbol?: string
	BankCodeNumber?: string
	CreatedOn?: Date
	Settings?: string
	TaxNumber?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	ExternalNumber?: string
	Number?: number
	AdvanceFreeAmountVATC?: number
	AdvanceFreeAmountNetC?: number
	AdvanceFreeAmountGrossC?: number
	AmountVATC?: number
	AmountNetC?: number
	AmountGrossC?: number
	AdvanceFreeAmountVAT?: number
	AdvanceFreeAmountNet?: number
	AdvanceFreeAmountGross?: number
	BorderCrossingDate?: Date
	RID?: number
	TimeStamp?: string
	TaxPointDate?: Date
	IBAN?: string
	RemainsToAllocateAmount?: number
	AmountPaidByArchiveC?: number
	AmountPaidC?: number
	AmountSettledC?: number
	ExchangeDifferenceArchive?: number
	ExchangeDifference?: number
	ExchangeRate2?: number
	RequiredPaymentDate?: Date
	AmountGross?: number
	IsPaid?: string
	PostedById?: number
	PostedOn?: Date
	RemainingAmount?: number
	PaymentDate?: Date
	RightGroupId?: number
	AccountingItemsCount?: number
	ItemCount?: number
	ChangedOn?: Date
	ConfirmedOn?: Date
	IssueDate?: Date
	DueDate?: Date
	AccountingTransactionDate?: Date
	AmountPaidByArchive?: number
	AmountPaid?: number
	AmountSettled?: number
	AmountVAT?: number
	AmountNet?: number
	PostingKeyId?: number
	TradingPartnerBankAccount?: string
	BankId?: number
	ReferenceNumber?: string
	Penalty?: number
	ExchangeRate?: number
	Description?: string
	RecordID?: number
	Ex_SalesOrderId?: string
	Ex_DeliveryNoteId?: string
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	AmountVATCalc?: number
	AmountGrossCalc?: number
	AmountVATCCalc?: number
	AmountGrossCCalc?: number
	ExchangeRateCalc?: number
	CompanyCurrencyCalc?: string
	HeaderCurrencyCalc?: string
	HeaderExchangeRateCalc?: number
	CurrencySwitchCalc?: number
	MaturityDaysCalc?: number
	PaymentAmountCalc?: number
	TotalDueAmountCalc?: number
	DelayDayCountCalc?: number
	AmountSettledCCalc?: number
	PayOffAmountCCalc?: number
	PaidCCalc?: number
	PaidByArchiveAmountCCalc?: number
	TodayLateChargesAmountCalc?: number
	TodayLateChargesAmountCCalc?: number
	RemainingAmountCalc?: number
	RemainingAmountCCalc?: number
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	TotalItemPlannedPriceNetCalc?: number
	TotalItemPlStockPriceCalc?: number
	TotalItemPlStockPriceCCalc?: number
	ProfitCalc?: number
	ProfitCCalc?: number
	MarginCalc?: number
	CurrencyBySwitchCalc?: string
	AssignedContractRIDCalc?: number
	DocumentAmountGrossCalc?: number
	DocumentAmountGrossCCalc?: number
	AmountPaidPercentCalc?: number
	AmountSettledPercentCalc?: number
	ReductionValueCalc?: number
	ReductionTypeBitCalc?: number
	ReductionAmountCalc?: number
	ReductionSignCalc?: number
	ReductionSignImgCalc?: string
	RemainingAmountByDueDateCalc?: number
	RemainingAmountCByDueDateCalc?: number
	RemainingDiscount1AmountCalc?: number
	RemainingDiscount1AmountCCalc?: number
	RemainingDiscount2AmountCalc?: number
	RemainingDiscount2AmountCCalc?: number
	DiscountDate1Calc?: Date
	DiscountDate2Calc?: Date
	TodayRemainingAmountCalc?: number
	TodayRemainingAmountCCalc?: number
	PlannedSurchargeAmountCalc?: number
	DiscountGrossCalc?: number
	DiscountGrossCCalc?: number
	CashDiscountRemainingAmountCalc?: number
	CashDiscountRemainingAmountCCalc?: number
	TotalItemPlStockBySettPriceCalc?: number
	ExchangeRate2Calc?: number
	AmountPaidByExchangeDifferenceCalc?: number
	DeliveryOrderDateCalc?: Date
	TotalPaymentsAmountCalc?: number
	RoundedAmountGrossCalc?: number
	CreditNoteImgCalc?: string
	DueDateFromCalc?: Date
	AdvanceDeductedAmountCalc?: number
	AdvDeductTrPartAmountCalc?: number
	AdvanceRemainsToDedcAmountCalc?: number
	AdvRemainsToDedcTrPartAmountCalc?: number
	AdvanceDeductedAmountCCalc?: number
	AdvDeductedTrPartAmountCCalc?: number
	AdvRemainsToDeductAmountCCalc?: number
	AdvRemainsToDeductTrPartAmountCCalc?: number
	ExistsNotDeductedAdvanceBitCalc?: number
	EditedAmountGrossImgCalc?: string
	ExistsAdvanceUnpaidBitCalc?: number
	ExistsTrPartAdvNotPaidBitCalc?: number
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	TotalItemActualPriceNetCalc?: number
	TotalItemActualStockPriceCalc?: number
	TotalItemActualStockPriceCCalc?: number
	ActualProfitCalc?: number
	ActualProfitCCalc?: number
	ActualMarginAmountCalc?: number
	ActualSurchargeAmountCalc?: number
	TotalItemActStockSettPriceCalc?: number
	ExistsNotDedcAdvTrPartBitCalc?: number
	PaymentDifferenceCalc?: number
	PaymentDifferenceImgCalc?: string
	QRCodeCZBankOrderStandardCalc?: string
	QRCodeCZBankOrderLargeCalc?: string
	QRCodeSKBankOrderPayBySquareCalc?: string
	QRSKBankOrderCSOBCalc?: string
	ExchangeRateByCurrencySwitchCalc?: number
	MarketingDocumentRIDCalc?: number
	ExternalAddressCalc?: string
	TradingPartnerInternalTextCalc?: string
	RoundingDifferenceCalc?: number
	ForeignCurrencyBitCalc?: number
	ApplyDiscountBitCalc?: number
	StatutoryInterestBitCalc?: number
	StatutoryInterestPctCalc?: string
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	IncorrectAmountBitCalc?: number
	VATTurnedOnBitCalc?: number
	MultiDocumentBitCalc?: number
	RecipientVATRegNumberBitCalc?: number
	AdvanceInvoiceBitCalc?: number
	PostZeroAmountDocumentsBitCalc?: number
	NotRegisterBitCalc?: number
	MultiPaymentBitCalc?: number
	ExciseTaxBitCalc?: number
	ChangedGrossBitCalc?: number
	IntrastatSmallPackageBitCalc?: number
	NotIncludeToIntrastatBitCalc?: number
	IntrastatCreditNoteBitCalc?: number
	AllocationDoneBitCalc?: number
	NonStockItemAllocatedBitCalc?: number
	IncidentalCostAllocatedBitCalc?: number
	ItemTaxTypeCalc?: string
	QRCZInvoiceCalc?: string
	VATRegNumberCalc?: string
	AmountInternalPaidPctCalc?: number
	BankCodeNumberCalc?: string
	VATControlStatementDateCalc?: Date
	TransportMethodRIDCalc?: number
	TotalWeightNetCalc?: number
	TotalWeightGrossCalc?: number
	IntrastatOutsideEUBitCalc?: number
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TotalVolumePARAMCalc?: number
	TotalLateChargesAmountCalc?: number
	TotalLateChargesAmountCCalc?: number
	VATDocumentImgCalc?: string
	ReminderCountCalc?: number
	ReminderIssueDateCalc?: Date
	ReminderDocumentCalc?: string
	InstrastatDocumentImgCalc?: string
	FullAccountNumberCalc?: string
	ExpectedPaymentDateCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	CompanyRegNumberCalc?: string
	VATRegNumberNotFullCalc?: string
	TaxNumberCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	PlannedPaymentAmountCCalc?: number
	PlannedPaymentAmountCalc?: number
	PlannedRemainingAmountCCalc?: number
	PlannedRemainingAmountCalc?: number
	PlannedCurrencyCalc?: string
	ShowTotalInBaseCurrencyCalc?: string
	ShowRemainingBaseCurrencyCalc?: string
	AverageDelayCalc?: number
	VATDocumentIdentificationCalc?: string
	ExchangeRateDateInformationCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TTextDM)
	PaymentStatusId?: TTextDM
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TransportKind)
	IntrastatTransportKindId?: TransportKind
	@Type(() => TCountryDM)
	IntrastatCountryId?: TCountryDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRID?: TPurchaseOrderDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => JCDObchod)
	IntrastatTransactionId?: JCDObchod
	@Type(() => JCDP20_a1)
	DeliveryTermsId?: JCDP20_a1
	@Type(() => InvoiceInStatus)
	StatusId?: InvoiceInStatus
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => FormOfOrder)
	OrderForm?: FormOfOrder
	@Type(() => KonstSymb)
	ConstantSymbol?: KonstSymb
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	PaidStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	EditedAmountBitCalc?: TTextDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	CreditNoteBitCalc?: TTextDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TTradingPartnerDM)
	TaxTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	EdiQueueMessageInStatusIdCalc?: TTextDM
	@Type(() => TEdiQueueDM)
	EdiQueueMessageInRIDCalc?: TEdiQueueDM
	@Type(() => TTextDM)
	InvoiceAllocationDoneBitCalc?: TTextDM
	@Type(() => TTextDM)
	VATDocumentIdCalc?: TTextDM
	@Type(() => TTextDM)
	InstrastatDocumentIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationChild?: TDeliveryConfirmationDM[]
	@Type(() => TReceiptCardDM)
	ReceiptCardChild?: TReceiptCardDM[]
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderChild?: TPurchaseOrderDM[]
	@Type(() => TPurchaseItemBookDM)
	ItemChild?: TPurchaseItemBookDM[]
	@Type(() => TInternalDocumentDM)
	InternalDocumentChild?: TInternalDocumentDM[]
	@Type(() => TPaymentConditionTradeDM)
	PaymentConditionChild?: TPaymentConditionTradeDM[]
	@Type(() => TExciseTaxSummaryDM)
	DocumentExciseTaxChild?: TExciseTaxSummaryDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TSuperiorDocumentDM)
	SuperiorDocumentChild?: TSuperiorDocumentDM[]
	@Type(() => TAdvanceDeductionInvoiceDM)
	AdvanceDeductionChild?: TAdvanceDeductionInvoiceDM[]
	@Type(() => TTaxSummaryDM)
	TaxSummaryChild?: TTaxSummaryDM[]
	@Type(() => TDocumentReminderDM)
	ReminderChild?: TDocumentReminderDM[]
	@Type(() => TPurchaseItemDM)
	PurchaseItemChild?: TPurchaseItemDM[]
	@Type(() => TAccountingPurchaseItemDM)
	AccountingPurchaseItemChild?: TAccountingPurchaseItemDM[]
	@Type(() => TDeliveryOrderItemBookDM)
	DistributionChild?: TDeliveryOrderItemBookDM[]
	@Type(() => TCommentDM)
	CommentChildCustomer?: TCommentDM[]
	@Type(() => TCommentDM)
	CommentChildSuperiorDocument?: TCommentDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}