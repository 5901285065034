import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'

export default class TEshopMeasureUnitDM extends Data {
	public static className = 'TEshopMeasureUnitDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	HandlingEquipmentId?: number
	AreaUnitId?: number
	Area?: number
	WeightNet?: number
	WeightGross?: number
	Depth?: number
	Height?: number
	Width?: number
	Volume?: number
	Ratio?: number
	ArticleId?: number
	Id?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordBitCalc?: number
	BasicOrBlockedImgCalc?: string
	DefaultForJobCardBitCalc?: number
	DefaultForPurchaseBitCalc?: number
	DefaultForSaleBitCalc?: number
	DefaultForTransferNoteBitCalc?: number
	IntegerUnitIdCalc?: number
	DefaultForEshopBitCalc?: number
	ShowOnEshopBitCalc?: number
	EshopQuantityBitCalc?: number
	DefaultForStockTakingBitCalc?: number
	InverseRateCalc?: number
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM
	@Type(() => TTextDM)
	VolumeUnitId?: TTextDM
	@Type(() => AlternativeUnit)
	Abbr?: AlternativeUnit
}