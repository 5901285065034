import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCommentDM from './TCommentDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkEmployedPersonDM from './TCompanyLinkEmployedPersonDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEmployedPersonSimpleDM extends Data {
	public static className = 'TEmployedPersonSimpleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsNightWorkingPerson?: string
	NativeLanguageId?: number
	LastSurname?: string
	LuncheonVoucherNumber?: string
	CreatedOn?: Date
	XMLFieldScript?: string
	IdentityCardAuthority?: string
	IdentityCardIssuedOn?: Date
	IdentityCardExpiryDate?: Date
	RatingIsirDate?: Date
	RatingIsirValue?: string
	TypeId?: number
	OrganizationUnitPWDsId?: number
	VATRegNumber?: string
	HealthInsurranceNumber?: string
	BroughtUpChildrenCount?: number
	SexId?: number
	RetirementSavingDateTo?: Date
	RetirementSavingDateFrom?: Date
	RetirementSavingMemberNumber?: string
	RetirementSavingPartnerId?: number
	PensionDecisionNumber?: string
	Email?: string
	DisabilityDateTo?: Date
	DisabilityDateFrom?: Date
	DisabilityId?: number
	XMLField?: string
	TimeStamp?: string
	ResidentCountryId?: number
	EmailPassword?: string
	NationalityCountryId?: number
	BirthCountryId?: number
	BirthTownPartId?: number
	PensionPayerAddressId?: number
	ContactAddressId?: number
	TemporaryAddressId?: number
	PermanentAddressId?: number
	DegreeAfterName?: string
	NewFields?: string
	AdditionalInfo?: string
	PensionPayer?: string
	WorkingAbility?: string
	TemporaryPhone?: string
	PermanentPhone?: string
	DependentChildrenCount?: number
	PensionDate?: Date
	PensionTypeId?: string
	RightGroupId?: number
	ChangedOn?: Date
	BirthDate?: Date
	HealthInsuranceId?: number
	TransportMode?: string
	BirthName?: string
	Degree?: string
	Surname?: string
	Id?: number
	EducationLevel?: string
	DriversLicence?: string
	PassportNumber?: string
	IdentityCardNumber?: string
	MaritalStatusDate?: Date
	MaritalStatus?: string
	Phone2?: string
	Phone1?: string
	BirthIdNumber?: string
	FirstName?: string
	EmployeePersonalNumber?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ForenameSurnameOldCalc?: string
	ForenameSurnameCalc?: string
	ForenameSurnameTitleCalc?: string
	FullNameWithTitleCalc?: string
	FullNameCalc?: string
	PersonalNumberCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	ValidityBitCalc?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	K2UserIdCalc?: TK2UserLookupDM
	@Type(() => TTextDM)
	EmploymentValidityDateCalc?: TTextDM
	@Type(() => TPrEmploymentSimpleDM)
	MainEmploymentIdCalc?: TPrEmploymentSimpleDM
	@Type(() => TPrEmploymentDM)
	MainPrEmploymentIdCalc?: TPrEmploymentDM
	@Type(() => TTextDM)
	EmploymentValidityBitCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentChild?: TPrEmploymentSimpleDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkEmployedPersonDM)
	CompanyChild?: TCompanyLinkEmployedPersonDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}