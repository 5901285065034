import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'

export default class TProductionSchedulingVariantDM extends Data {
	public static className = 'TProductionSchedulingVariantDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	SchedulingSetting?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	GroupByProductionOrdersCalc?: string
	IsMoveByNumberDaysCalc?: string
	MoveByNumberDaysCalc?: number
	IsFinishOnTimeCalc?: string
	FinishOnTimeCalc?: Date
	ScheduleMarkedCalc?: string
	AllowCollisionsOnResourcesCalc?: string
	OptimizeOperationsSequenceCalc?: string
	ConsiderCoverageCalc?: string
	ScheduleUncoveredTreesCalc?: string
	ConsiderDeliveryDaysCalc?: string
	MaxResourceOverloadCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	SchedulingMethodCalc?: TTextDM
	@Type(() => TTextDM)
	AreaWithoutCollisionsCalc?: TTextDM
}