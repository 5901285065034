import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSalesOrderDM from './TSalesOrderDM'

export default class TPromotionApplicationMemoryDM extends Data {
	public static className = 'TPromotionApplicationMemoryDM'
	public static primaryKey = 'DocumentRID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.DocumentRID!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AppliedDate?: Date
	AppliedCount?: number
	DocumentRID?: number
	DocumentTypeId?: number
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SoldQuantityCalc?: number
	SoldPriceNetCalc?: number
	SoldPriceGrossCalc?: number
	GiftCountCalc?: number
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
}