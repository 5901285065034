import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRequestedBatchParameterDM from './TRequestedBatchParameterDM'

export default class TRequestedBatchParameterHeaderDM extends Data {
	public static className = 'TRequestedBatchParameterHeaderDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	BatchTypeId?: number
	ArticleId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BatchTypeIdCalc?: number
	ParameterSetIdCalc?: number
	IsActiveCalc?: string
	@Type(() => TRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TRequestedBatchParameterDM[]
}