import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'
import TProductCostingSummaryItemDM from './TProductCostingSummaryItemDM'

export default class TProductCostingSummaryDM extends Data {
	public static className = 'TProductCostingSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsGenerated?: string
	UserDefinedPrice?: number
	CalculatedPrice?: number
	RedefinePrice?: string
	HeaderRID?: number
	UserDefinedPriceC?: number
	CalculatedPriceC?: number
	Name?: string
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ExchangeRateCalc?: number
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TProductCostingSummaryItemDM)
	SummaryItemChild?: TProductCostingSummaryItemDM[]
}