import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import EmploymentName from './EmploymentName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TCommentDM from './TCommentDM'
import TSickLeaveDM from './TSickLeaveDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TCompanyLinkDM from './TCompanyLinkDM'

export default class TPreviousEmploymentDM extends Data {
	public static className = 'TPreviousEmploymentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XMLField?: string
	RID?: number
	TaxSolid?: number
	TaxBonus?: number
	SequenceNumber?: number
	TaxAdvance?: number
	Insurance?: number
	Income?: number
	EmployerName?: string
	DateFrom?: Date
	DateTo?: Date
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AddressIdCoordinates?: string
	AddressIdTownCode?: string
	AddressIdChanged?: number
	AddressIdName?: string
	AddressIdHouseNumber?: string
	AddressIdOrientationNumber?: string
	AddressIdStreet?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastCommentParamCalc?: string
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => EmploymentName)
	EmployeePosition?: EmploymentName
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSickLeaveDM)
	PrevEmplSickLeaveHistChild?: TSickLeaveDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
}