import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import ReminderID from './ReminderID'

export default class TReminderItemDM extends Data {
	public static className = 'TReminderItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DelayDays?: number
	LateCharges?: number
	LateChargesC?: number
	RemainingAmount?: number
	RemainingAmountC?: number
	AmountPaid?: number
	AmountPaidC?: number
	AmountGross?: number
	AmountGrossC?: number
	DueDate?: Date
	AccountingTransactionDate?: Date
	DocumentRID?: number
	SequenceNumber?: number
	RID?: number
	HeaderRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ReferenceNumberCalc?: string
	CurrencyCalc?: string
	InvoiceInRIDCalc?: number
	InvoiceOutRIDCalc?: number
	AdvanceProvidedRIDCalc?: number
	AdvanceReceivedRIDCalc?: number
	DocumentIdentificationCalc?: string
	OtherLiabilityRIDCalc?: number
	OtherReceivableRIDCalc?: number
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => ReminderID)
	ReminderSequenceNumber?: ReminderID
}