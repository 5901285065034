import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'

export default class TJobHistoryResolutionDM extends Data {
	public static className = 'TJobHistoryResolutionDM'
	public static systemFields = [
		'RecordID',
	]

	ModuleTypeId?: number
	SequenceNumber?: number
	Memo?: string
	DateTo?: Date
	DateFrom?: Date
	CommentTypeId?: number
	TaskId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	UserId?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	AuthorId?: TContactPersonDM
}