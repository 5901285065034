import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCachedBookDM from './TCachedBookDM'

export default class TCoverageConfigurationDM extends Data {
	public static className = 'TCoverageConfigurationDM'
	public static primaryKey = 'GUID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.GUID!
	}

	GUID?: string
	Abbr?: string
	Descr?: string
	DaysCount?: number
	LoadIgnoreSale?: number
	LoadIgnorePurchase?: number
	LoadIgnoreJC?: number
	LoadIgnoreTN?: number
	LoadIgnoreCoverageType?: number
	CoverageTypes?: string
	ShowCreditNotes?: string
	IncludeParameters?: string
	OwnCompanies?: string
	TimeStamp?: Date
	CollectionItem?: number
	@Type(() => TCachedBookDM)
	BooksOfSale?: TCachedBookDM
	@Type(() => TCachedBookDM)
	BooksOfPurchase?: TCachedBookDM
	@Type(() => TCachedBookDM)
	BooksOfJC?: TCachedBookDM
	@Type(() => TCachedBookDM)
	BooksOfTN?: TCachedBookDM
}