import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TMarketingPhaseDM extends Data {
	public static className = 'TMarketingPhaseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ImplementationDate?: Date
	Description?: string
	Abbr?: string
	HeaderRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}