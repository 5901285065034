import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ESRegistrationAddr from './ESRegistrationAddr'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'

export default class TMarketingAddressAsItemDM extends Data {
	public static className = 'TMarketingAddressAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PickUpPointId?: string
	TaxNumber?: string
	CompanyRegNumber?: string
	VATRegNumber?: string
	RID?: number
	Fax?: string
	Email?: string
	DataModuleNumber?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	Phone?: string
	ValidDate?: Date
	SequenceOrder?: number
	ContactPersonId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AddressIdCoordinates?: string
	AddressIdTownCode?: string
	AddressIdChanged?: number
	AddressIdName?: string
	AddressIdHouseNumber?: string
	AddressIdOrientationNumber?: string
	AddressIdStreet?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AddressOneLineCalc?: string
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => ESRegistrationAddr)
	TypeId?: ESRegistrationAddr
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
}