import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TCodeCalendarOverlayDM extends Data {
	public static className = 'TCodeCalendarOverlayDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SupplementId?: number
	ArticleId?: number
	Percentage?: number
	DocumentRID2?: number
	Book2?: string
	DocumentRID1?: number
	Book1?: string
	RecordTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}