import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingChartDM from './TAccountingChartDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrDeductionDM from './TPrDeductionDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TTextDM from './TTextDM'
import TPrEmploymentSimpleDM from './TPrEmploymentSimpleDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TPrPersonDocumentDM from './TPrPersonDocumentDM'
import TPrBookDocumentDM from './TPrBookDocumentDM'

export default class TPrDeductionOutputComponentDM extends Data {
	public static className = 'TPrDeductionOutputComponentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RightGroupId?: number
	UnroundedBase?: number
	Base?: number
	HeaderRID?: number
	OriginalTypeId?: number
	OriginalRID?: number
	LinkRID?: number
	RID?: number
	ExchangeRate?: number
	UnroundedAmountC?: number
	AmountC?: number
	ValueX4?: number
	ValueX3?: number
	ValueX2?: number
	ValueX1?: number
	CreatedOn?: Date
	PrDeductionId?: number
	LinkPrComponentGUID?: string
	SortPriorityId?: number
	CalendarDays?: number
	CalendarHours?: number
	StandardId?: number
	LinkPrComponentId?: number
	UnroundedAverage?: number
	UnroundedBalance?: number
	UnroundedAmount?: number
	UnroundedTariff?: number
	ValueReal?: number
	ValueDecimal?: number
	TimeTo?: number
	TimeFrom?: number
	CalendarRecordTypeId?: number
	ValueString?: string
	ValueShort?: number
	ValueLong?: number
	ChangedOn?: Date
	PostingKeyId?: number
	Average?: number
	Balance?: number
	Amount?: number
	Tariff?: number
	RemainingHours?: number
	HourCount?: number
	DateTo?: Date
	DateFrom?: Date
	RemainingDays?: number
	DayCount?: number
	CalendarId?: number
	ValidTo?: Date
	ValidFrom?: Date
	Note?: string
	Description?: string
	PrComponentId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsItemCompleteCalc?: string
	InformationCalc?: string
	ValidFromQuarterCalc?: number
	ValidFromQuarterYearCalc?: number
	DateFromYearCalc?: number
	DateFromMonthCalc?: number
	DateFromDayCalc?: number
	DateFromWeekCalc?: number
	TaxPayerDeductionCalc?: number
	TaxStudentAllowanceCalc?: number
	TaxDisabilityDiscountCalc?: number
	ValueLongAsDateCalc?: Date
	ValueRealAsDateCalc?: Date
	ValidityDaysCalc?: number
	DateDayCountCalc?: number
	ValueDecimalInDaysCalc?: Date
	CalendarRecordTypeAsDateCalc?: Date
	HourCountAsDateCalc?: Date
	RemainingHoursAsDateCalc?: Date
	ValidFromYearCalc?: number
	ValidFromMonthCalc?: number
	ValidFromDayCalc?: number
	ValidFromWeekCalc?: number
	TotalAmountCalc?: number
	DecisiveQuarterCalc?: number
	DecisiveQuarterYearCalc?: number
	DateToYearCalc?: number
	DateToMonthCalc?: number
	DateToDayCalc?: number
	DateToWeekCalc?: number
	HoursCountCalc?: number
	RemainingHoursCountCalc?: number
	CalendarHoursCalc?: number
	RemainingHolidayDayCalc?: number
	BalanceAsHoursCalc?: number
	UnroundedBalanceAsHoursCalc?: number
	UnroundedAverageAsHoursCalc?: number
	UnroundedTariffAsHoursCalc?: number
	UnroundedAmountAsHoursCalc?: number
	ValueDecimalHoursCalc?: number
	StartTimeHoursCalc?: number
	EndTimeHoursCalc?: number
	ValueRealAsIntegerX1Calc?: number
	ValueRealAsIntegerX2Calc?: number
	ValueRealAsIntegerX3Calc?: number
	ValueRealAsIntegerX4Calc?: number
	UnroundedBalanceAsDateCalc?: Date
	HeaderExchangeRateCalc?: number
	ExchangeRateCalc?: number
	AmountCCCalc?: number
	UnroundedAmountClientCCalc?: number
	AmountCRCalc?: number
	UnroundedAmountCCalc?: number
	WageDeductionCurrencyCalc?: string
	DeductedAmountCalc?: number
	PCMAbbrCalc?: string
	AmountInCurrencyBitCalc?: number
	Flag50IdCalc?: number
	Flag51IdCalc?: number
	Flag52IdCalc?: number
	Flag53IdCalc?: number
	Flag54IdCalc?: number
	Flag55IdCalc?: number
	Flag56IdCalc?: number
	Flag57IdCalc?: number
	Flag58IdCalc?: number
	Flag59IdCalc?: number
	Flag60IdCalc?: number
	Flag61IdCalc?: number
	Flag62IdCalc?: number
	Flag63IdCalc?: number
	ClearWhilePreCalculationBitCalc?: number
	ClearWhileCalculationBitCalc?: number
	ClearWhileClosePeriodBitCalc?: number
	DefCompCreatedByIdCalc?: number
	DefCreateCalculationItemBitCalc?: number
	DefCompForShiftInputCompIdCalc?: number
	ClearWhenComputePrevPerBitCalc?: number
	PCIFromPreIdCalc?: number
	PCIFromPCDIdCalc?: number
	PCIFromPCMIdCalc?: number
	ImportStandardIdCalc?: number
	ImportAttendanceIdCalc?: number
	ImportProductionIdCalc?: number
	PCOFromPCMIdCalc?: number
	PCOFromScriptIdCalc?: number
	ClearWhileCreatePrComBitCalc?: number
	FirstPrecalculationDoneIdCalc?: number
	DoNotCheckCalendarCalc?: number
	AmountCalc?: number
	TaxRateCalc?: number
	AverageCalc?: number
	BalanceCalc?: number
	WorkingDayCountCalc?: number
	DateFromCalc?: Date
	DateToCalc?: Date
	CalendarDayCalc?: number
	HourCountAsHoursMinutesCalc?: number
	AmountCCalc?: number
	BaseAmountCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre2Id?: TCostCentreCollectionDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentre1Id?: TCostCentreCollectionDocumentDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM
	@Type(() => TPrPeriodDM)
	ValidityPeriodId?: TPrPeriodDM
	@Type(() => TPrDeductionDM)
	PersonPrDeductionId?: TPrDeductionDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TPrDeductionDM)
	OrganizationPrDeductionId?: TPrDeductionDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM
	@Type(() => TPrEmploymentDM)
	PrEmployementId?: TPrEmploymentDM
	@Type(() => TPrPeriodDM)
	PeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrPeriodDM)
	PeriodToIdCalc?: TPrPeriodDM
	@Type(() => TTextDM)
	SourceTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectBitCalc?: TTextDM
	@Type(() => TPrPeriodDM)
	InputCreationPeriodIdCalc?: TPrPeriodDM
	@Type(() => TPrEmploymentSimpleDM)
	PrEmploymentIdCalc?: TPrEmploymentSimpleDM
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonSimpleIdCalc?: TEmployedPersonSimpleDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM
	@Type(() => TPrEmployeeDocumentDM)
	PrEmployeeDocumentRIDCalc?: TPrEmployeeDocumentDM
	@Type(() => TPrPersonDocumentDM)
	PrPersonDocumentRIDCalc?: TPrPersonDocumentDM
	@Type(() => TPrBookDocumentDM)
	PrDocumentByBookRIDCalc?: TPrBookDocumentDM
}