import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleDM from './TArticleDM'
import TBatchDM from './TBatchDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'

export default class TServicedDeviceItemDM extends Data {
	public static className = 'TServicedDeviceItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TransferNoteRID?: number
	ReleaseNoteRID?: number
	Description?: string
	InstalledOn?: Date
	DiscardedOn?: Date
	IsDiscarded?: string
	MeasureUnitId?: number
	Quantity?: number
	ProductionNumber?: string
	SerialNumber?: string
	HeaderRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordNo?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ArticleAbbrCalc?: string
	MeasureUnitAbbrCalc?: string
	@Type(() => TOfficerDocumentDM)
	InstalledById?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	DiscardedById?: TOfficerDocumentDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => AlternativeUnit)
	GeneralMeasureUnitIdCalc?: AlternativeUnit
}