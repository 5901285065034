import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'

export default class TLoyaltyProgramItemHistoryDM extends Data {
	public static className = 'TLoyaltyProgramItemHistoryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CollectorId?: number
	GroupId?: number
	PointsCount?: number
	LoyaltyProgramItemId?: number
	CreatedOn?: Date
	LoyaltyProgramId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CollectorNameCalc?: string
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	ChangeType?: TTextDM
}