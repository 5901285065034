import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'

export default class TMarketingAddressDM extends Data {
	public static className = 'TMarketingAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PickUpPointId?: string
	TaxNumber?: string
	CompanyRegNumber?: string
	VATRegNumber?: string
	RID?: number
	Fax?: string
	Email?: string
	DataModuleNumber?: number
	Comment?: string
	CreatedOn?: Date
	ChangedOn?: Date
	Phone?: string
	TypeId?: string
	ValidDate?: Date
	SequenceOrder?: number
	ContactPersonId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RegionCalc?: string
	RegionFieldCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
}