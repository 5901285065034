import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TContactPersonDM from './TContactPersonDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TermOfDelivery from './TermOfDelivery'
import TTextDM from './TTextDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TReceiptCardDM from './TReceiptCardDM'
import TTransferNoteDM from './TTransferNoteDM'
import TReservingCardDM from './TReservingCardDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TSalesOrderDM from './TSalesOrderDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TDeliveryMeasureUnitDM from './TDeliveryMeasureUnitDM'

export default class TDeliveryPlanItemBookDM extends Data {
	public static className = 'TDeliveryPlanItemBookDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	UnloadingSequenceNumber?: number
	LoadingSequenceNumber?: number
	Volume?: number
	ConfirmedOn?: Date
	ConfirmedById?: number
	Weight?: number
	WarehouseToId?: number
	WarehouseFromId?: number
	PackageId?: string
	RegionId?: number
	EmailTo?: string
	EmailFrom?: string
	PhoneTo?: string
	PhoneFrom?: string
	Note?: string
	CreatedOn?: Date
	Description?: string
	HeaderTypeId?: number
	IsCanceledRecord?: string
	DeliveryTime?: Date
	ChangedOn?: Date
	OriginalDocumentRID?: number
	SequenceNumber?: number
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentIdentificationCalc?: string
	DeliveryTimeDateCalc?: Date
	DeliveryTimeTimeCalc?: Date
	DeliveryOrderRIDCalc?: number
	TotalVolumePARAMCalc?: number
	AddressFromIdStreetCalc?: string
	AddressFromIdOrientationNumberCalc?: string
	AddressFromIdHouseNumberCalc?: string
	AddressFromIdNameCalc?: string
	AddressFromIdTownCodeCalc?: string
	AddressFromIdCoordinatesCalc?: string
	AddressToIdStreetCalc?: string
	AddressToIdOrientationNumberCalc?: string
	AddressToIdHouseNumberCalc?: string
	AddressToIdNameCalc?: string
	AddressToIdTownCodeCalc?: string
	AddressToIdCoordinatesCalc?: string
	@Type(() => TAddressDM)
	AddressToId?: TAddressDM
	@Type(() => TAddressDM)
	AddressFromId?: TAddressDM
	@Type(() => TContactPersonDM)
	ContactPersonToId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ContactPersonFromId?: TContactPersonDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TermOfDelivery)
	DeliveryTimeText?: TermOfDelivery
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	OriginalDocumentTypeId?: TTextDM
	@Type(() => TDeliveryPlanDM)
	HeaderRID?: TDeliveryPlanDM
	@Type(() => TTradingPartnerDM)
	DocumentTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DocumentDeliveryTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRIDCalc?: TDeliveryNoteDM
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationRIDCalc?: TDeliveryConfirmationDM
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRIDCalc?: TPurchaseOrderDM
	@Type(() => TReceiptCardDM)
	ReceiptCardRIDCalc?: TReceiptCardDM
	@Type(() => TTransferNoteDM)
	TransferNoteRIDCalc?: TTransferNoteDM
	@Type(() => TReservingCardDM)
	ReservingCardRIDCalc?: TReservingCardDM
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRIDCalc?: TReleaseNoteDM
	@Type(() => TSalesOrderDM)
	SalesOrderRIDCalc?: TSalesOrderDM
	@Type(() => TCountryDM)
	AddressFromIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressFromIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressFromIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	AddressToIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressToIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressToIdTownIdCalc?: TTownDM
	@Type(() => TDeliveryMeasureUnitDM)
	MeasureUnitChild?: TDeliveryMeasureUnitDM[]
}