import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantOperationDM from './TRoutingVariantOperationDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TTextDM from './TTextDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TWarehouseDM from './TWarehouseDM'
import GoodsName from './GoodsName'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TRoutingVariantItemSubstitutionDM from './TRoutingVariantItemSubstitutionDM'

export default class TRoutingVariantItemDM extends Data {
	public static className = 'TRoutingVariantItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ArtIsOverheadsInPercentage?: string
	ArtArticleCodeId?: number
	ArtContractCodeRID?: number
	ArtArticleCategoryId?: number
	ArtOverheads?: number
	ArtArticleTypeId?: number
	IsSupplement?: string
	MultiplyQuantity?: string
	IsRedefinedPrice?: string
	RoutingResourceRID?: number
	RoutingId?: number
	RoutingTypeId?: number
	RoutingMainItemRID?: number
	RID?: number
	Scrap?: number
	ChangedOn?: Date
	Drawing?: string
	Position?: string
	StockPrice?: number
	Quantity?: number
	ItemId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	ArtPlannedStockPrice?: number
	InternalItemNumber?: number
	InternalBreakDown?: number
	OperationCode?: string
	OperationSequenceNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SubstitutionOrSupplementCalc?: string
	CodeDescriptionCalc?: string
	VATDescriptionCalc?: string
	QuantityGrossCalc?: number
	RedefUnitPriceCalc?: number
	RedefTotalPriceGrossCalc?: number
	NameCalc?: string
	StockPriceCalc?: number
	StockUnitPriceCalc?: number
	StockTotalPriceGrossCalc?: number
	TimeCalc?: number
	TimeQuantityCalc?: number
	PositiveQuantityGrossCalc?: number
	QuantitySignBitCalc?: number
	QuantitySignImgCalc?: string
	FullNameCalc?: string
	IndependentOperationBitCalc?: number
	PriceCalc?: number
	UnitPriceCalc?: number
	TotalPriceCalc?: number
	QuantityNetCalc?: number
	QuantityNetUMCalc?: number
	TotalStockPriceNetCalc?: number
	RedefTotalPriceNetCalc?: number
	PositiveQuantityNetCalc?: number
	BOMTypeCalc?: number
	BarcodeCalc?: string
	IsSubstitutionCalc?: string
	FilterCategoriesCalc?: string
	CategoryListCalc?: string
	@Type(() => TRoutingVariantOperationDM)
	RoutingOperationRID?: TRoutingVariantOperationDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
	@Type(() => TRoutingVariantItemSubstitutionDM)
	RoutingVariantItemSubstChild?: TRoutingVariantItemSubstitutionDM[]
}