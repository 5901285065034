import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'

export default class TSpecialTTradingPartnerDMArticleCustomerPrice extends Data {
	public static className = 'TSpecialTTradingPartnerDMArticleCustomerPrice'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Master?: number
	IsRelative?: string
	Price?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTradingPartnerDM)
	CustomerId?: TTradingPartnerDM
}