import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TTextDM from './TTextDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TContactPersonDM from './TContactPersonDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TFamilyDM extends Data {
	public static className = 'TFamilyDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XMLField?: string
	RID?: number
	ChildSequenceNumber?: number
	NewFieldId?: string
	DateTo?: Date
	DateFrom?: Date
	TaxInfo?: string
	InSameCompany?: string
	FaxNumber?: string
	PhoneNumber?: string
	BirthDate?: Date
	Surname?: string
	FirstName?: string
	SequenceNumber?: number
	Disability?: string
	EmployerName?: string
	PersonalId?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AddressIdCoordinates?: string
	AddressIdTownCode?: string
	AddressIdChanged?: number
	AddressIdName?: string
	AddressIdHouseNumber?: string
	AddressIdOrientationNumber?: string
	AddressIdStreet?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BirthIdNumberPart1Calc?: string
	BirthIdNumberPart2Calc?: string
	DeathDateCalc?: Date
	FullNameCalc?: string
	DependentPersonBitCalc?: number
	PSDCardIdCalc?: number
	SameCompanyBitCalc?: number
	AgeCalc?: number
	LastCommentParamCalc?: string
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TTextDM)
	Relationship?: TTextDM
	@Type(() => TEmployedPersonDM)
	PersonId?: TEmployedPersonDM
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
	@Type(() => TContactPersonDM)
	ContactPersonIdCalc?: TContactPersonDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}