import React, { SyntheticEvent } from 'react'
import TSalesItemDM from 'react-k2-api/Data/TSalesItemDM'
import { InputGroup, FormControl, Button } from 'react-bootstrap'

type ItemProps = {
    salesItem: TSalesItemDM
    onRemove: () => void
    onChange: (event: SyntheticEvent) => void
    onEnter: (event: SyntheticEvent) => void
}

export default function Item(props: ItemProps) {
    return (
        <tr>
            <td>
                #{props.salesItem.ItemNumber} {props.salesItem.ArticleId?.LanguageTextCalc}<br />
                <div className="d-flex justify-content-between align-items-center">
                    <InputGroup>
                        <FormControl
                            type="number"
                            value={props.salesItem.Quantity}
                            onChange={props.onChange}
                            onKeyUp={props.onEnter}
                        />
                        <InputGroup.Text>{props.salesItem.MeasureUnitId?.Abbr?.Abbr}</InputGroup.Text>
                    </InputGroup>
                    {/*
                    <span>
                        <NumberFormat value={props.saleItem.PlanGrossPriceTotalCCalc} decimalScale={2} fixedDecimalScale={true} decimalSeparator="," thousandSeparator="&nbsp;" displayType="text" />
                                    &nbsp;{props.saleItem.CurrencyHCalc?.CurrDisp}
                    </span>
                    */}
                    <Button variant="danger" onClick={props.onRemove}>Smazat</Button>
                </div>
            </td>
        </tr>
    )
}