import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TWorkflowRoleDM from './TWorkflowRoleDM'
import TContactPersonDM from './TContactPersonDM'
import TOrgStrTreeUnitDM from './TOrgStrTreeUnitDM'

export default class TOrgStrTreeChildPersonDM extends Data {
	public static className = 'TOrgStrTreeChildPersonDM'
	public static systemFields = [
		'RecordID',
	]

	CostCentreId?: number
	OrgStrUnitSuperiorRID?: number
	OrgStrTreeRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TWorkflowRoleDM)
	RoleId?: TWorkflowRoleDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitRID?: TOrgStrTreeUnitDM
	@Type(() => TOrgStrTreeUnitDM)
	OrgStrUnitRootRID?: TOrgStrTreeUnitDM
}