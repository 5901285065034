import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class REMLastTxt extends Data {
	public static className = 'REMLastTxt'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	RightGroupId?: number
	TypeId?: number
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	PlainTextCalc?: string
	LanguageDescriptionCalc?: string
	TranslatedImgCalc?: string
	NoteCalc?: string
	TextCalc?: string
	Description100Calc?: string
	ContentTextCalc?: string
	ContentPlainTextCalc?: string
	TextFormatCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	LanguageAbbrCalc?: TTextDM
	@Type(() => TTextDM)
	TranslatedBitCalc?: TTextDM
	@Type(() => TLanguageMemoTranslationDM)
	LanguageMemoChild?: TLanguageMemoTranslationDM[]
}