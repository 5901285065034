import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TAccountingJournalDM from './TAccountingJournalDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import ZarUmisteni from './ZarUmisteni'
import Pracoviste from './Pracoviste'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'

export default class TD_ZarPC extends Data {
	public static className = 'TD_ZarPC'
	public static systemFields = [
		'ItemRIDCalc',
	]

	PreselectionId?: number
	Plan?: string
	Desc?: string
	Kod?: string
	Cis?: number
	Likvid?: number
	DLikvid?: Date
	Pohyb_P?: number
	CENA?: number
	ROK?: Date
	CISLO?: number
	TM?: string
	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	CFUcDok?: number
	CFZa?: string
	CFOd?: string
	CFPo?: string
	CFKod?: string
	@Type(() => TAccountingJournalDM)
	AccDocRID?: TAccountingJournalDM
	@Type(() => TTextDM)
	Typ_Pol?: TTextDM
	@Type(() => TPeriodDM)
	CFUPref?: TPeriodDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreCalc?: TCostCentreCollectionDocumentDM
	@Type(() => ZarUmisteni)
	CFKod_Umi?: ZarUmisteni
	@Type(() => Pracoviste)
	CFKod_Pra?: Pracoviste
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeCalc?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeCalc?: TContractCodeDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerCalc?: TOfficerDocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Calc?: TCode1DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Calc?: TCode2DocumentDM
	@Type(() => TTradingPartnerDM)
	CFKod_Cdo?: TTradingPartnerDM
	@Type(() => TMatchingSymbolDM)
	CFKod_PS?: TMatchingSymbolDM
	@Type(() => TDeviceDocumentDM)
	DeviceCalc?: TDeviceDocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Calc?: TCode3DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Calc?: TCode4DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Calc?: TCode5DocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Calc?: TCode6DocumentDM
}