import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAssetLocationDM from './TAssetLocationDM'
import AssetLocationStatus from './AssetLocationStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAssetLocationDocumentDM extends Data {
	public static className = 'TAssetLocationDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	NotInsertIntoDocument?: string
	SequenceNumber?: number
	TimeStamp?: string
	Text?: string
	ValidTo?: Date
	ValidFrom?: Date
	IsBlockedRecord?: string
	RightGroupId?: number
	Description?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	LanguageTextCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TAssetLocationDM)
	NodeId?: TAssetLocationDM
	@Type(() => AssetLocationStatus)
	StatusId?: AssetLocationStatus
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}