import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TrackingStatus from './TrackingStatus'
import ManipulationUnit from './ManipulationUnit'
import TShipmentCollectionDM from './TShipmentCollectionDM'
import TShipmentDM from './TShipmentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TShipmentServiceDM from './TShipmentServiceDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TParcelStatusDM from './TParcelStatusDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TParcelDM extends Data {
	public static className = 'TParcelDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TrackingAddress?: string
	ManipulationUnitCount?: number
	SuperiorParcelId?: number
	CreatedOn?: Date
	ChangedOn?: Date
	TimeStamp?: string
	RightGroupId?: number
	DocumentTypeId?: number
	XMLField?: string
	Period?: string
	SequenceNumber?: number
	Price?: number
	Height?: number
	Width?: number
	Length?: number
	Weight?: number
	ValueStated?: number
	ParcelCode4?: string
	ParcelCode3?: string
	ParcelCode2?: string
	ParcelCode?: number
	ServiceSequenceNumber?: number
	Id?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	ShipmentStateIdCalc?: number
	ShipmentStateImgCalc?: string
	SwapServiceCalc?: string
	StatusChangedOnCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TrackingStatus)
	TrackingStatusId?: TrackingStatus
	@Type(() => ManipulationUnit)
	ManipulationUnitId?: ManipulationUnit
	@Type(() => TShipmentCollectionDM)
	ShipmentCollectionRID?: TShipmentCollectionDM
	@Type(() => TShipmentDM)
	ShipmentRID?: TShipmentDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	StatusId?: TTextDM
	@Type(() => TTextDM)
	WeightUnitId?: TTextDM
	@Type(() => TTextDM)
	DimensionUnitId?: TTextDM
	@Type(() => TShipmentServiceDM)
	ShipmentServiceId?: TShipmentServiceDM
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRID?: TDeliveryNoteDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSalesItemBookDM)
	DeliveryNoteItemChild?: TSalesItemBookDM[]
	@Type(() => TParcelStatusDM)
	ParcelTrackingStatusChild?: TParcelStatusDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}