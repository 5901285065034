import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ParameterUnit from './ParameterUnit'
import TParameterSetAsItemDM from './TParameterSetAsItemDM'
import TParameterGroupAsItemDM from './TParameterGroupAsItemDM'
import TParameterPossibleValueDM from './TParameterPossibleValueDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'

export default class TParameterDM extends Data {
	public static className = 'TParameterDM'
	public static primaryKey = 'ParameterId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ParameterId!
	}

	TextPageRID?: number
	Title?: string
	BetterValue?: number
	TimeStamp?: string
	ChangedOn?: Date
	EShopId?: string
	KeyWord?: string
	Usage?: string
	Picture?: string
	Description?: string
	Name?: string
	ParameterId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordBitCalc?: number
	RequiredBitCalc?: number
	FromListCalc?: number
	PossibleValuesEnumerationBitCalc?: number
	ControlledReferenceCalc?: number
	ValueFromBatchIdCalc?: number
	WebExportBitCalc?: number
	OlapExportIdCalc?: number
	WebFilterBitCalc?: number
	ImplicitRequiredParameterIdCalc?: number
	NotTranReqParamToBatchBitCalc?: number
	KeepOnSplitIdCalc?: number
	TranslatedNameCalc?: string
	TranslatedDescriptionCalc?: string
	@Type(() => TTextDM)
	OLAPPosition?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	ValueTypeId?: TTextDM
	@Type(() => ParameterUnit)
	UnitId?: ParameterUnit
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TParameterSetAsItemDM)
	ParameterSetChild?: TParameterSetAsItemDM[]
	@Type(() => TParameterGroupAsItemDM)
	ParameterGroupChild?: TParameterGroupAsItemDM[]
	@Type(() => TParameterPossibleValueDM)
	PossibleValueChild?: TParameterPossibleValueDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
}