import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCommentDM from './TCommentDM'

export default class TReductionTypeDM extends Data {
	public static className = 'TReductionTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CoefficientDenominator?: number
	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	Description?: string
	IsBlockedRecord?: string
	CoefficientNumerator?: number
	RightGroupId?: number
	Name?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CoeficientCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
}