import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPriceListDM from './TPriceListDM'

export default class TPriceGroupDM extends Data {
	public static className = 'TPriceGroupDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	RightGroupId?: number
	Description?: string
	Name?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IdCalc?: number
	@Type(() => TPriceListDM)
	PriceListChild?: TPriceListDM[]
}