import Data from '../Api/Data'

export default class TOldBookDM extends Data {
	public static className = 'TOldBookDM'
	public static systemFields = [
	]

	CompanyId?: number
	RightGroupId?: number
	Description?: string
	Book?: string
	TableId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TextCalc?: string
	RightGroupIdCalc?: number
}