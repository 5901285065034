import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchinSymbolAccountingJournalItemDM from './TMatchinSymbolAccountingJournalItemDM'

export default class TMatchingSymbolDM extends Data {
	public static className = 'TMatchingSymbolDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	IsMatched?: string
	IsBlockedRecord?: string
	CompanyId?: number
	TimeStamp?: string
	ArchiveTotalCreditAmount?: number
	ArchiveTotalDebitAmount?: number
	Description?: string
	TotalCreditAmount?: number
	TotalDebitAmount?: number
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	DebitAndCreditDifferenceCalc?: number
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	SelectedAccountIdCalc?: TAccountingChartDM
	@Type(() => TMatchinSymbolAccountingJournalItemDM)
	AccountingJournaItemBookChild?: TMatchinSymbolAccountingJournalItemDM[]
}