import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCodeTypeDM from './TCodeTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TArticleDM from './TArticleDM'
import TCountryDM from './TCountryDM'
import TTransportMethodDM from './TTransportMethodDM'
import TShippingMethodDM from './TShippingMethodDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TEshopDeliveryBranchDM from './TEshopDeliveryBranchDM'
import TEshopFreeDeliveryDM from './TEshopFreeDeliveryDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopDeliveryDM extends Data {
	public static className = 'TEshopDeliveryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Currency?: string
	RightGroupId?: number
	TimeStamp?: string
	XmlData?: string
	ChangedOn?: Date
	CreatedOn?: Date
	FreeFromPrice?: number
	Note?: string
	Description?: string
	Title?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	DescriptionCalc?: string
	RequireDeliveryInfoCalc?: string
	ChoosePickupPointCalc?: string
	IsFreeArticleHasFreeShippingCalc?: string
	TransportMethodRIDCalc?: number
	IsFreeWhenFreeShipCoupAppCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedTitleCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedNoteCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCodeTypeDM)
	GoogleDeliveryId?: TCodeTypeDM
	@Type(() => TCodeTypeDM)
	ZboziCzDeliveryId?: TCodeTypeDM
	@Type(() => TCodeTypeDM)
	HeurekaDeliveryId?: TCodeTypeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TArticleDM)
	FreeArticleId?: TArticleDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TTransportMethodDM)
	TransportMethodRID?: TTransportMethodDM
	@Type(() => TShippingMethodDM)
	ShippmentMethodId?: TShippingMethodDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TEshopDeliveryBranchDM)
	DeliveryBrancheChild?: TEshopDeliveryBranchDM[]
	@Type(() => TEshopFreeDeliveryDM)
	ChargeFreeFromAmountChild?: TEshopFreeDeliveryDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}