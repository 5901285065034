import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TVATRateDM from './TVATRateDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

export default class TEshopOrderItemDM extends Data {
	public static className = 'TEshopOrderItemDM'
	public static primaryKey = 'ItemNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ItemNumber!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	ItemRID?: number
	PlannedTotalPriceC?: number
	PlannedTotalPriceGrossC?: number
	PlannedUnitPriceC?: number
	PlannedUnitPriceGrossC?: number
	Quantity?: number
	Name?: string
	ItemNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TVATRateDM)
	TaxGroupId?: TVATRateDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}