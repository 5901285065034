import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdressKeyDM from './TAdressKeyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import AlternativeUnit from './AlternativeUnit'
import TArticleDM from './TArticleDM'

export default class TEkoKomPackagingUsageDM extends Data {
	public static className = 'TEkoKomPackagingUsageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	Weight?: number
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TAdressKeyDM)
	CustomerKeyRID?: TAdressKeyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TTextDM)
	ArticlePackTypeId?: TTextDM
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}