import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TPriceListDM from './TPriceListDM'

export default class TPriceListCalculationDM extends Data {
	public static className = 'TPriceListCalculationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsLastPriceList?: string
	Rounding?: number
	SequenceNumber?: number
	StepSourceRID?: number
	IsPercentage?: string
	Reduction?: number
	PriceNet?: number
	ItemRID?: number
	ItemTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	StepSourceCalc?: string
	@Type(() => TTextDM)
	StepTypeId?: TTextDM
	@Type(() => TPriceListDM)
	PriceListRIDCalc?: TPriceListDM
}