import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TJobCardItemDM from './TJobCardItemDM'

export default class TJobCardBindDM extends Data {
	public static className = 'TJobCardBindDM'
	public static systemFields = [
		'RecordID',
	]

	HeaderRID2?: number
	HeaderRID1?: number
	SuperiorBindId?: number
	SuperiorDocumentType?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TJobCardItemDM)
	SubordinateJobCardItemRID?: TJobCardItemDM
}