import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TTextDM from './TTextDM'
import PrCalTypDay from './PrCalTypDay'
import PrCalTypHours from './PrCalTypHours'

export default class TPrCalendarItemVisualDM extends Data {
	public static className = 'TPrCalendarItemVisualDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	WorkshiftDate?: Date
	WorkshiftRID?: number
	IntervalRID?: number
	DefinitionRID?: number
	RID?: number
	BreakTIme?: number
	TotalTime?: number
	DateTo?: Date
	DateFrom?: Date
	StringValue3?: string
	StringValue2?: string
	LongValue?: number
	TimeTotal?: number
	ShortValue?: number
	Description?: string
	TimeTo?: number
	TimeFrom?: number
	Date?: Date
	CodeCalendarId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AcceptChangeInDefBitCalc?: number
	TimeFromCalc?: Date
	TimeToCalc?: Date
	IsAllDayModeCalc?: string
	ItemCaptionCalc?: string
	ItemColorCalc?: number
	ItemVisualPriorityCalc?: number
	SectionDurationInHoursCalc?: number
	BreakDurationInHoursCalc?: number
	HoursCalc?: number
	MinCalc?: number
	TimeCalc?: number
	IntervalDaysCalc?: number
	IntervalHoursCalc?: number
	IntervalMinutesCalc?: number
	IntervalStartDateCalc?: Date
	IntervalEndDateCalc?: Date
	IntervalStartTimeCalc?: Date
	IntervalEndTimeCalc?: Date
	IntervalTimeCalc?: number
	IntervalStartDateTimeCalc?: Date
	IntervalEndDateTimeCalc?: Date
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	PriorityId?: TTextDM
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours
	@Type(() => TTextDM)
	SourceTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	DayInWeekCalc?: TTextDM
	@Type(() => TTextDM)
	ItemStateCalc?: TTextDM
}