import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationArticleDM from './TLocationArticleDM'
import TWarehouseDM from './TWarehouseDM'
import HandlingUnitStatus from './HandlingUnitStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import THandlingUnitTypeDM from './THandlingUnitTypeDM'
import TTextDM from './TTextDM'
import TArticleDM from './TArticleDM'
import TBatchDM from './TBatchDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TStockCardDM from './TStockCardDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class THandlingUnitArticleDM extends Data {
	public static className = 'THandlingUnitArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Settings?: string
	TimeStamp?: string
	ChangedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TotalAssignedCalc?: number
	TotalWeightCalc?: number
	ArticleCountCalc?: number
	AvailableTotalQuantityCalc?: number
	BatchCountCalc?: number
	TotalWeight2Calc?: number
	TotalAreaCalc?: number
	TotalCapacityCalc?: number
	TotalSizeACalc?: number
	TotalSizeBCalc?: number
	TotalSizeCCalc?: number
	TotalQuantityCalc?: number
	TotalWeightNetCalc?: number
	TotalReceivedCalc?: number
	TotalDispatchCalc?: number
	InReceiptTotalQuantityCalc?: number
	TotalInTransferCalc?: number
	IsFormShowEmptyCalc?: string
	IsFormShowInUsedCalc?: string
	IsFormShowSkipNullCalc?: string
	InvalidRecordBitCalc?: number
	BarcodeTotalWeight2Calc?: number
	BarcodeTotalAreaCalc?: number
	BarcodeTotalCapacityCalc?: number
	BarcodeTotalSizeACalc?: number
	BarcodeTotalSizeBCalc?: number
	BarcodeTotalSizeCCalc?: number
	BarcodeTotalAmountCalc?: number
	FillingPercentageWeightCalc?: number
	FillingPercentageVolumeCalc?: number
	TotalVolumePARAMCalc?: number
	AssignedCalc?: number
	AvailableQuantityCalc?: number
	ReceivedCalc?: number
	DispatchCalc?: number
	AssignedUMCalc?: number
	AvailableQuantityUMCalc?: number
	ReceivedUMCalc?: number
	DispatchUMCalc?: number
	InReceiptQuantityCalc?: number
	InReceiptQuantityUMCalc?: number
	InTransferCalc?: number
	InTransferUMCalc?: number
	MeasureUnitCoefficientCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => HandlingUnitStatus)
	StatusId?: HandlingUnitStatus
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => THandlingUnitTypeDM)
	HandlingUnitTypeRID?: THandlingUnitTypeDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseZoneRIDCalc?: TWarehouseDM
	@Type(() => TArticleDM)
	UniqueArticleIdCalc?: TArticleDM
	@Type(() => TBatchDM)
	BatchIdCalc?: TBatchDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TStockCardDM)
	StockCardChild?: TStockCardDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}