import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationVATRateDm from './TLegislationVATRateDm'
import TLegislationTaxTypeDm from './TLegislationTaxTypeDm'
import TCode6DM from './TCode6DM'
import TCode5DM from './TCode5DM'
import TCode4DM from './TCode4DM'
import TCode3DM from './TCode3DM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDM from './TDeviceDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDM from './TOfficerDM'
import TCode2DM from './TCode2DM'
import TCode1DM from './TCode1DM'
import TArticleCodeDM from './TArticleCodeDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreDM from './TCostCentreDM'
import TTextDM from './TTextDM'

export default class TMatchinSymbolAccountingJournalItemDM extends Data {
	public static className = 'TMatchinSymbolAccountingJournalItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RecalculateAmount?: string
	IsManuallyChanged?: string
	IsCanceledRecord?: string
	HeaderRID?: number
	Number?: number
	RID?: number
	RightGroupId?: number
	AmountC?: number
	SequenceNumber?: number
	ExchangeRate?: number
	Amount?: number
	Description?: string
	ItemDay?: number
	ItemDate?: Date
	ItemTypeId?: number
	ItemNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	IsIdentified?: string
	ComputedRecalculateAmountC?: number
	ComputedRecalculateAmount?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DateCalc?: Date
	TimeCalc?: Date
	MSDebitAmountCalc?: number
	MSCreditAmountCalc?: number
	@Type(() => TLegislationVATRateDm)
	VATRateId?: TLegislationVATRateDm
	@Type(() => TLegislationTaxTypeDm)
	TaxTypeId?: TLegislationTaxTypeDm
	@Type(() => TCode6DM)
	DebitCode6Id?: TCode6DM
	@Type(() => TCode5DM)
	DebitCode5Id?: TCode5DM
	@Type(() => TCode4DM)
	DebitCode4Id?: TCode4DM
	@Type(() => TCode3DM)
	DebitCode3Id?: TCode3DM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDM)
	DebitDeviceRID?: TDeviceDM
	@Type(() => TCurrencyDM)
	ForeignCurrency?: TCurrencyDM
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TMatchingSymbolDM)
	DebitMatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TTradingPartnerDM)
	DebitTradingPartnerId?: TTradingPartnerDM
	@Type(() => TOfficerDM)
	DebitOfficerId?: TOfficerDM
	@Type(() => TCode2DM)
	DebitCode2Id?: TCode2DM
	@Type(() => TCode1DM)
	DebitCode1Id?: TCode1DM
	@Type(() => TArticleCodeDM)
	DebitArticleCodeId?: TArticleCodeDM
	@Type(() => TContractCodeDocumentDM)
	DebitContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreDM)
	DebitCostCentreId?: TCostCentreDM
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM
	@Type(() => TTextDM)
	HeaderTypeId?: TTextDM
	@Type(() => TCode6DM)
	CreditCode6Id?: TCode6DM
	@Type(() => TCode5DM)
	CreditCode5Id?: TCode5DM
	@Type(() => TCode4DM)
	CreditCode4Id?: TCode4DM
	@Type(() => TCode3DM)
	CreditCode3Id?: TCode3DM
	@Type(() => TDeviceDM)
	CreditDeviceRID?: TDeviceDM
	@Type(() => TTradingPartnerDM)
	CreditTradingPartnerId?: TTradingPartnerDM
	@Type(() => TOfficerDM)
	CreditOfficerId?: TOfficerDM
	@Type(() => TCode2DM)
	CreditCode2Id?: TCode2DM
	@Type(() => TCode1DM)
	CreditCode1Id?: TCode1DM
	@Type(() => TMatchingSymbolDM)
	CreditMatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TArticleCodeDM)
	CreditArticleCodeId?: TArticleCodeDM
	@Type(() => TContractCodeDocumentDM)
	CreditContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreDM)
	CreditCostCentreId?: TCostCentreDM
}