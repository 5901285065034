import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TParameterSetItemDM from './TParameterSetItemDM'
import TParameterSetFormulaDM from './TParameterSetFormulaDM'
import TBatchTypeItemDM from './TBatchTypeItemDM'

export default class TParameterSetDM extends Data {
	public static className = 'TParameterSetDM'
	public static primaryKey = 'ParameterSetId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ParameterSetId!
	}

	TimeStamp?: string
	TypeId?: number
	ChangedOn?: Date
	Description?: string
	Name?: string
	ParameterSetId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordBitCalc?: number
	ActualBatchTypeIdCalc?: number
	VRMEnabledBitCalc?: number
	VRMCoefficientFormSetIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TParameterSetItemDM)
	ParameterSetItemChild?: TParameterSetItemDM[]
	@Type(() => TParameterSetFormulaDM)
	FormulaChild?: TParameterSetFormulaDM[]
	@Type(() => TBatchTypeItemDM)
	BatchTypeItemChild?: TBatchTypeItemDM[]
}