import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'

export default class TWarehouseTransferDM extends Data {
	public static className = 'TWarehouseTransferDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	RecordNumber?: number
	TransferredQuantity?: number
	OriginalQuantity?: number
	SuperiorRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	SuperiorDocumentTypeId?: TTextDM
}