import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'
import TInvoiceOutDM from './TInvoiceOutDM'

export default class TAdvanceDeductionAdvanceDM extends Data {
	public static className = 'TAdvanceDeductionAdvanceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DeductedAmountVATC?: number
	DeductedAmountNetC?: number
	DeductedAmountGrossC?: number
	DocumentRID?: number
	TaxTypeId?: number
	RID?: number
	AdvanceItemRID?: number
	DeductionExchangeRate?: number
	DeductedAmountVAT?: number
	DeductedAmountNet?: number
	ExchangeDifference?: number
	DeductedAmountGross?: number
	AdvanceExchangeRate?: number
	DeductionDate?: Date
	AdvanceTypeId?: number
	ItemNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CFDeductedNetto?: number
	CFCoefVAT?: number
	DeductedAmountVATCalc?: number
	AdvanceExchangeRateCalc?: number
	DeductedAmountNetCalc?: number
	ExtExchangeRateCalc?: number
	DescriptionCalc?: string
	DocumentFullIdentificationCalc?: string
	@Type(() => TAdvanceReceivedDM)
	AdvanceRID?: TAdvanceReceivedDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TInvoiceOutDM)
	InvoiceRIDCalc?: TInvoiceOutDM
	@Type(() => TAdvanceReceivedDM)
	AdvanceRIDCalc?: TAdvanceReceivedDM
}