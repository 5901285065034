import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import AssetPlanType from './AssetPlanType'
import TypVyr from './TypVyr'
import PurchaseWay from './PurchaseWay'
import AccKindPurch from './AccKindPurch'
import MajSkup from './MajSkup'
import FunctType from './FunctType'
import ConstrType from './ConstrType'
import ZarStatus from './ZarStatus'
import AlternativeUnit from './AlternativeUnit'
import TProducerDM from './TProducerDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TPeriodListDM from './TPeriodListDM'
import TaxOfficeId from './TaxOfficeId'
import KindParcel from './KindParcel'
import ForestType from './ForestType'
import TTownDM from './TTownDM'
import CadTerritory from './CadTerritory'
import KindBuild from './KindBuild'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import Pracoviste from './Pracoviste'
import TAssetLocationDocumentDM from './TAssetLocationDocumentDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TAssetConnectedDocumentDM from './TAssetConnectedDocumentDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAssetDM extends Data {
	public static className = 'TAssetDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	OriginalAssetRID?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RightGroupId?: number
	IsProcessed3?: string
	IsProcessed2?: string
	IsProcessed1?: string
	ConfirmedOn?: Date
	IsChangedPlan3?: string
	IsChangedPlan2?: string
	IsChangedPlan1?: string
	IsCreatedByDecompFromDate?: string
	IsCanceledRecord?: string
	UnappliedDepreciation3?: number
	UnappliedDepreciation2?: number
	UnappliedDepreciation1?: number
	DepreciatedYear3?: number
	DepreciatedYear2?: number
	DepreciatedYear1?: number
	YearAccumulatedDepreciation3?: number
	YearAccumulatedDepreciation2?: number
	YearAccumulatedDepreciation1?: number
	AccumulatedDepreciation3?: number
	AccumulatedDepreciation2?: number
	AccumulatedDepreciation1?: number
	DepreciationPreset3RID?: number
	DepreciationPreset2RID?: number
	DepreciationPreset1RID?: number
	InputPrice3?: number
	InputPrice2?: number
	InputPrice1?: number
	LastPostingDate?: Date
	LastConfirmationItemDate?: Date
	ProgramEntry?: Date
	ActivationDate?: Date
	RetirementDate?: Date
	AcquisitionDate?: Date
	AssetCategoryRID?: number
	DepreciationGroupRID?: number
	ProductionClassificationRID?: number
	Grant?: number
	ContractValidityDate?: Date
	ContractNumber?: string
	RegistrationNumber?: string
	Quantity?: number
	ProductNumber?: string
	BarCode?: string
	Description?: string
	Name?: string
	InventoryNumber?: string
	Number?: number
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	VersionFieldDateCalc?: Date
	LanguageNameCalc?: string
	LandRegPropertyRightDateCalc?: Date
	IsLandLegalRelationshipCalc?: string
	LandTownCountryIdCalc?: number
	LandParcelNumberBeforeSlashCalc?: string
	LandParcelPlotAreaCalc?: number
	LandParcelBuiltUpPropAcreCalc?: number
	LandExemEntitleInAcreageCalc?: number
	LandLegalGroundsExemption1Calc?: string
	LandLegalGroundsExemption2Calc?: string
	LandLegalGroundsExemption3Calc?: string
	LandExemptionLastYearCalc?: number
	LandDescriptionCalc?: string
	LandPropertyShareBeforeSlashCalc?: string
	LandTownNoIdCalc?: number
	LandTaxAmountCalc?: number
	LandParcelNumberBehindSlashCalc?: string
	LandPropertyShareBehindSlashCalc?: string
	UnitRegPropertyRightDateCalc?: Date
	UnitTownCountryIdCalc?: number
	UnitHouseNumberCalc?: string
	UnitOrientationNumberCalc?: string
	UnitParcelNumberBeforeSlashCalc?: string
	UnitParcelNumberBehindSlashCalc?: string
	UnitCompletionYearCalc?: string
	UnitParcelBuiltUpPropAcreaCalc?: number
	UnitLegalGroundsExemption1Calc?: string
	UnitLegalGroundsExemption2Calc?: string
	UnitLegalGroundsExemption3Calc?: string
	UnitExemptionLastYearCalc?: number
	UnitDescriptionCalc?: string
	IsUnitCoefficientCalc?: string
	UnitOvergroundFloorNumberCalc?: number
	UnitNonresidentialAreaCalc?: number
	IsUnitTaxIncreaseCalc?: string
	UnitExempEntitleInAcreageCalc?: number
	UnitPropertyShareBeforeSlashCalc?: string
	UnitPropertyShareBehindSlashCalc?: string
	UnitTownNumberIdCalc?: number
	UnitTaxAmountCalc?: number
	ShowTaxInfoLandCalc?: string
	ShowTaxInfoHouseUnitCalc?: string
	ActualPriceDate1Calc?: Date
	ActualPrice1Calc?: number
	DepreciatedDate1Calc?: Date
	DepreciatedAmount1Calc?: number
	Balance1Calc?: number
	ActualPriceDate2Calc?: Date
	ActualPrice2Calc?: number
	DepreciatedDate2Calc?: Date
	DepreciatedAmount2Calc?: number
	Balance2Calc?: number
	ActualPriceDate3Calc?: Date
	ActualPrice3Calc?: number
	DepreciatedDate3Calc?: Date
	DepreciatedAmount3Calc?: number
	Balance3Calc?: number
	UnitPriceCalc?: number
	IsAssetSetCalc?: string
	AcceptsElimBalance1PriceCalc?: number
	AcceptsElimBalance2PriceCalc?: number
	AcceptsElimBalance3PriceCalc?: number
	CurrencyCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	TaxInfoCalc?: string
	ProductionClassificationRID_ver?: number
	RightGroupIdCalc?: number
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => AssetPlanType)
	PlanType3Id?: AssetPlanType
	@Type(() => AssetPlanType)
	PlanType2Id?: AssetPlanType
	@Type(() => AssetPlanType)
	PlanType1Id?: AssetPlanType
	@Type(() => TypVyr)
	RetirementMethodId?: TypVyr
	@Type(() => PurchaseWay)
	AcquisitionMethodId?: PurchaseWay
	@Type(() => AccKindPurch)
	PostingKindId?: AccKindPurch
	@Type(() => MajSkup)
	AssetGroupId?: MajSkup
	@Type(() => FunctType)
	FunctionaltypeId?: FunctType
	@Type(() => ConstrType)
	ConstructionalTypeId?: ConstrType
	@Type(() => ZarStatus)
	StatusId?: ZarStatus
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
	@Type(() => TProducerDM)
	ProducerId?: TProducerDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmOrCancelOrRetireIdCalc?: TTextDM
	@Type(() => TPeriodListDM)
	PeriodFromIdCalc?: TPeriodListDM
	@Type(() => TPeriodListDM)
	PeriodToIdCalc?: TPeriodListDM
	@Type(() => TaxOfficeId)
	LandTaxOfficeIdCalc?: TaxOfficeId
	@Type(() => KindParcel)
	LandParcelTypeLandIdCalc?: KindParcel
	@Type(() => ForestType)
	LandCodeIdCalc?: ForestType
	@Type(() => TTownDM)
	LandTownIdCalc?: TTownDM
	@Type(() => CadTerritory)
	LandCadTerritoryIdCalc?: CadTerritory
	@Type(() => TTextDM)
	LandLegalRelationshipIdCalc?: TTextDM
	@Type(() => TaxOfficeId)
	UnitTaxOfficeIdCalc?: TaxOfficeId
	@Type(() => KindBuild)
	UnitParcelTypeLandIdCalc?: KindBuild
	@Type(() => TTownDM)
	UnitTownIdCalc?: TTownDM
	@Type(() => CadTerritory)
	UnitCadTerritoryIdCalc?: CadTerritory
	@Type(() => TTextDM)
	UnitLegalRelationshipIdCalc?: TTextDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeIdCalc?: TArticleCodeDocumentDM
	@Type(() => TCode1DocumentDM)
	Code1IdCalc?: TCode1DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2IdCalc?: TCode2DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3IdCalc?: TCode3DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4IdCalc?: TCode4DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5IdCalc?: TCode5DocumentDM
	@Type(() => TCode6DocumentDM)
	Code6IdCalc?: TCode6DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerIdCalc?: TOfficerDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRIDCalc?: TDeviceDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolIdCalc?: TMatchingSymbolDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => Pracoviste)
	WorkPlaceIdCalc?: Pracoviste
	@Type(() => TAssetLocationDocumentDM)
	LocationRIDCalc?: TAssetLocationDocumentDM
	@Type(() => TTextDM)
	DepreciatedCalc?: TTextDM
	@Type(() => TAccountingChartDM)
	AcquisitionAccountIdCalc?: TAccountingChartDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TAssetConnectedDocumentDM)
	ConnectedDocumentChild?: TAssetConnectedDocumentDM[]
	@Type(() => TServicedDeviceDM)
	ServicedDeviceChild?: TServicedDeviceDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}