import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPrEmploymentDM from './TPrEmploymentDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TElectronicSubmissionDM from './TElectronicSubmissionDM'

export default class TElectronicSubmissionPersonDM extends Data {
	public static className = 'TElectronicSubmissionPersonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	BookId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TElectronicSubmissionDM)
	ElectronicSubmissionRID?: TElectronicSubmissionDM
}