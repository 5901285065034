import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TEshopStockTextItemDM from './TEshopStockTextItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopStockTextDM extends Data {
	public static className = 'TEshopStockTextDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Name?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedNameCalc?: string
	TranslatedDescriptionCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TEshopStockTextItemDM)
	StockTextChild?: TEshopStockTextItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}