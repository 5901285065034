import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPartnerDM from './TPartnerDM'

export default class TProducerDM extends Data {
	public static className = 'TProducerDM'
	public static primaryKey = 'ProducerId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ProducerId!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	ProducerName?: string
	ProducerId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageNameCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
}