import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TArticleKindDM from './TArticleKindDM'

export default class TBatchCostItemDM extends Data {
	public static className = 'TBatchCostItemDM'
	public static systemFields = [
		'RecordID',
	]

	CostPrice?: number
	StockPrice?: number
	PlannedPrice?: number
	BatchId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM
}