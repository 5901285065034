import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopTextPageDM from './TEshopTextPageDM'
import TEshopPaymentDM from './TEshopPaymentDM'
import TEshopDeliveryDM from './TEshopDeliveryDM'

export default class TEshopDeliveryPaymentDM extends Data {
	public static className = 'TEshopDeliveryPaymentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XmlData?: string
	SequenceNumber?: number
	ShopRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DefaultDeliveryImgCalc?: string
	DefaultPaymentImgCalc?: string
	IsDefaultDeliveryCalc?: string
	IsDefaultPaymentCalc?: string
	UseShippAndPayInconvenientBitCalc?: number
	@Type(() => TEshopTextPageDM)
	PaymentPageRID?: TEshopTextPageDM
	@Type(() => TEshopTextPageDM)
	DeliveryPageRID?: TEshopTextPageDM
	@Type(() => TEshopPaymentDM)
	PaymentRID?: TEshopPaymentDM
	@Type(() => TEshopDeliveryDM)
	DeliveryRID?: TEshopDeliveryDM
}