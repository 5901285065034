import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import WarrantyB from './WarrantyB'
import TBatchDM from './TBatchDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPriceGroupDM from './TPriceGroupDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMarketingPhaseDM from './TMarketingPhaseDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import QuotationReqItemStat from './QuotationReqItemStat'
import TArticleDM from './TArticleDM'
import MrktngDocItemGroup from './MrktngDocItemGroup'
import TQuotationDM from './TQuotationDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TPriceListCalculationDM from './TPriceListCalculationDM'

export default class TQuotationRequestedItemDM extends Data {
	public static className = 'TQuotationRequestedItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CostingBind?: number
	ImplementationDate?: Date
	VATRateId?: number
	RequiredItemRID?: number
	InternalText?: string
	BusinessText?: string
	EstimatedPrice?: number
	CostPrice?: number
	ListPrice?: number
	OfferedPrice?: number
	MeasureUnitId?: number
	Quantity?: number
	ArticleName?: string
	SequenceNumber?: number
	ItemTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	OriginalRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityUMCalc?: number
	CurrencyCalc?: string
	ExchangeRateCalc?: number
	EstimatedUnitPriceCalc?: number
	EstimatedTotalPriceCalc?: number
	EstimatedPriceCCalc?: number
	EstimatedTotalPriceCCalc?: number
	CatalogUnitPriceCalc?: number
	OfferedTotalPriceNetCCalc?: number
	MeasureUnitCalc?: string
	MeasureUnitCoefficientCalc?: number
	ArticleNameCalc?: string
	OfferredTotalDiscountCalc?: number
	OfferedTotalDiscountCCalc?: number
	OfferredTotalProfitCalc?: number
	OfferedTotalProfitCCalc?: number
	OfferredTotalCatalogPriceCalc?: number
	OfferedTotalCatalogPriceCCalc?: number
	OfferredTotalCostPriceCalc?: number
	OfferedTotalCostPriceCCalc?: number
	OfferredTotalPriceCalc?: number
	OfferredDiscountPctCalc?: number
	MainCurrencyCalc?: string
	CoefficientVATCalc?: number
	ImplementationDateCalc?: Date
	NotContainsArticleCalc?: string
	ArticleTypeIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => WarrantyB)
	WarrantyId?: WarrantyB
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TMarketingPhaseDM)
	PhaseRID?: TMarketingPhaseDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => QuotationReqItemStat)
	ItemStatusId?: QuotationReqItemStat
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => MrktngDocItemGroup)
	GroupId?: MrktngDocItemGroup
	@Type(() => TQuotationDM)
	HeaderRID?: TQuotationDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitByArticleIdCalc?: TMeasureUnitDM
	@Type(() => AlternativeUnit)
	GeneralMueasureUnitIdCalc?: AlternativeUnit
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => TPriceListCalculationDM)
	PriceCalculationChild?: TPriceListCalculationDM[]
}