import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TypeOfTestName from './TypeOfTestName'
import DegreeOfKnowledge from './DegreeOfKnowledge'
import LanguageName from './LanguageName'
import TEmployedPersonDM from './TEmployedPersonDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TLanguageSkillDM extends Data {
	public static className = 'TLanguageSkillDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SequenceNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LastCommentParamCalc?: string
	@Type(() => TypeOfTestName)
	LanguageExam?: TypeOfTestName
	@Type(() => DegreeOfKnowledge)
	KnowledgeLevel?: DegreeOfKnowledge
	@Type(() => LanguageName)
	Language?: LanguageName
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}