import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterDM from './TParameterDM'
import TParameterGroupDM from './TParameterGroupDM'

export default class TParameterSetItemDM extends Data {
	public static className = 'TParameterSetItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	GroupSequenceNumber?: number
	ParameterSequenceNumber?: number
	ParameterSetId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	DescriptionCalc?: string
	ValueFromBatchBitCalc?: number
	ValueFromBatchImgCalc?: string
	VariableNameCalc?: string
	ShowFiltValuesBitCalc?: number
	FilterShowRangeBitCalc?: number
	LowerValueBetterBitCalc?: number
	HigherValueBetterBitCalc?: number
	ForVariationBitCalc?: number
	UseComparisonValueBitCalc?: number
	NotShowOnEshopBitCalc?: number
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM
	@Type(() => TParameterGroupDM)
	ParameterGroupId?: TParameterGroupDM
}