import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAllowedMethodItemDM from './TAllowedMethodItemDM'

export default class TAllowedTransportMethodDM extends Data {
	public static className = 'TAllowedTransportMethodDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	MethodTypeId?: number
	RID?: number
	TimeStamp?: string
	Description?: string
	RightGroupId?: number
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TAllowedMethodItemDM)
	AllowedMethodChild?: TAllowedMethodItemDM[]
}