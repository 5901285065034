import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TAccountingJournalDM from './TAccountingJournalDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'

export default class TD_ZarPM2 extends Data {
	public static className = 'TD_ZarPM2'
	public static systemFields = [
		'ItemRIDCalc',
	]

	DMP_RecordMod?: number
	DMP_DetailID?: number
	Zmena?: number
	QDate?: Date
	SumaRocni?: number
	Preselection?: number
	Plan?: string
	Desc?: string
	KdoLik?: number
	DatLik?: number
	Pohyb_P?: number
	Kod?: string
	Cena?: number
	Datum?: Date
	CPl?: number
	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	CFUcDok?: number
	CFProcento?: number
	CFKod?: string
	CFZa?: string
	CFOd?: string
	CFPo?: string
	CFRo?: string
	@Type(() => TAccountingJournalDM)
	AccDocRID?: TAccountingJournalDM
	@Type(() => TTextDM)
	Typ_Pol?: TTextDM
	@Type(() => TPeriodDM)
	CFUPref?: TPeriodDM
}