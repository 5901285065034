import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import CountryGroup from './CountryGroup'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCurrencyDM from './TCurrencyDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TCountryDM extends Data {
	public static className = 'TCountryDM'
	public static primaryKey = 'CountryId'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.CountryId!
	}

	FullCountryName?: string
	CountryCodeN3?: string
	CountryCodeA3?: string
	TimeStamp?: string
	DialingCode?: string
	Settings?: string
	Name?: string
	ChangedOn?: Date
	CountryId?: number
	CountryCode?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CountryIntrastatIdCalc?: number
	LanguageTextCalc?: string
	BlockedRecordBitCalc?: number
	FullCountryNameTranslatedTextCalc?: string
	@Type(() => TTextDM)
	LanguageId?: TTextDM
	@Type(() => CountryGroup)
	GroupId?: CountryGroup
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageFullCountryNameChild?: TLanguageTextTranslationDM[]
}