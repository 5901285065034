import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TLanguageMemoTranslationChildDM extends Data {
	public static className = 'TLanguageMemoTranslationChildDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	TimeStamp?: string
	DetailDataModuleNumber?: number
	LanguageId?: number
	Memo?: string
	Format?: number
	FieldNumber?: number
	ItemId?: number
	MasterRID?: number
	HeaderTypeId?: number
	HeaderBook?: string
	HeaderDataModuleNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FieldNameCalc?: string
}