import Data from '../Api/Data'

export default class TUserRoleRightExtensionDM extends Data {
	public static className = 'TUserRoleRightExtensionDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	WarehouseAbbr?: string
	WarehouseId?: number
	BookAbbr?: string
	BookId?: number
	DocumentAction?: string
	SectionModule?: string
	SectionSection?: string
	Section?: number
	Client?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}