import Data from '../Api/Data'
import { Type } from 'class-transformer'
import DocumentLinkType from './DocumentLinkType'
import TTextDM from './TTextDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'

export default class TAttachedDocumentDM extends Data {
	public static className = 'TAttachedDocumentDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	JoinTypeId?: string
	NodeId?: number
	UniqId?: number
	SequenceNumber?: number
	Book?: string
	TypeId?: number
	TableNumberId?: number
	DocumentRID?: number
	DocumentBook?: string
	DocumentTypeId?: number
	RID?: number
	RecordStateImgCalc?: string
	DescriptionCalc?: string
	PriceCalc?: number
	DeliveryConfirmationRIDCalc?: number
	DeliveryNoteRIDCalc?: number
	InvoiceInRIDCalc?: number
	InvoiceOutRIDCalc?: number
	OrderConfirmationRIDCalc?: number
	PurchaseOrderRIDCalc?: number
	ReceiptCardRIDCalc?: number
	ReservingCardRIDCalc?: number
	ReleaseNoteRIDCalc?: number
	SalesOrderRIDCalc?: number
	ItemRIDCalc?: number
	OpportunityIdCalc?: number
	ActivityIdCalc?: number
	CashDocumentRIDCalc?: number
	PartnerIdCalc?: number
	ContactPersonIdCalc?: number
	ArticleIdCalc?: number
	ToTradingPartnerIdCalc?: number
	AssetIdCalc?: number
	JobCardRIDCalc?: number
	TransferNoteRIDCalc?: number
	AccountingDocumentRIDCalc?: number
	MailIdCalc?: number
	SADReceiptIdCalc?: number
	SADWarehouseIdCalc?: number
	SADReleaseIdCalc?: number
	SADRealisationIdCalc?: number
	SADTransitIdCalc?: number
	SADSimpleReceiptIdCalc?: number
	SADSimpleReleaseIdCalc?: number
	WorkflowProcessIdCalc?: number
	BankOrderRIDCalc?: number
	BankStatementRIDCalc?: number
	DeliveryOrderRIDCalc?: number
	DocumentIdCalc?: number
	AdvanceReceivedRIDCalc?: number
	AdvanceProvidedRIDCalc?: number
	PostingChequeRIDCalc?: number
	PostSentRIDCalc?: number
	PostReceivedRIDCalc?: number
	CollectionIdCalc?: number
	ShipmentRIDCalc?: number
	ProductionOrderRIDCalc?: number
	JobIdCalc?: number
	InternalDocumentRIDCalc?: number
	DocumentIdentifierCalc?: string
	ServiceNoteRIDCalc?: number
	ServiceContractRIDCalc?: number
	QuotationRIDCalc?: number
	RequestForQuotationRIDCalc?: number
	ReminderRIDCalc?: number
	PrLiabilityRIDCalc?: number
	VehicleRIDCalc?: number
	QualificationRIDCalc?: number
	EmployedPersonRIDCalc?: number
	PrEmploymentRIDCalc?: number
	RoutingVariantIdCalc?: number
	ProjectRIDCalc?: number
	ProjectSPORIDCalc?: number
	ProjectTimeSheetRIDCalc?: number
	OtherReceivableRIDCalc?: number
	OtherLiabilityRIDCalc?: number
	VORIdCalc?: number
	DeviceRIDCalc?: number
	VATIdCalc?: number
	TravelOrderRIDCalc?: number
	BatchIdCalc?: number
	ResourceIdCalc?: number
	PrEmployeeDocumentRIDCalc?: number
	PrPersonDocumentRIDCalc?: number
	ProjectJobQueueRIDCalc?: number
	EshopCategoryRIDCalc?: number
	EshopRIDCalc?: number
	EshopTextPageRIDCalc?: number
	EshopBannerRIDCalc?: number
	EshopRegistrationRIDCalc?: number
	ItemPurchaseRIDCalc?: number
	ItemSaleRIDCalc?: number
	PaymentItemRIDCalc?: number
	PrComponentOutputItemRIDCalc?: number
	AssetRIDCalc?: number
	AssetStockTakingRIDCalc?: number
	AssetStockTakingListRIDCalc?: number
	SalesContractRIDCalc?: number
	PurchaseContractRIDCalc?: number
	OtherContractRIDCalc?: number
	AssetMovementRIDCalc?: number
	OneStopShopRIDCalc?: number
	HandlingUnitRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => DocumentLinkType)
	LinkTypeId?: DocumentLinkType
	@Type(() => TTextDM)
	TableReferenceId?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	AddresseeTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
}