import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TOperationDM from './TOperationDM'
import TJobCardDM from './TJobCardDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAttendanceTaskWorkDM extends Data {
	public static className = 'TAttendanceTaskWorkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TaskWorkDate?: Date
	Amount?: number
	HoursCount?: number
	BookId?: number
	WorkTimeReportRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TOperationDM)
	OperationId?: TOperationDM
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}