import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'

export default class TEshopCalculatedStatisticsDM extends Data {
	public static className = 'TEshopCalculatedStatisticsDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	RID?: number
	NewsPriority?: number
	Rating?: number
	VisitPriority?: number
	VisitCount?: number
	SoldPriority?: number
	SoldQuantity?: number
	ShopRID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AssignedCalc?: number
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}