import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TCurrencyCountryExchangeRateDM extends Data {
	public static className = 'TCurrencyCountryExchangeRateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	IsCurrent?: string
	CompanyId?: number
	ValutaExchangeRateSale?: number
	ValutaExchangeRateMiddle?: number
	ValutaExchangeRatePurchase?: number
	ForeignExchangeRateSale?: number
	ForeignExchangeRateMiddle?: number
	ForeignExchangeRatePurchase?: number
	MainCurrencyUnit?: number
	ExchangeRateDate?: Date
	Currency?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BuyNonCashAmountCalc?: number
	MiddleNonCashAmountCalc?: number
	SellNonCashAmountCalc?: number
	BuyCashAmountCalc?: number
	MiddleCashAmountCalc?: number
	SellCashAmountCalc?: number
}