import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TSuperiorDocumentDM extends Data {
	public static className = 'TSuperiorDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SuperiorRID?: number
	HeaderRID?: number
	HeaderTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}