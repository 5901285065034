import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'

export default class TContactPersonPartnerDM extends Data {
	public static className = 'TContactPersonPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AuxiliaryRID?: number
	MasterRID?: number
	LinkTypeId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DefaultValueImgCalc?: string
	AdministratorIdCalc?: number
	@Type(() => TPartnerDM)
	SlaveRID?: TPartnerDM
}