import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

export default class TSubordinateResourceDM extends Data {
	public static className = 'TSubordinateResourceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'ItemRIDCalc',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SupplementId?: number
	ArticleId?: number
	Percentage?: number
	Book2?: string
	DocumentRID1?: number
	Book1?: string
	RecordTypeId?: number
	RecordStateImgCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => TResourceDM)
	DocumentRID2?: TResourceDM
}