import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TPriceListDM from './TPriceListDM'

export default class TPriceListArticleDM extends Data {
	public static className = 'TPriceListArticleDM'
	public static systemFields = [
		'RecordID',
	]

	ValidToDate?: Date
	ValidFromDate?: Date
	PriceGross?: number
	PriceNet?: number
	Quantity?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	MemoryRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ValidFromEffectiveCalc?: Date
	ValidToEffectiveCalc?: Date
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
}