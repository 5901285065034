import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEkoKomPackagingDM from './TEkoKomPackagingDM'

export default class TEkoKomItemDM extends Data {
	public static className = 'TEkoKomItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PackTypeRID?: number
	Weight?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TEkoKomPackagingDM)
	PackagingRID?: TEkoKomPackagingDM
}