import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TAssetLinkItemDM extends Data {
	public static className = 'TAssetLinkItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Description?: string
	Amount?: number
	DocumentDate?: Date
	DocumentNumberItemRID?: number
	DocumentTypeId?: number
	HeaderRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ICItemRIDCalc?: number
	CaItemRIDCalc?: number
	IIItemRIDCalc?: number
	IOItemRIDCalc?: number
	RNItemRIDCalc?: number
	DocIdentificationCalc?: string
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	DocTypeCalc?: TTextDM
	@Type(() => TTextDM)
	DocTypeShowCalc?: TTextDM
}