import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'

export default class TWarehouseZoneStockLevelDM extends Data {
	public static className = 'TWarehouseZoneStockLevelDM'
	public static systemFields = [
		'RecordID',
	]

	Abbr?: string
	MaxLevel?: number
	MinLevel?: number
	BookValue?: number
	Dispatch?: number
	Assigned?: number
	Available?: number
	Received?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}