import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'
import TTextDM from './TTextDM'
import TJobCardDM from './TJobCardDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'

export default class TJobCardScheduleQueueDM extends Data {
	public static className = 'TJobCardScheduleQueueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	JobCardBindRID?: number
	Member?: string
	SequenceNumber?: number
	IsManuallyAdjustedSchedule?: string
	Duration?: number
	Quantity?: number
	WorkPlaceRID?: number
	CalendarId?: number
	IsArchived?: string
	Description?: string
	WorkshiftDate?: Date
	WorkshiftRID?: number
	TimeFrom?: Date
	Date?: Date
	JobCardResourceRID?: number
	JobCardOperationRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentCalc?: string
	DateFromCalc?: Date
	DateToCalc?: Date
	DurationCalc?: number
	DurationUMCalc?: number
	OperationCodeCalc?: string
	PlannedTimeToCalc?: Date
	MemberNumberCalc?: number
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
}