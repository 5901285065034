import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAttendanceMovementSubTypeDM from './TAttendanceMovementSubTypeDM'
import TAttendanceMovementDM from './TAttendanceMovementDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAttendanceProcessedMovementDM extends Data {
	public static className = 'TAttendanceProcessedMovementDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	MovementDate?: Date
	BookId?: number
	WorkTimeReportItemRID?: number
	WorkTimeReportRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DescriptionCalc?: string
	RoundedMovementDateCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	OriginTypeId?: TTextDM
	@Type(() => TAttendanceMovementSubTypeDM)
	MovementSubTypeRID?: TAttendanceMovementSubTypeDM
	@Type(() => TTextDM)
	MovementTypeId?: TTextDM
	@Type(() => TAttendanceMovementDM)
	MovementRID?: TAttendanceMovementDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}