import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrTariffLevelDM from './TPrTariffLevelDM'

export default class TPrTariffDateDM extends Data {
	public static className = 'TPrTariffDateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	RightGroupId?: number
	PrTariffRID?: number
	ValidFrom?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	LevelTo?: TTextDM
	@Type(() => TTextDM)
	LevelFrom?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	GradeTo?: TTextDM
	@Type(() => TTextDM)
	GradeFrom?: TTextDM
	@Type(() => TPrTariffLevelDM)
	PrTariffLevelChild?: TPrTariffLevelDM[]
}