import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TOpenItemListTrendDM extends Data {
	public static className = 'TOpenItemListTrendDM'
	public static systemFields = [
	]

	RecordID?: number
	TableId?: number
	AmountBefore?: number
	AmountAfter?: number
	DocumentIdentification?: string
	RID?: number
	Date?: Date
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	@Type(() => TTextDM)
	TypeId?: TTextDM
}