import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAdressKeyDM from './TAdressKeyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import AlternativeUnit from './AlternativeUnit'
import TMeasureUnitDM from './TMeasureUnitDM'
import TEkoKomItemDM from './TEkoKomItemDM'

export default class TEkoKomArticlePackDM extends Data {
	public static className = 'TEkoKomArticlePackDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ValidTo?: Date
	ValidFrom?: Date
	Name?: string
	ArticleId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTextDM)
	PackRestriction?: TTextDM
	@Type(() => TAdressKeyDM)
	CustomerKeyRID?: TAdressKeyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TEkoKomItemDM)
	EkokomItemChild?: TEkoKomItemDM[]
}