import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCommentDM from './TCommentDM'

export default class TPeriodicityDM extends Data {
	public static className = 'TPeriodicityDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsTerm?: string
	IsBlockedRecord?: string
	PeriodCount?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PeriodicityDescriptionCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	PeriodUnitId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
}