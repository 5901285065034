import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TElectronicSubmissionDM from './TElectronicSubmissionDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TPrEmployeeSickNoteMessageDM extends Data {
	public static className = 'TPrEmployeeSickNoteMessageDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XmlVersion?: string
	NotificationDate?: Date
	PrEmployeeSickNoteRID?: number
	XMLField?: string
	NotificationNumber?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TElectronicSubmissionDM)
	ElectronicSubmissionRID?: TElectronicSubmissionDM
	@Type(() => TTextDM)
	NotificationTypeId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}