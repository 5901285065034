import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TLocationArticleDM from './TLocationArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'

export default class TArticleWarehouseDM extends Data {
	public static className = 'TArticleWarehouseDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Settings?: string
	ReleaseEstimate?: number
	DeadStockDayMaximum?: number
	DeadStockDayMinimum?: number
	Maximum?: number
	Minimum?: number
	ArticleId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	MeasureUnitCalc?: string
	InfWarehouseMinLevelUMCalc?: number
	InfWarehouseMaxLevelUMCalc?: number
	ReleaseQuantityCalc?: number
	MinLevelRecordsBitCalc?: number
	MaxLevelRecordsBitCalc?: number
	DeadStockBitCalc?: number
	AllowNegativeStockLevelCalc?: number
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TTextDM)
	MonthTo?: TTextDM
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM
	@Type(() => TLocationArticleDM)
	OptimalLocationId?: TLocationArticleDM
	@Type(() => TTradingPartnerDM)
	OptimalSupplierId?: TTradingPartnerDM
	@Type(() => TTextDM)
	MonthFrom?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
}