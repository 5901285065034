import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPackageTypeItemDM from './TPackageTypeItemDM'

export default class TPackageTypeDM extends Data {
	public static className = 'TPackageTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'Timestamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Timestamp?: string
	ChangedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPackageTypeItemDM)
	TransportMethodChild?: TPackageTypeItemDM[]
}