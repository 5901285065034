import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TBankOldDM from './TBankOldDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

export default class TCachedBookDM extends Data {
	public static className = 'TCachedBookDM'
	public static primaryKey = 'ID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.ID!
	}

	BookModule?: number
	ID?: number
	Abbr?: string
	CompanyId?: number
	Description?: string
	LocalizedDescription?: string
	RightGroup?: number
	DLCH?: Date
	CollectionItem?: number
	SelectionImgCalc?: string
	@Type(() => TBankOldDM)
	RightGroupStr?: TBankOldDM
	@Type(() => TK2UserLookupDM)
	DLCH_Who?: TK2UserLookupDM
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
}