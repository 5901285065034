import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'

export default class TJobCardSubordinateDocumentDM extends Data {
	public static className = 'TJobCardSubordinateDocumentDM'
	public static systemFields = [
		'RecordID',
	]

	SubordinateJobCardItemRID?: number
	HeaderRID1?: number
	SuperiorBindId?: number
	SuperiorDocumentType?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	Final?: number
	BatchAbbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	OwnPlannedQuantityCalc?: number
	OwnActualQuantityCalc?: number
	OwnPlannedQuantityUMCalc?: number
	OwnActualQuantityUMCalc?: number
	@Type(() => TJobCardDM)
	HeaderRID2?: TJobCardDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
}