import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TArticleDM from './TArticleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import Coupon from './Coupon'
import TCurrencyDM from './TCurrencyDM'
import TContactPersonDM from './TContactPersonDM'
import TSalesActionDM from './TSalesActionDM'
import CouponStatus from './CouponStatus'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TDiscountCouponDM extends Data {
	public static className = 'TDiscountCouponDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'Timestamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	LoyaltyProgramGroupRID?: number
	UseForVariousCurrency?: string
	SalesItemRID?: number
	IsVoucher?: string
	Description?: string
	MaxDiscount?: number
	MaxRepetition?: number
	Timestamp?: string
	ChangedOn?: Date
	Discount?: number
	ValidFromPrice?: number
	ValidDays?: number
	ValidToDate?: Date
	ValidFromDate?: Date
	CouponCode?: string
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	IsUnlimitedCalc?: string
	ConfirmedBitCalc?: number
	UniqueTradingPartnerBitCalc?: number
	FreeTransportBitCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedDescriptionCalc?: string
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => Coupon)
	SupplementId?: Coupon
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => CouponStatus)
	StatusId?: CouponStatus
	@Type(() => TTextDM)
	PercentageDiscountBitCalc?: TTextDM
	@Type(() => TTextDM)
	NetDiscountBitCalc?: TTextDM
	@Type(() => TTextDM)
	UsingIdCalc?: TTextDM
	@Type(() => TTextDM)
	SingleUseBitCalc?: TTextDM
	@Type(() => TTextDM)
	RepeatableBitCalc?: TTextDM
	@Type(() => TTextDM)
	RoundToUnitBitCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TSalesItemBookDM)
	SalesItemChild?: TSalesItemBookDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}