import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TSupplierPriceListDM from './TSupplierPriceListDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ArticleCode2 from './ArticleCode2'
import ArticleCode1 from './ArticleCode1'
import TRoutingVariantDM from './TRoutingVariantDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TArticleDM from './TArticleDM'

export default class TSupplierPriceDM extends Data {
	public static className = 'TSupplierPriceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	BookId?: number
	TimeStamp?: string
	RID?: number
	MinPurchaseQuantity?: number
	ChangedOn?: Date
	ContractCode?: string
	SupplierProductGroupId?: number
	KeyCode?: string
	DateTo?: Date
	Description?: string
	Available?: number
	DeliveryQuantity?: number
	DeliveryDayCount?: number
	Price?: number
	Code?: string
	Date?: Date
	Quantity?: number
	RecordID?: number
	Ex_DoNotRecalculate?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RealNumberQuantityCalc?: number
	QuantityUMCalc?: number
	PriceNetCalc?: number
	DiscountAdjustedPriceNetCalc?: number
	DiscountAdjustedPriceGrossCalc?: number
	SupplierPriceGrossCalc?: number
	VATRateCalc?: number
	TradingPartnerAbbrCalc?: string
	ContractCodeIdCalc?: number
	DeliveryQuantityUMCalc?: number
	MinPurchaseQuantityUMCalc?: number
	AvailableQuantityUMCalc?: number
	VATCoefficientCalc?: number
	@Type(() => TSupplierPriceListDM)
	SupplierPriceListRID?: TSupplierPriceListDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => ArticleCode2)
	SupplierCode2?: ArticleCode2
	@Type(() => ArticleCode1)
	SupplierCode1?: ArticleCode1
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}