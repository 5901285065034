import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCurrencyDM from './TCurrencyDM'

export default class TEshopFreeDeliveryDM extends Data {
	public static className = 'TEshopFreeDeliveryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	UseForOtherCurrencies?: string
	PriceFrom?: number
	DeliveryRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
}