import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TPrPeriodDM from './TPrPeriodDM'
import PrCalTypDay from './PrCalTypDay'
import TPrEmploymentDM from './TPrEmploymentDM'

export default class TPrCalendarEmploymentItemDayDM extends Data {
	public static className = 'TPrCalendarEmploymentItemDayDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	BookId?: number
	RID?: number
	HeaderRID?: number
	ComponentRID?: number
	AddTime?: number
	BreakTime?: number
	TotalTime?: number
	DateTo?: Date
	DateFrom?: Date
	TypeId?: number
	CodeCalendarId?: number
	StringValue3?: string
	StringValue2?: string
	ShortValue?: number
	LongValue?: number
	TimeTotal?: number
	PrComponentId?: number
	Description?: string
	TimeTo?: number
	TimeFrom?: number
	Date?: Date
	CalendarRecordTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PeriodIdCalc?: number
	@Type(() => TTextDM)
	CalendarRecordDetailTypeId?: TTextDM
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	PriorityId?: TTextDM
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TTextDM)
	CalendarSourceTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	WeekDayIdCalc?: TTextDM
}