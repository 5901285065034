import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TParameterPossibleValueListDM extends Data {
	public static className = 'TParameterPossibleValueListDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ComparisonValue?: number
	RID?: number
	ArticleId?: number
	Description?: string
	Abbr?: string
	ParameterId?: number
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SequenceNumberCalc?: number
}