import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'

export default class TReceivableAdjustingEntryTypeDM extends Data {
	public static className = 'TReceivableAdjustingEntryTypeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Percentage?: number
	DocumentCardCancelId?: number
	IsForAccounting?: string
	PriorityId?: number
	Description?: string
	Abbr?: string
	Conditions?: string
	DocumentCardId?: number
	DateTo?: Date
	DateFrom?: Date
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	CurrencyCalc?: string
	DelayDaysMinCalc?: number
	DelayDaysMaxCalc?: number
	DelayMonthsMinCalc?: number
	DelayMonthsMaxCalc?: number
	ValueMinCalc?: number
	ValueMaxCalc?: number
	ReceivablesFromTotalValueCalc?: number
	ReceivablesToTotalValueCalc?: number
	IsInsolventCompanyCalc?: string
	ImportExportTypeCalc?: string
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
}