import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TCurrencyDM from './TCurrencyDM'
import TK2UserDM from './TK2UserDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TLoyaltyProgramDefinitionDM from './TLoyaltyProgramDefinitionDM'
import TLoyaltyProgramSpecificationDM from './TLoyaltyProgramSpecificationDM'
import TLoyaltyProgramItemDM from './TLoyaltyProgramItemDM'
import TLoyaltyProgramItemHistoryDM from './TLoyaltyProgramItemHistoryDM'
import TLoyaltyProgramCouponDM from './TLoyaltyProgramCouponDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TLoyaltyProgramGiftDM from './TLoyaltyProgramGiftDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TLoyaltyProgramDM extends Data {
	public static className = 'TLoyaltyProgramDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsPaidToDueDate?: string
	IsCanceledRecord?: string
	ConfirmedOn?: Date
	Active?: string
	PageAnalytics?: string
	PageUrlCanonical?: string
	PageUrl?: string
	PageKeyWords?: string
	PageDescription?: string
	PageTitle?: string
	DrawingLimitDate?: Date
	IsDefinedContactPerson?: string
	IsDefinedShipping?: string
	IsDefinedPayment?: string
	IsDefinedBook?: string
	IsDefinedPriceGroup?: string
	IsDefinedCustomerKey?: string
	IsDefinedCustomer?: string
	CollectingRate?: number
	PointLifespan?: number
	Number?: number
	ValidTo?: Date
	ValidFrom?: Date
	Name?: string
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	PictureCalc?: string
	RateSuffixCalc?: string
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	TranslatedNameCalc?: string
	TranslatedDescriptionCalc?: string
	TranslatedPageTitleCalc?: string
	TranslatedPageDescriptionCalc?: string
	TranslatedPageKeyWordsCalc?: string
	TranslatedPageUrlCalc?: string
	TranslatedPageAnalyticsCalc?: string
	@Type(() => TK2UserLookupDM)
	ConfirmedBy?: TK2UserLookupDM
	@Type(() => TTextDM)
	OpenGraphTypeId?: TTextDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTextDM)
	CollectingTypeId?: TTextDM
	@Type(() => TTextDM)
	DefiniteTypeId?: TTextDM
	@Type(() => TTextDM)
	IsDefinedEshop?: TTextDM
	@Type(() => TTextDM)
	PointLifeSpanRepeat?: TTextDM
	@Type(() => TTextDM)
	PointLifespanType?: TTextDM
	@Type(() => TK2UserDM)
	ChangedById?: TK2UserDM
	@Type(() => TK2UserDM)
	CreatedById?: TK2UserDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLoyaltyProgramDefinitionDM)
	DefinitionChild?: TLoyaltyProgramDefinitionDM[]
	@Type(() => TLoyaltyProgramSpecificationDM)
	SpecificationChild?: TLoyaltyProgramSpecificationDM[]
	@Type(() => TLoyaltyProgramItemDM)
	ItemChild?: TLoyaltyProgramItemDM[]
	@Type(() => TLoyaltyProgramItemHistoryDM)
	ItemHistoryChild?: TLoyaltyProgramItemHistoryDM[]
	@Type(() => TLoyaltyProgramCouponDM)
	CouponChild?: TLoyaltyProgramCouponDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TLoyaltyProgramGiftDM)
	GiftChild?: TLoyaltyProgramGiftDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}