import Data from '../Api/Data'

export default class TUserRoleObjectRowDM extends Data {
	public static className = 'TUserRoleObjectRowDM'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	TimeStamp?: string
	Flags?: number
	Client?: string
	UserId?: number
	Level?: number
	ObjectId?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ObjectStorageIdCalc?: string
}