import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TTextDM from './TTextDM'

export default class TTaxSummaryDM extends Data {
	public static className = 'TTaxSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	HeaderRID?: number
	HeaderTypeId?: number
	AmountGrossC?: number
	AmountVATC?: number
	AmountNetC?: number
	AmountGross?: number
	AmountVAT?: number
	AmountNet?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	NormalItemsCount?: number
	TotalNetC?: number
	TotalNet?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	HeaderCurrencyCalc?: string
	CompanyCurrencyCalc?: string
	ItemAmountNetCalc?: number
	ItemAmountVATCalc?: number
	ItemAmountNetCCalc?: number
	MultiDocumentAmountNetCCalc?: number
	AccountAmountNetCalc?: number
	AccountAmountVATCalc?: number
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTextDM)
	ChangedAmountBitCalc?: TTextDM
	@Type(() => TTextDM)
	VATSwitchBitCalc?: TTextDM
	@Type(() => TTextDM)
	AllocatedBitCalc?: TTextDM
	@Type(() => TTextDM)
	NotOnlyAdvanceBitCalc?: TTextDM
}