import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDividedBookDM from './TCompanyDividedBookDM'
import TWarehouseDM from './TWarehouseDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TLocationArticleDM from './TLocationArticleDM'
import TJobCardConfigFieldDM from './TJobCardConfigFieldDM'
import TOwnCompaniesJobCardConfigDM from './TOwnCompaniesJobCardConfigDM'

export default class TJobCardConfigDM extends Data {
	public static className = 'TJobCardConfigDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	RightGroupId?: number
	Name?: string
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TCompanyDividedBookDM)
	PlanningJCBookIdCalc?: TCompanyDividedBookDM
	@Type(() => TCompanyDividedBookDM)
	CompletionJCBookIdCalc?: TCompanyDividedBookDM
	@Type(() => TWarehouseDM)
	FinalWarehouseIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	ConsumptionWarehouseIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WIPWarehouseIdCalc?: TWarehouseDM
	@Type(() => TWarehouseDM)
	RejectionWarehouseIdCalc?: TWarehouseDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TLocationArticleDM)
	FinalLocationIdCalc?: TLocationArticleDM
	@Type(() => TLocationArticleDM)
	ConsumptionLocationIdCalc?: TLocationArticleDM
	@Type(() => TLocationArticleDM)
	WIPLocationIdCalc?: TLocationArticleDM
	@Type(() => TLocationArticleDM)
	RejectionLocationIdCalc?: TLocationArticleDM
	@Type(() => TJobCardConfigFieldDM)
	AddedFieldChild?: TJobCardConfigFieldDM[]
	@Type(() => TOwnCompaniesJobCardConfigDM)
	CompanyChild?: TOwnCompaniesJobCardConfigDM[]
}