import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationDm from './TLegislationDm'
import ISMUnit from './ISMUnit'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TIntrastatTariffDutyDM extends Data {
	public static className = 'TIntrastatTariffDutyDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Duty?: number
	Description?: string
	ValidTo?: Date
	ValidFrom?: Date
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	BlockedRecordBitCalc?: number
	LanguageDescriptionCalc?: string
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
	@Type(() => ISMUnit)
	MeasureUnitId?: ISMUnit
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
}