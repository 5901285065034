import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TContactPersonDM from './TContactPersonDM'
import TRNContent from './TRNContent'
import TAddressDM from './TAddressDM'
import TRNGoal from './TRNGoal'
import TRNDesignate from './TRNDesignate'
import TTextDM from './TTextDM'
import TPartnerDM from './TPartnerDM'
import TRNForm from './TRNForm'
import TRNCourse from './TRNCourse'
import TRNTyp from './TRNTyp'
import TK2UserLookupDM from './TK2UserLookupDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TCompanyLinkDM from './TCompanyLinkDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TQualificationModelDM extends Data {
	public static className = 'TQualificationModelDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Place?: string
	IsBlockedRecord?: string
	MaxCapacity?: number
	URL?: string
	MinCapacity?: number
	Period?: number
	Position?: string
	Duration?: number
	Costs?: number
	Name?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TContactPersonDM)
	Tutor2Id?: TContactPersonDM
	@Type(() => TRNContent)
	ContentId?: TRNContent
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TRNGoal)
	GoalId?: TRNGoal
	@Type(() => TRNDesignate)
	DesignateId?: TRNDesignate
	@Type(() => TTextDM)
	PeriodUnitId?: TTextDM
	@Type(() => TContactPersonDM)
	TutorId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TPartnerDM)
	OrganizedById?: TPartnerDM
	@Type(() => TRNForm)
	ExamFormId?: TRNForm
	@Type(() => TRNCourse)
	CourseId?: TRNCourse
	@Type(() => TRNTyp)
	TypeId?: TRNTyp
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TCompanyLinkDM)
	CompanyChild?: TCompanyLinkDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}