import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPeriodDM from './TPeriodDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TAssetLocationDocumentDM from './TAssetLocationDocumentDM'
import Pracoviste from './Pracoviste'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import AssetPlanType from './AssetPlanType'
import TAssetDM from './TAssetDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAssetMovementAnalyticalDimensionDM extends Data {
	public static className = 'TAssetMovementAnalyticalDimensionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CorrectionAmount?: number
	RetirementByScrapping?: string
	IsDepreciationInterrupted?: string
	AssetNumber?: number
	IsCorrectPosting?: string
	PostZeroAmount?: string
	PostedOn?: Date
	IsShortenTransitionalPeriod?: string
	IsExtendedTransitionalPeriod?: string
	IsWorkPlaceEmpty?: string
	IsLocationEmpty?: string
	ToBePosted?: string
	IsCustomerEmpty?: string
	IsMatchingSymbolEmpty?: string
	IsDeviceEmpty?: string
	IsOfficerEmpty?: string
	IsCode6Empty?: string
	IsCode5Empty?: string
	IsCode4Empty?: string
	IsCode3Empty?: string
	IsCode2Empty?: string
	IsCode1Empty?: string
	IsArticleCodeEmpty?: string
	IsContractCodeEmpty?: string
	IsCostCentreEmpty?: string
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	ConfirmedOn?: Date
	IsCanceledRecord?: string
	RightGroupId?: number
	DepreciationPlanRID?: number
	DepreciationPresetRID?: number
	PostingKeyId?: number
	Description?: string
	Amount2?: number
	Amount?: number
	Number?: number
	MoveDate?: Date
	InternalTypeId?: number
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	PercentCalc?: number
	ActualPercentCalc?: number
	ActualPriceCalc?: number
	DepreciatedAmountCalc?: number
	CurrentBalanceCalc?: number
	TotalPriceCalc?: number
	MoveTypeIdCalc?: number
	CurrencyCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	CorrectPostingBitCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TPeriodDM)
	AssetBusinessYearId?: TPeriodDM
	@Type(() => TK2UserLookupDM)
	PostedById?: TK2UserLookupDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TAssetLocationDocumentDM)
	LocationRID?: TAssetLocationDocumentDM
	@Type(() => Pracoviste)
	WorkPlaceId?: Pracoviste
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TTextDM)
	MoveTypeId?: TTextDM
	@Type(() => AssetPlanType)
	PlanTypeId?: AssetPlanType
	@Type(() => TAssetDM)
	AssetRID?: TAssetDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}