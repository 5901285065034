import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TArticleDM from './TArticleDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOperationDM from './TOperationDM'
import TTextDM from './TTextDM'

export default class TProductionOrderActualDM extends Data {
	public static className = 'TProductionOrderActualDM'
	public static primaryKey = 'RowNumber'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RowNumber!
	}

	RecordID?: number
	RowNumber?: number
	IsFinished?: string
	TransitTime?: number
	RunTime?: number
	SetupTime?: number
	ActualValue?: number
	PlanValue?: number
	OperationCompletion?: string
	JobCardTypeId?: number
	OperationText?: string
	ScrapCost?: number
	ScrapQuantity?: number
	MeasureUnit?: string
	Hidden?: number
	OperationConfirmationDate?: Date
	ActualDateTo?: Date
	ActualDateFrom?: Date
	LimitDate2?: Date
	LimitDate1?: Date
	PlannedDate2?: Date
	PlannedDate1?: Date
	ActualDuration?: number
	PlannedTime?: number
	ActualAmount?: number
	PlannedCostPrice?: number
	ActualQuantity?: number
	PlannedQuantity?: number
	OperationName?: string
	OperationCode?: string
	OperationSequenceNumber?: number
	JobCardOperationRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	SQLSequenceOrderCalc?: number
	JobCardCalc?: string
	QuantityPctCalc?: number
	CostPctCalc?: number
	SpentTimePercentageCalc?: number
	OperationDescriptionCalc?: string
	PlannedAllOperationQuantityCalc?: number
	ActualQuantityCalc?: number
	PlannedUnitIncidentalCostCalc?: number
	ActualCostPriceCalc?: number
	PlannedTimeCalc?: number
	ActualDurationCalc?: number
	FaultyQuantityCalc?: number
	FaultyCostAmountCalc?: number
	PercentageCompleteCalc?: number
	PlannedCoefficientCalc?: number
	ActualCoefficientCalc?: number
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
	@Type(() => TArticleDM)
	ProductId?: TArticleDM
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM
	@Type(() => TOperationDM)
	OperationId?: TOperationDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
}