import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TWarehouseDM from './TWarehouseDM'
import AlternateStockTextMeaning from './AlternateStockTextMeaning'
import AlternateStockText from './AlternateStockText'
import TLinkDM from './TLinkDM'

export default class TEshopStockTextItemAloneDM extends Data {
	public static className = 'TEshopStockTextItemAloneDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	QuantityFrom?: number
	StockTextRID?: number
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => AlternateStockTextMeaning)
	TextMeaningId?: AlternateStockTextMeaning
	@Type(() => AlternateStockText)
	TextId?: AlternateStockText
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
}