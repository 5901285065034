import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ShopPaymentStatus from './ShopPaymentStatus'
import ShopOrderStatus from './ShopOrderStatus'
import TTextDM from './TTextDM'
import TContactPersonDM from './TContactPersonDM'
import TEshopDM from './TEshopDM'
import TSalesOrderDM from './TSalesOrderDM'
import TEshopPaymentDM from './TEshopPaymentDM'
import TEshopDeliveryDM from './TEshopDeliveryDM'
import TCountryDM from './TCountryDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCurrencyDM from './TCurrencyDM'
import TPartnerDM from './TPartnerDM'
import TEshopOrderItemDM from './TEshopOrderItemDM'

export default class TEshopOrderDM extends Data {
	public static className = 'TEshopOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	QuotationRID?: number
	TransactionId?: string
	ContactPhone?: string
	ContactEmail?: string
	ContactLastName?: string
	ContactFirstName?: string
	IPv6Address?: string
	IPAddress?: string
	OrderDate?: Date
	XMLData?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	OrderDateCalc?: Date
	OrderTimeCalc?: Date
	XmlReportCalc?: string
	ConMarketingInfoCalc?: string
	DeliveryAddressNameCalc?: string
	DeliveryAddressPlaceCalc?: string
	DeliveryAddressEmailCalc?: string
	DeliveryAddressPhoneCalc?: string
	DeliveryAddressStreetCalc?: string
	DeliveryAddressHouseNumberCalc?: string
	DeliveryAddressTownCalc?: string
	DeliveryAddressPostCodeCalc?: string
	IsInvoiceAddressIdEnteredCalc?: string
	InvoiceAddressIdNameCalc?: string
	InvoiceAddressIdNumberCalc?: string
	InvoiceAddressIdVatNumberCalc?: string
	InvoiceAddressIdStreetCalc?: string
	InvoiceAddressIdrNumberCalc?: string
	InvoiceAddressIdTownCalc?: string
	InvoiceAddressIdPostCodeCalc?: string
	AdditionalInformation2Calc?: string
	EshopBasketRIDCalc?: number
	AdditionalInformationCalc?: string
	OrderNumberCalc?: string
	AllowSendByPartCalc?: string
	PaidAmountCalc?: number
	InvoiceAddressIdTaxNumberCalc?: string
	DeliveryTermCalc?: Date
	ReportHTMLCalc?: string
	@Type(() => ShopPaymentStatus)
	PaymentStatusId?: ShopPaymentStatus
	@Type(() => ShopOrderStatus)
	OrderStatusId?: ShopOrderStatus
	@Type(() => TTextDM)
	LanguageId?: TTextDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
	@Type(() => TEshopPaymentDM)
	PaymentMethodRIDCalc?: TEshopPaymentDM
	@Type(() => TEshopDeliveryDM)
	TransportMethodRIDCalc?: TEshopDeliveryDM
	@Type(() => TCountryDM)
	DeliveryAddressCountryIdCalc?: TCountryDM
	@Type(() => TCountryDM)
	InvoiceAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTradingPartnerDM)
	DeliveryTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TPartnerDM)
	PartnerIdCalc?: TPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TEshopOrderItemDM)
	EshopBasketItemChild?: TEshopOrderItemDM[]
}