import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TTownDM from './TTownDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TContactPersonDM from './TContactPersonDM'
import TAddressDM from './TAddressDM'

export default class TRelatedAddressDocumentDM extends Data {
	public static className = 'TRelatedAddressDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AddressDataModuleExtId?: number
	AddressDataModuleRID?: number
	HeaderRID?: number
	RID?: number
	AddressDataModuleNumber?: number
	HeaderDataModuleNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	PickUpPoint?: string
	Comment?: string
	Fax?: string
	Email?: string
	Phone?: string
	TownCode?: string
	Longitude?: number
	Latitude?: number
	Street?: string
	NameText?: string
	OrientationNumber?: string
	HouseNumber?: string
	AddressId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	SimpleAddressBindRIDCalc?: number
	VerificationDateCalc?: Date
	SimpleAddressPhoneCalc?: string
	SimpleAddressEmailCalc?: string
	SimpleAddressFaxCalc?: string
	SimpleAddressCommentCalc?: string
	CoordinatesCalc?: string
	ImplicitIdCalc?: number
	SimpleAddressPickUpPointCalc?: string
	SimpleAddressInvoiceBindRIDCalc?: number
	InvoiceAddressNameCalc?: string
	ExternalInvoiceAddressIdCalc?: number
	AddresIdDataModuleInvoiceCalc?: number
	InvoiceTradingPartnerIdCalc?: number
	SimpleAddressDeliveryBindRIDCalc?: number
	DeliveryAddressNameCalc?: string
	ExternalDeliveryAddressIdCalc?: number
	AddresIdDataModuleDeliveryCalc?: number
	DeliveryTradingPartnerIdCalc?: number
	SimpleAddressPostalBindRIDCalc?: number
	PostalAddressNameCalc?: string
	ExternalPostalAddressIdCalc?: number
	AddresIdDataModulePostalCalc?: number
	PostalTradingPartnerIdCalc?: number
	@Type(() => TTextDM)
	AddressTypeId?: TTextDM
	@Type(() => TTownDM)
	TownId?: TTownDM
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TTownPartDM)
	TownPartId?: TTownPartDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	ContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TAddressDM)
	AddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindInvoiceIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	InvoiceContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TTextDM)
	InvoiceAddressTypeIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindDeliveryIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	DeliveryContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TTextDM)
	DeliveryAddressTypeIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerBindPostalIdCalc?: TTradingPartnerDM
	@Type(() => TContactPersonDM)
	PostalContactPersonBindIdCalc?: TContactPersonDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TTextDM)
	PostalAddressTypeIdCalc?: TTextDM
}