import Data from '../Api/Data'

export default class TWorkflowProcedureRespPersonDM extends Data {
	public static className = 'TWorkflowProcedureRespPersonDM'
	public static systemFields = [
		'RecordID',
	]

	PersonId?: number
	ProcedureId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
}