import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTownPartDM from './TTownPartDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAddressDM extends Data {
	public static className = 'TAddressDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Longitude?: number
	Latitude?: number
	Street?: string
	OrientationNumber?: string
	Name?: string
	ChangedOn?: Date
	HouseNumber?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	OneLineCalc?: string
	CompleteNumberCalc?: string
	CompleteStreetCalc?: string
	CoordinatesCalc?: string
	HashCalc?: string
	@Type(() => TTownPartDM)
	TownPartId?: TTownPartDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
}