import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWebModuleCategoryDM from './TWebModuleCategoryDM'
import TWebAppointmentDM from './TWebAppointmentDM'
import TWebNewsDM from './TWebNewsDM'
import TWebCareerDM from './TWebCareerDM'
import TWebTrainingDM from './TWebTrainingDM'
import TEshopBannerDM from './TEshopBannerDM'
import TWebDownloadDM from './TWebDownloadDM'
import TWebSocialNetworkDM from './TWebSocialNetworkDM'
import TWebTimelineDM from './TWebTimelineDM'
import TWebSupportDM from './TWebSupportDM'
import TWebFAQDM from './TWebFAQDM'
import TWebDictionaryDM from './TWebDictionaryDM'
import TWebBranchDM from './TWebBranchDM'
import TWebContactPersonDM from './TWebContactPersonDM'
import TWebImageDM from './TWebImageDM'
import TWebVideoDM from './TWebVideoDM'
import TRegisteredEmailDM from './TRegisteredEmailDM'
import TWebReferenceDM from './TWebReferenceDM'
import TWebPartnerDM from './TWebPartnerDM'
import TWebPortfolioDM from './TWebPortfolioDM'
import TWebBlogDM from './TWebBlogDM'
import TWebAdvantageDM from './TWebAdvantageDM'

export default class TWebModuleLinkDM extends Data {
	public static className = 'TWebModuleLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	SlaveRID?: number
	SlaveDataModuleNumber?: number
	MasterRID?: number
	MasterDataModuleNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	WebRIDCalc?: number
	@Type(() => TTextDM)
	SlaveModuleId?: TTextDM
	@Type(() => TTextDM)
	MasterModuleId?: TTextDM
	@Type(() => TTextDM)
	SlaveTypeIdCalc?: TTextDM
	@Type(() => TWebModuleCategoryDM)
	CategoryRIDCalc?: TWebModuleCategoryDM
	@Type(() => TWebAppointmentDM)
	AppointmentRIDCalc?: TWebAppointmentDM
	@Type(() => TWebNewsDM)
	NewsRIDCalc?: TWebNewsDM
	@Type(() => TWebCareerDM)
	CareerRIDCalc?: TWebCareerDM
	@Type(() => TWebTrainingDM)
	LectorRIDCalc?: TWebTrainingDM
	@Type(() => TEshopBannerDM)
	BannerRIDCalc?: TEshopBannerDM
	@Type(() => TWebDownloadDM)
	DownloadRIDCalc?: TWebDownloadDM
	@Type(() => TWebSocialNetworkDM)
	SocialNetworkRIDCalc?: TWebSocialNetworkDM
	@Type(() => TWebTimelineDM)
	TimelineRIDCalc?: TWebTimelineDM
	@Type(() => TWebSupportDM)
	SupportRIDCalc?: TWebSupportDM
	@Type(() => TWebFAQDM)
	FaqRIDCalc?: TWebFAQDM
	@Type(() => TWebDictionaryDM)
	DictionaryRIDCalc?: TWebDictionaryDM
	@Type(() => TWebBranchDM)
	BranchRIDCalc?: TWebBranchDM
	@Type(() => TWebContactPersonDM)
	ContactPersonRIDCalc?: TWebContactPersonDM
	@Type(() => TWebImageDM)
	ImageRIDCalc?: TWebImageDM
	@Type(() => TWebVideoDM)
	VideoRIDCalc?: TWebVideoDM
	@Type(() => TRegisteredEmailDM)
	NewsletterRIDCalc?: TRegisteredEmailDM
	@Type(() => TWebReferenceDM)
	ReferenceRIDCalc?: TWebReferenceDM
	@Type(() => TWebPartnerDM)
	PartnerRIDCalc?: TWebPartnerDM
	@Type(() => TWebPortfolioDM)
	PortfolioRIDCalc?: TWebPortfolioDM
	@Type(() => TWebBlogDM)
	BlogRIDCalc?: TWebBlogDM
	@Type(() => TWebAdvantageDM)
	AdvantageRIDCalc?: TWebAdvantageDM
}