import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TLoyaltyProgramCouponDM extends Data {
	public static className = 'TLoyaltyProgramCouponDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Name?: string
	ValidTo?: Date
	ValidDays?: number
	IsPercentageForDiscount?: string
	ValidFromPrice?: number
	Discount?: number
	Points?: number
	LoyaltyProgramId?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	DiscountSuffixCalc?: string
	DiscountCurrencyCalc?: string
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTextDM)
	TypeId?: TTextDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}