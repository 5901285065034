import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TSalesActionDM from './TSalesActionDM'
import TPriceListDM from './TPriceListDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TSalesOrderDM from './TSalesOrderDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import ReservingCardStatus from './ReservingCardStatus'
import PlanType from './PlanType'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TermOfDelivery from './TermOfDelivery'
import TPriceGroupDM from './TPriceGroupDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCurrencyDM from './TCurrencyDM'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TAddressDM from './TAddressDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TDeliveryPlanDM from './TDeliveryPlanDM'
import TDeliveryOrderDM from './TDeliveryOrderDM'
import TDeliveryPlanItemBookDM from './TDeliveryPlanItemBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TSuperiorDocumentDM from './TSuperiorDocumentDM'
import TTaxSummaryDM from './TTaxSummaryDM'
import TSalesItemDM from './TSalesItemDM'
import TAccountingSalesItemDM from './TAccountingSalesItemDM'
import TTransferNoteDM from './TTransferNoteDM'
import TDeliveryOrderItemBookDM from './TDeliveryOrderItemBookDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TReservingCardDM extends Data {
	public static className = 'TReservingCardDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreatedOn?: Date
	Settings?: string
	Number?: number
	RID?: number
	TimeStamp?: string
	IssueDate?: Date
	RightGroupId?: number
	AccountingItemsCount?: number
	PostedById?: number
	PostedOn?: Date
	ConfirmedOn?: Date
	AmountNet?: number
	PostingKeyId?: number
	ItemCount?: number
	ChangedOn?: Date
	ReservedToDate?: Date
	ReservationDate?: Date
	Description?: string
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	AmountVATCalc?: number
	AmountGrossCalc?: number
	AmountVATCCalc?: number
	AmountGrossCCalc?: number
	ExchangeRateCalc?: number
	CompanyCurrencyCalc?: string
	AmountNetCCalc?: number
	HeaderExchangeRateCalc?: number
	CurrencySwitchCalc?: number
	SNReceiptReleaseFilterImgCalc?: string
	FilterArticleIdCalc?: number
	IsFilterOnCalc?: string
	FilterBatchOnBitCalc?: number
	TotalItemPlannedPriceNetCalc?: number
	TotalItemPlStockPriceCalc?: number
	TotalItemPlStockPriceCCalc?: number
	ProfitCalc?: number
	ProfitCCalc?: number
	MarginCalc?: number
	CurrencyBySwitchCalc?: string
	AssignedContractRIDCalc?: number
	DocumentAmountGrossCalc?: number
	DocumentAmountGrossCCalc?: number
	ReductionValueCalc?: number
	ReductionTypeBitCalc?: number
	ReductionAmountCalc?: number
	ReductionSignCalc?: number
	ReductionSignImgCalc?: string
	ReservedToDateCalc?: Date
	ReservedToTimeCalc?: Date
	ReservationDateCalc?: Date
	ReservationTimeCalc?: Date
	PlannedSurchargeAmountCalc?: number
	TotalItemPlStockBySettPriceCalc?: number
	DeliveryOrderDateCalc?: Date
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	TotalItemActualPriceNetCalc?: number
	TotalItemActualStockPriceCalc?: number
	TotalItemActualStockPriceCCalc?: number
	ActualProfitCalc?: number
	ActualProfitCCalc?: number
	ActualMarginAmountCalc?: number
	ActualSurchargeAmountCalc?: number
	TotalItemActStockSettPriceCalc?: number
	QRCodeCZBankOrderStandardCalc?: string
	QRCodeCZBankOrderLargeCalc?: string
	QRCodeSKBankOrderPayBySquareCalc?: string
	QRSKBankOrderCSOBCalc?: string
	MarketingDocumentRIDCalc?: number
	ExternalAddressCalc?: string
	TradingPartnerInternalTextCalc?: string
	ForeignCurrencyBitCalc?: number
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	IncorrectAmountBitCalc?: number
	VATTurnedOnBitCalc?: number
	VATGrossCalcMethodBitCalc?: number
	MultiDocumentBitCalc?: number
	PostZeroAmountDocumentsBitCalc?: number
	QRCZInvoiceCalc?: string
	VATRegNumberCalc?: string
	TaxTradingPartnerIdCalc?: number
	ItemPriceNetCCalc?: number
	ItemPriceVATCCalc?: number
	ItemPriceGrossCCalc?: number
	ItemPriceNetCalc?: number
	ItemPriceVATCalc?: number
	ItemPriceGrossCalc?: number
	ArticleFromPriceListIdCalc?: number
	TotalWeightNetCalc?: number
	TotalWeightGrossCalc?: number
	DeliveryOrderItemRIDCalc?: number
	DeliveryOrderTimeCalc?: Date
	TotalVolumePARAMCalc?: number
	CoverageTimeStampCalc?: Date
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TSalesActionDM)
	SellingActionRID?: TSalesActionDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => ReservingCardStatus)
	StatusId?: ReservingCardStatus
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TermOfDelivery)
	DeliveryTime?: TermOfDelivery
	@Type(() => TPriceGroupDM)
	PriceGroupId?: TPriceGroupDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TCurrencyDM)
	HeaderCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	SNRecRelFilterStateCalc?: TTextDM
	@Type(() => TBatchDM)
	FilterBatchIdCalc?: TBatchDM
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ForeignCurrencySwitchBitCalc?: TTextDM
	@Type(() => TTextDM)
	ForeignVATBitCalc?: TTextDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullDeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	FullPostalInvoiceAddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TDeliveryPlanDM)
	DeliveryPlanRIDCalc?: TDeliveryPlanDM
	@Type(() => TDeliveryOrderDM)
	DeliveryOrderRIDCalc?: TDeliveryOrderDM
	@Type(() => TDeliveryPlanItemBookDM)
	DeliveryPlanItemRIDCalc?: TDeliveryPlanItemBookDM
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TInvoiceOutDM)
	InvoiceOutChild?: TInvoiceOutDM[]
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteChild?: TDeliveryNoteDM[]
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationChild?: TOrderConfirmationDM[]
	@Type(() => TReleaseNoteDM)
	ReleaseNoteChild?: TReleaseNoteDM[]
	@Type(() => TSalesOrderDM)
	SalesOrderChild?: TSalesOrderDM[]
	@Type(() => TSalesItemBookDM)
	ItemChild?: TSalesItemBookDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TSuperiorDocumentDM)
	SuperiorDocumentChild?: TSuperiorDocumentDM[]
	@Type(() => TTaxSummaryDM)
	TaxSummaryInternalOnlyChild?: TTaxSummaryDM[]
	@Type(() => TSalesItemDM)
	SalesItemChild?: TSalesItemDM[]
	@Type(() => TAccountingSalesItemDM)
	AccountingSalesItemChild?: TAccountingSalesItemDM[]
	@Type(() => TTransferNoteDM)
	TransferNoteChild?: TTransferNoteDM[]
	@Type(() => TDeliveryOrderItemBookDM)
	DistributionChild?: TDeliveryOrderItemBookDM[]
	@Type(() => TCommentDM)
	CommentChildCustomer?: TCommentDM[]
	@Type(() => TCommentDM)
	CommentChildSuperiorDocument?: TCommentDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}