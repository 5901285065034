import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TArticleDM from './TArticleDM'
import TEshopCategoryInfoDM from './TEshopCategoryInfoDM'

export default class TEshopCategoryArticleDM extends Data {
	public static className = 'TEshopCategoryArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	ArticleGroupId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TEshopCategoryInfoDM)
	CategoryRID?: TEshopCategoryInfoDM
}