import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TCustomExchangeRateDM extends Data {
	public static className = 'TCustomExchangeRateDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	CompanyId?: number
	RightGroupId?: number
	ExchangeRateUnitCount?: number
	ExchangeRate?: number
	Currency?: string
	ExchangeRateDate?: Date
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ExchangeRateCalc?: number
}