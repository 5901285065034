import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopDeliveryDM from './TEshopDeliveryDM'
import TPartnerDM from './TPartnerDM'
import TDiscountCouponDM from './TDiscountCouponDM'
import TEshopDM from './TEshopDM'
import TContactPersonDM from './TContactPersonDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TEshopBasketItemDM from './TEshopBasketItemDM'
import TEshopBasketArticleDM from './TEshopBasketArticleDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopBasketDM extends Data {
	public static className = 'TEshopBasketDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AlzaOrderNumber?: string
	PickUpPointId?: string
	CurrencyCode?: string
	WebData?: string
	Quantity?: number
	PriceDifference?: number
	PriceGross?: number
	PriceNet?: number
	DeliveryAddressCompany?: string
	OrderedDate?: Date
	TransportBranchId?: number
	PaymentId?: number
	DeliveryAddressTaxId?: string
	DeliveryAddressCompanyRegNo?: string
	DeliveryAddressVATRegNo?: string
	DeliveryAddressCountryId?: number
	DeliveryAddressZIP?: string
	DeliveryAddressCity?: string
	DeliveryAddressStreet?: string
	DeliveryAddressPhone?: string
	DeliveryAddressComment?: string
	DeliveryAddressRID?: number
	InvoiceAddressCompany?: string
	InvoiceAddressCountryId?: number
	InvoiceAddressZIP?: string
	InvoiceAddressCity?: string
	InvoiceAddressPhone?: string
	InvoiceAddressComment?: string
	IPAddress?: string
	ContractRID?: number
	IsShared?: string
	IsFavorite?: string
	IsForQuotation?: string
	AdditionalInfo?: string
	IsOrdered?: string
	Name?: string
	TimeStamp?: string
	InvoiceAddressTaxId?: string
	InvoiceAddressCompanyRegNo?: string
	InvoiceAddressVATRegNo?: string
	InvoiceAddressRID?: number
	QuotationRID?: number
	PaymentStatusId?: number
	OrderStatusId?: number
	TransactionId?: string
	LanguageId?: number
	ContactPhone?: string
	ContactEmail?: string
	ContactLastName?: string
	ContactFirstName?: string
	InvoiceAddressStreet?: string
	IPv6Address?: string
	EndDate?: Date
	StartDate?: Date
	Session?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	EncodedRIDCalc?: string
	TotalPriceNetCalc?: number
	TotalPriceGrossCalc?: number
	ItemsCountCalc?: number
	CouponErrorCalc?: string
	CouponPriceNetCalc?: number
	CouponPriceGrossCalc?: number
	CouponCodeCalc?: string
	ShopCurrencyExchangeRateCalc?: number
	ShopCurrencyCalc?: string
	ReducedTotalPriceNetCalc?: number
	ReducedTotalPriceGrossCalc?: number
	TotalQuantityCalc?: number
	TotalPromotionPriceCalc?: number
	CombPromotionTotalPriceCalc?: number
	LastAddedArticleIdCalc?: number
	LastAddedQuantityCalc?: number
	LastAddedPriceNetCalc?: number
	LastAddedPriceGrossCalc?: number
	PromotionTotalPriceGrossCalc?: number
	CombPromotionTotalPriceGrossCalc?: number
	PromotionTotalPriceNetCalc?: number
	CombPromotionTotalPriceNetCalc?: number
	PromotionDiscountCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TEshopDeliveryDM)
	DeliveryRID?: TEshopDeliveryDM
	@Type(() => TPartnerDM)
	PartnerId?: TPartnerDM
	@Type(() => TDiscountCouponDM)
	CouponRID?: TDiscountCouponDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TEshopBasketItemDM)
	ItemChild?: TEshopBasketItemDM[]
	@Type(() => TEshopBasketArticleDM)
	ArticleChild?: TEshopBasketArticleDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}