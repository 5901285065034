import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TWarehouseDM from './TWarehouseDM'
import TBatchDM from './TBatchDM'
import TLocationArticleDM from './TLocationArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'

export default class TSerialNumberItemDM extends Data {
	public static className = 'TSerialNumberItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	ArticleId?: number
	StatusId?: number
	SuperiorSerialNumber?: string
	ProductionNumber?: string
	SerialNumber?: string
	ItemRID?: number
	DocumentTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	InsertCount?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ArticleIdCalc?: number
	UserWarehouseIdCalc?: number
	@Type(() => TTextDM)
	StatusIdCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TBatchDM)
	BatchIdCalc?: TBatchDM
	@Type(() => TLocationArticleDM)
	LocationIdCalc?: TLocationArticleDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRIDCalc?: THandlingUnitArticleDM
}