import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TNomenclatureDM from './TNomenclatureDM'

export default class TExciseTaxSummaryDM extends Data {
	public static className = 'TExciseTaxSummaryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	HeaderRID?: number
	HeaderType?: number
	Price?: number
	Amount?: number
	ExciseTaxId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ExciseTaxDateCalc?: Date
	@Type(() => TNomenclatureDM)
	NomenklatureId?: TNomenclatureDM
}