import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TCodeTypeDM from './TCodeTypeDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TBookDM from './TBookDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTaxTypeDM from './TTaxTypeDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TNomenclatureDM from './TNomenclatureDM'
import TAccountingChartDM from './TAccountingChartDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import PlanType from './PlanType'
import TWarehouseDM from './TWarehouseDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TLocationArticleDM from './TLocationArticleDM'
import TCountryDM from './TCountryDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TVATRateDM from './TVATRateDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TBatchDM from './TBatchDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TCurrencyDM from './TCurrencyDM'
import TTextDM from './TTextDM'
import TPriceGroupDM from './TPriceGroupDM'
import TParameterSetDM from './TParameterSetDM'
import KindAccounting from './KindAccounting'
import JCDObchod from './JCDObchod'
import JCDP20_a1 from './JCDP20_a1'
import SpecialMove from './SpecialMove'
import TransportKind from './TransportKind'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TPriceListDM from './TPriceListDM'
import TSupplierPriceDM from './TSupplierPriceDM'
import TLoyaltyProgramDM from './TLoyaltyProgramDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAssetLinkDM from './TAssetLinkDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TAssetConnectedDocumentDM from './TAssetConnectedDocumentDM'

export default class TPurchaseItemDM extends Data {
	public static className = 'TPurchaseItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	InvoiceOriginalUnitPrice?: number
	PlannedOriginalUnitPrice?: number
	FirstDelyConfirmationDate?: Date
	OrderingDate?: Date
	MainItemRID?: number
	TimeStamp?: string
	InvoiceDiscount?: number
	PlannedDiscount?: number
	ServiceNoteRID?: number
	RID?: number
	ReceiptCardRID?: number
	DeliveryConfirmationRID?: number
	InvoiceInRID?: number
	PurchaseOrderRID?: number
	XmlData?: string
	Warranty?: number
	BorderCrossingDate?: Date
	PlannedIncidentalCost?: number
	CostFreeStockPrice?: number
	ExciseTaxId?: number
	DesiredDeliveryDate?: Date
	DeliveryDate?: Date
	UnitCostPrice?: number
	OrginalNoReceiptCard?: number
	OrginalNoPurchaseOrder?: number
	OrginalNoInvoiceIn?: number
	OrginalNoDeliveryConfirmation?: number
	NoteId?: number
	PlannedUnitPrice?: number
	ReceiptCardPostingKeyId?: number
	InvoicePostingKeyId?: number
	IncidentalCost?: number
	StockPrice?: number
	InvoiceUnitPrice?: number
	Quantity?: number
	Tag?: number
	ConfirmedOn?: Date
	ItemNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	IsCached?: string
	ExchangeRate?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ExchangeRateCalc?: number
	InvoiceExchangeRateCalc?: number
	QuantityUMCalc?: number
	VATCoefficientCalc?: number
	InvoicePriceVATCalc?: number
	PlannedUnitPriceNetCalc?: number
	InvoiceUnitPriceNetCalc?: number
	StockUnitPriceCalc?: number
	UnitIncidentalCostCalc?: number
	PlannedTotalPriceNetCalc?: number
	InvoiceTotalPriceNetCalc?: number
	StockTotalPriceCalc?: number
	TotalIncidentalCostCalc?: number
	PlannedUnitPriceNetCCalc?: number
	InvoiceUnitPriceNetCCalc?: number
	StockUnitPriceCCalc?: number
	UnitIncidentalCostCCalc?: number
	PlannedTotalPriceNetCCalc?: number
	InvoiceTotalPriceNetCCalc?: number
	StockTotalPriceCCalc?: number
	TotalIncidentalCostCCalc?: number
	PlannedUnitPriceGrossCalc?: number
	InvoiceUnitPriceGrossCalc?: number
	PlannedTotalPriceGrossCalc?: number
	InvoiceTotalPriceGrossCalc?: number
	PlannedUnitPriceGrossCCalc?: number
	InvoiceUnitPriceGrossCCalc?: number
	PlannedTotalPriceGrossCCalc?: number
	InvoiceTotalPriceGrossCCalc?: number
	NameCalc?: string
	PlannedMarginCalc?: number
	InvoiceMarginCalc?: number
	PlannedMarginCCalc?: number
	InvoiceMarginCCalc?: number
	PlannedMarginPctCalc?: number
	InvoiceMarginPctCalc?: number
	PositiveQuantityCalc?: number
	FullNameCalc?: string
	ActualQuantityRNCalc?: number
	ExciseTaxAmountCalc?: number
	SetInvPriceCalc?: number
	SetInvUnitPriceCalc?: number
	SetInvUnitPriceCCalc?: number
	SetInvTotalPriceCalc?: number
	SetInvTotalPriceCCalc?: number
	SetInvPriceGrossCalc?: number
	SetInvUnitPriceGrossCalc?: number
	SetInvUnitPriceGrossCCalc?: number
	SetInvTotalPriceGrossCalc?: number
	SetInvTotalPriceGrossCCalc?: number
	SetPlPriceCalc?: number
	SetPlUnitPriceCalc?: number
	SetPlUnitPriceCCalc?: number
	SetPlTotalPriceCalc?: number
	SetPlTotalPriceCCalc?: number
	SetPlPriceGrossCalc?: number
	SetPlUnitPriceGrossCalc?: number
	SetPlUnitPriceGrossCCalc?: number
	SetPlTotalPriceGrossCalc?: number
	SetPlTotalPriceGrossCCalc?: number
	IsFromSetCalc?: number
	BarcodeCalc?: string
	PlannedBaseUnitPriceCalc?: number
	PlannedBaseUnitPriceGrossCalc?: number
	PlannedBaseUnitPriceCCalc?: number
	PlannedBaseUnitPriceGrossCCalc?: number
	MainItemCalc?: number
	StockDocumentExchangeRateCalc?: number
	PlannedOriginalUnitPriceNetCalc?: number
	InvoiceOriginalUnitPriceNetCalc?: number
	PlannedOriginalTotalPriceNetCalc?: number
	InvoiceOriginalTotalPriceNetCalc?: number
	PlannedOriginalUnitPriceNetCCalc?: number
	InvoiceOriginalUnitPriceNetCCalc?: number
	PlannedOriginalTotalPriceNetCCalc?: number
	InvoiceOriginalTotalPriceNetCCalc?: number
	PlannedOriginalUnitPriceGrossCalc?: number
	InvoiceOriginalUnitPriceGrossCalc?: number
	PlannedOriginalTotalPriceGrossCalc?: number
	InvoiceOriginalTotalPriceGrossCalc?: number
	PlannedOriginalUnitPriceGrossCCalc?: number
	InvoiceOriginalUnitPriceGrossCCalc?: number
	PlannedOriginalTotalPriceGrossCCalc?: number
	InvoiceOriginalTotalPriceGrossCCalc?: number
	DeliveryDateCalc?: Date
	DeliveryTimeCalc?: Date
	DiscountInPercentageBitCalc?: number
	BatchParameterRatioCalc?: number
	DesiredDeliveryDateCalc?: Date
	DesiredDeliveryTimeCalc?: Date
	BatchTypeIdCalc?: number
	TotalWeightCalc?: number
	CostFreeStockPriceCalc?: number
	CostFreeUnitStockPriceCalc?: number
	CostFreeTotalStockPriceCalc?: number
	CostFreeUnitStockPriceCCalc?: number
	CostFreeTotalStockPriceCCalc?: number
	SupplierCodeCalc?: string
	PlannedVATCoefficientCalc?: number
	InvoiceVATCoefficientCalc?: number
	CoverageUntilDateCalc?: Date
	DayToConsumptionCountCalc?: number
	TotalCostPriceCalc?: number
	DateForVersionCalc?: Date
	DateFromInvoiceCalc?: Date
	PlannedDiscountCalc?: number
	InvoiceDiscountCalc?: number
	TotalWeightNetCalc?: number
	WarehouseForBatchIdCalc?: number
	WarehouseForContractCodeIdCalc?: number
	PlDiscountPercentageBitCalc?: number
	PlannedUnitIncidentalCostCalc?: number
	PlannedTotalIncidentalCostCalc?: number
	PlannedUnitIncidentalCostCCalc?: number
	PlannedTotalIncidentalCostCCalc?: number
	ExistsPlIncidentalCostsBitCalc?: number
	HealthHandicapIdCalc?: number
	RCSModeBitCalc?: number
	PriceGroup1BitCalc?: number
	PriceGroup2BitCalc?: number
	ManuallyChangedPriceBitCalc?: number
	DiscountOnlyInvoicePriceBitCalc?: number
	DuplicateItemBitCalc?: number
	InverseTransformFormulaBitCalc?: number
	IncidentalCostBitCalc?: number
	ZeroActualIncidentalCostBitCalc?: number
	NotIncludeToIntrastatBitCalc?: number
	InvoiceItemHasCreditNoteBitCalc?: number
	IgnoreReceiptPriceCalc?: number
	WeightNetCalc?: number
	InvoicedPriceCCalc?: number
	ItemTextCalc?: string
	WarehouseZoneRIDCalc?: number
	DiscountTypePlanSuffixCalc?: string
	DiscountTypeInvoiceSuffixCalc?: string
	InvoiceIdentificationCalc?: string
	InvoiceSuffixCalc?: string
	DeliveryConfirmationIdentificationCalc?: string
	DeliveryNoteSuffixCalc?: string
	StockDocumentIdentificationCalc?: string
	StockDocumentSuffixCalc?: string
	ExciseTaxDateCalc?: Date
	EkokomDateCalc?: Date
	TotalCapacityCalc?: number
	OrderingDateCalc?: Date
	OrderingTimeCalc?: Date
	FirstDeliveryConfirmDateCalc?: Date
	FirstDeliveryConfirmTimeCalc?: Date
	SupplierPriceAvailableCalc?: number
	TotalVolumePARAMCalc?: number
	LoyaltyProgramPriceCalc?: number
	LoyaltyProgramGroupIdCalc?: number
	SerialNumberCountAsStringCalc?: string
	CF_NakSleva?: string
	StockSalePriceRatioPercCalc?: number
	SupplierPriceCalc?: number
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TCodeTypeDM)
	MobileTerminalStatusId?: TCodeTypeDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM
	@Type(() => TTradingPartnerDM)
	MainDocumentTradingPartnerId?: TTradingPartnerDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TNomenclatureDM)
	NomenclatureId?: TNomenclatureDM
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TCountryDM)
	OriginCountryId?: TCountryDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TCurrencyDM)
	HeaderCurrencyCalc?: TCurrencyDM
	@Type(() => TCurrencyDM)
	InvoiceCurrencyCalc?: TCurrencyDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	QuantitySignBitCalc?: TTextDM
	@Type(() => TCurrencyDM)
	StockCurrencyCalc?: TCurrencyDM
	@Type(() => TCostCentreCollectionDocumentDM)
	BindCostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	BindContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TCode1DocumentDM)
	BindCode1IdCalc?: TCode1DocumentDM
	@Type(() => TCode2DocumentDM)
	BindCode2IdCalc?: TCode2DocumentDM
	@Type(() => TOfficerDocumentDM)
	BindOfficerIdCalc?: TOfficerDocumentDM
	@Type(() => TPriceGroupDM)
	BindPriceGroupIdCalc?: TPriceGroupDM
	@Type(() => TTradingPartnerDM)
	BindTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseForIdCalc?: TWarehouseDM
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting
	@Type(() => JCDObchod)
	TransactionIdCalc?: JCDObchod
	@Type(() => JCDP20_a1)
	DeliveryConditionIdCalc?: JCDP20_a1
	@Type(() => SpecialMove)
	SpecialMoveIdCalc?: SpecialMove
	@Type(() => TransportKind)
	TransportTypeIdCalc?: TransportKind
	@Type(() => TCountryDM)
	CountryIdCalc?: TCountryDM
	@Type(() => TTaxTypeDM)
	TaxTypeIdCalc?: TTaxTypeDM
	@Type(() => TDeviceDocumentDM)
	BindDeviceRIDCalc?: TDeviceDocumentDM
	@Type(() => TTextDM)
	DeliveryConfirmationStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	InvoiceInStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	ReceiptCardStateIdCalc?: TTextDM
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffIdCalc?: TIntrastatTariffDutyDM
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => TPriceListDM)
	PriceListRIDCalc?: TPriceListDM
	@Type(() => TCode3DocumentDM)
	BindCode3IdCalc?: TCode3DocumentDM
	@Type(() => TCode4DocumentDM)
	BindCode4IdCalc?: TCode4DocumentDM
	@Type(() => TCode5DocumentDM)
	BindCode5IdCalc?: TCode5DocumentDM
	@Type(() => TCode6DocumentDM)
	BindCode6IdCalc?: TCode6DocumentDM
	@Type(() => TSupplierPriceDM)
	SupplierPriceRIDCalc?: TSupplierPriceDM
	@Type(() => TTextDM)
	DeliveryReliability1IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliability2IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliability3IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliability4IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliability5IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliability6IdCalc?: TTextDM
	@Type(() => TTextDM)
	DeliveryReliabilityIdCalc?: TTextDM
	@Type(() => TLoyaltyProgramDM)
	LoyaltyProgramRIDCalc?: TLoyaltyProgramDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAssetLinkDM)
	AssetLinkChild?: TAssetLinkDM[]
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
	@Type(() => TAssetConnectedDocumentDM)
	AssetConnectedDocumentChild?: TAssetConnectedDocumentDM[]
}