import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TJobCardDM from './TJobCardDM'
import TWarehouseZoneDM from './TWarehouseZoneDM'
import TBookDM from './TBookDM'
import TWMSQueueDM from './TWMSQueueDM'
import Priority from './Priority'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TDeliveryNoteDM from './TDeliveryNoteDM'
import TReservingCardDM from './TReservingCardDM'
import TOrderConfirmationDM from './TOrderConfirmationDM'
import TSalesOrderDM from './TSalesOrderDM'
import TInvoiceInDM from './TInvoiceInDM'
import TDeliveryConfirmationDM from './TDeliveryConfirmationDM'
import TPurchaseOrderDM from './TPurchaseOrderDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import PlanType from './PlanType'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TLocationArticleDM from './TLocationArticleDM'
import TBatchDM from './TBatchDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TTransferNoteDM from './TTransferNoteDM'
import TReleaseNoteDM from './TReleaseNoteDM'
import TReceiptCardDM from './TReceiptCardDM'
import TPeriodDM from './TPeriodDM'
import TTextDM from './TTextDM'

export default class TStockCardDM extends Data {
	public static className = 'TStockCardDM'
	public static systemFields = [
		'RecordID',
	]

	ProductionBookId?: number
	IsPlanningJobCardItem?: string
	ItemNumber?: number
	ReleaseQuantity?: number
	ReceiptQuantity?: number
	EndDate?: Date
	StartDate?: Date
	ReservationDate?: Date
	DeliveryDate?: Date
	DesiredDeliveryDate?: Date
	Weight?: number
	InvoicePrice?: number
	IncidentalCosts?: number
	CostPrice?: number
	StockPrice?: number
	StockPriceNet?: number
	Quantity?: number
	Number?: number
	ConfirmedOn?: Date
	TransferSign?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	BatchCoefficient?: number
	BatchMeasureUnit?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ReceiptCalc?: number
	ReleaseCalc?: number
	LevelCalc?: number
	StockPriceCalc?: number
	DocumentTypeCalc?: string
	UnitCostPriceCalc?: number
	DocumentCalc?: string
	OpenItemQuantityCalc?: number
	OpenItemAmountCalc?: number
	CompanyCurrencyCalc?: string
	TurnoverReceiptQuantityCalc?: number
	TurnoverReleaseQuantityCalc?: number
	TurnoverReceiptAmountCalc?: number
	TurnoverReleaseAmountCalc?: number
	QuantityBalanceCalc?: number
	BalanceCalc?: number
	LevelBaseQuantityCalc?: number
	LevelBaseAmountCalc?: number
	MeasureUnitCoefficientCalc?: number
	ShowInternalTransferNoteCalc?: string
	LevelQuantityCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
	@Type(() => TWarehouseZoneDM)
	TransferWarehouseZoneRID?: TWarehouseZoneDM
	@Type(() => TWarehouseZoneDM)
	WarehouseZoneRID?: TWarehouseZoneDM
	@Type(() => TBookDM)
	WarehouseBookId?: TBookDM
	@Type(() => TBookDM)
	SalesBookId?: TBookDM
	@Type(() => TBookDM)
	PurchaseBookId?: TBookDM
	@Type(() => TWMSQueueDM)
	QueueId?: TWMSQueueDM
	@Type(() => Priority)
	PriorityId?: Priority
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TTradingPartnerDM)
	SupplierId?: TTradingPartnerDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TInvoiceOutDM)
	InvoiceOutRID?: TInvoiceOutDM
	@Type(() => TDeliveryNoteDM)
	DeliveryNoteRID?: TDeliveryNoteDM
	@Type(() => TReservingCardDM)
	ReservingCardRID?: TReservingCardDM
	@Type(() => TOrderConfirmationDM)
	OrderConfirmationRID?: TOrderConfirmationDM
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
	@Type(() => TInvoiceInDM)
	InvoiceInRID?: TInvoiceInDM
	@Type(() => TDeliveryConfirmationDM)
	DeliveryConfirmationRID?: TDeliveryConfirmationDM
	@Type(() => TPurchaseOrderDM)
	PurchaseOrderRID?: TPurchaseOrderDM
	@Type(() => TTradingPartnerDM)
	MainDocumentTradingPartnerId?: TTradingPartnerDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TOfficerDocumentDM)
	WarehousemanId?: TOfficerDocumentDM
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TWarehouseDM)
	TransferWarehouseId?: TWarehouseDM
	@Type(() => TWarehouseDM)
	PlannedWarehouseId?: TWarehouseDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TTransferNoteDM)
	TransferNoteRID?: TTransferNoteDM
	@Type(() => TReleaseNoteDM)
	ReleaseNoteRID?: TReleaseNoteDM
	@Type(() => TReceiptCardDM)
	ReceiptCardRID?: TReceiptCardDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
}