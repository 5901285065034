import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TPriceListDM from './TPriceListDM'

export default class TPriceListTradingPartnerDM extends Data {
	public static className = 'TPriceListTradingPartnerDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RelationTypeCalc?: number
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPriceListDM)
	PriceListRID?: TPriceListDM
}