import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TArticleKindDM from './TArticleKindDM'
import TArticleDM from './TArticleDM'

export default class TProductionOrderCostDM extends Data {
	public static className = 'TProductionOrderCostDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	RowNumber?: number
	ActualTeardownTime?: number
	ActualRunTime?: number
	ActualSetupTime?: number
	TransitTime?: number
	RunTime?: number
	SetupTime?: number
	OperationTimeType?: number
	MeasureUnit?: string
	ActualAmount?: number
	PlannedCostPrice?: number
	ActualQuantity?: number
	PlannedQuantity?: number
	KindAbbr?: string
	ArticleName?: string
	JobCardOperationRID?: number
	SequenceNumber?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityPctCalc?: number
	CostPctCalc?: number
	@Type(() => TJobCardDM)
	JobCardRID?: TJobCardDM
	@Type(() => TArticleKindDM)
	ArticleCategoryId?: TArticleKindDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
}