import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TPaymentMethodDM extends Data {
	public static className = 'TPaymentMethodDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	AlwaysRoundUpMax?: number
	IsBlockedRecord?: string
	NotAllocateRounding?: string
	RoundingOrder?: number
	RoundingConst?: number
	IsCashOnDelivery?: string
	IsAdvancePayment?: string
	IsCashPayment?: string
	Name?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageTextCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}