import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TTextDM from './TTextDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TSalesOrderDM from './TSalesOrderDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import AdvanceReceivedStatus from './AdvanceReceivedStatus'
import KonstSymb from './KonstSymb'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TAccountingChartDM from './TAccountingChartDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TAddressDM from './TAddressDM'
import TVATRateDM from './TVATRateDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TInternalDocumentDM from './TInternalDocumentDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TAdvanceDeductionAdvanceDM from './TAdvanceDeductionAdvanceDM'
import TDocumentReminderDM from './TDocumentReminderDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAdvanceReceivedDM extends Data {
	public static className = 'TAdvanceReceivedDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreateReminders?: string
	PlannedPaymentDate?: Date
	NotIncludeIntoFinancialMngmt?: string
	SpecificSymbol?: string
	BankCodeNumber?: string
	BankId?: number
	IBAN?: string
	CreatedOn?: Date
	Settings?: string
	TaxNumber?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	RemainingAmountC?: number
	AmountVATC?: number
	AmountNetC?: number
	AmountGrossC?: number
	ExternalNumber?: string
	Number?: number
	RID?: number
	TimeStamp?: string
	TaxPointDate?: Date
	RightGroupId?: number
	AmountSettledC?: number
	AmountPaidByArchiveC?: number
	AmountPaidC?: number
	IsPaid?: string
	AmountPaidByArchive?: number
	PaymentDate?: Date
	AccountingItemsCount?: number
	PostedById?: number
	PostedOn?: Date
	ConfirmedOn?: Date
	ChangedOn?: Date
	RemainingAmount?: number
	AmountPaid?: number
	AmountSettled?: number
	Description?: string
	ReferenceNumber?: string
	DueDate?: Date
	AccountingTransactionDate?: Date
	IssueDate?: Date
	TradingPartnerBankAccount?: string
	PostingKeyId?: number
	ExchangeRate2?: number
	ExchangeRate?: number
	AmountVAT?: number
	AmountNet?: number
	AmountGross?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PictureCalc?: string
	ClientCurrencyCalc?: string
	ExchangeRateCalc?: number
	ExchangeRate2Calc?: number
	MaturityDaysCalc?: number
	VATCoefficientCalc?: number
	PriceGrossCalc?: number
	PriceNetCalc?: number
	PriceVATCalc?: number
	PriceGrossCCalc?: number
	PriceNetCCalc?: number
	PriceVATCCalc?: number
	CurrencyCheckBoxCalc?: string
	DeductedAmountCalc?: number
	DeductedAmountCCalc?: number
	RemainsToDeductCCalc?: number
	OnlyPaidBitCalc?: number
	CreditNoteImgCalc?: string
	AmountSettledCCalc?: number
	AmountPaidCCalc?: number
	AmountPaidByArchiveCalc?: number
	RemainingAmountCCalc?: number
	TaxDocumentBitCalc?: number
	NotReachedIncomeHeaInsBitCalc?: number
	PaidAndPaidByArchiveAmountCalc?: number
	CancelOrAmountGrossCalc?: number
	IsUnpaidOrUndeductedBitCalc?: number
	TaxDocumentStateCalc?: string
	AccountNetCalc?: number
	AccountVATCalc?: number
	VATDocumentImgCalc?: string
	ExchangeRate2Or1Calc?: number
	AdvanceExchangeRateDiffCalc?: number
	AdvanceExchangeRateDiffImgCalc?: string
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	FullInvoiceAddressIdCalc?: number
	FullDeliveryAddressIdCalc?: number
	FullPostalAddressIdCalc?: number
	FullPostalInvoiceAddressIdCalc?: number
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	RemainsToDeductVATCCalc?: number
	RemainsToDeductNetCCalc?: number
	PaymentDifferenceCalc?: number
	PaymentDifferenceImgCalc?: string
	IncludeDeductedCalc?: number
	RemainsToDeductCalc?: number
	RemainsToDeductNetCalc?: number
	RemainsToDeductVATCalc?: number
	PriceEGrossCalc?: number
	TotalPriceNetCalc?: number
	AmountVATCalc?: number
	PriceEGrossCCalc?: number
	TotalPriceNetCCalc?: number
	AmountVATCCalc?: number
	EditedTotalAmountVATCalc?: number
	MultiAdvanceImgCalc?: string
	BlTestAccTransactionDateCalc?: Date
	BlTestTaxPointDateCalc?: Date
	ExchangeDifferenceCalc?: number
	SettleCCalc?: number
	QRCodeCZBankOrderStandardCalc?: string
	QRCodeCZBankOrderLargeCalc?: string
	ReminderCountCalc?: number
	ReminderIssueDateCalc?: Date
	ReminderDocumentCalc?: string
	MaturityDateFromCalc?: Date
	CurrencyExchangeRateCalc?: number
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	PostZeroAmountDocumentsBitCalc?: number
	QRCZInvoiceCalc?: string
	VATRegNumberCalc?: string
	AmountInternalPaidPctCalc?: number
	IsRecordInEETCalc?: string
	BankCodeNumberCalc?: string
	VATControlStatementDateCalc?: Date
	FullAccountNumberCalc?: string
	ExpectedPaymentDateCalc?: Date
	QRCodeSKBankOrderPayBySquareCalc?: string
	VATGrossCalcMethodBitCalc?: number
	RecipientVATRegNumberBitCalc?: number
	MultiAdvanceBitCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	CompanyRegNumberCalc?: string
	VATRegNumberNotFullCalc?: string
	TaxNumberCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	PlannedPaymentAmountCCalc?: number
	PlannedPaymentAmountCalc?: number
	PlannedRemainingAmountCCalc?: number
	PlannedRemainingAmountCalc?: number
	PlannedCurrencyCalc?: string
	ShowTotalInBaseCurrencyCalc?: string
	ShowRemainingBaseCurrencyCalc?: string
	AverageDelayCalc?: number
	VATDocumentIdentificationCalc?: string
	ExchangeRateDateInformationCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TTextDM)
	PaymentStatusId?: TTextDM
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TSalesOrderDM)
	SalesOrderRID?: TSalesOrderDM
	@Type(() => TK2UserLookupDM)
	TaxDocumentSetById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => AdvanceReceivedStatus)
	StatusId?: AdvanceReceivedStatus
	@Type(() => KonstSymb)
	ConstantSymbol?: KonstSymb
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TAccountingChartDM)
	DebitAccountId?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	CreditAccountId?: TAccountingChartDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TPaymentMethodDM)
	PaymentMethod?: TPaymentMethodDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	CreditNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	ExistsCreditNoteBitCalc?: TTextDM
	@Type(() => TTextDM)
	CurrencyCheckboxBitCalc?: TTextDM
	@Type(() => TTextDM)
	VATBitCalc?: TTextDM
	@Type(() => TTextDM)
	PaidStateIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	InvoiceAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	DeliveryAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	VATDocumentIdCalc?: TTextDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TTradingPartnerDM)
	PostalAddressTrPartIdCalc?: TTradingPartnerDM
	@Type(() => TVATRateDM)
	VATRateIdCalc?: TVATRateDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TTradingPartnerDM)
	TaxTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByIdInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerByAbbr2InputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerNameInputIdCalc?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TrPartByCompRegNumberInputIdCalc?: TTradingPartnerDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TInternalDocumentDM)
	InternalDocumentChild?: TInternalDocumentDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TAdvanceDeductionAdvanceDM)
	AdvanceDeductionChild?: TAdvanceDeductionAdvanceDM[]
	@Type(() => TDocumentReminderDM)
	ReminderChild?: TDocumentReminderDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}