import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPromotionDM from './TPromotionDM'

export default class TAppliedPromotionDM extends Data {
	public static className = 'TAppliedPromotionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	AppliedDate?: Date
	AppliedCount?: number
	DocumentRID?: number
	DocumentTypeId?: number
	PromotionGroupRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PromotionTypeIdCalc?: number
	@Type(() => TPromotionDM)
	PromotionRID?: TPromotionDM
}