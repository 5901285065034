import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TTextDM from './TTextDM'
import PrCalTypDay from './PrCalTypDay'
import PrCalTypHours from './PrCalTypHours'

export default class TPrCalendarDefinitionDM extends Data {
	public static className = 'TPrCalendarDefinitionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsDeactivatedRecord?: string
	WorkshiftRID?: number
	RID?: number
	DateShift?: number
	BreakTime?: number
	TotalTime?: number
	ValidTo?: Date
	ValidFrom?: Date
	StringValue2?: string
	StringValue3?: string
	ShortValue?: number
	RepetitionDayCount?: number
	LongValue?: number
	RepetitionMonthId?: number
	RepetitionDayId?: number
	RepetitionEveryB?: number
	RepetitionEveryA?: number
	DayCount?: number
	ValidToTime?: number
	ValidFromTime?: number
	ValidToDate?: Date
	ValidFromDate?: Date
	Description?: string
	CodeCalendarId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TimeHoursCalc?: number
	TimeMinutesCalc?: number
	OverdaysCalc?: number
	DurationTimeCalc?: number
	BreakInHoursCalc?: number
	StateLockedCalc?: number
	AllDayIdCalc?: number
	WorkDayIdCalc?: number
	AccountWorkDayIdCalc?: number
	SaturdayIdCalc?: number
	SundayIdCalc?: number
	DayTypeIdCalcCalc?: number
	DaySequence1BitCalc?: number
	DaySequence2BitCalc?: number
	DaySequence3BitCalc?: number
	DaySequence4BitCalc?: number
	DaySequence5BitCalc?: number
	DaySequence6BitCalc?: number
	DaySequence7BitCalc?: number
	DaySequence8BitCalc?: number
	DaySequence9BitCalc?: number
	DaySequence10BitCalc?: number
	DaySequence11BitCalc?: number
	DaySequence12BitCalc?: number
	DaySequence13BitCalc?: number
	DaySequence14BitCalc?: number
	DaySequence15BitCalc?: number
	DaySequence16BitCalc?: number
	DaySequence17BitCalc?: number
	DaySequence18BitCalc?: number
	DaySequence19BitCalc?: number
	DaySequence20BitCalc?: number
	DaySequence21BitCalc?: number
	DaySequence22BitCalc?: number
	DaySequence23BitCalc?: number
	DaySequence24BitCalc?: number
	DaySequence25BitCalc?: number
	DaySequence26BitCalc?: number
	DaySequence27BitCalc?: number
	DaySequence28BitCalc?: number
	DaySequence29BitCalc?: number
	DaySequence30BitCalc?: number
	DaySequence31BitCalc?: number
	ShowDayAbbrCalc?: string
	ShowDayFlagCalc?: string
	TimeFromCalc?: Date
	TimeToCalc?: Date
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM
	@Type(() => TTextDM)
	WorkShiftTypeId?: TTextDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	PriorityId?: TTextDM
	@Type(() => TTextDM)
	RepetitionTypeId?: TTextDM
	@Type(() => PrCalTypDay)
	RepetitionDayTypeId?: PrCalTypDay
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours
	@Type(() => PrCalTypDay)
	DayTypeId?: PrCalTypDay
	@Type(() => TTextDM)
	RepetitionEveryId?: TTextDM
}