import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TCapacityPlanDayDM from './TCapacityPlanDayDM'
import TCapacityPlanDetailDM from './TCapacityPlanDetailDM'
import TWorkplaceResourceDM from './TWorkplaceResourceDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TWorkplaceDM extends Data {
	public static className = 'TWorkplaceDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	BlockingDate?: Date
	CompanyId?: number
	TimeStamp?: string
	RightGroupId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Name?: string
	Abbr?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	TotalDelayedCalc?: number
	TotalCapacityCalc?: number
	TotalRequirementsCalc?: number
	TotalUtilizationCalc?: number
	TotalDelayedUMCalc?: number
	TotalCapacityUMCalc?: number
	TotalRequirementsUMCalc?: number
	DateFromCalc?: Date
	DateToCalc?: Date
	IsShiftDelayedCalc?: string
	ShowInHoursCalc?: string
	ManuallyAdjustedScheduleOnlyCalc?: string
	AllowCollisionsCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TCapacityPlanDayDM)
	CapacityPlanDayChild?: TCapacityPlanDayDM[]
	@Type(() => TCapacityPlanDetailDM)
	CapacityPlanDetailChild?: TCapacityPlanDetailDM[]
	@Type(() => TWorkplaceResourceDM)
	ProductionResourceChild?: TWorkplaceResourceDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}