import { Container } from 'react-bootstrap'
import Order from 'components/Order'
import { OrderProps } from 'components/Order'

const orderProps: OrderProps = {
  statusId: {
      free: Number(process.env.REACT_APP_STATUS_FREE),
      open: Number(process.env.REACT_APP_STATUS_OPEN),
      pending: Number(process.env.REACT_APP_STATUS_PENDING)
  },
  orderForm: String(process.env.REACT_APP_ORDER_FORM),
  tradingPartnerId: Number(process.env.REACT_APP_TRADING_PARTNER),
  bookId: Number(process.env.REACT_APP_BOOK),
  plannedWarehouseId: Number(process.env.REACT_APP_PLANNED_WAREHOUSE),
}

function App() {
  return (
    <Container fluid className="p-0 mb-5">
      <Order
        {...orderProps}
      />
    </Container>
  )
}

export default App
