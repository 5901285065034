import Data from '../Api/Data'

export default class TServiceOperationDM extends Data {
	public static className = 'TServiceOperationDM'
	public static systemFields = [
		'RecordID',
	]

	RecordMod?: number
	RecordES?: number
	RecordNo?: number
	RecordID?: number
	RecordState?: number
	SuperiorHeaderRID?: number
	DecisiveDate?: Date
	ItemNumber?: number
	ChildType?: number
	HeaderRID?: number
	HeaderTypeId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IdentificationCalc?: string
	DescriptionCalc?: string
	RecordPermissionCalc?: string
	IsConfirmedCalc?: string
	ModuleNameCalc?: string
}