import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCodeTypeDM from './TCodeTypeDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'

export default class TCommentDM extends Data {
	public static className = 'TCommentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ChangedOn?: Date
	CreatedOn?: Date
	TableId?: number
	RightGroupId?: number
	SequenceNumber?: number
	MasterRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	Content100Calc?: string
	ContentCalc?: string
	PlainContentCalc?: string
	Content300Calc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TCodeTypeDM)
	TypeId?: TCodeTypeDM
	@Type(() => TLanguageMemoTranslationDM)
	ContentChild?: TLanguageMemoTranslationDM[]
}