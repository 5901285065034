import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ContractCodeStatus from './ContractCodeStatus'
import TSummationContractCodeDM from './TSummationContractCodeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TWarehouseDM from './TWarehouseDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TSalesItemAnalyticalDimensionDM from './TSalesItemAnalyticalDimensionDM'
import TPurchaseItemAnalyticalDimensionDM from './TPurchaseItemAnalyticalDimensionDM'
import TPaymentDocumentItemAnalyticalDimensionDM from './TPaymentDocumentItemAnalyticalDimensionDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'
import TAccountingJournalItemAnalyticalDimensionDM from './TAccountingJournalItemAnalyticalDimensionDM'
import TAssetMovementAnalyticalDimensionDM from './TAssetMovementAnalyticalDimensionDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TContractCodeDM extends Data {
	public static className = 'TContractCodeDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsPublicContract?: string
	SequenceNumber?: number
	IsBlockedRecord?: string
	IsAutogenerated?: string
	NotLoadItemsAutomatically?: string
	NotEvaluateAutomatically?: string
	NotInsertIntoDocument?: string
	ConfirmedOn?: Date
	TimeStamp?: string
	Text?: string
	RIghtGroupId?: number
	RID?: number
	ChangedOn?: Date
	Description?: string
	DateTo?: Date
	DateFrom?: Date
	CreatedOn?: Date
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	LanguageTextCalc?: string
	ReceivedCalc?: number
	AvailableQuantityCalc?: number
	AssignedCalc?: number
	DispatchCalc?: number
	ReceivedUMCalc?: number
	AvailableQuantityUMCalc?: number
	AssignedUMCalc?: number
	DispatchUMCalc?: number
	IsAvailableCalc?: string
	ShowItemCalc?: number
	IsUnconfirmedFilterCalc?: string
	SummationEvaluationField1Calc?: number
	SummationEvaluationField2Calc?: number
	SummationEvaluationField3Calc?: number
	SummationEvaluationField4Calc?: number
	SummationEvaluationField5Calc?: number
	SummationEvaluationField6Calc?: number
	InReceiptQuantityCalc?: number
	InReceiptQuantityUMCalc?: number
	InTransferCalc?: number
	InTransferUMCalc?: number
	SkipNullCalc?: string
	EvaluationConfigCalc?: string
	UseAnalyticalDimensionCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => ContractCodeStatus)
	StatusId?: ContractCodeStatus
	@Type(() => TSummationContractCodeDM)
	SummationContractCodeRID?: TSummationContractCodeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TWarehouseDM)
	WarehouseIdCalc?: TWarehouseDM
	@Type(() => TArticleDM)
	ArticleIdCalc?: TArticleDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitCalc?: TMeasureUnitDM
	@Type(() => TTextDM)
	ConfirmedBitCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TSalesItemAnalyticalDimensionDM)
	SalesItemChild?: TSalesItemAnalyticalDimensionDM[]
	@Type(() => TPurchaseItemAnalyticalDimensionDM)
	PurchaseItemChild?: TPurchaseItemAnalyticalDimensionDM[]
	@Type(() => TPaymentDocumentItemAnalyticalDimensionDM)
	FinanceItemChild?: TPaymentDocumentItemAnalyticalDimensionDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TAccountingJournalItemAnalyticalDimensionDM)
	AccountingJournaItemBookChild?: TAccountingJournalItemAnalyticalDimensionDM[]
	@Type(() => TAssetMovementAnalyticalDimensionDM)
	AssetMovementChild?: TAssetMovementAnalyticalDimensionDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}