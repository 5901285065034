import PrimaryKey from './PrimaryKey'
import ISimpleObject from './ISimpleObject'
import { plainToInstance } from 'class-transformer'
import { diff } from 'deep-object-diff'

export default class Data {
    private _originalData?: Data

    public _isDeleted?: boolean

    public static className = 'Data'

    public static primaryKey: string = ''

    public static systemFields: string[] = []

    public set originalData(data: Data) {
        if (this._originalData) {
            throw new Error('Property originalData is already assigned.')
        }

        this._originalData = data
    }

    public get originalData(): Data {
        return this._originalData!
    }

    public getPrimaryKey(): PrimaryKey {
        throw new Error('Method "getPrimaryKey" must be defined in descendant class.')
    }

    public getChangedData(): Data {
        const changedData: ISimpleObject = {}
        let queue = [{
            dataChanges: diff(this.originalData, this) as ISimpleObject,
            originalData: this,
            changedData: changedData,
            addSystemFields: true
        }]

        while (queue.length > 0) {
            const item = queue.pop()!
            let changedKeys = Object.keys(item.dataChanges).reverse().filter(key => key !== '_originalData')

            if (typeof item.originalData === 'object' && !Array.isArray(item.originalData)) {
                const c = (item.originalData.constructor as typeof Data)
                changedKeys.push(c.primaryKey)
                c.systemFields.forEach(field => changedKeys.push(field))
            }

            for (const [key, value] of Object.entries(item.originalData)) {
                if (!changedKeys.includes(key)) continue
                if (typeof item.dataChanges[key] === 'object') {
                    let myChangedData = item.changedData[key]
                    if (Array.isArray(value)) {
                        item.changedData[key] = []
                        myChangedData = item.changedData[key]
                    } else {
                        myChangedData = plainToInstance(value.constructor as typeof Data, {})
                        if (Array.isArray(item.changedData)) {
                            item.changedData.push(myChangedData)
                        } else {
                            item.changedData[key] = myChangedData
                        }
                    }

                    queue.push({
                        dataChanges: item.dataChanges[key],
                        originalData: value,
                        changedData: myChangedData,
                        addSystemFields: Array.isArray(item.originalData)
                    })
                } else {
                    item.changedData[key] = value
                }
            }
        }

        return plainToInstance(this.constructor as typeof Data, changedData)
    }
}