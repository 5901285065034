import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TResourceDM from './TResourceDM'

export default class TCapacityPlanDetailDM extends Data {
	public static className = 'TCapacityPlanDetailDM'
	public static primaryKey = 'RecordNumber'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RecordNumber!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	IsGroup?: string
	IsSummationResource?: string
	PlannedDateTimeTo?: Date
	WorkshiftAbbr?: string
	PlannedDateTimeFrom?: Date
	RecordNumber?: number
	ScheduledOnlyRequest?: number
	Request?: number
	Capacity?: number
	WorkshiftRID?: number
	WorkshiftDate?: Date
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TResourceDM)
	ResourceId?: TResourceDM
}