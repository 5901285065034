import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TWMSQueueDM from './TWMSQueueDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TWMSEquipmentDM extends Data {
	public static className = 'TWMSEquipmentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CreatedOn?: Date
	TimeStamp?: string
	RightGroupId?: number
	ChangedOn?: Date
	Description?: string
	Id?: number
	Name?: string
	Abbr?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageCodeCalc?: string
	LanguageDescriptionCalc?: string
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TWMSQueueDM)
	LastSequenceNo?: TWMSQueueDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageNameChild?: TLanguageTextTranslationDM[]
}