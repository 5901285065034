import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TAttendanceWorkShiftTypeDM from './TAttendanceWorkShiftTypeDM'
import TPrCalendarDM from './TPrCalendarDM'
import TAttendanceScheduleDM from './TAttendanceScheduleDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TContactPersonDM from './TContactPersonDM'
import TAttendanceMovementSubTypeDM from './TAttendanceMovementSubTypeDM'
import TAttendanceProcessedMovementDM from './TAttendanceProcessedMovementDM'
import TAttendanceWorkTimeReportItemAccountDM from './TAttendanceWorkTimeReportItemAccountDM'
import TAttendanceProcessedIntervalDM from './TAttendanceProcessedIntervalDM'
import TAttendanceAuxiliaryIntervalDM from './TAttendanceAuxiliaryIntervalDM'
import TAttendanceTaskWorkDM from './TAttendanceTaskWorkDM'
import TAttendanceProcessedCalendarItemDM from './TAttendanceProcessedCalendarItemDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLinkedDocumentDM from './TLinkedDocumentDM'

export default class TAttendanceWorkTimeReportItemDM extends Data {
	public static className = 'TAttendanceWorkTimeReportItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsUserEdited?: string
	Note?: string
	AfterEnd?: number
	BeforeStart?: number
	IsEarlyDeparture?: string
	IsLateArrival?: string
	IsLateDeparture?: string
	IsEarlyArrival?: string
	WorkFundDays?: number
	IsHoliday?: string
	Balance?: number
	Absence?: number
	BreakTime?: number
	WorkedOut?: number
	WorkFund?: number
	IsOvertimeShift?: string
	IsCanceledRecord?: string
	ConfirmedOn?: Date
	BookId?: number
	Date?: Date
	WorkTimeReportRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DayCalc?: number
	CumulatedBalanceCalc?: number
	AttendanceMovementsDescriptionCalc?: string
	AbsenceDescriptionCalc?: string
	PlanMinStartTimeCalc?: Date
	PlanMinEndTimeCalc?: Date
	PlanContractStartTimeCalc?: Date
	PlanContractEndTimeCalc?: Date
	PlanMaxStartTimeCalc?: Date
	PlanMaxEndTimeCalc?: Date
	AbbrCalc?: string
	WorkedOutCalc?: number
	AbsenceCalc?: number
	BalanceCalc?: number
	BreakTimeCalc?: number
	WorkTimeHoursCountCalc?: number
	WorkTimeDaysCountCalc?: number
	DoctorVisitHoursCountCalc?: number
	LeaveHoursCountCalc?: number
	OverTimeCompensTimeOffCountCalc?: number
	HolidayCompensTimeOffCountCalc?: number
	WorkOverTimeHoursCountCalc?: number
	ReadinessHoursCountCalc?: number
	OverTimeExcessFareHoursCountCalc?: number
	NightWorkExcessFareHoursCountCalc?: number
	AfternoonWorkExcessFareHoursCountCalc?: number
	WeekendWorkExcessFareHoursCountCalc?: number
	HolidayExcessFareHoursCountCalc?: number
	MealTicketClaimCalc?: number
	BreakLunchTimeCalc?: number
	OfficialJourneyCalc?: number
	IllnessCalc?: number
	NursingCalc?: number
	SafetyBreakCalc?: number
	UnexcusedAbsenceHoursCountCalc?: number
	SickDaysHoursCountCalc?: number
	TrainingHoursCountCalc?: number
	WeatherConditionsHoursCountCalc?: number
	WorkInterruptionHoursCountCalc?: number
	DowntimeHoursCountCalc?: number
	EmployerObstacleHoursCountCalc?: number
	WeddingHoursCountCalc?: number
	BloodCollectionHoursCountCalc?: number
	FuneralHoursCountCalc?: number
	TaskSalaryHoursCountCalc?: number
	TaskSalaryAmountCalc?: number
	BonusAmountCalc?: number
	FirstArrivalCalc?: Date
	LastDepartureCalc?: Date
	NoCompensationAbsenceHoursCountCalc?: number
	UnpaidVacationHoursCountCalc?: number
	HomeOfficeHoursCountCalc?: number
	PaidHolidayHoursCountCalc?: number
	HolidayWorkHoursCountCalc?: number
	BalanceCompensTimeOffCountCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TTextDM)
	ErrorTypeId?: TTextDM
	@Type(() => TAttendanceWorkShiftTypeDM)
	AttendanceWorkShiftTypeRID?: TAttendanceWorkShiftTypeDM
	@Type(() => TPrCalendarDM)
	WHModelId?: TPrCalendarDM
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleRID?: TAttendanceScheduleDM
	@Type(() => TK2UserLookupDM)
	CanceledById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TAttendanceMovementSubTypeDM)
	LastDepartureTypeIdCalc?: TAttendanceMovementSubTypeDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TAttendanceScheduleDM)
	AttendanceScheduleChild?: TAttendanceScheduleDM[]
	@Type(() => TAttendanceProcessedMovementDM)
	AttendanceProcessedMovementChild?: TAttendanceProcessedMovementDM[]
	@Type(() => TAttendanceWorkTimeReportItemAccountDM)
	AttendanceWorkTimeReportItemAccountsChild?: TAttendanceWorkTimeReportItemAccountDM[]
	@Type(() => TAttendanceProcessedIntervalDM)
	AttendanceProcessedIntervalChild?: TAttendanceProcessedIntervalDM[]
	@Type(() => TAttendanceAuxiliaryIntervalDM)
	AttendanceAuxiliaryIntervalChild?: TAttendanceAuxiliaryIntervalDM[]
	@Type(() => TAttendanceTaskWorkDM)
	AttendanceTaskWorkChild?: TAttendanceTaskWorkDM[]
	@Type(() => TAttendanceProcessedCalendarItemDM)
	AttendanceProcessedCalendarItemChild?: TAttendanceProcessedCalendarItemDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLinkedDocumentDM)
	AttachedDocument?: TLinkedDocumentDM[]
}