import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterGroupDM from './TParameterGroupDM'
import TParameterDM from './TParameterDM'
import ParameterUnit from './ParameterUnit'
import TParameterPossibleValueListDM from './TParameterPossibleValueListDM'

export default class TArticleParameterValueDM extends Data {
	public static className = 'TArticleParameterValueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	RID?: number
	LanguageId?: number
	UniqueValueId?: number
	ComparisonValue?: number
	PriorityId?: number
	Value?: string
	HeaderDM?: number
	HeaderId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageValueCalc?: string
	ValueDescriptionCalc?: string
	LanguageValueDescriptionCalc?: string
	NameCalc?: string
	DescriptionCalc?: string
	IconCalc?: number
	IconImgCalc?: string
	ValueCalc?: string
	ValueStringCalc?: string
	ValueIntegerCalc?: number
	ValueDecimalCalc?: number
	ValueDateCalc?: Date
	ValueTimeCalc?: Date
	ValueFromListCalc?: string
	ShowOnShopCalc?: string
	@Type(() => TParameterGroupDM)
	ParameterGroupId?: TParameterGroupDM
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM
	@Type(() => ParameterUnit)
	UnitId?: ParameterUnit
	@Type(() => TParameterPossibleValueListDM)
	CheckedBindListValueCalc?: TParameterPossibleValueListDM
}