import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCountryDM from './TCountryDM'
import TElectronicAddressTypeDM from './TElectronicAddressTypeDM'
import DepartmentName from './DepartmentName'

export default class TElectronicAddressDM extends Data {
	public static className = 'TElectronicAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Username?: string
	SendNotifications?: string
	SendInformation?: string
	RID?: number
	FullElectronicAddress?: string
	ElectronicAddress?: string
	HeaderRID?: number
	ReferenceTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AddressFormattedCalc?: string
	AddressTypeCalc?: string
	@Type(() => TCountryDM)
	CountryId?: TCountryDM
	@Type(() => TElectronicAddressTypeDM)
	ElectronicAddressTypeId?: TElectronicAddressTypeDM
	@Type(() => DepartmentName)
	Department?: DepartmentName
}