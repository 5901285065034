import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TAddressDM from './TAddressDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCurrencyDM from './TCurrencyDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TTextDM from './TTextDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'
import TInvoiceInDM from './TInvoiceInDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TAdvanceProvidedDM from './TAdvanceProvidedDM'
import TAdvanceReceivedDM from './TAdvanceReceivedDM'
import TPrLiabilityDM from './TPrLiabilityDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAssetLinkDM from './TAssetLinkDM'

export default class TFinanceCashItemDM extends Data {
	public static className = 'TFinanceCashItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ExternalReference?: string
	BankCodeNumber?: string
	BookId?: number
	PaidDocumentRID?: number
	BankId?: number
	RID?: number
	HeaderRID?: number
	AdditionalInfo?: string
	PaymentCalendarId?: number
	RightGroupId?: number
	PostingKeyId?: number
	ConstantSymbolId?: string
	BankOrderItemId?: number
	RowId?: number
	AmountPaid?: number
	AmountPaidC?: number
	AmountVAT?: number
	AmountVatC?: number
	AmountNet?: number
	AmountNetC?: number
	PaidDocumentExchangeRate?: number
	PaidItemExchangeRate?: number
	ExchangeRate?: number
	TaxPointDate?: Date
	AccountingTransactionDate?: Date
	ReferenceNumber?: string
	Description2?: string
	Description?: string
	PaidDocumentCardId?: number
	PaidDocumentIdOld?: number
	PaidDocumentBookOld?: string
	PaidDocumentTypeId?: number
	SequenceNumber?: number
	ItemId?: number
	HeaderTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AddressIdCoordinates?: string
	AddressIdTownCode?: string
	AddressIdChanged?: number
	AddressIdName?: string
	AddressIdHouseNumber?: string
	AddressIdOrientationNumber?: string
	AddressIdStreet?: string
	OldAmountPaid?: number
	OldPaidDocumentRID?: number
	OldBankOrderItemRID?: number
	OldPaidDocumentTypeId?: number
	OldAmountPaidC?: number
	OldDescription?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	AccountingTransactionDateCalc?: Date
	AccountingTransactionTimeCalc?: Date
	ReferenceNumberInvoiceInCalc?: string
	ReferenceNumberInvoiceOutCalc?: string
	ReferenceNumberAdvProvidedCalc?: string
	ReferenceNumberAdvReceivedCalc?: string
	PaidDocumentNameCalc?: string
	ExchangeRateCalc?: number
	DocumentExchangeRateCalc?: number
	PaidDocumentExchangeRateCalc?: number
	PaymentDocumentDescriptionCalc?: string
	DescriptionCalc?: string
	CompanyDateCalc?: Date
	ReferenceNumberPayrollCalc?: string
	PaidDocAccTransactionDateCalc?: Date
	PaidDocumentAmountGrossCCalc?: number
	PaidDocumentAmountGrossCalc?: number
	PaidDocumentRemainingAmountCCalc?: number
	PaidDocRemainingAmountCalc?: number
	PaidDocumentDifferenceCalc?: string
	PaymentStatusIdCalc?: number
	RemainingAmountCCalc?: number
	RemainingAmountCalc?: number
	OriginalPaidDocAmountGrossCCalc?: number
	OtherReceivableRIDCalc?: number
	OtherLiabilityRIDCalc?: number
	ReferenceNumberOtherReceivableCalc?: string
	ReferenceNumberOtherLiabilityCalc?: string
	VATRegNumberCalc?: string
	CanceledRecordBitCalc?: number
	AllocationDoneIdCalc?: number
	RevaluationBitCalc?: number
	ConfirmedBitCalc?: number
	CashDiscountIdCalc?: number
	VATIdCalc?: number
	NotRoundBitCalc?: number
	ManuallyChangedBitCalc?: number
	ExchangeDocumentIdCalc?: number
	PaymentStatusPctCalc?: number
	ShowDocumentPanelCalc?: string
	IsCompanyCurrencyCalc?: string
	IsRevaluatedCalc?: string
	ShowAddressCalc?: string
	BankCodeNumberCalc?: string
	VATRateCalc?: number
	IdCardAbbrCalc?: string
	AmountGrossCCalc?: number
	AmountGrossCalc?: number
	DifferenceCalc?: number
	VATFromAboveCoefficientCalc?: number
	InOutDifferenceCalc?: number
	ExchangeRateDifferenceCalc?: number
	CreditAmountCCalc?: number
	CreditAmountCalc?: number
	DebitAmountCCalc?: number
	DebitAmountCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TAccountingChartDM)
	Account2Id?: TAccountingChartDM
	@Type(() => TAccountingChartDM)
	Account1Id?: TAccountingChartDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TCurrencyDM)
	PaidDocumentCurrency?: TCurrencyDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => TTradingPartnerDM)
	PaidDocumentTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TInvoiceInDM)
	InvoiceInRIDCalc?: TInvoiceInDM
	@Type(() => TInvoiceOutDM)
	InvoiceOutRIDCalc?: TInvoiceOutDM
	@Type(() => TAdvanceProvidedDM)
	AdvanceProvidedRIDCalc?: TAdvanceProvidedDM
	@Type(() => TAdvanceReceivedDM)
	AdvanceReceivedRIDCalc?: TAdvanceReceivedDM
	@Type(() => TPrLiabilityDM)
	PayrollDocumentRIDCalc?: TPrLiabilityDM
	@Type(() => TAccountingChartDM)
	VATAccountIdCalc?: TAccountingChartDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerVATDateIdCalc?: TTradingPartnerDM
	@Type(() => TAccountingChartDM)
	DebitAccountIdCalc?: TAccountingChartDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAssetLinkDM)
	AssetLinkChild?: TAssetLinkDM[]
}