import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TSpecialStringListDM from './TSpecialStringListDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TJobCardDM from './TJobCardDM'
import TProductionOrderPlanDM from './TProductionOrderPlanDM'
import TProductionOrderActualDM from './TProductionOrderActualDM'
import TProductionOrderCostDM from './TProductionOrderCostDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TProductionOrderDM extends Data {
	public static className = 'TProductionOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsCanceledRecord?: string
	Number?: number
	RID?: number
	TimeStamp?: string
	RightGroupId?: number
	ConfirmedOn?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	PlannedDateTo?: Date
	PlannedDateFrom?: Date
	Description?: string
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	PlannedDate1DateCalc?: Date
	PlannedDate1HourCalc?: Date
	PlannedDate2DateCalc?: Date
	PlannedDate2HourCalc?: Date
	MinJobCardPlannedDateTimeCalc?: Date
	MaxJobCardPlannedDateTimeCalc?: Date
	MinJobCardPlannedDateCalc?: Date
	MinJobCardPlannedTimeCalc?: Date
	MaxJobCardPlannedDateCalc?: Date
	MaxJobCardPlannedTimeCalc?: Date
	PlannedTimeCalc?: number
	ActualDurationCalc?: number
	SpentTimePercentageCalc?: number
	PlannedValueCalc?: number
	ActualValueCalc?: number
	PercentageCompleteCalc?: number
	FlagCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TSpecialStringListDM)
	StatusId?: TSpecialStringListDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	ChartOptionsIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TJobCardDM)
	ProductionTreeChild?: TJobCardDM[]
	@Type(() => TProductionOrderPlanDM)
	ProductionOrderPlanChild?: TProductionOrderPlanDM[]
	@Type(() => TProductionOrderActualDM)
	ProductionOrderActualChild?: TProductionOrderActualDM[]
	@Type(() => TProductionOrderCostDM)
	ProductionOrderCostChild?: TProductionOrderCostDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}