import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TFragmentRasterDM from './TFragmentRasterDM'
import TFragmentAssignDM from './TFragmentAssignDM'
import TFragmentItemDM from './TFragmentItemDM'

export default class TFragmentDM extends Data {
	public static className = 'TFragmentDM'
	public static primaryKey = 'Id'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	RootIdentifier?: string
	ClientTypeId?: number
	ChangedOn?: Date
	CreatedOn?: Date
	Content?: string
	DataModuleNumber?: number
	KindId?: number
	GroupId?: number
	CategoryId?: number
	Identifier?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IsLibraryFragmentCalc?: string
	IsImportedCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	ComponentTypeId?: TTextDM
	@Type(() => TFragmentRasterDM)
	RasterRID?: TFragmentRasterDM
	@Type(() => TTextDM)
	StateId?: TTextDM
	@Type(() => TFragmentAssignDM)
	FragmentAssignOldChild?: TFragmentAssignDM[]
	@Type(() => TFragmentItemDM)
	FragmentItemOldChild?: TFragmentItemDM[]
}