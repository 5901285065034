import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TVATRateDM from './TVATRateDM'
import TTaxTypeDM from './TTaxTypeDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TCurrencyDM from './TCurrencyDM'
import TAccountingChartDM from './TAccountingChartDM'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TTextDM from './TTextDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAccountingJournalItemAnalyticalDimensionDM extends Data {
	public static className = 'TAccountingJournalItemAnalyticalDimensionDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RecalculateAmount?: string
	IsManuallyChanged?: string
	IsCanceledRecord?: string
	HeaderRID?: number
	Number?: number
	RID?: number
	RightGroupId?: number
	AmountC?: number
	SequenceNumber?: number
	ExchangeRate?: number
	Amount?: number
	Description?: string
	ItemDay?: number
	ItemDate?: Date
	ItemNumber?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	PrimaryDocumentNumberCalc?: number
	PrimaryDocumentBookCalc?: string
	PrimaryDocumentPeriodCalc?: string
	MeasureUnitCountCalc?: number
	ExchangeRateCalc?: number
	AmountCalc?: number
	AmountCCalc?: number
	ReferenceNumberCalc?: string
	PrimaryDocumentIdentificationCalc?: string
	DescriptionCalc?: string
	BankStatementRIDCalc?: number
	CashDocumentRIDCalc?: number
	InvoiceOutRIDCalc?: number
	InvoiceInRIDCalc?: number
	ReceiptCardRIDCalc?: number
	ReleaseNoteRIDCalc?: number
	JobCardRIDCalc?: number
	AssetOldIdCalc?: number
	ReservingCardRIDCalc?: number
	TransferNoteRIDCalc?: number
	AdvanceProvidedRIDCalc?: number
	AdvanceReceivedRIDCalc?: number
	PrimaryDocumentBusinessYearCalc?: number
	InternalDocumentRIDCalc?: number
	PrDocumentRIDCalc?: number
	OtherReceivableRIDCalc?: number
	OtherLiabilityRIDCalc?: number
	AssetMovementRIDCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TTaxTypeDM)
	TaxTypeId?: TTaxTypeDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TCurrencyDM)
	ForeignCurrency?: TCurrencyDM
	@Type(() => TAccountingChartDM)
	CounterAccountId?: TAccountingChartDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TTextDM)
	HeaderTypeId?: TTextDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CounterCostCentreIdCalc?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	CounterContractCodeRIDCalc?: TContractCodeDocumentDM
	@Type(() => TArticleCodeDocumentDM)
	CounterArticleCodeIdCalc?: TArticleCodeDocumentDM
	@Type(() => TCode1DocumentDM)
	CounterCode1IdCalc?: TCode1DocumentDM
	@Type(() => TCode2DocumentDM)
	CounterCode2IdCalc?: TCode2DocumentDM
	@Type(() => TOfficerDocumentDM)
	CounterOfficerIdCalc?: TOfficerDocumentDM
	@Type(() => TTradingPartnerDM)
	CounterTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TMatchingSymbolDM)
	CounterMatchingSymbolIdCalc?: TMatchingSymbolDM
	@Type(() => TDeviceDocumentDM)
	CounterDeviceRIDCalc?: TDeviceDocumentDM
	@Type(() => TCode3DocumentDM)
	CounterCode3IdCalc?: TCode3DocumentDM
	@Type(() => TCode4DocumentDM)
	CounterCode4IdCalc?: TCode4DocumentDM
	@Type(() => TCode5DocumentDM)
	CounterCode5IdCalc?: TCode5DocumentDM
	@Type(() => TCode6DocumentDM)
	CounterCode6IdCalc?: TCode6DocumentDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}