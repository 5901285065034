import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TRoutingVariantDM from './TRoutingVariantDM'
import TArticleDM from './TArticleDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import AlternativeUnit from './AlternativeUnit'
import TCurrencyDM from './TCurrencyDM'

export default class TProductCostingSummaryItemDM extends Data {
	public static className = 'TProductCostingSummaryItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsGenerated?: string
	ProductPrice?: number
	MaterialPrice?: number
	MeasureUnitId?: number
	ProductPriceC?: number
	MaterialPriceC?: number
	Quantity?: number
	Name?: string
	SummaryRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	QuantityCalc?: number
	PriceCUMCalc?: number
	TotalPriceCCalc?: number
	TotalPriceCalc?: number
	ExchangeRateCalc?: number
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TMeasureUnitDM)
	ArticleMeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => AlternativeUnit)
	GeneralMeasureUnitIdCalc?: AlternativeUnit
	@Type(() => TCurrencyDM)
	CurrencyCalc?: TCurrencyDM
	@Type(() => AlternativeUnit)
	MeasureUnitGridIdCalc?: AlternativeUnit
}