import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TEshopDM from './TEshopDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import TWebModuleCategoryDM from './TWebModuleCategoryDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLinkDM from './TLinkDM'
import TCommentDM from './TCommentDM'
import TWebModuleLinkDM from './TWebModuleLinkDM'
import TWebModuleLinkSuperiorDM from './TWebModuleLinkSuperiorDM'
import TWebURLAddressItemDM from './TWebURLAddressItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TLanguageMemoTranslationChildDM from './TLanguageMemoTranslationChildDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TWebFAQDM extends Data {
	public static className = 'TWebFAQDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	BigNumber1?: number
	Number1?: number
	OtherText3?: string
	OtherText2?: string
	OtherText1?: string
	Url?: string
	ShowTime?: number
	ShortText?: string
	DateTo?: Date
	SequenceNumber?: number
	IsInvalidRecord?: string
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	DateFrom?: Date
	Description?: string
	ShortDescription?: string
	MetaDescription?: string
	MetaTitle?: string
	Title?: string
	Name?: string
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PictureCalc?: string
	ImageFromLinkFullPathCalc?: string
	UrlCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	TranslatedTitleCalc?: string
	TranslatedDescriptionCalc?: string
	@Type(() => TTextDM)
	ModuleId?: TTextDM
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	UserAccessId?: TTextDM
	@Type(() => TTextDM)
	OpenGraphTypeId?: TTextDM
	@Type(() => TContactPersonDM)
	AuthorId?: TContactPersonDM
	@Type(() => TWebModuleCategoryDM)
	CategoryRID?: TWebModuleCategoryDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TEshopDM)
	ShownWebRIDCalc?: TEshopDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	TranslationLanguageIdCalc?: TTextDM
	@Type(() => TLinkDM)
	LinkChild?: TLinkDM[]
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TWebModuleLinkDM)
	WebModuleLinkChild?: TWebModuleLinkDM[]
	@Type(() => TWebModuleLinkSuperiorDM)
	SuperiorWebModuleLinkChild?: TWebModuleLinkSuperiorDM[]
	@Type(() => TWebURLAddressItemDM)
	WebUrlPageAddressItemChild?: TWebURLAddressItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationChildDM)
	TranslatedFieldValuesChild?: TLanguageMemoTranslationChildDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}