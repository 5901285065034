import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTaxTypeDM from './TTaxTypeDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

export default class TLegislationLinkTradingPartnerDM extends Data {
	public static className = 'TLegislationLinkTradingPartnerDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsStatutoryInterestSale?: string
	IsStatutoryInterestPurchase?: string
	IsVATPayerSale?: string
	IsVATPayerPurchase?: string
	IsValid?: string
	ChangedOn?: Date
	RecordNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTaxTypeDM)
	SaleTaxTypeId?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeId?: TTaxTypeDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
}