import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TDeliveryLineDM from './TDeliveryLineDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import DeliveryOrderStatus from './DeliveryOrderStatus'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TInvoiceInDM from './TInvoiceInDM'
import AreaCode from './AreaCode'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TDeliveryOrderItemDM from './TDeliveryOrderItemDM'
import TDeliveryMeasureUnitDM from './TDeliveryMeasureUnitDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TDeliveryOrderDM extends Data {
	public static className = 'TDeliveryOrderDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Volume?: number
	DateTo?: Date
	ConfirmedOn2?: Date
	CreatedOn?: Date
	IsCanceledRecord?: string
	RightGroupId?: number
	RID?: number
	TimeStamp?: string
	InvoiceInCosts?: number
	Weight?: number
	ConfirmedOn1?: Date
	ChangedOn?: Date
	Description?: string
	QuantityInUnit3?: number
	QuantityInUnit2?: number
	QuantityInUnit1?: number
	Quantity?: number
	DeliveryTime?: string
	VehicleId?: number
	DateFrom?: Date
	Number?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	DeliveryTimeFromDateCalc?: Date
	DeliveryTimeFromTimeCalc?: Date
	DeliveryTimeToDateCalc?: Date
	DeliveryTimeToTimeCalc?: Date
	TotalVolumePARAMCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TDeliveryLineDM)
	DeliveryLineId?: TDeliveryLineDM
	@Type(() => TK2UserLookupDM)
	ConfirmedBy2Id?: TK2UserLookupDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => DeliveryOrderStatus)
	StatusId?: DeliveryOrderStatus
	@Type(() => TK2UserLookupDM)
	ConfirmedBy1Id?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TInvoiceInDM)
	InvoiceInRID?: TInvoiceInDM
	@Type(() => AreaCode)
	RegionId?: AreaCode
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TDeliveryOrderItemDM)
	ItemChild?: TDeliveryOrderItemDM[]
	@Type(() => TDeliveryMeasureUnitDM)
	MeasureUnitChild?: TDeliveryMeasureUnitDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}