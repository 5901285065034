import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TPeriodDM from './TPeriodDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import KonstSymb from './KonstSymb'
import TMatchingSymbolDM from './TMatchingSymbolDM'
import TAccountingChartDM from './TAccountingChartDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCurrencyDM from './TCurrencyDM'
import TPrPeriodDM from './TPrPeriodDM'
import TPrDeductionDM from './TPrDeductionDM'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrLiabilityDM extends Data {
	public static className = 'TPrLiabilityDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	PlannedPaymentDate?: Date
	NotIncludeIntoFinancialMngmt?: string
	BankCodeNumber?: string
	Number?: number
	IsPaid?: string
	RemainingAmount?: number
	RemainingAmountC?: number
	AmountSettled?: number
	AmountSettledC?: number
	AmountPaid?: number
	AmountPaidC?: number
	Amount?: number
	AmountC?: number
	ExchangeDifference?: number
	ExchangeRate2?: number
	ExchangeRate?: number
	BankId?: number
	IBAN?: string
	CreditBankAccount?: string
	SpecificSymbol?: string
	ReferenceNumber?: string
	PaidDate?: Date
	DueDate?: Date
	AccountingTransactionDate?: Date
	IssueDate?: Date
	Description?: string
	ChangedOn?: Date
	ConfirmedOn?: Date
	RightGroupId?: number
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	ExchangeRateCalc?: number
	ExchangeRate2Calc?: number
	MaturityDaysCalc?: number
	CancelOrAmountGrossCCalc?: number
	PaymentDifferenceCalc?: number
	PaymentDifferenceImgCalc?: string
	CancelOrAmountGrossCalc?: number
	AmountInternalPaidPctCalc?: number
	BankCodeNumberCalc?: string
	InCurrencyIdCalc?: number
	ExpectedPaymentDateCalc?: Date
	AmountCCalc?: number
	AmountPaidCCalc?: number
	AmountSettledCCalc?: number
	RemainingAmountCCalc?: number
	SettleCCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	PlannedPaymentAmountCCalc?: number
	PlannedPaymentAmountCalc?: number
	PlannedRemainingAmountCCalc?: number
	PlannedRemainingAmountCalc?: number
	PlannedCurrencyCalc?: string
	ShowTotalInBaseCurrencyCalc?: string
	ShowRemainingBaseCurrencyCalc?: string
	AverageDelayCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TTextDM)
	PaymentStatusId?: TTextDM
	@Type(() => TTextDM)
	PaymentPriorityId?: TTextDM
	@Type(() => TBookDM)
	PrDocumentBookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => KonstSymb)
	ConstantSymbol?: KonstSymb
	@Type(() => TMatchingSymbolDM)
	MatchingSymbolId?: TMatchingSymbolDM
	@Type(() => TAccountingChartDM)
	AccountId?: TAccountingChartDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TPrPeriodDM)
	PeriodId?: TPrPeriodDM
	@Type(() => TPrDeductionDM)
	PrDeductionId?: TPrDeductionDM
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonId?: TEmployedPersonSimpleDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TCurrencyDM)
	ClientCurrencyCalc?: TCurrencyDM
	@Type(() => TTextDM)
	PaidStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}