import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TBookDM from './TBookDM'
import Effect from './Effect'
import Outcome from './Outcome'
import TEmployedPersonSimpleDM from './TEmployedPersonSimpleDM'
import TQualificationDM from './TQualificationDM'
import TCommentDM from './TCommentDM'

export default class TQualificationEmployedPersonDM extends Data {
	public static className = 'TQualificationEmployedPersonDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	XMLField?: string
	RID?: number
	EffectMemo?: string
	OutcomeMemo?: string
	ValidTo?: Date
	EffectAbbr?: string
	OutcomeAbbr?: string
	ReturnsC?: number
	TypeId?: number
	Screening?: number
	Costs?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ValiFromDateCalc?: Date
	LastCommentParamCalc?: string
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => Effect)
	EffectId?: Effect
	@Type(() => Outcome)
	OutcomeId?: Outcome
	@Type(() => TEmployedPersonSimpleDM)
	EmployedPersonId?: TEmployedPersonSimpleDM
	@Type(() => TQualificationDM)
	QualificationRID?: TQualificationDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
}