import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardDM from './TJobCardDM'
import TLocationArticleDM from './TLocationArticleDM'
import GoodsName from './GoodsName'
import TWarehouseDM from './TWarehouseDM'
import TTextDM from './TTextDM'
import TOperationDM from './TOperationDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TArticleDM from './TArticleDM'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'

export default class TJobCardOperationDM extends Data {
	public static className = 'TJobCardOperationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsTransferQtyByLot?: string
	OperationTextFormat?: number
	IsSoftSplitting?: string
	IsPlanScrap?: string
	CompleteOperation?: string
	IsCooperation?: string
	StatusId?: number
	SuperiorRID?: number
	RoutingOperationRID?: number
	LastPlannedDateTo?: Date
	LastPlannedDateFrom?: Date
	IsFinished?: string
	IsFixated?: string
	IsManuallyAdjustedSchedule?: string
	PlannerMessage?: string
	RID?: number
	ActualDateTo?: Date
	ActualDateFrom?: Date
	ScrapAmount?: number
	ActualAmount?: number
	PlannedAmount?: number
	ActualTeardownTime?: number
	ActualRunTime?: number
	ActualSetupTime?: number
	LimitDateTo?: Date
	LimitDateFrom?: Date
	PlannedDateTo?: Date
	PlannedDateFrom?: Date
	ScrapQuantity?: number
	ActualQuantity?: number
	PlanQuantity?: number
	OperationText?: string
	OperationConfirmationDate?: Date
	Yield?: number
	TransferQuantity?: number
	TeardownTime?: number
	TimeBefore?: number
	TimeAfter?: number
	SetupTime?: number
	RunTime?: number
	OperationCode?: string
	SequenceNumber?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	HasAlreadyOutput?: string
	OriginalRID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ProductionTimeUMCalc?: number
	SetupTimeUMCalc?: number
	TimeAfterUMCalc?: number
	TimeBeforeUMCalc?: number
	TeardownTimeUMCalc?: number
	DescriptionCalc?: string
	PlannedDurationCalc?: number
	ActualTimeCalc?: number
	PlannedDate1DateCalc?: Date
	PlannedDate1HourCalc?: Date
	PlannedDate2DateCalc?: Date
	PlannedDate2HourCalc?: Date
	LimitDate1DateCalc?: Date
	LimitDate1TimeCalc?: Date
	LimitDate2DateCalc?: Date
	LimitDate2HourCalc?: Date
	PlannedQuantityUMCalc?: number
	ActualQuantityUMCalc?: number
	FaultyQuantityUMCalc?: number
	ActualDate1Calc?: Date
	ActualTime1Calc?: Date
	ActualDate2Calc?: Date
	ActualTime2Calc?: Date
	TransferTimeCalc?: number
	DifferenceQuantityCalc?: number
	DifferenceAmountCalc?: number
	DifferenceTimeCalc?: number
	DifferenceSetupTimeCalc?: number
	DifferenceOperationTimeCalc?: number
	DifferenceTeardownTimeCalc?: number
	ActualSetupTimeUMCalc?: number
	ActualProductionTimeUMCalc?: number
	ActualTeardownTimeUMCalc?: number
	SpentTimePercentageCalc?: number
	PlannedValueCalc?: number
	ActualValueCalc?: number
	PercentageCompleteCalc?: number
	CoverageDateCalc?: Date
	BatchRatioCalc?: number
	CoverageTimeStampCalc?: Date
	OperationCodeBaseCalc?: string
	OperationCodePartCalc?: string
	BlockingDateCalc?: Date
	MASPlannedTimeCalc?: number
	MASPlannedSetupTimeCalc?: number
	MASPlannedProductionTimeCalc?: number
	MASPlannedTeardownTimeCalc?: number
	MASPlannedQuantityCalc?: number
	ExistsPlannerMessageCalc?: string
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => GoodsName)
	TextId?: GoodsName
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TOperationDM)
	OperationId?: TOperationDM
	@Type(() => TTradingPartnerDM)
	CooperantId?: TTradingPartnerDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitIdCalc?: TMeasureUnitDM
	@Type(() => TArticleDM)
	ProductIdCalc?: TArticleDM
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM
	@Type(() => TOperationDM)
	OperationByIdInputCalc?: TOperationDM
	@Type(() => TOperationDM)
	OperationByNameInputIdCalc?: TOperationDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
}