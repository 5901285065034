import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TIntrastatTariffDutyDM from './TIntrastatTariffDutyDM'
import TTaxTypeDM from './TTaxTypeDM'
import TVATRateDM from './TVATRateDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TLegislationDm from './TLegislationDm'

export default class TLegislationLinkArticleDM extends Data {
	public static className = 'TLegislationLinkArticleDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsValid?: string
	ChangedOn?: Date
	RecordNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionFieldDateCalc?: Date
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId?: TIntrastatTariffDutyDM
	@Type(() => TTaxTypeDM)
	SaleTaxTypeRCSId?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeRCSId?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	SaleTaxTypeId?: TTaxTypeDM
	@Type(() => TTaxTypeDM)
	PurchaseTaxTypeId?: TTaxTypeDM
	@Type(() => TVATRateDM)
	VATRateId?: TVATRateDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
	@Type(() => TVATRateDM)
	VATRateId_ver?: TVATRateDM
	@Type(() => TIntrastatTariffDutyDM)
	CustomsTariffId_ver?: TIntrastatTariffDutyDM
}