import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTradingPartnerDM from './TTradingPartnerDM'
import TEdiMessageTypeDM from './TEdiMessageTypeDM'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEdiQueueDM extends Data {
	public static className = 'TEdiQueueDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInvalidRecord?: string
	DataModuleRecordRID?: number
	DataModuleNumber?: number
	RecipientName?: string
	RecipientGln?: string
	SenderName?: string
	SenderGln?: string
	MessageDate?: Date
	MessageIdentifier?: string
	CompanyId?: number
	TimeStamp?: string
	CreatedOn?: Date
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PreviewMessageDataCalc?: string
	DocumentIdentificationCalc?: string
	ShowOnlyToReviewItemsCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	@Type(() => TTradingPartnerDM)
	RecipientTradingPartnerId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	SenderTradingPartnerId?: TTradingPartnerDM
	@Type(() => TEdiMessageTypeDM)
	MessageTypeId?: TEdiMessageTypeDM
	@Type(() => TTextDM)
	DirectionId?: TTextDM
	@Type(() => TTextDM)
	StatusId?: TTextDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}