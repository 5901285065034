import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import Sensitivity from './Sensitivity'
import PrCalTypHours from './PrCalTypHours'
import TWorkflowPriorityDM from './TWorkflowPriorityDM'
import TExternalDocumentDM from './TExternalDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TContactPersonDM from './TContactPersonDM'
import WkfDiagramChapter from './WkfDiagramChapter'
import WkfDiagramClassification from './WkfDiagramClassification'
import WkfDiagramNorm from './WkfDiagramNorm'
import TTextDM from './TTextDM'
import TWorkflowProcedureHeaderDM from './TWorkflowProcedureHeaderDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TWorkflowProcedureDM extends Data {
	public static className = 'TWorkflowProcedureDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	EditorSettings?: string
	TimeStamp?: string
	MaxTimeStep?: number
	MaxTime?: number
	CalendarId?: number
	ConfirmedOn?: Date
	TestedDate?: Date
	Revision?: number
	Release?: number
	ValidTo?: Date
	ValidFrom?: Date
	ApprovedDate?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	VersionNumber?: number
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	VersionedFieldDateCalc?: Date
	NameCalc?: string
	DeploymentStatusCalc?: number
	StatusImgCalc?: string
	DeployedImgCalc?: string
	ValidDateFromCalc?: Date
	ValidTimeFromCalc?: Date
	ValidDateToCalc?: Date
	ValidTimeToCalc?: Date
	DescriptionMemoCalc?: string
	TargetMemoCalc?: string
	NodeStepNameCalc?: string
	NodeAbbrCalc?: string
	NodeDocumentTextCalc?: string
	NodeStepGroupCalc?: string
	NodeRIDCalc?: number
	NodeLanguageNameCalc?: string
	NodeCalendarIdCalc?: number
	NodeMaxTimeCalc?: number
	ArrowNameCalc?: string
	ArrowSequenceNumberCalc?: number
	ArrowRIDCalc?: number
	ArrowLanguageNameCalc?: string
	DirectiveIdCalc?: number
	UseTaskBitCalc?: number
	DiagramSVGCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => Sensitivity)
	SensitivityId?: Sensitivity
	@Type(() => PrCalTypHours)
	CalendarRecordTypeId?: PrCalTypHours
	@Type(() => TWorkflowPriorityDM)
	PriorityId?: TWorkflowPriorityDM
	@Type(() => TExternalDocumentDM)
	ProcedureDocumentId?: TExternalDocumentDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	TestedById?: TContactPersonDM
	@Type(() => WkfDiagramChapter)
	ChapterId?: WkfDiagramChapter
	@Type(() => WkfDiagramClassification)
	Abbr?: WkfDiagramClassification
	@Type(() => WkfDiagramNorm)
	NormId?: WkfDiagramNorm
	@Type(() => TTextDM)
	StatusId?: TTextDM
	@Type(() => TContactPersonDM)
	ApprovedById?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TWorkflowProcedureHeaderDM)
	HeaderId?: TWorkflowProcedureHeaderDM
	@Type(() => TTextDM)
	NodeMethodSelectingSolverIdCalc?: TTextDM
	@Type(() => TWorkflowProcedureHeaderDM)
	NodeSubordinateProcedureIdCalc?: TWorkflowProcedureHeaderDM
	@Type(() => TWorkflowPriorityDM)
	NodePriorityIdCalc?: TWorkflowPriorityDM
	@Type(() => PrCalTypHours)
	NodeCalendarRecordTypeIdCalc?: PrCalTypHours
	@Type(() => TTextDM)
	ArrowProcessStateIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TLanguageMemoTranslationDM)
	DescriptionChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TargetChild?: TLanguageMemoTranslationDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}