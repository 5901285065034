import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TEshopDM from './TEshopDM'
import TCodeTypeBindPropertyIdLinkTypeDM from './TCodeTypeBindPropertyIdLinkTypeDM'

export default class TLinkDM extends Data {
	public static className = 'TLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	TimeStamp?: string
	Description?: string
	Title?: string
	Link?: string
	LinkTypeId?: number
	TableId?: number
	MasterRID?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	FullPathCalc?: string
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TCodeTypeBindPropertyIdLinkTypeDM)
	CategoryId?: TCodeTypeBindPropertyIdLinkTypeDM
}