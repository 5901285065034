import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import ZoneType from './ZoneType'
import TWarehouseDM from './TWarehouseDM'
import TLocationDM from './TLocationDM'

export default class TWarehouseZoneDM extends Data {
	public static className = 'TWarehouseZoneDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	ChangedOn?: Date
	Description?: string
	Abbr?: string
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => ZoneType)
	ZoneTypeId?: ZoneType
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TLocationDM)
	LocationChild?: TLocationDM[]
}