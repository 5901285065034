import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterGroupDM from './TParameterGroupDM'
import TTextDM from './TTextDM'
import TParameterDM from './TParameterDM'

export default class TBatchTypeItemDM extends Data {
	public static className = 'TBatchTypeItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	GroupSequenceNumber?: number
	SequenceNumber?: number
	BatchTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	DescriptionCalc?: string
	@Type(() => TParameterGroupDM)
	GroupId?: TParameterGroupDM
	@Type(() => TTextDM)
	ValueType?: TTextDM
	@Type(() => TParameterDM)
	ParameterId?: TParameterDM
}