import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TAccountingJournalDM from './TAccountingJournalDM'
import RPCashStatus from './RPCashStatus'
import TPeriodDM from './TPeriodDM'
import TCurrencyDM from './TCurrencyDM'
import TBookDM from './TBookDM'
import TTextDM from './TTextDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TCostCentreDM from './TCostCentreDM'
import TOfficerDM from './TOfficerDM'
import TDeviceDM from './TDeviceDM'
import TContractCodeDM from './TContractCodeDM'
import TArticleCodeDM from './TArticleCodeDM'
import TCode1DM from './TCode1DM'
import TCode2DM from './TCode2DM'
import TCode3DM from './TCode3DM'
import TCode4DM from './TCode4DM'
import TCode5DM from './TCode5DM'
import TCode6DM from './TCode6DM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TFinanceCashItemDM from './TFinanceCashItemDM'
import TAccountingFinanceCashItemDM from './TAccountingFinanceCashItemDM'
import TInternalDocumentDM from './TInternalDocumentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TDocumentAccountingJournalItemDM from './TDocumentAccountingJournalItemDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TCashDocumentDM extends Data {
	public static className = 'TCashDocumentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	CreatedOn?: Date
	Settings?: string
	ExternalNumber?: string
	OnlinePaymentId?: string
	TypeOld?: number
	TimeStamp?: string
	AmountGross?: number
	AmountGrossC?: number
	RemainsToAllocateAmount?: number
	PostedById?: number
	AccountingItemCount?: number
	PostedOn?: Date
	ConfirmedOn?: Date
	ClosingBalance?: number
	ClosingBalanceC?: number
	Amount2?: number
	Amount2C?: number
	Amount1?: number
	Amount1C?: number
	Description?: string
	RightGroupId?: number
	ChangedOn?: Date
	IssueDate?: Date
	Number?: number
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	ClientCurrencyCalc?: string
	AmountNetCCalc?: number
	AmountVATCCalc?: number
	AmountGrossCCalc?: number
	AmountNetCalc?: number
	AmountVATCalc?: number
	VATDocumentImgCalc?: string
	TradingPartnerDateCalc?: Date
	FiscalIdentificationCodeCalc?: string
	TaxpayersSignatureCodeCalc?: string
	AmountGrossCalc?: number
	TaxpayersSecurityCodeCalc?: string
	DisabledEETBitCalc?: number
	EETVATRegNumberCalc?: string
	EETPlaceBussinesIdCalc?: number
	EETCashDeskCalc?: string
	EETSalesModeIdCalc?: number
	IsRecordInEETCalc?: string
	AmountGrossByTypeCCalc?: number
	AmountGrossByTypeCalc?: number
	IssueDateCalc?: Date
	IssueTimeCalc?: Date
	ExchangeRateCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	DocumentAmountCalc?: number
	AccountBalanceCalc?: number
	DocumentAccountCalc?: string
	PostedCalc?: string
	PostedOnCalc?: Date
	AccountingDocDifferenceCalc?: number
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	PostedManuallyCalc?: string
	BarcodeCalc?: string
	PostZeroAmountBitCalc?: number
	IsReversedExchangeRateViewCalc?: string
	AccAssignmentPostingViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TAccountingJournalDM)
	AccountingDocumentRID?: TAccountingJournalDM
	@Type(() => RPCashStatus)
	StatusId?: RPCashStatus
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TTextDM)
	DocumentTypeId?: TTextDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	VATDocumentIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TCostCentreDM)
	CostCentreFromFirstItemIdCalc?: TCostCentreDM
	@Type(() => TOfficerDM)
	OfficerFromFirstItemIdCalc?: TOfficerDM
	@Type(() => TDeviceDM)
	DeviceFromFirstItemRIDCalc?: TDeviceDM
	@Type(() => TContractCodeDM)
	ContractCodeFromFirstItemRIDCalc?: TContractCodeDM
	@Type(() => TArticleCodeDM)
	ArticleCodeFromFirstItemIdCalc?: TArticleCodeDM
	@Type(() => TCode1DM)
	Code1FromFirstItemIdCalc?: TCode1DM
	@Type(() => TCode2DM)
	Code2FromFirstItemIdCalc?: TCode2DM
	@Type(() => TCode3DM)
	Code3FromFirstItemIdCalc?: TCode3DM
	@Type(() => TCode4DM)
	Code4FromFirstItemIdCalc?: TCode4DM
	@Type(() => TCode5DM)
	Code5FromFirstItemIdCalc?: TCode5DM
	@Type(() => TCode6DM)
	Code6FromFirstItemIdCalc?: TCode6DM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TK2UserLookupDM)
	PostedByIdCalc?: TK2UserLookupDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	CorrectPostingBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TFinanceCashItemDM)
	CashItemChild?: TFinanceCashItemDM[]
	@Type(() => TAccountingFinanceCashItemDM)
	AccountingCashItemChild?: TAccountingFinanceCashItemDM[]
	@Type(() => TInternalDocumentDM)
	InternalDocumentChild?: TInternalDocumentDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TDocumentAccountingJournalItemDM)
	DocumentAccJournalItemChild?: TDocumentAccountingJournalItemDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}