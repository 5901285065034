import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'

export default class TMarketingSpecificationDM extends Data {
	public static className = 'TMarketingSpecificationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Description?: string
	Abbr?: string
	ActivityTypeId?: number
	RID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageDescriptionCalc?: string
}