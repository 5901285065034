import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ManipulationUnit from './ManipulationUnit'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TShippingMethodDM from './TShippingMethodDM'
import TCostCentreDocumentDM from './TCostCentreDocumentDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TTextDM from './TTextDM'
import TPaymentMethodDM from './TPaymentMethodDM'
import TSPSTransportMethodDM from './TSPSTransportMethodDM'
import TPartnerDM from './TPartnerDM'
import TCurrencyDM from './TCurrencyDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCTShipmentKindDM from './TCTShipmentKindDM'
import TContactPersonDM from './TContactPersonDM'
import ShipmentStatus from './ShipmentStatus'
import TAddressDM from './TAddressDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TShipmentServiceDM from './TShipmentServiceDM'
import TrackingStatus from './TrackingStatus'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TParcelDM from './TParcelDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TShipmentDM extends Data {
	public static className = 'TShipmentDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	ManipulationUnitCount?: number
	PickUpPoint?: string
	Number?: number
	BranchRID?: number
	RID?: number
	TimeStamp?: string
	RightGroupId?: number
	CODPrice?: number
	EmailTo?: string
	EmailFrom?: string
	PhoneTo?: string
	PhoneFrom?: string
	XMLField?: string
	Description?: string
	ParcelCode4?: string
	ParcelCode3?: string
	ParcelCode2?: string
	ParcelCode?: number
	EURPallet?: number
	Length?: number
	Width?: number
	Height?: number
	Weight?: number
	Price?: number
	AdditionalInsurance?: number
	ServicePrice?: number
	ValueStated?: number
	COD?: number
	ReferenceNumber?: string
	ConfirmedOn2?: Date
	ConfirmedOn1?: Date
	SentDate?: Date
	ChangedOn?: Date
	CreatedOn?: Date
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	Confirmation1ImgCalc?: string
	Confirmation2ImgCalc?: string
	CashOnDeliveryAmountCCalc?: number
	CashOnDeliveryBitCalc?: number
	HasEURPalletBitCalc?: number
	GenerateCodeBitCalc?: number
	CancelBitCalc?: number
	TransportMethodRIDCalc?: number
	TrackingAddressCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AddressFromIdStreetCalc?: string
	AddressFromIdOrientationNumberCalc?: string
	AddressFromIdHouseNumberCalc?: string
	AddressFromIdNameCalc?: string
	AddressFromIdTownCodeCalc?: string
	AddressFromIdCoordinatesCalc?: string
	AddressToIdStreetCalc?: string
	AddressToIdOrientationNumberCalc?: string
	AddressToIdHouseNumberCalc?: string
	AddressToIdNameCalc?: string
	AddressToIdTownCodeCalc?: string
	AddressToIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => ManipulationUnit)
	ManipulationUnitId?: ManipulationUnit
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TShippingMethodDM)
	ShippingMethodId?: TShippingMethodDM
	@Type(() => TCostCentreDocumentDM)
	CostCentreId?: TCostCentreDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TBookDM)
	BankBookId?: TBookDM
	@Type(() => TTextDM)
	WeightUnit?: TTextDM
	@Type(() => TTextDM)
	DimensionUnit?: TTextDM
	@Type(() => TPaymentMethodDM)
	PaymentMethodId?: TPaymentMethodDM
	@Type(() => TSPSTransportMethodDM)
	TransportMethodRID?: TSPSTransportMethodDM
	@Type(() => TPartnerDM)
	TransportCompanyId?: TPartnerDM
	@Type(() => TCurrencyDM)
	Currency?: TCurrencyDM
	@Type(() => TInvoiceOutDM)
	InvoiceOutRID?: TInvoiceOutDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCTShipmentKindDM)
	KindId?: TCTShipmentKindDM
	@Type(() => TContactPersonDM)
	SentById?: TContactPersonDM
	@Type(() => ShipmentStatus)
	StatusId?: ShipmentStatus
	@Type(() => TAddressDM)
	AddressToId?: TAddressDM
	@Type(() => TAddressDM)
	AddressFromId?: TAddressDM
	@Type(() => TContactPersonDM)
	ContactPersonToId?: TContactPersonDM
	@Type(() => TContactPersonDM)
	ContactPersonFromId?: TContactPersonDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerToId?: TTradingPartnerDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerFromId?: TTradingPartnerDM
	@Type(() => TPartnerDM)
	PartnerToId?: TPartnerDM
	@Type(() => TPartnerDM)
	PartnerFromId?: TPartnerDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TShipmentServiceDM)
	ShipmentServiceId?: TShipmentServiceDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceled2IdCalc?: TTextDM
	@Type(() => TrackingStatus)
	TrackingStatusIdCalc?: TrackingStatus
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCountryDM)
	AddressFromIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressFromIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressFromIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	AddressToIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressToIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressToIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TParcelDM)
	ParcelChild?: TParcelDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}