import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TBookDM from './TBookDM'
import TWarehouseDM from './TWarehouseDM'
import RegistrationStatus from './RegistrationStatus'
import TK2UserLookupDM from './TK2UserLookupDM'
import TitleName from './TitleName'
import TEshopDM from './TEshopDM'
import TContactPersonDM from './TContactPersonDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TMarketingAddressAsItemDM from './TMarketingAddressAsItemDM'
import TEshopRegistrationAddressDM from './TEshopRegistrationAddressDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TEshopRegistrationDM extends Data {
	public static className = 'TEshopRegistrationDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	WebData?: string
	IsQuotationsEnabled?: string
	Phone?: string
	Email?: string
	Password?: string
	Login?: string
	Surname?: string
	FirstName?: string
	ChangedOn?: Date
	CreatedOn?: Date
	TimeStamp?: string
	RID?: number
	Ex_PraSk?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PasswordCalc?: string
	PasswordConfirmationCalc?: string
	SendInformationBitCalc?: number
	IsActiveCalc?: string
	FullNameCalc?: string
	ActivationURLCalc?: string
	PasswordURLCalc?: string
	HelpURLCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TTextDM)
	LanguageId?: TTextDM
	@Type(() => TBookDM)
	ImmediatePaymentBook?: TBookDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => RegistrationStatus)
	StatusId?: RegistrationStatus
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TBookDM)
	SalesBookId?: TBookDM
	@Type(() => TitleName)
	Title?: TitleName
	@Type(() => TEshopDM)
	ShopRID?: TEshopDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TMarketingAddressAsItemDM)
	AddressChild?: TMarketingAddressAsItemDM[]
	@Type(() => TEshopRegistrationAddressDM)
	AddressForEshopChild?: TEshopRegistrationAddressDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}