import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import TPeriodDM from './TPeriodDM'
import AlternativeUnit from './AlternativeUnit'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TServiceContractDM from './TServiceContractDM'
import TWarehouseDM from './TWarehouseDM'
import TParameterSetDM from './TParameterSetDM'
import TArticleDM from './TArticleDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import ServiceNoteStatus from './ServiceNoteStatus'
import ServiceType from './ServiceType'
import TTextDM from './TTextDM'
import TBatchDM from './TBatchDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TBookDM from './TBookDM'
import TInvoiceOutDM from './TInvoiceOutDM'
import ServiceWorkflow from './ServiceWorkflow'
import TLocationArticleDM from './TLocationArticleDM'
import ServiceSolutionType from './ServiceSolutionType'
import TSalesOrderDM from './TSalesOrderDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TServiceNoteParameterDM from './TServiceNoteParameterDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TServiceNoteDM extends Data {
	public static className = 'TServiceNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TimeStamp?: string
	SuppplierId?: number
	ServiceWorkflowRID?: number
	RID?: number
	RMA?: string
	DefectCauseId?: number
	DefectCodeId?: number
	ReceiptDate2?: Date
	RightGroupId?: number
	ServiceRecommendation?: string
	ServiceOpinion?: string
	DefectDescription?: string
	CustomerOpinion?: string
	ExecutionDate?: Date
	ExpectedExecutionDate?: Date
	RequiredStartDate?: Date
	ReceiptDate?: Date
	LaunchDate?: Date
	SalesDate?: Date
	ConfirmedOn?: Date
	ChangedOn?: Date
	IssueDate?: Date
	EstimatedPrice?: number
	SolutionMethodId?: number
	Description?: string
	SaleDocument?: string
	Quantity?: number
	ArticleName?: string
	Number?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	ArticleNameCalc?: string
	XmlReportCalc?: string
	ReceivedOnStockCalc?: number
	ReceivedOnStockImgCalc?: string
	StatusIdCalc?: number
	StatusImgCalc?: string
	StatusDateCalc?: number
	DateStatusImgCalc?: string
	HtmlCodeReportCalc?: string
	RelationTypeCalc?: number
	RelationTypeImgCalc?: string
	OwnArticleIdCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContactPersonDM)
	ServiceWorkerId?: TContactPersonDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => AlternativeUnit)
	MeasureUnitId?: AlternativeUnit
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TServiceContractDM)
	ServiceContractRID?: TServiceContractDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TParameterSetDM)
	ServiceParameterSetId?: TParameterSetDM
	@Type(() => TArticleDM)
	ServiceCardId?: TArticleDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => ServiceNoteStatus)
	StatusId?: ServiceNoteStatus
	@Type(() => ServiceType)
	ServiceTypeId?: ServiceType
	@Type(() => TTextDM)
	SaleDocumentTypeId?: TTextDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TInvoiceOutDM)
	SalesDocumentRIDCalc?: TInvoiceOutDM
	@Type(() => ServiceWorkflow)
	ServiceWorkflowIdCalc?: ServiceWorkflow
	@Type(() => TLocationArticleDM)
	LocationIdCalc?: TLocationArticleDM
	@Type(() => ServiceSolutionType)
	SupplierSolutionTypeIdCalc?: ServiceSolutionType
	@Type(() => TSalesOrderDM)
	MainContractRIDCalc?: TSalesOrderDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRIDCalc?: THandlingUnitArticleDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	BaseConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TServiceNoteParameterDM)
	ParameterValueChild?: TServiceNoteParameterDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}