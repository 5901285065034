import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import ServiceOperationType from './ServiceOperationType'
import TTextDM from './TTextDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TBookDM from './TBookDM'

export default class TMaintenanceFrequencyDefinitionTemplateDM extends Data {
	public static className = 'TMaintenanceFrequencyDefinitionTemplateDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	RepetitionCount?: number
	ServiceParameterGroupId?: number
	GenerationHorizon?: number
	GenerationHorizonUnit?: number
	OperationInstructions?: string
	PlannedOperationDuration?: number
	Frequency?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => ServiceOperationType)
	ServiceTypeId?: ServiceOperationType
	@Type(() => TTextDM)
	TimeUnitId?: TTextDM
	@Type(() => TTextDM)
	FrequencyUnitId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TBookDM)
	BookId?: TBookDM
}