import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TJobCardConfigDM from './TJobCardConfigDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TCompanyDM from './TCompanyDM'

export default class TCompanyLinkArticleCategoryDM extends Data {
	public static className = 'TCompanyLinkArticleCategoryDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsValid?: string
	ChangedOn?: Date
	RecordNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	IconCalc?: number
	@Type(() => TJobCardConfigDM)
	JobCardConfigId?: TJobCardConfigDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TCompanyDM)
	CompanyId?: TCompanyDM
}