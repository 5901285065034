import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TCommentDM from './TCommentDM'
import TUserRoleEmbeddedDM from './TUserRoleEmbeddedDM'
import TUserRoleRightAsItemDM from './TUserRoleRightAsItemDM'
import TUserRoleGroupDM from './TUserRoleGroupDM'
import TUserRoleAssignDM from './TUserRoleAssignDM'
import TUserRoleParentRoleDM from './TUserRoleParentRoleDM'
import TUserRoleUserDM from './TUserRoleUserDM'
import TUserRoleFragmentDM from './TUserRoleFragmentDM'
import TUserRoleObjectRowDM from './TUserRoleObjectRowDM'
import TUserRoleRightExtensionDM from './TUserRoleRightExtensionDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TUserRoleDM extends Data {
	public static className = 'TUserRoleDM'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	OriginType?: number
	IsInvalid?: string
	Name?: string
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	Id?: number
	ActualRecordNumberCalc?: string
	MinRecordNumberCalc?: string
	MaxRecordNumberCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ReferencesCalc?: number
	UsersCountCalc?: number
	RoleCalc?: number
	FragmentCalc?: number
	OriginTypeCalc?: number
	ObjectRowCalc?: number
	RightsExtensionCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTextDM)
	InvalidRecordImgCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TUserRoleEmbeddedDM)
	EmbeddedChild?: TUserRoleEmbeddedDM[]
	@Type(() => TUserRoleRightAsItemDM)
	RightChild?: TUserRoleRightAsItemDM[]
	@Type(() => TUserRoleGroupDM)
	BrowseGroupChild?: TUserRoleGroupDM[]
	@Type(() => TUserRoleGroupDM)
	ChangeGroupChild?: TUserRoleGroupDM[]
	@Type(() => TUserRoleAssignDM)
	AssignChild?: TUserRoleAssignDM[]
	@Type(() => TUserRoleParentRoleDM)
	ParentRoleChild?: TUserRoleParentRoleDM[]
	@Type(() => TUserRoleUserDM)
	UserChild?: TUserRoleUserDM[]
	@Type(() => TUserRoleFragmentDM)
	FragmentChild?: TUserRoleFragmentDM[]
	@Type(() => TUserRoleObjectRowDM)
	ObjectRowChild?: TUserRoleObjectRowDM[]
	@Type(() => TUserRoleRightExtensionDM)
	RightExtensionChild?: TUserRoleRightExtensionDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}