import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TWebModuleLinkSuperiorDM extends Data {
	public static className = 'TWebModuleLinkSuperiorDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceNumber?: number
	SlaveRID?: number
	SlaveDataModuleNumber?: number
	MasterRID?: number
	MasterDataModuleNumber?: number
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	NameCalc?: string
	@Type(() => TTextDM)
	SlaveModuleId?: TTextDM
	@Type(() => TTextDM)
	MasterModuleId?: TTextDM
	@Type(() => TTextDM)
	MasterLinkIdCalc?: TTextDM
}