import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TWorkflowProcessVariableDM extends Data {
	public static className = 'TWorkflowProcessVariableDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	BindParameter4?: number
	BindParameter3?: number
	BindParameter2?: number
	BindParameter1?: number
	BindTypeId?: number
	ProcessId?: number
	Label?: string
	Picture?: string
	Decimal?: number
	Size?: number
	DataTypeId?: number
	Name?: string
	ProgrammerName?: string
	Value?: string
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ValueNumberCalc?: number
	ValueCurrencyCalc?: number
	ValueDateCalc?: Date
	ValueStringCalc?: string
	ValueLongCalc?: number
	ValueTimeCalc?: Date
	ValueCalc?: string
	@Type(() => TTextDM)
	ValueBoolCalc?: TTextDM
}