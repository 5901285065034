import Data from '../Api/Data'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'

export default class TFragmentItemDM extends Data {
	public static className = 'TFragmentItemDM'
	public static systemFields = [
		'ItemRIDCalc',
	]

	OrderX?: number
	FragmentContent?: string
	FragmentIdentifier?: string
	SequenceNumber?: number
	MasterFragmentId?: number
	RecordStateImgCalc?: string
	FieldNameCalc?: string
	ItemRIDCalc?: number
	RecordModeCalc?: number
	@Type(() => TTextDM)
	FragmentCompType?: TTextDM
}