import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import TPrCalendarDM from './TPrCalendarDM'
import AttendanceWorkTimeReportStatus from './AttendanceWorkTimeReportStatus'
import TAttendanceWorkAccountDM from './TAttendanceWorkAccountDM'
import TTextDM from './TTextDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TPrPeriodDM from './TPrPeriodDM'
import TContactPersonDM from './TContactPersonDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TPrEmployeeDocumentDM from './TPrEmployeeDocumentDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TAttendanceWorkTimeReportItemDM from './TAttendanceWorkTimeReportItemDM'
import TAttendanceWorkTimeReportAccountsSummaryDM from './TAttendanceWorkTimeReportAccountsSummaryDM'
import TAttendanceWorkTimeReportPayrollComponentDM from './TAttendanceWorkTimeReportPayrollComponentDM'
import TAttendanceWTRUnliqOvertimesDM from './TAttendanceWTRUnliqOvertimesDM'
import TAttendanceWTRUnliqHolidayWorkDM from './TAttendanceWTRUnliqHolidayWorkDM'
import TAttendanceProcessedIntervalDM from './TAttendanceProcessedIntervalDM'
import TAttendanceProcessedMovementDM from './TAttendanceProcessedMovementDM'
import TAttendanceTaskWorkDM from './TAttendanceTaskWorkDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TAttendanceWorkTimeReportDM extends Data {
	public static className = 'TAttendanceWorkTimeReportDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	WasChangedAfterExport?: string
	IsExported?: string
	ExportedOn?: Date
	IsProcessed?: string
	ProcessedOn?: Date
	PartialConfirmedOn?: Date
	DateTo?: Date
	DateFrom?: Date
	PartOfBalance?: number
	BalanceNext?: number
	BalanceActual?: number
	BalancePrevious?: number
	WorkFundDays?: number
	Absence?: number
	BreakTime?: number
	WorkedOut?: number
	WorkFund?: number
	ConfirmedOn?: Date
	CostCentreId?: number
	Number?: number
	IsCanceledRecord?: string
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	DateCalc?: Date
	MonthShiftTimeCountCalc?: number
	PublicHlMonthShiftHoursCalc?: number
	MonthShiftDayCountCalc?: number
	PublicHlMonthShiftDayCountCalc?: number
	BalanceSumCalc?: number
	GenerateOverTimeCompensCalc?: string
	GenerateHolidayWorkCompensCalc?: string
	OverTimeCompensMonthsCalc?: number
	HolidayWorkCompensMonthsCalc?: number
	RemNormHlActYearCompAmountCalc?: number
	RemExtHlActYearCompAmountCalc?: number
	RemAddHlActYearCompAmountCalc?: number
	RemNormHlPrevYearCompAmountCalc?: number
	RemExtHlPrevYearCompAmountCalc?: number
	RemTotalHlRemCompAmountCalc?: number
	RemNormHlActYearCompDaysAmountCalc?: number
	RemExtHlActYearCompDaysAmountCalc?: number
	RemAddHlActYearCompDaysAmountCalc?: number
	RemNormHlPrevYearCompDaysAmountCalc?: number
	RemExtHlPrevYearCompDaysAmountCalc?: number
	RemTotalHlRemCompDaysAmountCalc?: number
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	WorkFundCalc?: number
	WorkedOutCalc?: number
	AbsenceCalc?: number
	BreakTimeCalc?: number
	WorkFundDaysCalc?: number
	BalanceCalc?: number
	BonusAmountCalc?: number
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ExportedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ProcessedById?: TK2UserLookupDM
	@Type(() => TPrCalendarDM)
	AdditionalCalendarId?: TPrCalendarDM
	@Type(() => TK2UserLookupDM)
	PartialConfirmedById?: TK2UserLookupDM
	@Type(() => AttendanceWorkTimeReportStatus)
	StatusId?: AttendanceWorkTimeReportStatus
	@Type(() => TAttendanceWorkAccountDM)
	NegativeBalanceWorkAccountRID?: TAttendanceWorkAccountDM
	@Type(() => TTextDM)
	NegativeBalanceUseTypeId?: TTextDM
	@Type(() => TAttendanceWorkAccountDM)
	PositiveBalanceWorkAccountRID?: TAttendanceWorkAccountDM
	@Type(() => TTextDM)
	PositiveBalanceUseTypeId?: TTextDM
	@Type(() => TPrCalendarDM)
	WHModelId?: TPrCalendarDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TPrPeriodDM)
	PayrollPeriodId?: TPrPeriodDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TPrEmployeeDocumentDM)
	PreviousPayrollDocumentRIDCalc?: TPrEmployeeDocumentDM
	@Type(() => TPrEmployeeDocumentDM)
	ActualPayrollDocumentRIDCalc?: TPrEmployeeDocumentDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TAttendanceWorkTimeReportItemDM)
	AttendanceWorkTimeReportItemChild?: TAttendanceWorkTimeReportItemDM[]
	@Type(() => TAttendanceWorkTimeReportAccountsSummaryDM)
	AttendanceWorkTimeReportAccountsSummaryChild?: TAttendanceWorkTimeReportAccountsSummaryDM[]
	@Type(() => TAttendanceWorkTimeReportPayrollComponentDM)
	AttendanceWorkTimeReportPayrollComponentChild?: TAttendanceWorkTimeReportPayrollComponentDM[]
	@Type(() => TAttendanceWTRUnliqOvertimesDM)
	AttendanceWTRUnliqOvertimesChild?: TAttendanceWTRUnliqOvertimesDM[]
	@Type(() => TAttendanceWTRUnliqHolidayWorkDM)
	AttendanceWTRUnliqHolidayWorkChild?: TAttendanceWTRUnliqHolidayWorkDM[]
	@Type(() => TAttendanceProcessedIntervalDM)
	AttendanceProcessedIntervalChild?: TAttendanceProcessedIntervalDM[]
	@Type(() => TAttendanceProcessedMovementDM)
	AttendanceProcessedMovementChild?: TAttendanceProcessedMovementDM[]
	@Type(() => TAttendanceTaskWorkDM)
	AttendanceTaskWorkChild?: TAttendanceTaskWorkDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}