import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TScheduledServiceOperationDM from './TScheduledServiceOperationDM'
import TServicedDeviceDM from './TServicedDeviceDM'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import TContactPersonDM from './TContactPersonDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TPeriodDM from './TPeriodDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import ServiceContractStatus from './ServiceContractStatus'
import ServiceType from './ServiceType'
import TTradingPartnerDM from './TTradingPartnerDM'
import TBookDM from './TBookDM'
import TAddressDM from './TAddressDM'
import TSalesOrderDM from './TSalesOrderDM'
import TMarketingAddressDM from './TMarketingAddressDM'
import TTextDM from './TTextDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TWorkflowProcessDM from './TWorkflowProcessDM'
import TActivityDM from './TActivityDM'
import TWarehouseDM from './TWarehouseDM'
import TCommentDM from './TCommentDM'
import TJobDM from './TJobDM'
import TServiceNoteDM from './TServiceNoteDM'
import TSalesItemBookDM from './TSalesItemBookDM'
import TRelatedAddressDocumentDM from './TRelatedAddressDocumentDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TWorkflowProcessAsItemDM from './TWorkflowProcessAsItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TServiceContractDM extends Data {
	public static className = 'TServiceContractDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	TaxNumber?: string
	VATRegNumber?: string
	CompanyRegNumber?: string
	TimeStamp?: string
	EstimatedPrice?: number
	CustomerOpinion?: string
	RID?: number
	RightGroupId?: number
	RMA?: string
	ExpectedExecutionDate?: Date
	RequiredStartDate?: Date
	ConfirmedOn?: Date
	ChangedOn?: Date
	IssueDate?: Date
	SolutionMethodId?: number
	Description?: string
	Number?: number
	RecordID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	SQLSequenceOrderCalc?: number
	VersionedFieldDateCalc?: Date
	InvoiceAddressExistsImgCalc?: string
	DeliveryAddressExistsImgCalc?: string
	PostalAddressExistsImgCalc?: string
	FullInvoiceAddressIdCalc?: number
	FullDeliveryAddressIdCalc?: number
	FullPostalAddressIdCalc?: number
	FullPostalInvoiceAddressIdCalc?: number
	InvoiceAddressFullNameCalc?: string
	DeliveryAddressFullNameCalc?: string
	PostalAddressFullNameCalc?: string
	PostalInvoiceAddressFullNameCalc?: string
	TotalAdvanceCalc?: number
	AdvanceCurrencyCalc?: string
	StatusIdCalc?: number
	StatusImgCalc?: string
	InvoiceAddressPlainCalc?: string
	DeliveryAddressPlainCalc?: string
	PostalAddressPlainCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TScheduledServiceOperationDM)
	ServiceOperationRID?: TScheduledServiceOperationDM
	@Type(() => TServicedDeviceDM)
	ServicedDeviceRID?: TServicedDeviceDM
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => TContactPersonDM)
	ServiceWorkerId?: TContactPersonDM
	@Type(() => TOfficerDocumentDM)
	OfficerId?: TOfficerDocumentDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	IssuedById?: TK2UserLookupDM
	@Type(() => TContactPersonDM)
	ContactPersonId?: TContactPersonDM
	@Type(() => ServiceContractStatus)
	StatusId?: ServiceContractStatus
	@Type(() => ServiceType)
	ServiceTypeId?: ServiceType
	@Type(() => TTradingPartnerDM)
	TradingPartnerId?: TTradingPartnerDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TAddressDM)
	InvoiceAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	DeliveryAddressIdCalc?: TAddressDM
	@Type(() => TAddressDM)
	PostalAddressIdCalc?: TAddressDM
	@Type(() => TSalesOrderDM)
	MainContractRIDCalc?: TSalesOrderDM
	@Type(() => TMarketingAddressDM)
	SimpleInvoiceAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimpleDeliveryAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TMarketingAddressDM)
	SimplePostalAddressRIDCalc?: TMarketingAddressDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTextDM)
	ProcessStateIdCalc?: TTextDM
	@Type(() => TWorkflowProcessDM)
	CurrentProcessIdCalc?: TWorkflowProcessDM
	@Type(() => TActivityDM)
	ActivityIdCalc?: TActivityDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TTextDM)
	CanceledRecordBitCalc?: TTextDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TTextDM)
	BaseConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TJobDM)
	JobChild?: TJobDM[]
	@Type(() => TServiceNoteDM)
	ServiceNoteChild?: TServiceNoteDM[]
	@Type(() => TSalesItemBookDM)
	ContractItemChild?: TSalesItemBookDM[]
	@Type(() => TRelatedAddressDocumentDM)
	DocumentAddressChild?: TRelatedAddressDocumentDM[]
	@Type(() => TActivityDM)
	ActivityChild?: TActivityDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TWorkflowProcessAsItemDM)
	WorkflowProcessChild?: TWorkflowProcessAsItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}