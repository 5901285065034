import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TParameterGroupDM from './TParameterGroupDM'

export default class TParameterGroupAsItemDM extends Data {
	public static className = 'TParameterGroupAsItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SequenceNumber?: number
	ParameterId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TParameterGroupDM)
	ParameterGroupId?: TParameterGroupDM
}