import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAttendanceWorkAccountDM from './TAttendanceWorkAccountDM'
import TK2UserLookupDM from './TK2UserLookupDM'

export default class TAttendanceWorkTimeReportItemAccountDM extends Data {
	public static className = 'TAttendanceWorkTimeReportItemAccountDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	UnroundedHoursCount?: number
	OriginTypeId?: number
	Amount?: number
	Tariff?: number
	DaysCount?: number
	HoursCount?: number
	BookId?: number
	SequenceNumber?: number
	WorkTimeReportItemRID?: number
	WorkTimeReportRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TAttendanceWorkAccountDM)
	WorkAccountRID?: TAttendanceWorkAccountDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
}