import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLegislationDm from './TLegislationDm'
import TTextDM from './TTextDM'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class TLegislationTaxTypeDm extends Data {
	public static className = 'TLegislationTaxTypeDm'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	CanBeUsedOnAdvance?: string
	TimeStamp?: string
	RightGroupId?: number
	InvoiceText?: string
	Application?: string
	Description?: string
	Abbr?: string
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageDescriptionCalc?: string
	LanguageInvoiceTextCalc?: string
	EETNotSubjectVATCalc?: number
	VATRow1Calc?: number
	VATRow2Calc?: number
	VATRow3Calc?: number
	VATRow4Calc?: number
	VATRow5Calc?: number
	VATRow6Calc?: number
	RightGroupIdCalc?: number
	@Type(() => TLegislationDm)
	LegislationId?: TLegislationDm
	@Type(() => TTextDM)
	BlockedRecordBitCalc?: TTextDM
	@Type(() => TTextDM)
	VATIdCalc?: TTextDM
	@Type(() => TTextDM)
	VATAdvancesSelfAssessmentIdCalc?: TTextDM
	@Type(() => TTextDM)
	RCSSystemIdCalc?: TTextDM
	@Type(() => TTextDM)
	OutputOnlyBitCalc?: TTextDM
	@Type(() => TLegislationDm)
	LegislationIdCalc?: TLegislationDm
	@Type(() => TLanguageTextTranslationDM)
	LanguageDescriptionChild?: TLanguageTextTranslationDM[]
	@Type(() => TLanguageTextTranslationDM)
	LanguageInvoiceTextChild?: TLanguageTextTranslationDM[]
}