import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TPartnerDM from './TPartnerDM'
import TAdressKeyDM from './TAdressKeyDM'

export default class TAdressKeyItemDM extends Data {
	public static className = 'TAdressKeyItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SequenceOrder?: number
	MainDataModuleNumber?: number
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TPartnerDM)
	MainValue?: TPartnerDM
	@Type(() => TAdressKeyDM)
	SortingKeyRID?: TAdressKeyDM
}