import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TD_Zar from './TD_Zar'

export default class TAssetLinkDM extends Data {
	public static className = 'TAssetLinkDM'
	public static primaryKey = 'RID'
	public static systemFields = [
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	Description?: string
	Amount?: number
	DocumentDate?: Date
	DocumentNumberItemRID?: number
	DocumentTypeId?: number
	RID?: number
	RecordID?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TTextDM)
	ItemTypeId?: TTextDM
	@Type(() => TD_Zar)
	HeaderRID?: TD_Zar
}