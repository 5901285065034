import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TTextDM from './TTextDM'
import TCapacityPlanWorkshiftDM from './TCapacityPlanWorkshiftDM'

export default class TCapacityPlanDayDM extends Data {
	public static className = 'TCapacityPlanDayDM'
	public static primaryKey = 'Date'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Date!
	}

	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	PlannedDateTimeTo?: Date
	PlannedDateTimeFrom?: Date
	Utilization?: number
	RequestedQuantity?: number
	Capacity?: number
	Date?: Date
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	ShowInHoursCalc?: string
	CapacityUMCalc?: number
	RequestedQuantityUMCalc?: number
	@Type(() => TTextDM)
	WeekDayId?: TTextDM
	@Type(() => TCapacityPlanWorkshiftDM)
	CapacityPlanWorkshiftChild?: TCapacityPlanWorkshiftDM[]
}