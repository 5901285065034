import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TCode6DocumentDM from './TCode6DocumentDM'
import TCode5DocumentDM from './TCode5DocumentDM'
import TCode4DocumentDM from './TCode4DocumentDM'
import TCode3DocumentDM from './TCode3DocumentDM'
import TBookDM from './TBookDM'
import TJobCardResourceDM from './TJobCardResourceDM'
import TArticleCodeDocumentDM from './TArticleCodeDocumentDM'
import TCostCentreCollectionDocumentDM from './TCostCentreCollectionDocumentDM'
import TJobCardOperationDM from './TJobCardOperationDM'
import TJobCardDM from './TJobCardDM'
import TDeviceDocumentDM from './TDeviceDocumentDM'
import THandlingUnitArticleDM from './THandlingUnitArticleDM'
import TContractCodeDocumentDM from './TContractCodeDocumentDM'
import TTextDM from './TTextDM'
import PlanType from './PlanType'
import TRequestedBatchParameterHeaderDM from './TRequestedBatchParameterHeaderDM'
import TCode2DocumentDM from './TCode2DocumentDM'
import TCode1DocumentDM from './TCode1DocumentDM'
import TRoutingVariantDM from './TRoutingVariantDM'
import TLocationArticleDM from './TLocationArticleDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'
import TMeasureUnitDM from './TMeasureUnitDM'
import TWarehouseDM from './TWarehouseDM'
import TBatchDM from './TBatchDM'
import GoodsName from './GoodsName'
import TArticleDM from './TArticleDM'
import TParameterSetDM from './TParameterSetDM'
import KindAccounting from './KindAccounting'
import TCommentDM from './TCommentDM'
import TItemNoteDM from './TItemNoteDM'
import TSerialNumberItemDM from './TSerialNumberItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedBatchParameterValueDM from './TAttachedBatchParameterValueDM'
import TAttachedRequestedBatchParameterDM from './TAttachedRequestedBatchParameterDM'
import TJobCardSubordinateDocumentDM from './TJobCardSubordinateDocumentDM'

export default class TAccountingFinanceJobCardItemDM extends Data {
	public static className = 'TAccountingFinanceJobCardItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	SuperiorItemRID?: number
	IsInversedTransactionFormula?: string
	IsRedefinedPrice?: string
	IsInsertedLater?: string
	IsSubstitution?: string
	IsSupplement?: string
	MultiplyQuantity?: string
	IsPlanningJobCard?: string
	IgnoreReceiptPrices?: string
	IsCompletionItem?: string
	NotCreateSubJobCards?: string
	IsPromissedAvailability?: string
	NotRecordActualQuantity?: string
	IsReserved?: string
	RoutingVariantItemRID?: number
	RID?: number
	ReservationDate?: Date
	ProductItem?: number
	RoutingQuantity?: number
	JobCardBindId?: number
	UnitCostPrice?: number
	Drawing?: string
	DrawingPosition?: string
	ConfirmedById?: number
	ItemConfirmedOn?: Date
	PlannedUnitPrice?: number
	ReleaseDate?: Date
	PostingKeyId?: number
	UnitPrice?: number
	Quantity?: number
	PlannedQuantity?: number
	ConfirmedOn?: Date
	ItemId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	CorrectionJobCardOperationRID?: number
	CorrectionHeaderRID?: number
	OriginalRID?: number
	OperationCode?: string
	OperationSequenceNumber?: number
	ArtOverheads?: number
	ArtIsOverheadsInPercentage?: string
	ArtArticleTypeId?: number
	ArtArticleCategoryId?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	PlannedQuantityCalc?: number
	ActualQuantityCalc?: number
	PlannedUnitPriceCalc?: number
	PlannedTotalPriceCalc?: number
	ActualUnitPriceCalc?: number
	ActualTotalPriceCalc?: number
	ReservedBitCalc?: string
	IsReleasedCalc?: string
	JobCardExistsCalc?: string
	NameCalc?: string
	AvailableCalc?: number
	SubstitutionOrSupplementCalc?: string
	PlannedTimeCalc?: number
	ActualTimeCalc?: number
	PlannedTimeQuantityCalc?: number
	ActualTimeQuantityCalc?: number
	IsConfirmedCalc?: string
	ActualQuantityRNCalc?: number
	IsReservedCalc?: string
	PlannedPositiveQuantityCalc?: number
	PlannedQuantitySignCalc?: number
	ActualPositiveQuantityCalc?: number
	ActualQuantitySignCalc?: number
	PlannedQuantitySignImgCalc?: string
	ActualQuantitySignImgCalc?: string
	FullNameCalc?: string
	RemainingTimeCalc?: number
	IndependentOperationBitCalc?: number
	BarcodeCalc?: string
	ReleaseDateCalc?: Date
	ReleaseTimeCalc?: Date
	ReservationDateCalc?: Date
	ReservationTimeCalc?: Date
	ArticleCategoryCalc?: number
	ArticleTypeCalc?: number
	IsArticlePercentForOverheadsCalc?: string
	ArticleOverheadsCalc?: number
	SNIncorrectCountImgCalc?: string
	BatchTypeIdCalc?: number
	BOMTypeCalc?: number
	BatchRatioCalc?: number
	TotalCostPriceCalc?: number
	CoverageDateCalc?: Date
	OperationDescriptionCalc?: string
	CoverageTimeStampCalc?: Date
	OperationPlannedQuantityCalc?: number
	IgnoreReceiptPriceCalc?: string
	SerialNumberInputCalc?: string
	FilterCategoriesCalc?: string
	CategoryListCalc?: string
	IsIgnoredActualValuesCalc?: string
	ItemTextCalc?: string
	SerialNumberCountAsStringCalc?: string
	@Type(() => TCode6DocumentDM)
	Code6Id?: TCode6DocumentDM
	@Type(() => TCode5DocumentDM)
	Code5Id?: TCode5DocumentDM
	@Type(() => TCode4DocumentDM)
	Code4Id?: TCode4DocumentDM
	@Type(() => TCode3DocumentDM)
	Code3Id?: TCode3DocumentDM
	@Type(() => TBookDM)
	MainDocumentBookId?: TBookDM
	@Type(() => TJobCardResourceDM)
	JobCardResourceRID?: TJobCardResourceDM
	@Type(() => TArticleCodeDocumentDM)
	ArticleCodeId?: TArticleCodeDocumentDM
	@Type(() => TCostCentreCollectionDocumentDM)
	CostCentreId?: TCostCentreCollectionDocumentDM
	@Type(() => TJobCardOperationDM)
	JobCardOperationRID?: TJobCardOperationDM
	@Type(() => TJobCardDM)
	HeaderRID?: TJobCardDM
	@Type(() => TDeviceDocumentDM)
	DeviceRID?: TDeviceDocumentDM
	@Type(() => THandlingUnitArticleDM)
	HandlingUnitRID?: THandlingUnitArticleDM
	@Type(() => TContractCodeDocumentDM)
	ContractCodeRID?: TContractCodeDocumentDM
	@Type(() => TTextDM)
	OperationTime?: TTextDM
	@Type(() => PlanType)
	PlanTypeId?: PlanType
	@Type(() => TRequestedBatchParameterHeaderDM)
	RequestedBatchParametersId?: TRequestedBatchParameterHeaderDM
	@Type(() => TCode2DocumentDM)
	Code2Id?: TCode2DocumentDM
	@Type(() => TCode1DocumentDM)
	Code1Id?: TCode1DocumentDM
	@Type(() => TRoutingVariantDM)
	RoutingVariantId?: TRoutingVariantDM
	@Type(() => TLocationArticleDM)
	LocationId?: TLocationArticleDM
	@Type(() => TOfficerDocumentDM)
	WorkerId?: TOfficerDocumentDM
	@Type(() => TMeasureUnitDM)
	MeasureUnitId?: TMeasureUnitDM
	@Type(() => TWarehouseDM)
	WarehouseId?: TWarehouseDM
	@Type(() => TBatchDM)
	BatchId?: TBatchDM
	@Type(() => GoodsName)
	ArticleNameId?: GoodsName
	@Type(() => TArticleDM)
	ArticleId?: TArticleDM
	@Type(() => TArticleDM)
	ProductIdCalc?: TArticleDM
	@Type(() => TTextDM)
	SuppressCreateJobCardBitCalc?: TTextDM
	@Type(() => TTextDM)
	SNIncorrectCountBitCalc?: TTextDM
	@Type(() => TParameterSetDM)
	ParameterSetIdCalc?: TParameterSetDM
	@Type(() => TTextDM)
	CoveredBitCalc?: TTextDM
	@Type(() => TTextDM)
	CoveredByStockBitCalc?: TTextDM
	@Type(() => KindAccounting)
	PostingKindIdCalc?: KindAccounting
	@Type(() => TArticleDM)
	ArticleByIdInputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByAbbr2InputIdCalc?: TArticleDM
	@Type(() => TArticleDM)
	ArticleByNameInputIdCalc?: TArticleDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TItemNoteDM)
	ItemNotesChild?: TItemNoteDM[]
	@Type(() => TSerialNumberItemDM)
	SerialNumberItemChild?: TSerialNumberItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	ItemTextChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedBatchParameterValueDM)
	BatchParameterChild?: TAttachedBatchParameterValueDM[]
	@Type(() => TAttachedRequestedBatchParameterDM)
	RequestedBatchParameterChild?: TAttachedRequestedBatchParameterDM[]
	@Type(() => TJobCardSubordinateDocumentDM)
	JobCardSubordinateDocChild?: TJobCardSubordinateDocumentDM[]
}