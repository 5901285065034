import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLanguageTextTranslationDM from './TLanguageTextTranslationDM'

export default class NatureName extends Data {
	public static className = 'NatureName'
	public static primaryKey = 'Id'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.Id!
	}

	TimeStamp?: string
	Text?: string
	TypeId?: number
	Id?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	LanguageTextCalc?: string
	@Type(() => TLanguageTextTranslationDM)
	LanguageTextChild?: TLanguageTextTranslationDM[]
}