import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TAddressDM from './TAddressDM'
import TK2UserLookupDM from './TK2UserLookupDM'
import TTownDM from './TTownDM'
import TTownPartDM from './TTownPartDM'
import TCountryDM from './TCountryDM'

export default class TPrEmployeeSickNoteAddressDM extends Data {
	public static className = 'TPrEmployeeSickNoteAddressDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	DateTo?: Date
	DateFrom?: Date
	PrEmployeeSickNoteRID?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	AddressIdCoordinates?: string
	AddressIdTownCode?: string
	AddressIdChanged?: number
	AddressIdName?: string
	AddressIdHouseNumber?: string
	AddressIdOrientationNumber?: string
	AddressIdStreet?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	RightGroupIdCalc?: number
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TTownDM)
	AddressIdTownId?: TTownDM
	@Type(() => TTownPartDM)
	AddressIdTownPartId?: TTownPartDM
	@Type(() => TCountryDM)
	AddressIdCountryId?: TCountryDM
}