import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TK2UserLookupDM from './TK2UserLookupDM'
import PrEmployeeSickNoteStatus from './PrEmployeeSickNoteStatus'
import TAddressDM from './TAddressDM'
import TPrEmploymentDM from './TPrEmploymentDM'
import TEmployedPersonDM from './TEmployedPersonDM'
import TTextDM from './TTextDM'
import TPeriodDM from './TPeriodDM'
import TBookDM from './TBookDM'
import TMarketingActivityTypeDM from './TMarketingActivityTypeDM'
import TTradingPartnerDM from './TTradingPartnerDM'
import TWarehouseDM from './TWarehouseDM'
import TCountryDM from './TCountryDM'
import TTownPartDM from './TTownPartDM'
import TTownDM from './TTownDM'
import TCommentDM from './TCommentDM'
import TPrEmployeeSickNoteWalkTimeOnlyDM from './TPrEmployeeSickNoteWalkTimeOnlyDM'
import TPrEmployeeSickNoteMessageDM from './TPrEmployeeSickNoteMessageDM'
import TPrEmployeeSickNoteAddressDM from './TPrEmployeeSickNoteAddressDM'
import TPrEmployeeSickNoteWalkDateDM from './TPrEmployeeSickNoteWalkDateDM'
import TOnePersonAbsenceDM from './TOnePersonAbsenceDM'
import TExternalDocumentItemDM from './TExternalDocumentItemDM'
import TLanguageMemoTranslationDM from './TLanguageMemoTranslationDM'
import TAttachedDocumentDM from './TAttachedDocumentDM'
import TOldNoteDM from './TOldNoteDM'

export default class TPrEmployeeSickNoteDM extends Data {
	public static className = 'TPrEmployeeSickNoteDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'TimeStamp',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	IsInjury?: string
	Profession?: string
	XMLField?: string
	AllowedWalksTo?: Date
	AllowedWalksFrom?: Date
	IsAddictiveSubstances?: string
	IsOtherPersonFault?: string
	IsWorkInjury?: string
	SicknessCode?: string
	DoctorName?: string
	HealthServiceName?: string
	DateTo?: Date
	VerificationDate?: Date
	DateFrom?: Date
	DecisionNumber?: string
	ConfirmedOn?: Date
	IsRecordCanceled?: string
	Number?: number
	RightGroupId?: number
	TimeStamp?: string
	ChangedOn?: Date
	CreatedOn?: Date
	RID?: number
	BookmarkImgCalc?: string
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	DocumentNumberCalc?: number
	VersionedFieldDateCalc?: Date
	ImportedDataCalc?: string
	ImportedStatusCalc?: number
	LastESickNoteMessageIdentifierCalc?: string
	CollectingResponseIntervalCalc?: number
	ImportedXmlVersionCalc?: string
	AddressesCountCalc?: number
	WalksCountCalc?: number
	PrPeriodCalc?: number
	RewritePrComponentItemCalc?: string
	IsNoPayrollCompensationCalc?: string
	IsExistsPrComponentCalc?: string
	ImportedElectronicSubmissionRIDCalc?: number
	IsPayrollCompensationCalc?: string
	TextHeaderMemoCalc?: string
	TextFooterMemoCalc?: string
	LastCommentParamCalc?: string
	OwnAddressIdCalc?: number
	OwnAddressNameCalc?: string
	DocumentIdentificationCalc?: string
	BarcodeCalc?: string
	IsReversedExchangeRateViewCalc?: string
	AddressId_ver?: number
	HealthServiceAddressId_ver?: number
	AddressIdStreetCalc?: string
	AddressIdOrientationNumberCalc?: string
	AddressIdHouseNumberCalc?: string
	AddressIdNameCalc?: string
	AddressIdChangedOnCalc?: Date
	AddressIdTownCodeCalc?: string
	AddressIdCoordinatesCalc?: string
	HealthServiceAddressIdStreetCalc?: string
	HealthServiceAddressIdOrientationNumberCalc?: string
	HealthServiceAddressIdHouseNumberCalc?: string
	HealthServiceAddressIdNameCalc?: string
	HealthServiceAddressIdChangedOnCalc?: Date
	HealthServiceAddressIdTownCodeCalc?: string
	HealthServiceAddressIdCoordinatesCalc?: string
	RightGroupIdCalc?: number
	@Type(() => TK2UserLookupDM)
	ConfirmedById?: TK2UserLookupDM
	@Type(() => PrEmployeeSickNoteStatus)
	StatusId?: PrEmployeeSickNoteStatus
	@Type(() => TAddressDM)
	HealthServiceAddressId?: TAddressDM
	@Type(() => TAddressDM)
	AddressId?: TAddressDM
	@Type(() => TPrEmploymentDM)
	PrEmploymentId?: TPrEmploymentDM
	@Type(() => TEmployedPersonDM)
	EmployedPersonId?: TEmployedPersonDM
	@Type(() => TTextDM)
	LastNotificationTypeId?: TTextDM
	@Type(() => TK2UserLookupDM)
	ChangedById?: TK2UserLookupDM
	@Type(() => TK2UserLookupDM)
	CreatedById?: TK2UserLookupDM
	@Type(() => TPeriodDM)
	BusinessYearId?: TPeriodDM
	@Type(() => TBookDM)
	BookId?: TBookDM
	@Type(() => TPeriodDM)
	BusinessYearIdCalc?: TPeriodDM
	@Type(() => TTextDM)
	ConfirmedOrCanceledIdCalc?: TTextDM
	@Type(() => TTextDM)
	ImportedNotificationTypeIdCalc?: TTextDM
	@Type(() => TTextDM)
	DocumentExistsCalc?: TTextDM
	@Type(() => TMarketingActivityTypeDM)
	ActivityTypeRIDCalc?: TMarketingActivityTypeDM
	@Type(() => TTradingPartnerDM)
	OwnTradingPartnerIdCalc?: TTradingPartnerDM
	@Type(() => TPeriodDM)
	CurrentBusinessYearIdCalc?: TPeriodDM
	@Type(() => TWarehouseDM)
	CurrentWarehouseIdCalc?: TWarehouseDM
	@Type(() => TCountryDM)
	AddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	AddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	AddressIdTownIdCalc?: TTownDM
	@Type(() => TCountryDM)
	HealthServiceAddressIdCountryIdCalc?: TCountryDM
	@Type(() => TTownPartDM)
	HealthServiceAddressIdTownPartIdCalc?: TTownPartDM
	@Type(() => TTownDM)
	HealthServiceAddressIdTownIdCalc?: TTownDM
	@Type(() => TCommentDM)
	CommentChild?: TCommentDM[]
	@Type(() => TPrEmployeeSickNoteWalkTimeOnlyDM)
	PrEmployeeSickNoteWalkTimeOnlyChild?: TPrEmployeeSickNoteWalkTimeOnlyDM[]
	@Type(() => TPrEmployeeSickNoteMessageDM)
	PrEmployeeSickNoteMessageChild?: TPrEmployeeSickNoteMessageDM[]
	@Type(() => TPrEmployeeSickNoteAddressDM)
	PrEmployeeSickNoteAddressChild?: TPrEmployeeSickNoteAddressDM[]
	@Type(() => TPrEmployeeSickNoteWalkDateDM)
	PrEmployeeSickNoteWalkDateChild?: TPrEmployeeSickNoteWalkDateDM[]
	@Type(() => TOnePersonAbsenceDM)
	PlannedAbsenceChild?: TOnePersonAbsenceDM[]
	@Type(() => TExternalDocumentItemDM)
	ExternalDocumentItemChild?: TExternalDocumentItemDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextHeaderChild?: TLanguageMemoTranslationDM[]
	@Type(() => TLanguageMemoTranslationDM)
	TextFooterChild?: TLanguageMemoTranslationDM[]
	@Type(() => TAttachedDocumentDM)
	AttachedDocumentOldChild?: TAttachedDocumentDM[]
	@Type(() => TOldNoteDM)
	DeprecatedNoteOldChild?: TOldNoteDM[]
}