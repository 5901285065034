import Data from '../Api/Data'
import PrimaryKey from '../Api/PrimaryKey'
import { Type } from 'class-transformer'
import TLocationArticleDM from './TLocationArticleDM'
import TOfficerDocumentDM from './TOfficerDocumentDM'

export default class TQueueItemDM extends Data {
	public static className = 'TQueueItemDM'
	public static primaryKey = 'RID'
	public static systemFields = [
		'RecordID',
	]

	public getPrimaryKey(): PrimaryKey {
		return this.RID!
	}

	RID?: number
	SupplementId?: number
	Percentage?: number
	Book2?: string
	DocumentRID1?: number
	Book1?: string
	RecordTypeId?: number
	RecordMod?: number
	RecordES?: number
	RecordID?: number
	RecordState?: number
	SelectionImgCalc?: string
	LegislationContextCalc?: number
	CurrentDateCalc?: Date
	CurrentTimeCalc?: Date
	@Type(() => TLocationArticleDM)
	ArticleId?: TLocationArticleDM
	@Type(() => TOfficerDocumentDM)
	DocumentRID2?: TOfficerDocumentDM
}